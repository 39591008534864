import React, { useState, useRef } from 'react';
import api from '../utils/auth';

const ATSAnalysis = ({ setFeedback }) => {
  const [loading, setLoading] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('Please upload a CV file.');
      return;
    }

    const formData = new FormData();
    formData.append('cv', file);
    formData.append('analysisType', 'ats');
    formData.append('jobDescription', jobDescription);

    try {
      setLoading(true);
      const response = await api.post('/students/upload-cv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data.feedback) {
        setFeedback(response.data.feedback);
      } else {
        throw new Error('Invalid response from server');
      }
      fileInputRef.current.value = ''; // Reset the file input to allow re-upload
    } catch (error) {
      console.error('Error uploading CV:', error);
      alert('Error uploading CV. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div className="mb-5 max-w-[600px] mx-auto">
        <textarea
          placeholder="Paste the job description here..."
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          className="w-full h-[100px] border border-primary-color rounded p-2 text-base"
        />
      </div>
      <div className="flex justify-center items-center gap-2 mb-5">
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
          disabled={!jobDescription} // Disable file upload button if no job description
        />
        <button 
          onClick={handleButtonClick} 
          disabled={loading || !jobDescription}  // Disable button if no job description
          className={`bg-primary-color text-white border-none rounded py-2 px-5 cursor-pointer transition-colors duration-300 hover:bg-primary-color-hover ${!jobDescription ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? 'Uploading...' : 'Upload CV'}
        </button>
      </div>
    </div>
  );
};

export default ATSAnalysis;