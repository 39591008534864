import React, { useEffect, useState } from 'react';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { FaCalendarAlt, FaClock, FaUser } from 'react-icons/fa';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from '../paginationControl'; // Assuming a Pagination component exists

const OneOnOneSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [timezone, setTimezone] = useState('');
  const [loading, setLoading] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSessions, setTotalSessions] = useState(0);
  const limit = 6;

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      const csrfToken = await fetchCsrfToken();
      try {
        const oneOnOneResponse = await api.get(`/my-bookings/one-on-one-upcoming?page=${currentPage}&limit=${limit}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });

        const sessions = oneOnOneResponse.data.sessions.map((session) => ({ ...session, type: 'one-on-one' }));
        sessions.sort((a, b) => new Date(a.startUTC) - new Date(b.startUTC));

        setSessions(sessions);
        setTimezone(oneOnOneResponse.data.timezone);
        setTotalSessions(oneOnOneResponse.data.totalSessions);
      } catch (error) {
        console.error('Error fetching one-on-one sessions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [currentPage]); // Refetch sessions when currentPage changes

  const formatSessionDate = (utcDate) => {
    if (!timezone) return 'Loading...';
    return moment(utcDate).tz(timezone).format('dddd, MMMM Do, YYYY');
  };

  const formatSessionTime = (startUTC, endUTC) => {
    if (!timezone) return 'Loading...';
    const startTime = moment(startUTC).tz(timezone).format('hh:mm A');
    const endTime = moment(endUTC).tz(timezone).format('hh:mm A');
    return `${startTime} - ${endTime}`;
  };

  const renderSession = (session) => (
    <div key={session._id} className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className="absolute left-4 top-3 bottom-3 w-1 bg-green-500"></div>
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-center mb-4">
          <div className="text-lg font-bold text-primary-color">
            {session.instructorId.firstName} {session.instructorId.lastName}
          </div>
          {session.pending && (
            <div className="flex items-center bg-red-400 text-white py-1 px-3 rounded-full">
              <FaClock className="mr-2" /> Pending
            </div>
          )}
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaCalendarAlt className="mr-2 text-primary-color" />
          <span>{formatSessionDate(session.startUTC)}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaClock className="mr-2 text-primary-color" />
          <span>{formatSessionTime(session.startUTC, session.endUTC)}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaUser className="mr-2 text-primary-color" />
          <span>One-on-One Session</span>
        </div>
      </div>
    </div>
  );

  const renderSkeletonLoader = () => (
    <div className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-start mb-2">
          <Skeleton width={200} />
        </div>
        <div className="flex flex-col justify-between mt-2">
          <Skeleton width={150} height={20} className="mb-2" />
          <Skeleton width={100} height={20} />
        </div>
      </div>
    </div>
  );

  const totalPages = Math.ceil(totalSessions / limit); // Calculate total pages

  return (
    <div>
    <div className="max-h-[575px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
      {loading
        ? Array(6)
            .fill(0)
            .map((_, index) => <div key={index}>{renderSkeletonLoader()}</div>)
        : sessions.map((session) => renderSession(session))}
      
      
    </div>
    {/* Pagination Controls */}
    {!loading && totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      )}
    </div>
  );
};

export default OneOnOneSessions;
