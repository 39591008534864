import React from 'react';
import styles from './AdminNavbar.module.css';

const AdminNavBar = ({ setActiveComponent }) => {
  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        <li className={styles.navItem} onClick={() => setActiveComponent('Analytics')}>Analytics</li> {/* New Tab */}
        <li className={styles.navItem} onClick={() => setActiveComponent('UserManagement')}>User Management</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('SessionsRequest')}>Sessions Request</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('ApproveInstructor')}>Approve Mentor</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('InstructorServices')}>Mentor Services</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('VideoRecordings')}>Video Records</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('Reports')}>Reports</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('CreateChallenge')}>Create Challenge</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('ManageChallenges')}>Manage Challenges</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('ChallengeSubmissions')}>Challenge Submissions</li>
        <li className={styles.navItem} onClick={() => setActiveComponent('OneOnOneSessions')}>One-on-One Sessions</li> {/* New Tab */}
        <li className={styles.navItem} onClick={() => setActiveComponent('Logout')}>Logout</li>
      </ul>
    </nav>
  );
};

export default AdminNavBar;
