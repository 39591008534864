import React from 'react';

// Helper function to clean up markdown-style data (remove '**', etc.)
const cleanText = (text) => {
  if (!text) return ''; // Guard against undefined or null values
  return text.replace(/\*\*/g, '')
             .replace(/^\d+\.\s*/g, '') // Remove leading numbers
             .replace(/\d+\.$/g, '')    // Remove trailing numbers like "2.", "3."
             .replace(/[-–—]/g, '')     // Remove hyphens or dashes
             .trim();                   // Trim whitespace
};

// Component to display the ATS analysis results
const ATSAnalysisResults = ({ feedback }) => {
  if (!feedback) {
    return <p>No ATS analysis data available. Upload a CV first.</p>;
  }

  const { jobDescription, resumeSummary, detailedAnalysis } = feedback;

  // Handle empty bullets (ignore empty items)
  const renderListItems = (items) => {
    return items
      .filter((item) => cleanText(item)) // Filter out empty items
      .map((item, index) => <li key={index}>{cleanText(item)}</li>);
  };

  return (
    <div className="mt-5">
      <div className="bg-white p-5 shadow rounded-lg">
        <h2 className="text-xl font-bold mb-4">ATS Analysis Results</h2>

        {/* Job Description Summary */}
        {jobDescription && jobDescription !== 'No data available' && (
          <div className="mb-5">
            <h3 className="font-semibold">Job Description Summary</h3>
            <ul className="list-disc ml-5">
              {renderListItems(jobDescription.split('\n'))}
            </ul>
          </div>
        )}

        {/* Resume Summary */}
        {resumeSummary && resumeSummary !== 'No data available' && (
          <div className="mb-5">
            <h3 className="font-semibold">Resume Summary</h3>
            <ul className="list-disc ml-5">
              {renderListItems(resumeSummary.split('\n'))}
            </ul>
          </div>
        )}

        {/* Detailed Analysis */}
        {Array.isArray(detailedAnalysis) && detailedAnalysis.length > 0 && (
          <div className="mb-5">
            <h3 className="font-semibold">Detailed Analysis</h3>
            <table className="table-auto w-full text-left border">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Category</th>
                  <th className="border px-4 py-2">Explanation</th>
                </tr>
              </thead>
              <tbody>
                {detailedAnalysis.map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{cleanText(item.category)}</td>
                    <td className="border px-4 py-2">{cleanText(item.explanation)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ATSAnalysisResults;