import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { fetchCsrfToken } from '../../utils/csrf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import api from '../../utils/auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for React Quill
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './SessionsRequest.module.css';
import moment from 'moment'; // Import moment.js

const allSkills = [
  "Career Development Coaching", "Life Coaching", "Interview Preparation", "CV Review", "One-to-one support",
  "Cover Letters", "Profile Assessment", "Job Interview preparation", "Mock interview", "LinkedIn Networking",
  "Personal Branding", "Entrepreneurship Guidance", "Sponsored Job-Searching", "Accountability coach",
  "Deciding my Career Path", "Confidence Building", "Business in UK", "Global Talent Route", "Innovator Founder Route",
  "Networking Strategies", "Work Visa Guidance", "Start-up Advice", "Cultural Adaptation", "Industry Insights",
  "Professional Etiquette", "Goal Setting", "Time Management", "Stress Management", "Work-Life Balance",
  "Professional Certifications", "Business Development", "Sales Strategies", "Negotiation Skills",
  "Leadership Training", "Team Management", "Conflict Resolution", "Presentation Skills",
  "Effective Communication", "Digital Marketing Strategies", "SEO Optimization", "E-commerce Strategies",
  "Project Planning", "Risk Management", "Financial Planning", "Investment Strategies", "Budget Management",
  "Market Analysis", "Product Development", "Innovation Coaching", "Software Development", "IT Security Advice",
  "Data Analytics", "Machine Learning Insights", "Artificial Intelligence Trends", "Cloud Computing",
  "Mobile Development", "User Experience Design", "Graphic Design Tips", "Freelancing scaling",
  "Job Contract Negotiation", "Public Speaking", "Research Methodologies", "Graduate School Applications",
  "Postdoctoral Opportunities", "PhD proposals and admission", "MRes admissions"
];

const predefinedSessionGoals = [
  "Improve communication skills", "Enhance leadership abilities", "Gain technical expertise",
  "Develop problem-solving skills", "Learn time management", "Understand project management",
  "Master financial planning", "Acquire marketing strategies", "Enhance team collaboration",
  "Build self-confidence", "Legal Consultation", "Job Finding Assistance"
];

const SessionRequest = () => {
  const [isChangeInstructorModalOpen, setIsChangeInstructorModalOpen] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [newInstructorId, setNewInstructorId] = useState('');
  const [instructors, setInstructors] = useState([]);
  const [instructorSearchTerm, setInstructorSearchTerm] = useState('');
  const [filteredInstructors, setFilteredInstructors] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [sessionSearchTerm, setSessionSearchTerm] = useState('');
  const [selectedSession, setSelectedSession] = useState(null);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [loadingSessions, setLoadingSessions] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false); // Loading state for image upload
  const [loadingSave, setLoadingSave] = useState(false); // Loading state for session save
  const [formState, setFormState] = useState({
    sessionName: '',
    date: '',
    availability: {
      fromHour: '12',
      fromPeriod: 'PM',
      toHour: '12',
      toPeriod: 'PM',
    },
    skills: [],
    description: '',
    image: null,
    searchTerm: '',
    sessionGoals: [],
    prerequisites: ''
  });

  const dropdownRef = useRef(null);

  const handleInputFocus = () => {
    setIsDropdownVisible(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest(`.${styles.input}`)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openChangeInstructorModal = (session) => {
    setCurrentSession(session);
    setIsChangeInstructorModalOpen(true);
    fetchInstructors();
  };

  const fetchInstructors = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.get('/admin/instructors', {
        headers: { 'CSRF-Token': csrfToken },
      });
      setInstructors(response.data);
      setFilteredInstructors(response.data);
    } catch (err) {
      console.error('Error fetching instructors:', err);
    }
  };

  const handleChangeInstructor = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      await api.patch('/admin/session/change-instructor', {
        sessionId: currentSession._id,
        newInstructorId: newInstructorId
      }, {
        headers: { 'CSRF-Token': csrfToken },
      });

      Swal.fire('Success!', 'Mentor changed successfully.', 'success');
      fetchSessions();

      setIsChangeInstructorModalOpen(false);
    } catch (err) {
      console.error('Error changing Mentor:', err);
      Swal.fire('Error!', 'There was an error changing the Mentor.', 'error');
    }
  };

  const fetchSessions = async () => {
    try {
      setLoadingSessions(true);
      const csrfToken = await fetchCsrfToken();
      const response = await api.get('/admin/unverified-sessions', {
        headers: { 'CSRF-Token': csrfToken },
      });
      setSessions(response.data);
    } catch (err) {
      console.error('Error fetching sessions:', err);
    } finally {
      setLoadingSessions(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    if (formState.searchTerm) {
      setFilteredSkills(
        allSkills
          .filter(skill =>
            skill.toLowerCase().includes(formState.searchTerm.toLowerCase())
          )
          .slice(0, 5)
      );
    } else {
      setFilteredSkills([]);
    }
  }, [formState.searchTerm]);

  useEffect(() => {
    if (formState.searchTerm) {
      setFilteredGoals(
        predefinedSessionGoals
          .filter(goal =>
            goal.toLowerCase().includes(formState.searchTerm.toLowerCase())
          )
          .slice(0, 5)
      );
    } else {
      setFilteredGoals([]);
    }
  }, [formState.searchTerm]);

  const handleAddSkill = skill => {
    if (formState.skills.length < 5 && !formState.skills.includes(skill)) {
      setFormState(prevState => ({
        ...prevState,
        skills: [...prevState.skills, skill],
        searchTerm: ''
      }));
    }
  };

  const handleRemoveSkill = skill => {
    setFormState(prevState => ({
      ...prevState,
      skills: prevState.skills.filter(s => s !== skill)
    }));
  };

  const handleAddGoal = goal => {
    if (!formState.sessionGoals.includes(goal)) {
      setFormState(prevState => ({
        ...prevState,
        sessionGoals: [...prevState.sessionGoals, goal]
      }));
    }
  };

  const handleRemoveGoal = goal => {
    setFormState(prevState => ({
      ...prevState,
      sessionGoals: prevState.sessionGoals.filter(g => g !== goal)
    }));
  };

  const handleApprove = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to approve this session?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.patch(`/admin/session/${id}/approve`, null, {
            headers: { 'CSRF-Token': csrfToken },
          });
          setSessions(sessions.filter(session => session._id !== id));
          Swal.fire('Approved!', 'The session has been approved.', 'success');
        } catch (err) {
          console.error('Error approving session:', err);
          Swal.fire('Error!', 'There was an error approving the session.', 'error');
        }
      }
    });
  };

  const handleDisapprove = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to disapprove this session?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, disapprove it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.patch(`/admin/session/${id}/disapprove`, null, {
            headers: { 'CSRF-Token': csrfToken },
          });
          setSessions(sessions.filter(session => session._id !== id));
          Swal.fire('Disapproved!', 'The session has been disapproved.', 'success');
        } catch (err) {
          console.error('Error disapproving session:', err);
          Swal.fire('Error!', 'There was an error disapproving the session.', 'error');
        }
      }
    });
  };

  const handlePending = async (id, isPending) => {
    Swal.fire({
      title: 'Are you sure?',
      text: isPending ? 'Do you want to resolve this session from pending status?' : 'Do you want to put this session in pending status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: isPending ? 'Yes, resolve it!' : 'Yes, set as pending!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          const route = isPending ? `/admin/session/${id}/resolve-pending` : `/admin/session/${id}/pending`;
          await api.patch(route, null, {
            headers: { 'CSRF-Token': csrfToken },
          });

          setSessions(sessions.map(session => session._id === id ? { ...session, pending: !isPending } : session));

          Swal.fire(isPending ? 'Resolved!' : 'Pending!', isPending ? 'The session has been resolved from pending.' : 'The session has been set to pending.', 'success');
        } catch (err) {
          console.error('Error updating session pending status:', err);
          Swal.fire('Error!', 'There was an error updating the session pending status.', 'error');
        }
      }
    });
  };

  const handleEdit = (session) => {
    const startTime = moment(session.availability.start);
    const endTime = moment(session.availability.end);
    const fromHour = startTime.format('hh');
    const fromPeriod = startTime.format('A');
    const toHour = endTime.format('hh');
    const toPeriod = endTime.format('A');
  
    setSelectedSession(session);
    setFormState({
      sessionName: session.sessionName,
      date: new Date(session.date).toISOString().split('T')[0],
      availability: {
        fromHour,
        fromPeriod,
        toHour,
        toPeriod,
      },
      skills: session.skills,
      description: session.description,
      image: session.imagePath ? `${session.imagePath}` : null,
      searchTerm: '',
      sessionGoals: session.sessionGoals || [],
      prerequisites: session.prerequisites || ''
    });
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormState((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setLoadingImage(true); // Start loading when the file upload starts
      reader.onload = event => {
        setFormState(prevState => ({
          ...prevState,
          image: event.target.result
        }));
        setLoadingImage(false); // Stop loading after file is loaded
      };
      reader.readAsDataURL(file);
    }
  };

  const convertToUTC = (date, hour, period) => {
    const timeString = `${date} ${hour} ${period}`;
    return moment(timeString, 'YYYY-MM-DD hh A').local().utc().toDate();
  };

  const handleSave = async () => {
    try {
      setLoadingSave(true); // Start loading when saving session
      const csrfToken = await fetchCsrfToken();

      const startUTC = convertToUTC(formState.date, formState.availability.fromHour, formState.availability.fromPeriod);
      const endUTC = convertToUTC(formState.date, formState.availability.toHour, formState.availability.toPeriod);

      const sessionData = {
        sessionName: formState.sessionName,
        date: formState.date,
        availability: {
          start: startUTC,
          end: endUTC,
        },
        skills: formState.skills,
        description: formState.description,
        image: formState.image,
        sessionGoals: formState.sessionGoals,
        prerequisites: formState.prerequisites,
      };

      await api.patch(`/admin/session/${selectedSession._id}/edit`, sessionData, {
        headers: { 'CSRF-Token': csrfToken },
      });

      setSessions((prevState) =>
        prevState.map((session) =>
          session._id === selectedSession._id ? { ...session, ...sessionData } : session
        )
      );
      setSelectedSession(null);
      fetchSessions();

      Swal.fire('Success!', 'The session has been updated.', 'success');
    } catch (err) {
      console.error('Error updating session:', err);
      Swal.fire('Error!', 'There was an error updating the session.', 'error');
    } finally {
      setLoadingSave(false); // Stop loading after save is complete
    }
  };

  const renderTimeSelect = (fieldHour, fieldPeriod) => (
    <div className={styles.timeSelectContainer}>
      <select
        name={`availability.${fieldHour}`}
        value={formState.availability[fieldHour]}
        onChange={handleChange}
        className={styles.timeSelect}
      >
        {Array.from({ length: 12 }, (_, i) => {
          const hourValue = (i + 1).toString().padStart(2, '0');
          const isToField = fieldHour.includes('toHour');
          const fromHour = parseInt(formState.availability.fromHour, 10);
          const fromPeriod = formState.availability.fromPeriod;
          
          if (isToField && (
            (fromPeriod === 'AM' && hourValue <= fromHour) ||
            (fromPeriod === 'PM' && ((hourValue <= fromHour && hourValue !== '12') || (hourValue === '12')))
          )) {
            return null;
          }

          return (
            <option key={i} value={hourValue}>
              {hourValue}
            </option>
          );
        })}
      </select>
      <select
        name={`availability.${fieldPeriod}`}
        value={formState.availability[fieldPeriod]}
        onChange={handleChange}
        className={styles.timeSelect}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );

  return (
    <div className={styles.container}>
      <h2>Admin Session Management</h2>
      <input
        type="text"
        placeholder="Search sessions..."
        value={sessionSearchTerm}
        onChange={(e) => setSessionSearchTerm(e.target.value)}
        className={styles.searchBar}
      />
      {loadingSessions ? (
        <div className={styles.loader}>
          <ClipLoader color={'#123abc'} loading={loadingSessions} size={50} />
        </div>
      ) : (
        <>
          {sessions.length === 0 ? (
            <p>No session requests right now.</p>
          ) : (
            <ul className={styles.list}>
              {sessions.map(session => (
                <li key={session._id} className={styles.listItem}>
                  <div className={styles.sessionInfo}>
                    <p>{session.instructorId.firstName} {session.instructorId.lastName}</p>
                    <p className={styles.sessionName}>{session.sessionName}</p>
                    {!session.instructorId.accessGranted && (
                      <p className={styles.revokedAccess}>Mentor access is revoked. Take action.</p>
                    )}
                  </div>
                  <div className={styles.buttonGroup}>
                    <button onClick={() => handleEdit(session)} className={styles.editButton}>Edit</button>
                    <button onClick={() => handleApprove(session._id)} className={styles.approveButton}>Approve</button>
                    <button onClick={() => handleDisapprove(session._id)} className={styles.disapproveButton}>Disapprove</button>
                    <button onClick={() => openChangeInstructorModal(session)} className={styles.changeInstructorButton}>Change Mentor</button>
                    <button onClick={() => handlePending(session._id, session.pending)} className={styles.pendingButton}>
                      {session.pending ? 'Resolve' : 'Pending'}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}

      {selectedSession && (
        <Modal
          isOpen={!!selectedSession}
          onRequestClose={() => setSelectedSession(null)}
          contentLabel="Edit Session"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <div className={styles.userDetails}>
            <h3>Edit Session</h3>
            <div className={styles.inputGroup}>
              <label htmlFor="sessionName" className={styles.modalHeadings}>Session Name:</label>
              <input
                type="text"
                name="sessionName"
                value={formState.sessionName}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="date" className={styles.modalHeadings}>Date:</label>
              <input
                type="date"
                name="date"
                value={formState.date}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="time" className={styles.modalHeadings}>Time:</label>
              <div className={styles.timeContainer}>
                {renderTimeSelect('fromHour', 'fromPeriod')}
                <span>to</span>
                {renderTimeSelect('toHour', 'toPeriod')}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="skills" className={styles.modalHeadings}>Skills:</label>
              <div className={styles.searchContainer}>
                <input
                  type="text"
                  name="searchTerm"
                  placeholder="Search for a skill..."
                  value={formState.searchTerm}
                  onChange={handleChange}
                  className={styles.searchInput}
                  onFocus={handleInputFocus}
                />
                {filteredSkills.length > 0 && (
                  <div className={styles.dropdown} ref={dropdownRef}>
                    {filteredSkills.map(skill => (
                      <div
                        key={skill}
                        className={styles.dropdownItem}
                        onClick={() => handleAddSkill(skill)}
                      >
                        {skill}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.addedSkills}>
                {formState.skills.map(skill => (
                  <div
                    key={skill}
                    className={`${styles.skill} ${styles.addedSkill}`}
                    onClick={() => handleRemoveSkill(skill)}
                  >
                    {skill}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="sessionGoals" className={styles.modalHeadings}>Session Goals:</label>
              <div className={styles.searchContainer}>
                <select
                  value=""
                  onChange={e => handleAddGoal(e.target.value)}
                  className={styles.searchInput}
                >
                  <option value="" disabled>Select a goal...</option>
                  {predefinedSessionGoals.map(goal => (
                    <option key={goal} value={goal}>
                      {goal}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.addedSkills}>
                {formState.sessionGoals.map(goal => (
                  <div
                    key={goal}
                    className={`${styles.skill} ${styles.addedSkill}`}
                    onClick={() => handleRemoveGoal(goal)}
                  >
                    {goal}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="description" className={styles.modalHeadings}>Description:</label>
              <ReactQuill
                value={formState.description}
                onChange={(value) => setFormState(prevState => ({ ...prevState, description: value }))}
                className={styles.quill} // Apply quill class here
                theme="snow"
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="prerequisites" className={styles.modalHeadings}>Prerequisites:</label>
              <textarea
                name="prerequisites"
                value={formState.prerequisites}
                onChange={handleChange}
                className={styles.textarea}
                rows="3"
              ></textarea>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="imageUpload" className={styles.modalHeadings}>Upload Image:</label>
              <div className={styles.imageUploadContainer}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className={styles.imageInput}
                  id="imageUpload"
                />
                <label htmlFor="imageUpload" className={styles.imageUploadLabel}>
                  {loadingImage ? (
                    <ClipLoader color={'#123abc'} loading={loadingImage} size={24} />
                  ) : (
                    <>
                      {formState.image ? (
                        <>
                          <img
                            src={formState.image}
                            alt="Preview"
                            className={styles.imagePreview}
                            style={{ backgroundImage: `url(${formState.image})` }}
                          />
                          <FontAwesomeIcon
                            icon={faUpload}
                            className={styles.uploadIcon}
                          />
                        </>
                      ) : (
                        <span>Add an image</span>
                      )}
                    </>
                  )}
                </label>
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <button onClick={handleSave} className={styles.saveButton} disabled={loadingSave}>
                {loadingSave ? (
                  <ClipLoader color={'#fff'} loading={loadingSave} size={20} />
                ) : (
                  'Save'
                )}
              </button>
              <button onClick={() => setSelectedSession(null)} className={styles.closeButton}>Cancel</button>
            </div>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={isChangeInstructorModalOpen}
        onRequestClose={() => setIsChangeInstructorModalOpen(false)}
        contentLabel="Change Mentor"
        className={styles.modalChange}
        overlayClassName={styles.overlay}
      >
        <div className={styles.userDetails}>
          <h2>Change Mentor</h2>
          <h3 className={styles.currentIns}>Current Mentor: {currentSession?.instructorId.firstName} {currentSession?.instructorId.lastName} ({currentSession?.instructorId.email})</h3>
          <div className={styles.inputGroup}>
            <label htmlFor="newInstructor" className={styles.modalHeadings}>Select New Mentor:</label>
            <input
              type="text"
              placeholder="Search for a Mentor..."
              value={instructorSearchTerm}
              onFocus={handleInputFocus}
              onChange={(e) => setInstructorSearchTerm(e.target.value)}
              className={styles.input}
            />
            {isDropdownVisible && filteredInstructors.length > 0 && (
              <div className={styles.dropdown} ref={dropdownRef}>
                {filteredInstructors.map(instructor => (
                  <div
                    key={instructor._id}
                    className={styles.dropdownItem}
                    onClick={() => {
                      setNewInstructorId(instructor._id);
                      setInstructorSearchTerm(`${instructor.firstName} ${instructor.lastName} (${instructor.email})`);
                      setIsDropdownVisible(false);
                    }}
                  >
                    {instructor.firstName} {instructor.lastName} ({instructor.email})
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.buttonGroup}>
            <button onClick={handleChangeInstructor} className={styles.saveButton}>Save</button>
            <button onClick={() => setIsChangeInstructorModalOpen(false)} className={styles.closeButton}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SessionRequest;