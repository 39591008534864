import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faArrowLeft, faLock } from '@fortawesome/free-solid-svg-icons';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import api from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';

const SessionPaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mentorship, instructor, selectedDate, selectedHour, studentTimezone, useFreeSession } = state;
  const [clientSecret, setClientSecret] = useState('');
  const [studentId, setStudentId] = useState('');
  const [studentName, setStudentName] = useState('');
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false); // New state for button loading
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await api.get('/user/user-role');
        setStudentId(response.data.id);
        setStudentName(`${response.data.firstName} ${response.data.lastName}`);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching student details:', error);
        setError('Error fetching student details');
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, []);

  useEffect(() => {
    if (!useFreeSession && studentId && studentName) {
      const createPaymentIntent = async () => {
        try {
          const response = await api.post('/payment_intents', {
            amount: mentorship.price * 100,
            studentId: studentId,
            instructorId: instructor._id,
            studentName: studentName,
            instructorName: `${instructor.firstName} ${instructor.lastName}`,
            type: 'one-on-one-session',
          });

          setClientSecret(response.data.clientSecret);
          setLoading(false);
        } catch (error) {
          console.error('Error creating payment intent:', error);
          setError('Error creating payment intent');
          setLoading(false);
        }
      };

      createPaymentIntent();
    } else {
      setLoading(false);
    }
  }, [studentId, studentName, mentorship.price, instructor, useFreeSession]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true); // Start button loading

    if (useFreeSession) {
      try {
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
        const studentDateTimeStart = moment.tz(`${formattedDate} ${String(selectedHour.start).padStart(2, '0')}:00:00`, studentTimezone);
        const utcDateTimeStart = studentDateTimeStart.clone().utc().format();

        const studentDateTimeEnd = moment.tz(`${formattedDate} ${String(selectedHour.end).padStart(2, '0')}:00:00`, studentTimezone);
        const utcDateTimeEnd = studentDateTimeEnd.clone().utc().format();

        await api.post('/update_session', {
          studentId: studentId,
          instructorId: instructor._id,
          startUTC: utcDateTimeStart,
          endUTC: utcDateTimeEnd,
          isFreeSession: true,
        });

        Swal.fire('Booking Successful', 'Your free session has been booked!', 'success');
        navigate('/student/dashboard');
      } catch (error) {
        console.error('Error booking free session:', error);
        Swal.fire('Booking Failed', `There was an issue booking your session. Please try again. ${error.message}`, 'error');
      } finally {
        setButtonLoading(false); // Stop button loading
      }
    } else {
      if (!stripe || !elements) {
        setButtonLoading(false); // Stop button loading if stripe or elements not ready
        return;
      }

      const confirm = await Swal.fire({
        title: 'Confirm Payment',
        text: `Are you sure you want to pay £${mentorship.price} for this session?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, pay now!',
      });

      if (confirm.isConfirmed) {
        const cardElement = elements.getElement(CardElement);

        try {
          const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: studentName,
              },
            },
          });

          if (error) {
            Swal.fire('Payment Failed', `There was an issue processing your payment: ${error.message}`, 'error');
          } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            Swal.fire('Payment Successful', 'Your payment was successful!', 'success');

            const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
            const studentDateTimeStart = moment.tz(`${formattedDate} ${String(selectedHour.start).padStart(2, '0')}:00:00`, studentTimezone);
            const utcDateTimeStart = studentDateTimeStart.clone().utc().format();

            const studentDateTimeEnd = moment.tz(`${formattedDate} ${String(selectedHour.end).padStart(2, '0')}:00:00`, studentTimezone);
            const utcDateTimeEnd = studentDateTimeEnd.clone().utc().format();

            await api.post('/update_session', {
              studentId: studentId,
              instructorId: instructor._id,
              startUTC: utcDateTimeStart,
              endUTC: utcDateTimeEnd,
              paymentIntentId: paymentIntent.id,
            });

            navigate('/student/dashboard');
          }
        } catch (error) {
          console.error('Error confirming card payment:', error);
          Swal.fire('Payment Failed', `There was an issue processing your payment. Please try again. ${error.message}`, 'error');
        } finally {
          setButtonLoading(false); // Stop button loading
        }
      } else {
        setButtonLoading(false); // Stop button loading if user cancels
      }
    }
  };

  const formatDate = (date) => {
    return moment(date).tz(studentTimezone).format('dddd, MMMM Do, YYYY');
  };

  const formatTime = (hour) => {
    const startHour = hour.start % 12 || 12;
    const endHour = hour.end % 12 || 12;
    const startPeriod = hour.start < 12 ? 'AM' : 'PM';
    const endPeriod = hour.end < 12 ? 'AM' : 'PM';
    return `${startHour} ${startPeriod} - ${endHour} ${endPeriod}`;
  };

  return (
    <div className="max-w-lg mx-auto mt-16 p-8 border border-gray-300 rounded-lg bg-white text-center shadow-lg relative">
      <button
        className="absolute top-5 left-5 text-[#107F9A] hover:text-[#055a79] flex items-center font-semibold"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
      </button>
      <h2 className="text-2xl font-bold mb-5 text-gray-800">Complete Your {useFreeSession ? 'Free' : 'Paid'} Session Booking</h2>
      <p className="text-base font-normal text-gray-600 mb-5">
        You are about to book a one-on-one session with <strong>{instructor.firstName} {instructor.lastName}</strong> on{' '}
        <strong>{formatDate(selectedDate)}</strong> at <strong>{formatTime(selectedHour)}</strong>.
      </p>
      {useFreeSession ? (
        <p className="text-xl font-semibold text-green-600 mb-8">This session will be booked as a free session.</p>
      ) : (
        <p className="text-xl font-semibold text-[#107F9A] mb-8">Total: £{mentorship.price}</p>
      )}
      <div className="flex items-center justify-center mb-5 text-sm text-gray-600">
        <FontAwesomeIcon icon={faLock} className="text-[#107F9A] mr-2" />
        <span>Your payment is securely processed by Stripe.</span>
      </div>
      {error && <div className="text-red-500 mb-5">{error}</div>}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <form onSubmit={handleSubmit} className="mt-5">
          {!useFreeSession && (
            <div className="mb-5 p-3 border border-gray-300 rounded-md bg-gray-100 transition-colors duration-300">
              <CardElement options={{ hidePostalCode: true }} className="p-2" />
            </div>
          )}
          <button
            type="submit"
            className="bg-[#107F9A] text-white py-3 px-5 rounded-md font-semibold transition-colors duration-300 hover:bg-[#106f8a] disabled:bg-gray-300 disabled:cursor-not-allowed"
            disabled={loading || (!useFreeSession && !clientSecret) || buttonLoading} // Disable button when loading
          >
            {buttonLoading ? (
              <span className="flex items-center justify-center">
                {useFreeSession ? 'Booking...' : 'Processing...'}
                <span className="ml-2 loader animate-spin inline-block w-4 h-4 border-4 rounded-full"></span>
              </span>
            ) : (
              useFreeSession ? 'Book Free Session' : 'Pay Now'
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default SessionPaymentPage;
