import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';
import useInstructorStore from '../../store/useInstructorStore';
import { fetchCsrfToken } from '../../utils/csrf'; // Import your CSRF utility
import api from '../../utils/auth'; // Import your API helper

const Experience = () => {
  const { instructorData, setInstructorData } = useInstructorStore();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentExperienceId, setCurrentExperienceId] = useState(null);
  const [jobTitle, setJobTitle] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [industryType, setIndustryType] = useState('');
  const [jobLevel, setJobLevel] = useState('');
  const [experiences, setExperiences] = useState(instructorData.experiences || []);
  const [errors, setErrors] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const industryOptions = [
    'Tech', 'Education', 'Finance', 'Healthcare', 'Engineering', 'Marketing', 'Sales', 'Manufacturing',
    'Retail', 'Construction', 'Transportation', 'Hospitality', 'Telecommunications', 'Agriculture',
    'Real Estate', 'Energy', 'Government', 'Nonprofit', 'Media', 'Entertainment', 'Law', 'Consulting',
    'Pharmaceuticals', 'Biotechnology', 'Insurance', 'Food and Beverage', 'Aerospace', 'Automotive',
    'Environmental Services', 'Logistics', 'Public Services', 'Sports', 'Arts and Design', 'Other'
  ];

  const jobLevelOptions = ['Beginner', 'Intermediate', 'Expert'];

  const validateForm = () => {
    const newErrors = {};
    if (!jobTitle) newErrors.jobTitle = true;
    if (!from) newErrors.from = true;
    if (!companyName) newErrors.companyName = true;
    if (!currentlyWorking && !to) newErrors.to = true;
    if (!industryType) newErrors.industryType = true;
    if (!jobLevel) newErrors.jobLevel = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addExperience = async () => {
    if (!validateForm()) return;

    const newExperience = {
      jobTitle,
      from,
      to,
      currentlyWorking,
      companyName,
      jobDescription,
      industryType,
      jobLevel
    };

    setLoadingSubmit(true);
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.post(`/instructorsExperience/experience`, newExperience, {
        headers: { 'CSRF-Token': csrfToken }
      });
      const updatedExperiences = [...experiences, response.data];
      setInstructorData({
        ...instructorData,
        experiences: updatedExperiences,
      });
      setExperiences(updatedExperiences);
      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error adding experience:', error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const updateExperience = async () => {
    if (!validateForm()) return;

    const updatedExperience = {
      jobTitle,
      from,
      to,
      currentlyWorking,
      companyName,
      jobDescription,
      industryType,
      jobLevel
    };

    setLoadingSubmit(true);
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.put(`/instructorsExperience/experience/${currentExperienceId}`, updatedExperience, {
        headers: { 'CSRF-Token': csrfToken }
      });
      const updatedExperiences = experiences.map(exp => exp._id === currentExperienceId ? response.data : exp);
      setInstructorData({
        ...instructorData,
        experiences: updatedExperiences,
      });
      setExperiences(updatedExperiences);
      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error updating experience:', error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const deleteExperience = async (id) => {
    try {
      const csrfToken = await fetchCsrfToken();
      await api.delete(`/instructorsExperience/experience/${id}`, {
        headers: { 'CSRF-Token': csrfToken }
      });
      const updatedExperiences = experiences.filter(exp => exp._id !== id);
      setInstructorData({
        ...instructorData,
        experiences: updatedExperiences,
      });
      setExperiences(updatedExperiences);
    } catch (error) {
      console.error('Error deleting experience:', error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this experience?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteExperience(id);
        swal("Deleted!", "Your experience has been deleted.", "success");
      } else {
        swal("Cancelled", "Your experience is safe.", "error");
      }
    });
  };

  const handleEdit = (experience) => {
    setIsEdit(true);
    setCurrentExperienceId(experience._id);
    setJobTitle(experience.jobTitle);
    setFrom(experience.from);
    setTo(experience.to);
    setCurrentlyWorking(experience.currentlyWorking);
    setCompanyName(experience.companyName);
    setJobDescription(experience.jobDescription);
    setIndustryType(experience.industryType);
    setJobLevel(experience.jobLevel);
    setModalIsOpen(true);
  };

  const handleAdd = () => {
    setIsEdit(false);
    clearForm();
    setModalIsOpen(true);
  };

  const clearForm = () => {
    setJobTitle('');
    setFrom('');
    setTo('');
    setCurrentlyWorking(false);
    setCompanyName('');
    setJobDescription('');
    setIndustryType('');
    setJobLevel('');
    setErrors({});
  };

  const handleSave = () => {
    if (isEdit) {
      updateExperience();
    } else {
      addExperience();
    }
  };

  const formatDate = (date) => {
    if (!date) return 'Present';
    return moment(date).format('MMMM YYYY');
  };

  return (
    <div className="max-w-[700px] p-5">
      <>
        <div className="flex flex-col md:flex-row justify-between mb-6">
          <div className="mb-4 md:mb-0">
            <div className="text-2xl font-bold">
              Work experience
            </div>
            <p className="text-sm text-gray-600 mb-5">
              Past experience proves your work and skills.
            </p>
          </div>
          <button className="bg-white text-[var(--primary-color)] text-[14px] font-normal border border-[var(--primary-color)] px-6 py-3 cursor-pointer rounded-[8px] mb-4 md:mb-0 hover:bg-[var(--primary-color-hover)] transition delay-3000 hover:text-white" onClick={handleAdd}>
            + Add Work Experience
          </button>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="absolute max-h-[90vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 box-border rounded-[8px] outline-none w-full md:w-[600px] max-w-[90%] m-auto overflow-y-auto"
          overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.75)] z-[3000]"
          contentLabel="Add Experience"
        >
          <div className="mt-2 flex justify-between items-center border-b border-[#ddd] pb-2">
            <div>
              <h2 className="m-0">{isEdit ? "Edit Work Experience" : "Add Work Experience"}</h2>
            </div>
            <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => setModalIsOpen(false)} />
          </div>
          <form className="mt-4">
            <div className="flex flex-col md:flex-row gap-4 flex-wrap">
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>Enter job title</label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.jobTitle ? 'border-red-500' : ''}`}
                />
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>From</label>
                <input
                  type="date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.from ? 'border-red-500' : ''}`}
                />
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>To</label>
                <input
                  type="date"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  disabled={currentlyWorking}
                  className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.to ? 'border-red-500' : ''} ${currentlyWorking ? 'bg-[#f0f0f0] cursor-not-allowed' : ''}`}
                />
              </div>
            </div>
            <div className="flex items-center mb-4">
              <label className="flex items-center text-[14px]">
                <input type="checkbox" checked={currentlyWorking} onChange={(e) => setCurrentlyWorking(e.target.checked)} className="mr-2" />
                Currently working here
              </label>
            </div>

            <div className="flex-1 flex flex-col mb-4 box-border w-full">
              <label>Enter Company's name</label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.companyName ? 'border-red-500' : ''}`}
              />
            </div>
            <div className="flex-1 flex flex-col mb-4 box-border w-full">
              <label>Enter job description</label>
              <textarea
                rows={4}
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                className="mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full"
              ></textarea>
            </div>
            <div className="flex-1 flex flex-col mb-4 box-border w-full">
              <label>Industry Type</label>
              <select
                value={industryType}
                onChange={(e) => setIndustryType(e.target.value)}
                className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.industryType ? 'border-red-500' : ''}`}
              >
                <option value="">Select industry type</option>
                {industryOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            <div className="flex-1 flex flex-col mb-4 box-border w-full">
              <label>Job Level</label>
              <select
                value={jobLevel}
                onChange={(e) => setJobLevel(e.target.value)}
                className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.jobLevel ? 'border-red-500' : ''}`}
              >
                <option value="">Select job level</option>
                {jobLevelOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            <div className="flex justify-end gap-2 mt-10 mb-10">
              <button
                type="button"
                onClick={handleSave}
                className="bg-[var(--primary-color)] text-white px-6 py-3 rounded-[8px] cursor-pointer hover:bg-[var(--primary-color-hover)]"
                disabled={loadingSubmit} // Disable while loading
              >
                <div className="flex justify-center items-center">
                  {loadingSubmit ? (
                    <div className="loader" style={{ width: '24px', height: '24px' }}></div>
                  ) : (
                    isEdit ? "Save Changes" : "Add Experience"
                  )}
                </div>
              </button>
            </div>
          </form>
        </Modal>

        {experiences.map((exp) => (
          <div key={exp._id} className="border border-[var(--black-overlay)] p-6 mb-4 rounded-[16px] bg-white">
            <div className="text-[#1673bb] text-[18px] font-semibold flex justify-between items-center">
              <h3 className="m-0 text-[18px] font-semibold pb-2">{exp.jobTitle}</h3>
              <div className="flex space-x-4">
                <FontAwesomeIcon icon={faEdit} className="cursor-pointer text-[#1673bb]" onClick={() => handleEdit(exp)} />
                <FontAwesomeIcon icon={faTrash} className="cursor-pointer text-[#1673bb]" onClick={() => handleDelete(exp._id)} />
              </div>
            </div>
            <p className="text-[14px] text-black font-normal opacity-40 pb-1">{exp.companyName}</p>
            <p className="text-[14px] text-black font-normal opacity-40">{formatDate(exp.from)} - {exp.to ? formatDate(exp.to) : 'Present'}</p>
            <p className="text-[14px] text-black font-normal opacity-40 pt-1">Industry: {exp.industryType}</p>
            <p className="text-[14px] text-black font-normal opacity-40 pt-1">Job Level: {exp.jobLevel}</p>
            <p className="pt-2 text-[14px] overflow-hidden text-ellipsis display-webkit-box webkit-line-clamp-3 webkit-box-orient-vertical whitespace-pre-wrap">{exp.jobDescription}</p>
          </div>
        ))}
      </>
    </div>
  );
};

export default Experience;
