import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import { fetchCsrfToken } from '../../utils/csrf';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../utils/auth';
import useUserStore from '../../store/useStudentStore';

const allSkills = [
  "Transportation", "Education", "Engineering", "Healthcare", "Business", "Finance", "Marketing", "Sales",
  "Services", "Public Services", "Repair", "Maintenance", "Aviation", "Accountancy", "Insurance", "Food and Beverage",
  "Hospitality", "Tourism", "Recreation", "Information Technology", "Management", "Software Development", "Human Resources",
  "Legal", "Social Work", "Art and Design", "Communications", "Construction", "Environmental", "Science", "Technology",
  "Pharmaceuticals", "Real Estate", "Retail", "Security", "Logistics", "Manufacturing", "Nonprofit", "Project Management",
  "Research", "Sports", "Digital Marketing", "Data Analysis", "Cybersecurity", "Machine Learning", "Event Management",
  "Customer Service", "Graphic Design", "Consulting", "Entrepreneurship", "Agricultural Science", "Biotechnology", "Blockchain",
  "Cloud Computing", "Compliance", "Corporate Strategy", "Creative Writing", "Crisis Management", "E-commerce", "Educational Technology",
  "Elder Care", "Electrical Engineering", "Energy Sector", "Fashion Design", "Film Production", "Game Development", "Government Relations",
  "Health Informatics", "Humanitarian Aid", "Interior Design", "International Business", "Journalism", "Labor Relations",
  "Leadership Development", "Market Research", "Materials Science", "Mental Health", "Mobile App Development", "Nanotechnology",
  "Network Security", "Occupational Therapy", "Patent Law", "Performance Arts", "Petroleum Engineering", "Philanthropy",
  "Photography", "Physical Therapy", "Political Science", "Public Health", "Quality Assurance", "Renewable Energy", "Risk Management",
  "Robotics", "Social Media Management", "Supply Chain Management", "Urban Planning", "Veterinary Medicine", "Video Production",
  "Virtual Reality", "Web Design"
];

const predefinedSessionGoals = [
  "Improve communication skills", "Enhance leadership abilities", "Gain technical expertise",
  "Develop problem-solving skills", "Learn time management", "Understand project management",
  "Master financial planning", "Acquire marketing strategies", "Enhance team collaboration",
  "Build self-confidence", "Legal Consultation", "Job Finding Assistance"
];

const Skills = () => {
  const { userData, setUserData } = useUserStore(); // Access user data and set function
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [desiredSearchTerm, setDesiredSearchTerm] = useState('');
  const [goalSearchTerm, setGoalSearchTerm] = useState('');

  const [filteredCurrentSkills, setFilteredCurrentSkills] = useState(allSkills);
  const [filteredDesiredSkills, setFilteredDesiredSkills] = useState(allSkills);
  const [filteredGoals, setFilteredGoals] = useState(predefinedSessionGoals);
  const [isCurrentDropdownVisible, setCurrentDropdownVisible] = useState(false);
  const [isDesiredDropdownVisible, setDesiredDropdownVisible] = useState(false);
  const [isGoalDropdownVisible, setGoalDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [loadingSubmit, setLoadingSubmit] = useState(false); // Loading state for save button
  const currentDropdownRef = useRef(null);
  const desiredDropdownRef = useRef(null);
  const goalDropdownRef = useRef(null);

  const currentSkills = userData.currentSkills;
  const desiredSkills = userData.desiredSkills;
  const sessionGoals = userData.sessionGoals;

  // This effect runs on component mount to set loading state
  useEffect(() => {
    setLoading(false); // No need to fetch, just set loading to false
  }, []);


  const handleCurrentSearchChange = (e) => {
    const value = e.target.value;
    setCurrentSearchTerm(value);
    setFilteredCurrentSkills(allSkills.filter(skill => skill.toLowerCase().includes(value.toLowerCase())));
    setCurrentDropdownVisible(true);
  };

  const handleDesiredSearchChange = (e) => {
    const value = e.target.value;
    setDesiredSearchTerm(value);
    setFilteredDesiredSkills(allSkills.filter(skill => skill.toLowerCase().includes(value.toLowerCase())));
    setDesiredDropdownVisible(true);
  };

  const handleGoalSearchChange = (e) => {
    const value = e.target.value;
    setGoalSearchTerm(value);
    setFilteredGoals(predefinedSessionGoals.filter(goal => goal.toLowerCase().includes(value.toLowerCase())));
    setGoalDropdownVisible(true);
  };

  const handleAddSkill = (skill, type) => {
    if (type === 'current' && !userData.currentSkills.includes(skill)) {
      const updatedCurrentSkills = [...userData.currentSkills, skill];
  
      // Update Zustand store
      setUserData({
        ...userData,
        currentSkills: updatedCurrentSkills,
      });
  
      setCurrentSearchTerm('');
      setCurrentDropdownVisible(false);
    } else if (type === 'desired' && !userData.desiredSkills.includes(skill)) {
      const updatedDesiredSkills = [...userData.desiredSkills, skill];
  
      // Update Zustand store
      setUserData({
        ...userData,
        desiredSkills: updatedDesiredSkills,
      });
  
      setDesiredSearchTerm('');
      setDesiredDropdownVisible(false);
    }
  };
  
  const handleRemoveSkill = (skill, type) => {
    let updatedSkills;
    if (type === 'current') {
      updatedSkills = userData.currentSkills.filter(s => s !== skill);
  
      // Update Zustand store
      setUserData({
        ...userData,
        currentSkills: updatedSkills,
      });
    } else if (type === 'desired') {
      updatedSkills = userData.desiredSkills.filter(s => s !== skill);
  
      // Update Zustand store
      setUserData({
        ...userData,
        desiredSkills: updatedSkills,
      });
    }
  };
  
  
  const handleAddGoal = (goal) => {
    if (!sessionGoals.includes(goal)) {
      const updatedGoals = [...sessionGoals, goal];
      
      // Update Zustand store directly
      setUserData({
        ...userData,
        sessionGoals: updatedGoals,
      });

      setGoalSearchTerm('');
      setGoalDropdownVisible(false);
    }
  };

  const handleRemoveGoal = (goal) => {
    const updatedGoals = sessionGoals.filter(g => g !== goal);
    
    // Update Zustand store directly
    setUserData({
      ...userData,
      sessionGoals: updatedGoals,
    });
  };
  

  

  

  const handleClickOutside = (event) => {
    if (currentDropdownRef.current && !currentDropdownRef.current.contains(event.target)) {
      setCurrentDropdownVisible(false);
    }
    if (desiredDropdownRef.current && !desiredDropdownRef.current.contains(event.target)) {
      setDesiredDropdownVisible(false);
    }
    if (goalDropdownRef.current && !goalDropdownRef.current.contains(event.target)) {
      setGoalDropdownVisible(false);
    }
  };

  const handleSaveChanges = async () => {
    setLoadingSubmit(true); // Show loader when saving
    try {
      const csrfToken = await fetchCsrfToken();

      // Update the Zustand store
      setUserData({
        ...userData,
        currentSkills, // Update current skills
        desiredSkills, // Update desired skills
        sessionGoals, // Update session goals
      });

      await api.put(`/students/skills`, { currentSkills, desiredSkills, sessionGoals }, {
        headers: { 'CSRF-Token': csrfToken }
      });
      swal("Success", "Skills and goals updated successfully!", "success");
    } catch (error) {
      console.error('Error updating skills and goals:', error);
    } finally {
      setLoadingSubmit(false); // Hide loader after completion
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isCurrentDropdownVisible && currentSearchTerm === '') {
      setFilteredCurrentSkills(allSkills);
    }
    if (isDesiredDropdownVisible && desiredSearchTerm === '') {
      setFilteredDesiredSkills(allSkills);
    }
    if (isGoalDropdownVisible && goalSearchTerm === '') {
      setFilteredGoals(predefinedSessionGoals);
    }
  }, [isCurrentDropdownVisible, isDesiredDropdownVisible, isGoalDropdownVisible, currentSearchTerm, desiredSearchTerm, goalSearchTerm]);

  const SkillsSkeleton = () => (
    <div className="max-w-[700px] p-4 sm:p-5">
      <Skeleton height={24} width={150} className="mb-3 sm:mb-4" />
      <Skeleton height={16} width={`100%`} className="mb-3 sm:mb-4" />
  
      {/* Current Skills Section Skeleton */}
      <Skeleton height={24} width={120} className="mb-3 sm:mb-4" />
      <Skeleton height={36} width={`100%`} className="mb-3 sm:mb-4" />
      <div className="flex flex-wrap gap-2 mb-4 sm:mb-6">
        {Array(3)
          .fill()
          .map((_, index) => (
            <Skeleton key={index} height={24} width={80} className="rounded-full" />
          ))}
      </div>
  
      {/* Session Goals Section Skeleton */}
      <Skeleton height={24} width={120} className="mb-3 sm:mb-4" />
      <Skeleton height={36} width={`100%`} className="mb-3 sm:mb-4" />
      <div className="flex flex-wrap gap-2 mb-4 sm:mb-6">
        {Array(3)
          .fill()
          .map((_, index) => (
            <Skeleton key={index} height={24} width={100} className="rounded-full" />
          ))}
      </div>
      
      {/* Save Changes Button Skeleton */}
      <Skeleton height={40} width={100} className="mx-auto sm:mx-0" />
    </div>
  );
  

  return (
    <div className="max-w-[700px] p-5">
      {loading ? (
        <SkillsSkeleton />
      ) : (
        <>
          <h2 className="text-left text-2xl font-bold">Skills and Goals</h2>
          <p className="text-sm text-gray-600 mb-2">Add your current skills, desired skills, and session goals to help us better match you with opportunities.</p>

          {/* Current Skills Section */}
          <div className="mt-6">
            <h3 className="text-lg font-bold mb-2">Current Skills</h3>
            <div className="relative w-full" ref={currentDropdownRef}>
              <input
                type="text"
                placeholder="Search for a skill"
                value={currentSearchTerm}
                onChange={handleCurrentSearchChange}
                onFocus={() => setCurrentDropdownVisible(true)}
                className="w-full p-2.5 border border-border-color rounded-lg"
              />
              {isCurrentDropdownVisible && (
                <div className="absolute top-full left-0 w-full border border-border-color border-t-0 rounded-b-md max-h-[150px] overflow-y-auto bg-white z-10">
                  {filteredCurrentSkills.map(skill => (
                    <div key={skill} className="p-2.5 cursor-pointer hover:bg-gray-100" onClick={() => handleAddSkill(skill, 'current')}>
                      {skill}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-wrap mt-4 mb-5">
              {currentSkills.map(skill => (
                <div key={skill} className="p-2.5 rounded-full cursor-pointer m-1 text-primary border border-primary-color bg-[#e0f7fa]" onClick={() => handleRemoveSkill(skill, 'current')}>
                  {skill}
                </div>
              ))}
            </div>
          </div>

          {/* Desired Skills Section */}
          <div className="mt-6">
            <h3 className="text-lg font-bold mb-2">Desired Skills</h3>
            <div className="relative w-full" ref={desiredDropdownRef}>
              <input
                type="text"
                placeholder="Search for a skill"
                value={desiredSearchTerm}
                onChange={handleDesiredSearchChange}
                onFocus={() => setDesiredDropdownVisible(true)}
                className="w-full p-2.5 border border-gray-300 rounded-lg"
              />
              {isDesiredDropdownVisible && (
                <div className="absolute top-full left-0 w-full border border-gray-300 border-t-0 rounded-b-md max-h-[150px] overflow-y-auto bg-white z-10">
                  {filteredDesiredSkills.map(skill => (
                    <div key={skill} className="p-2.5 cursor-pointer hover:bg-gray-100" onClick={() => handleAddSkill(skill, 'desired')}>
                      {skill}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-wrap mt-4 mb-5">
              {desiredSkills.map(skill => (
                <div key={skill} className="border-primary-color p-2.5 rounded-full cursor-pointer m-1 text-primary border bg-[#e0f7fa]" onClick={() => handleRemoveSkill(skill, 'desired')}>
                  {skill}
                </div>
              ))}
            </div>
          </div>

          {/* Session Goals Section */}
          <div className="mt-6">
            <h3 className="text-lg font-bold mb-2">Session Goals</h3>
            <div className="relative w-full" ref={goalDropdownRef}>
              <input
                type="text"
                placeholder="Search for a goal"
                value={goalSearchTerm}
                onChange={handleGoalSearchChange}
                onFocus={() => setGoalDropdownVisible(true)}
                className="w-full p-2.5 border border-gray-300 rounded-lg"
              />
              {isGoalDropdownVisible && (
                <div className="absolute top-full left-0 w-full border border-gray-300 border-t-0 rounded-b-md max-h-[150px] overflow-y-auto bg-white z-10">
                  {filteredGoals.map(goal => (
                    <div key={goal} className=" p-2.5 cursor-pointer hover:bg-gray-100 " onClick={() => handleAddGoal(goal)}>
                      {goal}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-wrap mt-4 mb-5">
              {sessionGoals.map(goal => (
                <div key={goal} className="p-2.5 rounded-full cursor-pointer m-1 text-primary border border-primary-color bg-[#e0f7fa]" onClick={() => handleRemoveGoal(goal)}>
                  {goal}
                </div>
              ))}
            </div>
          </div>

          {/* Save Changes Button */}
          <button
            onClick={handleSaveChanges}
            className="mt-5 px-5 py-3 border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:bg-primary-color-hover"
            disabled={loadingSubmit} // Disable button while loading
            style={{ width: '200px', height: '50px' }} // Maintain consistent button dimensions
          >
            <div className="flex justify-center items-center">
              {loadingSubmit ? (
                <div className="loader" style={{ width: '24px', height: '24px' }}></div> // Show loader
              ) : (
                "Save Changes"
              )}
            </div>
          </button>
        </>
      )}
    </div>
  );
};

export default Skills;
