import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ServiceDetailsModal = ({ service, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-5 max-w-[600px] w-full shadow-lg">
        <button
          className="text-gray-500 text-right mb-3"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <h3 className="text-2xl font-bold">{service.serviceTitle}</h3>
        <p className="text-gray-600 mt-2">{service.serviceDescription}</p>
        <div className="flex flex-wrap gap-2 mt-4">
          {service.industrySkills.map((skill, index) => (
            <span
              key={index}
              className="bg-gray-100 border border-gray-300 px-3 py-1 rounded-full text-sm text-gray-700"
            >
              {skill}
            </span>
          ))}
        </div>
        <div className="mt-5">
          <strong>Price: £{service.price}</strong>
        </div>
        <button
          className="mt-3 bg-[#107F9A] text-white px-4 py-2 rounded w-full"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ServiceDetailsModal;