import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import stripeLogo from '../../assets/icons/stripe.svg';
import paypalLogo from '../../assets/icons/paypal.svg';
import bankLogo from '../../assets/icons/bank.svg';
import swal from 'sweetalert';
import api from '../../utils/auth';
import useInstructorStore from '../../store/useInstructorStore';
// Set the app element for accessibility
Modal.setAppElement('#root');

const Billing = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [paymentDetails, setPaymentDetails] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [currentPaymentId, setCurrentPaymentId] = useState(null);
  const [showSensitiveInfo, setShowSensitiveInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState({});

  const { instructorData, setInstructorData } = useInstructorStore();
  const { payments } = instructorData;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedPaymentMethod('');
    setPaymentDetails({});
    setIsEdit(false);
    setErrorMessage('');
    setErrorMessages({});
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    setErrorMessages({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrorMessages({});
  };

  const validateInput = () => {
    const errors = {};
    if (selectedPaymentMethod === 'Stripe') {
      if (!paymentDetails.stripeEmail) {
        errors.stripeEmail = 'Stripe account email is required.';
      } else if (!/\S+@\S+\.\S+/.test(paymentDetails.stripeEmail)) {
        errors.stripeEmail = 'Please enter a valid Stripe account email.';
      }
    }

    if (selectedPaymentMethod === 'PayPal') {
      if (!paymentDetails.paypalEmail) {
        errors.paypalEmail = 'PayPal account email is required.';
      } else if (!/\S+@\S+\.\S+/.test(paymentDetails.paypalEmail)) {
        errors.paypalEmail = 'Please enter a valid PayPal account email.';
      }
    }

    if (selectedPaymentMethod === 'Bank') {
      if (!paymentDetails.bankAccountNumber || !/^\d+$/.test(paymentDetails.bankAccountNumber)) {
        errors.bankAccountNumber = 'Please enter a valid bank account number.';
      }
      if (!paymentDetails.bankRoutingNumber || !/^\d+$/.test(paymentDetails.bankRoutingNumber)) {
        errors.bankRoutingNumber = 'Please enter a valid bank routing number.';
      }
      if (!paymentDetails.bankName) {
        errors.bankName = 'Bank name is required.';
      }
    }

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const updatePaymentsInStore = (newPayments) => {
    setInstructorData({
      ...instructorData,
      payments: newPayments,
    });
  };

  const savePaymentDetails = async () => {
    setErrorMessages({});
    if (!validateInput()) return;

    const newPaymentDetails = {
      method: selectedPaymentMethod,
      ...paymentDetails,
    };

    try {
      if (isEdit) {
        const response = await api.put(`/instructorsPayment/updatePaymentMethod/${currentPaymentId}`, newPaymentDetails);
        const updatedPayments = payments.map((pm) => (pm._id === currentPaymentId ? response.data : pm));
        updatePaymentsInStore(updatedPayments);
      } else {
        const response = await api.post('/instructorsPayment/addPaymentMethod', newPaymentDetails);
        updatePaymentsInStore([...payments, response.data]);
      }
      closeModal();
    } catch (error) {
      console.error('Error saving payment details:', error);
      setErrorMessage(error.response?.data?.msg || 'Error adding payment method');
    }
  };

  const deletePaymentMethod = async (id) => {
    try {
      await api.delete(`/instructorsPayment/deletePaymentMethod/${id}`);
      const updatedPayments = payments.filter((pm) => pm._id !== id);
      updatePaymentsInStore(updatedPayments);
    } catch (error) {
      console.error('Error deleting payment method:', error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete this payment method?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deletePaymentMethod(id);
        swal('Deleted!', 'The payment method has been deleted.', 'success');
      } else {
        swal('Cancelled', 'Your payment method is safe.', 'info');
      }
    });
  };

  const handleEdit = (paymentMethod) => {
    setIsEdit(true);
    setCurrentPaymentId(paymentMethod._id);
    setSelectedPaymentMethod(paymentMethod.method);
    setPaymentDetails(paymentMethod);
    setModalIsOpen(true);
  };

  const getPaymentIcon = (method) => {
    switch (method) {
      case 'Stripe':
        return stripeLogo;
      case 'PayPal':
        return paypalLogo;
      case 'Bank':
        return bankLogo;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-[700px] p-5">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <h2 className="text-2xl font-bold">Billing Settings</h2>
        <button
          className="bg-white text-[var(--primary-color)] border border-[var(--primary-color)] px-6 py-4 rounded-lg hover:bg-[var(--primary-color-hover)] hover:text-white transition duration-300"
          onClick={openModal}
        >
          + Add Payment Option
        </button>
      </div>

      <div className="flex items-center justify-end mb-4">
        <label className="flex items-center cursor-pointer gap-2">
          <input
            type="checkbox"
            checked={showSensitiveInfo}
            onChange={() => setShowSensitiveInfo(!showSensitiveInfo)}
            className="sr-only"
          />
          <div className="relative w-10 h-6">
            <div className="block bg-gray-200 w-full h-full rounded-full"></div>
            <div
              className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 ease-in-out ${
                showSensitiveInfo ? 'transform translate-x-4 bg-[var(--primary-color)]' : ''
              }`}
            ></div>
          </div>
          <span className="text-sm">Show Sensitive Info</span>
        </label>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="bg-white p-6 rounded-lg max-w-lg w-full mx-auto h-auto max-h-[90vh] overflow-y-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        contentLabel="Add Payment Option"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{isEdit ? 'Edit Payment Option' : 'Add Payment Option'}</h2>
          <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-2xl" onClick={closeModal} />
        </div>
        <hr className="mb-4" />
        {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
        <div className="flex flex-col gap-4">
          {/* Payment Method Radio Buttons */}
          {/* Stripe */}
          <label
            className={`flex items-center gap-2 p-3 rounded-lg border cursor-pointer ${
              selectedPaymentMethod === 'Stripe' ? 'bg-[var(--primary-color)] text-white' : 'bg-gray-100'
            }`}
            onClick={() => handlePaymentMethodChange('Stripe')}
          >
            <input
              type="radio"
              name="paymentMethod"
              value="Stripe"
              checked={selectedPaymentMethod === 'Stripe'}
              onChange={() => handlePaymentMethodChange('Stripe')}
              className="hidden"
            />
            <img src={stripeLogo} alt="Stripe" className="w-10 h-10" />
            Stripe
          </label>
          {/* PayPal */}
          <label
            className={`flex items-center gap-2 p-3 rounded-lg border cursor-pointer ${
              selectedPaymentMethod === 'PayPal' ? 'bg-[var(--primary-color)] text-white' : 'bg-gray-100'
            }`}
            onClick={() => handlePaymentMethodChange('PayPal')}
          >
            <input
              type="radio"
              name="paymentMethod"
              value="PayPal"
              checked={selectedPaymentMethod === 'PayPal'}
              onChange={() => handlePaymentMethodChange('PayPal')}
              className="hidden"
            />
            <img src={paypalLogo} alt="PayPal" className="w-10 h-10" />
            PayPal
          </label>
          {/* Bank */}
          <label
            className={`flex items-center gap-2 p-3 rounded-lg border cursor-pointer ${
              selectedPaymentMethod === 'Bank' ? 'bg-[var(--primary-color)] text-white' : 'bg-gray-100'
            }`}
            onClick={() => handlePaymentMethodChange('Bank')}
          >
            <input
              type="radio"
              name="paymentMethod"
              value="Bank"
              checked={selectedPaymentMethod === 'Bank'}
              onChange={() => handlePaymentMethodChange('Bank')}
              className="hidden"
            />
            <img src={bankLogo} alt="Bank" className="w-10 h-10" />
            Bank
          </label>
        </div>

        {selectedPaymentMethod && (
          <div className="flex flex-col gap-4 mt-4">
            {/* Stripe Fields */}
            {selectedPaymentMethod === 'Stripe' && (
              <div className="flex flex-col gap-2">
                <label className="font-semibold">Stripe Account Email:</label>
                <input
                  type="email"
                  name="stripeEmail"
                  value={paymentDetails.stripeEmail || ''}
                  onChange={handleInputChange}
                  className="p-2 border border-gray-300 rounded-lg"
                />
                {errorMessages.stripeEmail && <p className="text-red-500 text-sm">{errorMessages.stripeEmail}</p>}
              </div>
            )}
            {/* PayPal Fields */}
            {selectedPaymentMethod === 'PayPal' && (
              <div className="flex flex-col gap-2">
                <label className="font-semibold">PayPal Account Email:</label>
                <input
                  type="email"
                  name="paypalEmail"
                  value={paymentDetails.paypalEmail || ''}
                  onChange={handleInputChange}
                  className="p-2 border border-gray-300 rounded-lg"
                />
                {errorMessages.paypalEmail && <p className="text-red-500 text-sm">{errorMessages.paypalEmail}</p>}
              </div>
            )}
            {/* Bank Fields */}
            {selectedPaymentMethod === 'Bank' && (
              <>
                <div className="flex flex-col gap-2">
                  <label className="font-semibold">Bank Account Number:</label>
                  <input
                    type="text"
                    name="bankAccountNumber"
                    value={paymentDetails.bankAccountNumber || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-lg"
                  />
                  {errorMessages.bankAccountNumber && (
                    <p className="text-red-500 text-sm">{errorMessages.bankAccountNumber}</p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <label className="font-semibold">Bank Routing Number:</label>
                  <input
                    type="text"
                    name="bankRoutingNumber"
                    value={paymentDetails.bankRoutingNumber || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-lg"
                  />
                  {errorMessages.bankRoutingNumber && (
                    <p className="text-red-500 text-sm">{errorMessages.bankRoutingNumber}</p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <label className="font-semibold">Bank Name:</label>
                  <input
                    type="text"
                    name="bankName"
                    value={paymentDetails.bankName || ''}
                    onChange={handleInputChange}
                    className="p-2 border border-gray-300 rounded-lg"
                  />
                  {errorMessages.bankName && <p className="text-red-500 text-sm">{errorMessages.bankName}</p>}
                </div>
              </>
            )}
            <div className="flex justify-end mt-4">
              <button
                onClick={savePaymentDetails}
                className="bg-[var(--primary-color)] text-white px-6 py-2 rounded-lg hover:bg-[var(--primary-color-hover)] transition duration-300"
              >
                {isEdit ? 'Save Changes' : 'Save'}
              </button>
            </div>
          </div>
        )}
      </Modal>

      {/* Payment Methods Display */}
      {payments.map((pm) => (
        <div key={pm._id} className="border border-[var(--black-overlay)] p-4 mb-4 rounded-lg bg-white">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <img src={getPaymentIcon(pm.method)} alt={pm.method} className="w-12 h-12 mr-3" />
              <h3 className="text-lg font-semibold">{pm.method}</h3>
            </div>
            <div className="flex gap-3">
              <FontAwesomeIcon icon={faEdit} className="cursor-pointer text-blue-600" onClick={() => handleEdit(pm)} />
              <FontAwesomeIcon icon={faTrash} className="cursor-pointer text-blue-600" onClick={() => handleDelete(pm._id)} />
            </div>
          </div>
          {showSensitiveInfo && pm.method === 'Bank' && (
            <div className="text-sm mt-2">
              <p><strong>Bank Name:</strong> {pm.bankName}</p>
              <p><strong>Account Number:</strong> {pm.bankAccountNumber}</p>
              <p><strong>Routing Number:</strong> {pm.bankRoutingNumber}</p>
            </div>
          )}
          {showSensitiveInfo && (pm.method === 'Stripe' || pm.method === 'PayPal') && (
            <p className="text-sm mt-2">{pm.stripeEmail || pm.paypalEmail}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Billing;
