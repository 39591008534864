import React from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBrain, faBell, faCreditCard, faGraduationCap, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const StudentSettingsNavbar = ({ selectedOption, setSelectedOption, handleLogout }) => {
  const navigate = useNavigate();

  const options = [
    { name: 'My Profile', route: 'profile', icon: faUser },
    { name: 'Area of Interest', route: 'skills', icon: faBrain },
    { name: 'Notification', route: 'notification', icon: faBell },
    { name: 'Billing', route: 'billing', icon: faCreditCard },
    { name: 'Education', route: 'education', icon: faGraduationCap },
    { name: 'Preferences', route: 'industry-preferences', icon: faCogs },
    { name: 'Log out', icon: faSignOutAlt, className: 'text-[#ff7a60] hover:text-[#ff7a60]' }
  ];

  const onNavItemClick = (option) => {
    if (option.name === 'Log out') {
      swal({
        title: "Are you sure?",
        text: "You will be logged out of your account!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willLogout) => {
        if (willLogout) {
          handleLogout();
        }
      });
    } else {
      setSelectedOption(option.name);
      navigate(option.route);
    }
  };

  return (
    <nav className="w-[40px] md:w-[126px] flex flex-col items-center md:items-start mt-[72px]">
      {options.map(option => (
        <div
          key={option.name}
          className={`flex flex-col items-center md:flex-row md:items-center font-normal text-base py-2 md:py-2.5 px-1 md:px-4 cursor-pointer text-black rounded-lg mb-2 md:mb-4 hover:bg-[#cce6e0] hover:text-primary-color ${option.className || ''} ${selectedOption === option.name ? 'bg-[#cce6e0] text-primary-color' : ''}`}
          onClick={() => onNavItemClick(option)}
        >
          <div className="sm:flex md:hidden flex justify-center w-full">
            <FontAwesomeIcon icon={option.icon} className="text-xl" />
          </div>
          <div className="hidden md:flex md:items-center">
            {option.name}
          </div>
        </div>
      ))}
    </nav>
  );
};

export default StudentSettingsNavbar;
