import React, { useEffect, useState } from 'react';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import styles from './AdminReports.module.css';

const AdminReports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReports = async () => {
    const csrfToken = await fetchCsrfToken();
    try {
      const response = await api.get('/admin/reports', {
        headers: {
          'CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const resolveReport = async (reportId) => {
    const csrfToken = await fetchCsrfToken();
    try {
      await api.delete(`/admin/report/${reportId}`, {
        headers: {
          'CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });
      setReports(reports.filter(report => report._id !== reportId));
    } catch (error) {
      console.error('Error resolving report:', error);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  if (loading) {
    return <div>Loading reports...</div>;
  }

  return (
    <div className={styles.reportsContainer}>
      <h2>Reported Messages</h2>
      <ul className={styles.reportList}>
        {reports.map(report => (
          <li key={report._id} className={styles.reportItem}>
            <p>Message ID: {report.messageId}</p>
            <p>Message Content: {report.messageContent ? report.messageContent.message : 'Message content not found'}</p>
            {report.messageContent && report.messageContent.senderDetails ? (
              <p>Sent By: {report.messageContent.senderDetails.firstName} {report.messageContent.senderDetails.lastName} ({report.messageContent.senderDetails.role})</p>
            ) : (
              <p>Sent By: Unknown</p>
            )}
            <p>Reported By: {report.reportedBy.firstName} {report.reportedBy.lastName}</p>
            <p>Reason: {report.reason}</p>
            <button onClick={() => resolveReport(report._id)}>Resolve</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminReports;
