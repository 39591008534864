import React, { useEffect, useState } from 'react';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { FaCalendarAlt, FaClock, FaUser, FaChalkboardTeacher } from 'react-icons/fa';
import GeneralModal from '../GeneralSessionModal/GeneralSessionModal';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from '../paginationControl'; // Assuming you have a Pagination component

const PastSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for skeleton loader
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalPages, setTotalPages] = useState(1); // State for total pages
  const limit = 6; // Define limit for sessions per page

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true); // Start loading
      const csrfToken = await fetchCsrfToken();
      try {
        console.log('Fetching past sessions...');
        const [generalResponse, oneOnOneResponse] = await Promise.all([
          api.get(`/my-bookings/general-past?page=${currentPage}&limit=${limit}`, {
            headers: {
              'CSRF-Token': csrfToken,
            },
          }),
          api.get(`/my-bookings/one-on-one-past?page=${currentPage}&limit=${limit}`, {
            headers: {
              'CSRF-Token': csrfToken,
            },
          }),
        ]);
  
        console.log('General Sessions Response:', generalResponse.data);
        console.log('One-on-One Sessions Response:', oneOnOneResponse.data);
  
        const combinedSessions = [
          ...generalResponse.data.sessions.map((session) => ({ ...session, type: 'general' })),
          ...oneOnOneResponse.data.sessions.map((session) => ({ ...session, type: 'one-on-one' })),
        ];
  
        combinedSessions.sort(
          (a, b) => new Date(a.startUTC || a.availability.start) - new Date(b.startUTC || b.availability.start)
        );
  
        setSessions(combinedSessions);
        setTimezone(generalResponse.data.timezone || oneOnOneResponse.data.timezone); // Set timezone from responses
        setTotalPages(Math.ceil(Math.max(generalResponse.data.totalSessions, oneOnOneResponse.data.totalSessions) / limit)); // Set total pages
      } catch (error) {
        console.error('Error fetching past sessions:', error);
      } finally {
        setLoading(false); // End loading
      }
    };
  
    fetchSessions();
  }, [currentPage]); // Re-fetch sessions when currentPage changes
  

  const formatSessionDate = (utcDate) => {
    if (!timezone) return 'Loading...'; // Show a fallback if timezone is not available
    return moment(utcDate).tz(timezone).format('dddd, MMMM Do, YYYY');
  };

  const formatSessionTime = (startUTC, endUTC) => {
    if (!timezone) return 'Loading...'; // Show a fallback if timezone is not available
    const startTime = moment(startUTC).tz(timezone).format('hh:mm A');
    const endTime = moment(endUTC).tz(timezone).format('hh:mm A');
    return `${startTime} - ${endTime}`;
  };

  const openModal = (session) => {
    setSelectedSession(session);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSession(null);
  };

  const renderSession = (session) => (
    <div key={session._id} className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className={`absolute left-4 top-3 bottom-3 w-1 ${session.type === 'one-on-one' ? 'bg-green-500' : 'bg-red-500'}`}></div>
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-start mb-4">
          <div className="text-lg font-bold text-primary-color">
            {session.type === 'one-on-one' ? `${session.instructorId.firstName} ${session.instructorId.lastName}` : session.sessionName}
          </div>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaCalendarAlt className="mr-2 text-primary-color" />
          <span>{session.type === 'one-on-one' ? formatSessionDate(session.startUTC) : formatSessionDate(session.availability.start)}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaClock className="mr-2 text-primary-color" />
          <span>{session.type === 'one-on-one' ? formatSessionTime(session.startUTC, session.endUTC) : formatSessionTime(session.availability.start, session.availability.end)}</span>
        </div>
        <div className="text-sm text-black opacity-40">
          {session.type === 'one-on-one' ? 'One-on-One Session' : 'General Session'}
        </div>
        {session.type === 'general' && (
          <button className="mt-4 px-4 py-2 bg-primary-color text-white rounded-md hover:bg-primary-color-hover transition duration-300" onClick={() => openModal(session)}>
            View Details
          </button>
        )}
      </div>
    </div>
  );

  const renderSkeletonLoader = () => (
    <div className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-start mb-4">
          <Skeleton width={200} />
        </div>
        <div className="flex flex-col justify-between mt-2">
          <Skeleton width={150} height={20} className="mb-2" />
          <Skeleton width={100} height={20} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
    <div className="max-h-[575px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
      {loading ? Array(6).fill(0).map((_, index) => <div key={index}>{renderSkeletonLoader()}</div>) : sessions.length > 0 ? sessions.map((session) => renderSession(session)) : <p>No past sessions found.</p>}
      


      <GeneralModal isOpen={modalIsOpen} onRequestClose={closeModal} session={selectedSession} showApplyButton={false} timezone={timezone} />
    </div>
          {/* Pagination Controls */}
          {!loading && totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      )}
    </div>
  );
};

export default PastSessions;
