import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faUser,
  faCog,
  faSignOutAlt,
  faQuestionCircle,
  faMoon,
  faHome,
  faUsers,
  faCalendar,
  faBook,
  faChalkboardTeacher,
  faClock,
  faTrophy,
  faFileAlt,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import Notifications from '../Notifications';
import { db } from '../../firebase';
import { collection, query, onSnapshot, updateDoc, where } from 'firebase/firestore';
import UpcomingSessionsModal from '../UpcomingSessionsModal/UpcomingSessionsModal';
import { slide as Menu } from 'react-burger-menu';
import './Header.css'; // Import your burger menu CSS if needed
import useUserStore from '../../store/useStudentStore';
const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const notificationListenerRef = useRef(null);
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;
  const logo = `${process.env.PUBLIC_URL}/assets/logos/Navigrated_logo.png`;

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleNotifications = () => {
    if (!notificationsVisible && unreadCount > 0) {
      const notificationsQuery = query(
        collection(db, `users/${userData._id}/notifications`),
        where('read', '==', false)
      );

      onSnapshot(notificationsQuery, (snapshot) => {
        snapshot.docs.forEach((docSnapshot) => {
          updateDoc(docSnapshot.ref, { read: true });
        });
        setUnreadCount(0); // Reset unread count after marking all as read
      });
    }
    setNotificationsVisible(!notificationsVisible);
  };

  const setUserDataInStore = useUserStore((state) => state.setUserData); // Zustand setter
  const userData = useUserStore((state) => state.userData); // Zustand state for user data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get(`/students/dashboard`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });

        // Setting user data in Zustand including education, industry, and subscription
        setUserDataInStore({
          ...response.data.student,
          education: response.data.education, // Pass education data
          industry: response.data.industry,   // Pass industry data
          subscription: response.data.subscription, // Pass subscription data
        });

        // Logging the fetched data for confirmation
        console.log('Fetched User Data:', response.data);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [setUserDataInStore]);

  // Log Zustand stored user data after it has been updated
  useEffect(() => {
    console.log('Stored User Data in Zustand:', userData);
  }, [userData]);

  // Log Zustand stored user data after it has been updated
  useEffect(() => {
    console.log('Stored User Data in Zustand:', userData);
  }, [userData]);

  useEffect(() => {
    if (userData?._id && !notificationListenerRef.current) {
      notificationListenerRef.current = onSnapshot(
        query(collection(db, `users/${userData._id}/notifications`), where('read', '==', false)),
        (snapshot) => setUnreadCount(snapshot.size) // Update unread count
      );

      return () => {
        if (notificationListenerRef.current) {
          notificationListenerRef.current();
        }
      };
    }
  }, [userData?._id]);

  const handleJoinSessionClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = async () => {
    swal({
      title: 'Are you sure?',
      text: 'You will be logged out of your account!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willLogout) => {
      if (willLogout) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.post(`/auth/logout`, {
            headers: {
              'CSRF-Token': csrfToken,
            },
          });
          navigate('/sign-in');
        } catch (error) {
          console.error('Error during logout:', error);
        }
      }
    });
  };

  const handleItemClick = (path) => {
    navigate(path);
    setMenuOpen(false); // Close the menu after clicking on an item
  };

  return (
    <header className="flex justify-between items-center h-20 w-full bg-gray-50 border-b border-border-color fixed top-0 z-[70000]">
      <div className="flex items-center justify-center w-60 border-r border-border-color bg-white" style={{ width: 'clamp(160px, 20vw, 250px)' }}>
        <img src={logo} alt="Navigrated Logo" className="h-[79px] hidden lg:block " />
        <div className="block lg:hidden">
          <Menu isOpen={menuOpen} onStateChange={(state) => setMenuOpen(state.isOpen)}>
            <a className="menu-item" onClick={() => handleItemClick('/student/dashboard')}>
              <FontAwesomeIcon icon={faHome} className="mr-3" /> Home
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/dashboard')}>
            <FontAwesomeIcon icon={faHome} className="mr-3" /> Home
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/chat')}>
            <FontAwesomeIcon icon={faUsers} className="mr-3" /> My Mentors
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/my-bookings')}>
            <FontAwesomeIcon icon={faCalendar} className="mr-3" /> My Bookings
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/forum')}>
            <FontAwesomeIcon icon={faQuestionCircle} className="mr-3" /> Help Forum
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/content')}>
            <FontAwesomeIcon icon={faBook} className="mr-3" /> Content Library
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/browse-mentors')}>
            <FontAwesomeIcon icon={faChalkboardTeacher} className="mr-3" /> Browse Mentors
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/browse-sessions')}>
            <FontAwesomeIcon icon={faClock} className="mr-3" /> Browse Sessions
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/challenges')}>
            <FontAwesomeIcon icon={faTrophy} className="mr-3" /> Challenges
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/CVClinic')}>
            <FontAwesomeIcon icon={faFileAlt} className="mr-3" /> CV Clinic
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/Recruitment')}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-3" /> Recruitment
            </a>
            <a className="menu-item" onClick={() => handleItemClick('/student/settings')}>
            <FontAwesomeIcon icon={faCog} className="mr-3" /> Settings
            </a>
            {/* Other menu items */}
          </Menu>
        </div>
      </div>
      <div className="flex items-center gap-4 md:gap-6 mr-4 md:mr-6">
        <button className="custom-color text-white border-none px-4 py-2 md:px-6 md:py-4 text-xs md:text-sm font-normal rounded-md cursor-pointer custom-color:hover" onClick={handleJoinSessionClick}>
          Join Session Now
        </button>
        <div className="relative cursor-pointer" onClick={toggleNotifications}>
          <FontAwesomeIcon icon={faBell} className="text-base md:text-lg" style={{ color: 'var(--primary-color)' }} />
          {unreadCount > 0 && <span className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full"></span>}
          {notificationsVisible && <Notifications userId={userData?._id} />}
        </div>
        <div className="flex items-center cursor-pointer relative" onClick={toggleDropdown}>
          {userData && <img src={userData.profilePicture || defaultProfileImage} alt="Profile" className="w-8 h-8 md:w-10 md:h-10 rounded-full mr-2 md:mr-2.5" />}
          <span className="text-xs md:text-sm">▼</span>
          {dropdownVisible && (
            <div className="absolute top-16 right-0 bg-white shadow-lg rounded-md w-64 text-sm z-[7000]">
              <div className="flex items-center p-4">
                {userData && <img src={userData.profilePicture || defaultProfileImage} alt="Profile" className="w-12 h-12 md:w-16 md:h-16 rounded-full mr-3" />}
                <p className="font-bold">{userData ? `${userData.firstName} ${userData.lastName}` : 'Loading...'}</p>
              </div>
              <hr className="m-0 border-t border-border-color" />
              <ul className="list-none p-0 m-0">
                <li className="p-4 flex items-center cursor-pointer hover:bg-gray-100" onClick={() => navigate('student/settings')}>
                  <FontAwesomeIcon icon={faUser} className="mr-3" /> Edit Profile
                </li>
                <li className="p-4 flex items-center cursor-pointer hover:bg-gray-100" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" /> Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && <UpcomingSessionsModal closeModal={closeModal} />}
    </header>
  );
};

export default Header;




