import { create } from 'zustand';

const useInstructorStore = create((set) => ({
  instructorData: {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: '',
    location: '',
    phone: '',
    profilePicture: '',
    accessGranted: false,
    addedSkills: [],
    headline: '',
    areaOfExpertise: '',
    bio: '',
    cv: '',
    education: [],
    experiences: [],
    achievements: [], // Add achievements field
    helpFrequency: 0,
    industry: null,
    isProfileComplete: false,
    isVerified: false,
    mentorship: null,
    messages: [],
    notificationPreferences: {
      appEmail: false,
      appWhatsApp: false,
      reminderEmail: false,
      reminderWhatsApp: false,
    },
    payments: [],
    ratings: [],
    recommendedServicesSkills: [],
    recommendedSkills: [],
    service: [],
    timezone: '',
    ratingCount: 0,
    socialLinks: [], // Add this for future use
    // Exclude the following fields from the store:
    // verificationToken, verificationTokenExpires, resetPasswordToken, resetPasswordExpires
  },

  setInstructorData: (data) => set((state) => ({
    instructorData: {
      ...state.instructorData,
      _id: data._id || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      email: data.email || '',
      location: data.location || '',
      phone: data.phone || '',
      profilePicture: data.profilePicture || '',
      accessGranted: data.accessGranted || false,
      addedSkills: data.addedSkills || [],
      areaOfExpertise: data.areaOfExpertise || '',
      bio: data.bio || '',
      cv: data.cv || '',
      headline: data.headline || '',
      education: data.education || [],
      experiences: data.experiences || [],
      achievements: data.achievements || [], // Update achievements field
      helpFrequency: data.helpFrequency || 0,
      industry: data.industry || null,
      emailVerified: data.emailVerified || false,
      isProfileComplete: data.isProfileComplete || false,
      isVerified: data.isVerified || false,
      mentorship: data.mentorship || null,
      messages: data.messages || [],
      notificationPreferences: data.notificationPreferences || {
        appEmail: false,
        appWhatsApp: false,
        reminderEmail: false,
        reminderWhatsApp: false,
      },
      payments: data.payments || [],
      ratings: data.ratings || [],
      recommendedServicesSkills: data.recommendedServicesSkills || [],
      recommendedSkills: data.recommendedSkills || [],
      service: data.service || [],
      timezone: data.timezone || '',
      ratingCount: data.ratingCount || 0,
      socialLinks: data.socialLinks || [],
    },
  })),
}));

export default useInstructorStore;
