import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardCard = ({ icon, title, description, onClick }) => {
  return (
    <div 
      className="bg-white rounded-lg shadow-md p-5 text-left transition-transform duration-300 ease-in-out cursor-pointer my-2.5 flex items-center hover:transform hover:-translate-y-1" 
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className="text-4xl text-primary-color mr-5" />
      <div>
        <h3 className="text-xl font-bold my-2.5">{title}</h3>
        <p className="text-base text-gray-600">{description}</p>
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default DashboardCard;
