import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import api from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ServicePaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { service, instructor } = state;
  const [clientSecret, setClientSecret] = useState('');
  const [studentId, setStudentId] = useState('');
  const [studentName, setStudentName] = useState('');

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await api.get('/user/user-role');
        setStudentId(response.data.id);
        setStudentName(`${response.data.firstName} ${response.data.lastName}`);
      } catch (error) {
        console.error('Error fetching student details:', error);
      }
    };

    fetchStudentDetails();
  }, []);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await api.post('/service_payment_intents', {
          amount: service.price * 100, // Stripe expects amounts in cents/pence
          studentId: studentId,
          instructorId: instructor._id,
          serviceId: service._id,
          studentName: studentName,
          instructorName: `${instructor.firstName} ${instructor.lastName}`,
        });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    };

    if (studentId && studentName) {
      createPaymentIntent();
    }
  }, [service.price, studentId, instructor._id, service._id, studentName]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe.js has not yet loaded.');
      return;
    }

    const confirm = await Swal.fire({
      title: 'Confirm Payment',
      text: `Are you sure you want to pay £${service.price} for this service?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, pay now!',
    });

    if (confirm.isConfirmed) {
      const cardElement = elements.getElement(CardElement);

      try {
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: studentName,
            },
          },
        });

        if (error) {
          console.error('Error confirming card payment:', error);
          Swal.fire('Payment Failed', `There was an issue processing your payment: ${error.message}`, 'error');
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
          Swal.fire('Payment Successful', 'Your payment was successful!', 'success');

          await api.post('/update_service_purchase', {
            studentId: studentId,
            instructorId: instructor._id,
            serviceId: service._id,
            paymentIntentId: paymentIntent.id,
          });

          navigate('/student/dashboard');
        }
      } catch (error) {
        console.error('Error confirming card payment:', error);
        Swal.fire('Payment Failed', `There was an issue processing your payment. Please try again. ${error.message}`, 'error');
      }
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-16 p-8 border border-gray-300 rounded-lg bg-white text-center shadow-lg relative">
      <button
        className="absolute top-5 left-5 text-[#107F9A] hover:text-[#055a79] flex items-center font-semibold"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
      </button>
      <h2 className="text-2xl font-bold mb-5 text-gray-800">Complete Your Purchase</h2>
      <p className="text-base font-normal text-gray-600 mb-5">
        You are about to purchase the service <strong>{service.serviceTitle}</strong> offered by <strong>{instructor.firstName} {instructor.lastName}</strong>.
      </p>
      <p className="text-xl font-semibold text-[#107F9A] mb-8">Total: £{service.price}</p>
      <div className="flex items-center justify-center mb-5 text-sm text-gray-600">
        <FontAwesomeIcon icon={faLock} className="text-[#107F9A] mr-2" />
        <span>Your payment is securely processed by Stripe.</span>
      </div>
      <form onSubmit={handleSubmit} className="mt-5">
        <div className="mb-5 p-3 border border-gray-300 rounded-md bg-gray-100">
          <CardElement options={{ hidePostalCode: true }} className="p-2" />
        </div>
        <button
          type="submit"
          className="bg-[#107F9A] text-white py-3 px-5 rounded-md font-semibold transition-colors duration-300 hover:bg-[#106f8a] disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={!stripe || !clientSecret}
        >
          Pay Now
        </button>
      </form>
    </div>
  );
};

export default ServicePaymentPage;
