import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import styles from './OneOnOne.module.css';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';

const OneOnOne = () => {
  const [price, setPrice] = useState('');
  const [availability, setAvailability] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  useEffect(() => {
    const fetchMentorshipData = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get(`/instructorsMentorship`, {
          headers: { 'CSRF-Token': csrfToken },
        });

        if (response.data) {
          const fetchedAvailability = response.data.availability || {};
          console.log('Fetched Availability:', fetchedAvailability);

          setPrice(response.data.price !== null ? response.data.price : '');
          setAvailability(fetchedAvailability);
        }
      } catch (error) {
        console.error('Error fetching mentorship data:', error);
      }
    };

    fetchMentorshipData();
  }, []);

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setPrice(value);
    } else {
      swal("Invalid Input", "Please enter a valid number for the price.", "error");
    }
  };

  const handlePriceBlur = () => {
    if (price && !isNaN(price)) {
      setPrice(parseFloat(price).toFixed(2));
    } else if (isNaN(price)) {
      swal("Invalid Input", "Price must be a numeric value.", "error");
      setPrice('');
    }
  };

  const handleAvailabilityChange = (day, index, field, value) => {
    if (field === 'fromHour' || field === 'toHour') {
      // Ensure that only values from 1 to 12 are allowed
      if (value < 1 || value > 12) return;
    }

    setAvailability((prevAvailability) => {
      const updatedAvailability = { ...prevAvailability };
      updatedAvailability[day][index] = {
        ...updatedAvailability[day][index],
        [field]: value,
      };

      // Adjust the "to" time to ensure it's always after the "from" time
      const fromHour = parseInt(updatedAvailability[day][index].fromHour, 10);
      const toHour = parseInt(updatedAvailability[day][index].toHour, 10);
      const fromPeriod = updatedAvailability[day][index].fromPeriod;
      const toPeriod = updatedAvailability[day][index].toPeriod;

      if (fromPeriod === toPeriod && toHour <= fromHour) {
        updatedAvailability[day][index].toHour = (fromHour + 1 > 12 ? 1 : fromHour + 1).toString().padStart(2, '0');
        updatedAvailability[day][index].toPeriod = fromPeriod === 'AM' ? 'PM' : 'AM';
      }

      console.log('Updated Availability:', updatedAvailability);
      return updatedAvailability;
    });
  };

  const handleDayToggle = (day) => {
    setAvailability((prevAvailability) => {
      const updatedAvailability = { ...prevAvailability };

      if (updatedAvailability[day].length > 0) {
        updatedAvailability[day] = [];
      } else {
        updatedAvailability[day] = [{
          fromHour: '09',
          fromPeriod: 'AM',
          toHour: '10',
          toPeriod: 'AM',
        }];
      }

      console.log('Toggled Day:', day, updatedAvailability);
      return updatedAvailability;
    });
  };

  const removeShift = (day, index) => {
    setAvailability((prevAvailability) => {
      const updatedAvailability = { ...prevAvailability };
      updatedAvailability[day].splice(index, 1);
      console.log('Removed Shift:', updatedAvailability);
      return updatedAvailability;
    });
  };

  const renderTimeSelect = (day, index, fieldHour, fieldPeriod, isFrom) => {
    const hourValue = availability[day]?.[index]?.[fieldHour] || '01';
    const periodValue = availability[day]?.[index]?.[fieldPeriod] || 'PM';

    return (
      <div className={styles.timeSelectContainer}>
        <input
          type="number"
          min="1"
          max="12"
          value={hourValue}
          onChange={(e) => handleAvailabilityChange(day, index, fieldHour, e.target.value)}
          className={styles.timeInput}
        />
        <select
          value={periodValue}
          onChange={(e) => handleAvailabilityChange(day, index, fieldPeriod, e.target.value)}
          className={styles.timeSelect}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    );
  };

  const saveMentorship = async () => {
    const csrfToken = await fetchCsrfToken();

    try {
      const response = await api.post(`/instructorsMentorship`,
        { price, availability },
        { headers: { 'CSRF-Token': csrfToken } }
      );
      swal("Success", "Mentorship changes saved successfully!", "success");
      console.log('Saved Availability:', availability);
    } catch (error) {
      console.error('Error saving mentorship:', error);
    }
  };

  return (
    <div className={styles.editSkills}>
      <h2>1-on-1 Mentorship</h2>
      <p>Set up your 1-on-1 mentorship session details</p>
      <div className={styles.priceContainer}>
        <h3>How much will you charge for the session?</h3>
        <input
          type="text"
          value={price}
          onChange={handlePriceChange}
          onBlur={handlePriceBlur}
          placeholder="£/hour"
          className={styles.priceInput}
        />
      </div>

      <div className={styles.availabilityContainer}>
        <h2>Set Availability Hours</h2>
        <p className={styles.note}>* Please carefully select your availability times. Ensure your "from" time is before your "to" time.</p>
        {Object.keys(availability).map(day => (
          <div key={day} className={styles.dayContainer}>
            <label>
              <input
                type="checkbox"
                checked={availability[day].length > 0}
                onChange={() => handleDayToggle(day)}
              />
              {day}
            </label>
            {availability[day].map((shift, index) => (
              <div key={`${day}-${index}`} className={styles.shiftContainer}>
                {renderTimeSelect(day, index, 'fromHour', 'fromPeriod', true)}
                <span>to</span>
                {renderTimeSelect(day, index, 'toHour', 'toPeriod', false)}
                <button
                  type="button"
                  className={styles.removeShiftButton}
                  onClick={() => removeShift(day, index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>

      <button onClick={saveMentorship} className={styles.saveChangesButton}>Save Changes</button>
    </div>
  );
};

export default OneOnOne;
