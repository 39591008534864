import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ChooseAccountType.module.css';
import api from '../../utils/auth';
import 'font-awesome/css/font-awesome.min.css';

const ChooseAccountType = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false); // State to track error
  const onboardingImage = `${process.env.PUBLIC_URL}/assets/images/onboarding.jpg`;
  const backbutton = `${process.env.PUBLIC_URL}/assets/icons/back-button.png`;

  const navigate = useNavigate();

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setError(false); // Reset error when an option is selected
  };

  useEffect(() => {
    // Check user role and redirect to the appropriate dashboard
    const checkUserRole = async () => {
      try {
        const response = await api.get('/auth/check-role'); // Ensure this matches your server's endpoint
        const { redirectTo } = response.data;
        navigate(`/${redirectTo}`); // Redirect based on role
      } catch (error) {
        console.error('Error checking user role:', error);
        // Handle unauthenticated case or error if needed
      }
    };

    checkUserRole();
  }, [navigate]);
  
  

  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar); // Use the class from module CSS

    // Cleanup by removing class when component unmounts
    return () => {
      document.body.classList.remove(styles.customScrollbar); // Use the class from module CSS
    };
  }, []);

  const handleContinue = () => {
    if (selectedOption === 'student') {
      navigate('/student/signup');
    } else if (selectedOption === 'instructor') {
      navigate('/mentor/signup');
    } else {
      setError(true); // Display error if no option is selected
    }
  };

  const handleSignInClick = () => {
    navigate('/sign-in');
  };

  return (
    <div className={styles.container}>
      {/* Left Column */}
      <div className={styles.leftColumn}>
        <a href="#" className={styles.goBackIcon}>
          <img src={backbutton} alt="Go Back" />
          {/* will lead to landing page developed in future */}
        </a>
        <div className={styles.header}>
          <div className={styles.progressBar}>
            <div className={`${styles.progressStep} ${step >= 1 ? styles.progressStepActive : ''}`}></div>
            <div className={`${styles.progressStep} ${step >= 2 ? styles.progressStepActive : ''}`}></div>
            <div className={`${styles.progressStep} ${step >= 3 ? styles.progressStepActive : ''}`}></div>
          </div>
          <h1 className={styles.h1}>Create an Account</h1>
          <p className={styles.subHeading}>
            Choose an account to get started with our platform and transform your career.
          </p>
          <p className={styles.chooseLabel}>Choose whether you're a:</p>
          <div className={styles.accountOptions}>
            <div
              className={`${styles.accountOption} ${selectedOption === 'student' ? styles.accountOptionSelected : ''}`}
              onClick={() => handleOptionSelect('student')}
            >
              <h2 className={styles.accountOptionTitle}>Student</h2>
              <p>I am looking to get mentorship sessions.</p>
              <div className={`${styles.selectIndicator} ${selectedOption === 'student' ? styles.selectIndicatorSelected : ''}`}></div>
            </div>
            <div
              className={`${styles.accountOption} ${selectedOption === 'instructor' ? styles.accountOptionSelected : ''}`}
              onClick={() => handleOptionSelect('instructor')}
            >
              <h2 className={styles.accountOptionTitle}>Mentor</h2>
              <p>I am looking to hold mentorship sessions.</p>
              <div className={`${styles.selectIndicator} ${selectedOption === 'instructor' ? styles.selectIndicatorSelected : ''}`}></div>
            </div>
          </div>
          {error && <p className={styles.errorMessage}>Please select an option.</p>}
          <button className={styles.continueButton} onClick={handleContinue}>Continue</button>
          <p className={styles.signIn}>
            Already have an account? <span onClick={handleSignInClick} className={styles.signInLink} style={{ cursor: 'pointer' }}>Sign in</span>
          </p>
        </div>
      </div>

      {/* Right Column */}
      <div className={styles.rightColumn}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img src={onboardingImage} alt="Onboarding" />
            <div className={styles.overlay}></div>
            <div className={styles.blackOverlay}></div>
            <div className={styles.textOverlay}>
  <h2>Why Choose Navigrated?</h2>
  <p>
    Our mission with Navigrated is to help international students and professionals secure industry-relevant jobs and build successful ventures in the UK - through the power of community mentorship.
  </p>
  <ul className={styles.featuresList}>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Expert-led sessions</b></span>
      </div>
      <div className={styles.nonBoldText}>
        on landing sponsored jobs, entrepreneurship, and overcoming immigration challenges.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Mentor-led daily group calls</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Gain insights from professionals who have built careers at companies like Deloitte, KPMG, Loreal, Rolls Royce, etc.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>CV reviews</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Tools and challenges built to help you stand out in the competitive job market.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Build your network</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Attend both virtual and in-person events to expand your professional circle.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Success stories</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Be inspired by real experiences from mentors who’ve faced and overcome the same challenges you are now facing.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Exclusive insights</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Access templates, resources, and webinars designed to fast-track your UK career journey.
      </div>
    </li>
  </ul>
</div>




          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAccountType;
