import React from 'react';
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';
import InstructorSettingsNavbar from '../../components/InstructorSettings/InstructorSettingsNavbar';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';

const InstructorSettingsLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      await api.post(`/auth/logout`, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });
      navigate('/sign-in');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Check if the current path is exactly /mentor/settings and redirect to profile
  if (location.pathname === '/mentor/settings') {
    return <Navigate to="/mentor/settings/profile" replace />;
  }

  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold pb-5 text-primary-color">
        Settings
      </div>

      <div className="flex flex-row min-h-[calc(100vh-228px)] border border-border-color p-5 rounded-md bg-background-color">
        <InstructorSettingsNavbar handleLogout={handleLogout} />
        <div className="flex-1 w-full bg-white ml-8 mt-5 md:mt-0 md:ml-24">
          <Outlet /> {/* Outlet to render sub-routes */}
        </div>
      </div>
    </div>
  );
};

export default InstructorSettingsLayout;
