import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import api from '../../utils/auth'; // Import your Axios instance
import useInstructorStore from '../../store/useInstructorStore';
const InstructorNotification = () => {
  // Access notification preferences from the store
  const { instructorData, setInstructorData } = useInstructorStore();
  const notificationPreferences = instructorData.notificationPreferences;

  // Set state from the store
  const [reminderWhatsApp, setReminderWhatsApp] = useState(notificationPreferences.reminderWhatsApp);
  const [appWhatsApp, setAppWhatsApp] = useState(notificationPreferences.appWhatsApp);

  useEffect(() => {
    // Fetch preferences from backend only if not already in the store
    const fetchPreferences = async () => {
      if (!notificationPreferences.reminderWhatsApp && !notificationPreferences.appWhatsApp) {
        try {
          const response = await api.get('/notifications/preferences');
          const data = response.data;
          setReminderWhatsApp(data.reminderWhatsApp);
          setAppWhatsApp(data.appWhatsApp);

          // Update the store with fetched preferences
          setInstructorData({
            ...instructorData,
            notificationPreferences: {
              ...notificationPreferences,
              reminderWhatsApp: data.reminderWhatsApp,
              appWhatsApp: data.appWhatsApp,
            },
          });
        } catch (error) {
          console.error('Error fetching notification preferences:', error);
        }
      }
    };

    fetchPreferences();
  }, [notificationPreferences, setInstructorData, instructorData]);

  const handleToggle = async (type, value) => {
    // Update local state
    if (type === 'reminderWhatsApp') setReminderWhatsApp(value);
    if (type === 'appWhatsApp') setAppWhatsApp(value);

    try {
      // Update preferences on the backend
      await api.post('/notifications/updatePreferences', {
        type,
        value
      });

      // Update the store with the new preferences
      setInstructorData({
        ...instructorData,
        notificationPreferences: {
          ...notificationPreferences,
          [type]: value, // Dynamically set the correct preference in the store
        },
      });
    } catch (error) {
      console.error('Error updating notification preferences:', error);
    }
  };

  return (
    <div className="max-w-[700px] p-5">
      <div className="text-2xl font-bold">Notifications</div>
      <div className="text-sm text-gray-600 mb-5">
        Help yourself get notified of any important events.
      </div>
      <div className="flex flex-col space-y-8">
        <div>
          <div className="text-lg font-semibold mb-2">Reminders</div>
          <div className="flex flex-col md:flex-row md:items-start justify-between mb-6 space-y-4 md:space-y-0">
            <div className="text-sm text-gray-700 md:max-w-md">
              These are notifications to remind you about an important event like upcoming sessions.
            </div>
            <div className="flex items-center">
              <Switch 
                onChange={(checked) => handleToggle('reminderWhatsApp', checked)}
                checked={reminderWhatsApp}
                offColor="#ccc"
                onColor="#1c6b9b"
                uncheckedIcon={false}
                checkedIcon={false}
              />
              <label className="ml-2">WhatsApp</label>
            </div>
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold mb-2">App Notifications</div>
          <div className="flex flex-col md:flex-row md:items-start justify-between space-y-4 md:space-y-0">
            <div className="text-sm text-gray-700 md:max-w-md">
              These are app notifications that will help notify you whenever someone books a one-on-one session or buys your service.
            </div>
            <div className="flex items-center">
              <Switch 
                onChange={(checked) => handleToggle('appWhatsApp', checked)}
                checked={appWhatsApp}
                offColor="#ccc"
                onColor="#1c6b9b"
                uncheckedIcon={false}
                checkedIcon={false}
              />
              <label className="ml-2">WhatsApp</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorNotification;
