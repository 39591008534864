import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import styles from './UserManagement.module.css';
import ClipLoader from 'react-spinners/ClipLoader'; // Importing the loader

const UserManagement = () => {
  const [students, setStudents] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [studentSearchTerm, setStudentSearchTerm] = useState('');
  const [instructorSearchTerm, setInstructorSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`; // Default profile image path
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader while fetching data
      try {
        const csrfToken = await fetchCsrfToken();
        const studentResponse = await api.get('/admin/students', {
          headers: { 'CSRF-Token': csrfToken },
        });
        setStudents(studentResponse.data);

        const instructorResponse = await api.get('/admin/instructors', {
          headers: { 'CSRF-Token': csrfToken },
        });
        setInstructors(instructorResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false); // Hide loader after data is fetched
      }
    };

    fetchData();
  }, []);

  const handleDeletePermanently = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.delete(`/admin/user/${id}`, {
            headers: { 'CSRF-Token': csrfToken },
          });
          setStudents(students.filter(student => student._id !== id));
          setInstructors(instructors.filter(instructor => instructor._id !== id));
          setSelectedUser(null);
          Swal.fire(
            'Deleted!',
            'The user and related data have been deleted.',
            'success'
          );
        } catch (err) {
          console.error('Error deleting user:', err);
          Swal.fire(
            'Error!',
            'There was an error deleting the user.',
            'error'
          );
        }
      }
    });
  };

  const handleView = (user) => {
    setSelectedUser(user);
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  const handleDownloadCV = (cv) => {
    const link = document.createElement('a');
    link.href = `${cv}`;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleToggleFreeSession = async (id, currentFreeSession) => {
    const action = currentFreeSession ? 'disable' : 'enable';
    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to ${action} free sessions for this mentor.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${action}!`,
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.patch(`/admin/instructor/free-session/${id}`, {
            headers: { 'CSRF-Token': csrfToken },
          });
  
          // Fetch updated instructors list after toggling free session
          const updatedInstructors = await api.get('/admin/instructors', {
            headers: { 'CSRF-Token': csrfToken },
          });
          setInstructors(updatedInstructors.data);
          Swal.fire('Success', `Free session status has been updated`, 'success');
        } catch (err) {
          console.error('Error toggling free session:', err);
          Swal.fire('Error', 'There was an error updating free session status', 'error');
        }
      }
    });
  };

  const handleToggleAccess = async (id, currentAccess) => {
    const action = currentAccess ? 'remove' : 'grant';
    const confirmText = currentAccess ? 'remove access from' : 'grant access to';
    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to ${confirmText} this user.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${action} access!`,
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.patch(`/admin/user/access/${id}`, {
            headers: { 'CSRF-Token': csrfToken },
          });
          const updatedStudents = await api.get('/admin/students', {
            headers: { 'CSRF-Token': csrfToken },
          });
          setStudents(updatedStudents.data);
          const updatedInstructors = await api.get('/admin/instructors', {
            headers: { 'CSRF-Token': csrfToken },
          });
          setInstructors(updatedInstructors.data);
          Swal.fire('Success', 'User access has been updated', 'success');
        } catch (err) {
          console.error('Error toggling access:', err);
          Swal.fire('Error', 'There was an error updating user access', 'error');
        }
      }
    });
  };

  const filteredStudents = students.filter(student =>
    student.firstName.toLowerCase().includes(studentSearchTerm.toLowerCase()) ||
    student.lastName.toLowerCase().includes(studentSearchTerm.toLowerCase()) ||
    student.email.toLowerCase().includes(studentSearchTerm.toLowerCase())
  );

  const filteredInstructors = instructors.filter(instructor =>
    instructor.firstName.toLowerCase().includes(instructorSearchTerm.toLowerCase()) ||
    instructor.lastName.toLowerCase().includes(instructorSearchTerm.toLowerCase()) ||
    instructor.email.toLowerCase().includes(instructorSearchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h2>User Management</h2>
      <div className={styles.userSections}>
        {/* Loader Display */}
        {loading ? (
          <div className={styles.loader}>
            <ClipLoader color={'#123abc'} loading={loading} size={50} />
          </div>
        ) : (
          <>
            <div className={styles.section}>
              <h3>Students</h3>
              <input
                type="text"
                placeholder="Search students..."
                value={studentSearchTerm}
                onChange={(e) => setStudentSearchTerm(e.target.value)}
                className={styles.searchBar}
              />
              <ul className={styles.list}>
                {filteredStudents.map(student => (
                  <li key={student._id} className={styles.listItem}>
                    <div>
                      <p className={styles.name}>{student.firstName} {student.lastName}</p>
                      <p className={styles.email}>{student.email}</p>
                    </div>
                    <div className={styles.buttonGroup}>
                      <button onClick={() => handleView(student)} className={styles.viewButton}>View</button>
                      <button onClick={() => handleToggleAccess(student._id, student.accessGranted)} className={styles.toggleAccessButton}>
                        {student.accessGranted ? 'Remove Access' : 'Grant Access'}
                      </button>
                      <button onClick={() => handleDeletePermanently(student._id)} className={styles.deleteButton}>Delete Permanently</button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.section}>
              <h3>Mentors</h3>
              <input
                type="text"
                placeholder="Search Mentors..."
                value={instructorSearchTerm}
                onChange={(e) => setInstructorSearchTerm(e.target.value)}
                className={styles.searchBar}
              />
              <ul className={styles.list}>
                {filteredInstructors.map(instructor => (
                  <li key={instructor._id} className={styles.listItem}>
                    <div>
                      <p className={styles.name}>{instructor.firstName} {instructor.lastName}</p>
                      <p className={styles.email}>{instructor.email}</p>
                    </div>
                    <div className={styles.buttonGroup}>
                      <button onClick={() => handleView(instructor)} className={styles.viewButton}>View</button>
                      <button onClick={() => handleToggleAccess(instructor._id, instructor.accessGranted)} className={styles.toggleAccessButton}>
                        {instructor.accessGranted ? 'Remove Access' : 'Grant Access'}
                      </button>
                      <button onClick={() => handleDeletePermanently(instructor._id)} className={styles.deleteButton}>Delete Permanently</button>
                      {/* Add a new button to toggle freeSession */}
                      <button onClick={() => handleToggleFreeSession(instructor._id, instructor.freeSession)} className={styles.toggleFreeSessionButton}>
                        {instructor.freeSession ? 'Disable Free Session' : 'Enable Free Session'}
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>

      {selectedUser && (
        <Modal
          isOpen={!!selectedUser}
          onRequestClose={closeModal}
          contentLabel="User Details"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <div className={styles.userDetails}>
            <img
              src={selectedUser.profilePicture ? `${selectedUser.profilePicture}` : defaultProfileImage}
              alt="Profile"
              className={styles.profileImage}
            />
            <h3>{selectedUser.firstName} {selectedUser.lastName}</h3>
            <p className={styles.emailDetail}>{selectedUser.email}</p>
            {selectedUser.cv && (
              <div>
                <button onClick={() => handleDownloadCV(selectedUser.cv)} className={styles.downloadButton}>View CV</button>
              </div>
            )}
            {selectedUser.bio && (
              <p className={styles.bio}>
                {selectedUser.bio}
              </p>
            )}
            {selectedUser.isVerified !== undefined && <p className={styles.detail}>Verified: {selectedUser.isVerified.toString()}</p>}
            {selectedUser.location && <p className={styles.detail}>Location: {selectedUser.location}</p>}
            {selectedUser.timezone && <p className={styles.detail}>Timezone: {selectedUser.timezone}</p>}
            {selectedUser.headline && <p className={styles.detail}>Headline: {selectedUser.headline}</p>}
            {selectedUser.areaOfExpertise && <p className={styles.detail}>Area of Expertise: {selectedUser.areaOfExpertise}</p>}
            <button onClick={closeModal} className={styles.closeButton}>Close</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserManagement;
