import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './AdminSignIn.module.css';
import api from '../../utils/auth';

const AdminSignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();


  const validateForm = () => {
    // Add form validation logic if needed
    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await api.post('/admin/sign-in', { username, password });
        setGeneralError('');
        navigate('/admin/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setGeneralError(error.response.data.msg);
        } else {
          console.error('There was an error submitting the form:', error);
          setGeneralError('An unexpected error occurred. Please try again.');
        }
      }
    }
  };

 

  return (
    <div className={styles.signInContainer}>
      <form onSubmit={handleSubmit}>
        <h2>Admin Sign-In</h2>
        {generalError && <p className={styles.error}>{generalError}</p>}
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="username">Username</label>
          <input
            className={styles.input}
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="password">Password</label>
          <input
            className={styles.input}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className={styles.button} type="submit">Sign In</button>
      </form>
    </div>
  );
};

export default AdminSignIn;
