import React from 'react';
import Modal from 'react-modal';
import styles from './CustomModal.module.css'; // Import the CSS module for sanitized content

const CustomModal = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[3000]"
      className="relative bg-white rounded-lg p-4 max-w-lg w-full max-h-[80vh] overflow-y-auto"
      ariaHideApp={false}
    >
      <button
        onClick={onRequestClose}
        className="absolute top-3 right-3 text-gray-700"
      >
        &times;
      </button>
      <div className={styles['modal-content']}>
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
