import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineQuestionCircle,
  AiOutlineBook,
  AiOutlineTeam,
  AiOutlineClockCircle,
  AiOutlineTrophy,
  AiOutlineFileText,
  AiOutlineSolution,
  AiOutlineSetting,
} from 'react-icons/ai';

const NavBar = () => {
  const [selected, setSelected] = useState('Home');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname.startsWith('/student/settings')) {
      setSelected('Settings');
    } else {
      switch (pathname) {
        case '/student/dashboard':
          setSelected('Home');
          break;
        case '/student/browse-sessions':
          setSelected('Browse Sessions');
          break;
        case '/student/browse-mentors':
          setSelected('Browse Mentors');
          break;
        case '/student/forum':
          setSelected('Help Forum');
          break;
        case '/student/content':
        setSelected('Content Library');
        break;
      case '/CVClinic':
        setSelected('CV Clinic');
        break;
      case '/student/challenges':
        setSelected('Challenges');
        break;
        case '/student/chat':
          setSelected('My Mentors');
          break;
        case '/student/my-bookings':
          setSelected('My Bookings');
          break;
          case '/job-portal': // Ensure path matches the route
          setSelected('Job Portal'); // Ensure selected state matches the nav item
          break;
        default:
          setSelected('Home');
      }
    }
  }, [location]);

  const handleNavigation = (option, path) => {
    setSelected(option);
    navigate(path);
  };

  const navOptions = [
    { name: 'Home', path: '/student/dashboard', icon: <AiOutlineHome /> },
    { name: 'Browse Sessions', path: '/student/browse-sessions', icon: <AiOutlineClockCircle /> },
    { name: 'Browse Mentors', path: '/student/browse-mentors', icon: <AiOutlineTeam /> },
    { name: 'Help Forum', path: '/student/forum', icon: <AiOutlineQuestionCircle /> },
    { name: 'Content Library', path: '/student/content', icon: <AiOutlineBook /> },
    { name: 'CV Clinic', path: '/CVClinic', icon: <AiOutlineFileText /> },
    { name: 'Challenges', path: '/student/challenges', icon: <AiOutlineTrophy /> },
    { name: 'My Mentors', path: '/student/chat', icon: <AiOutlineUser /> },
    { name: 'My Bookings', path: '/student/my-bookings', icon: <AiOutlineCalendar /> },
    { name: 'Job Portal', path: '/job-portal', icon: <AiOutlineSolution /> }, // Add this for Job Portal
    { name: 'Settings', path: '/student/settings', icon: <AiOutlineSetting /> },
  ];

  return (
    <nav
      className="fixed top-20 left-0 bg-gray-50 border-r border-border-color transition-all duration-300 lg:block hidden"
      style={{ width: 'clamp(160px, 20vw, 250px)', height: '95vh', overflowY: 'auto' }} // Adjusted height and overflow
    >
      <ul className="list-none p-0 m-0">
        {navOptions.map(option => (
          <li
            key={option.name}
            className={`px-6 py-4 flex items-center cursor-pointer text-base font-normal text-black hover:bg-gray-200 ${selected === option.name ? 'bg-gray-200 text-primary-color border-r-4 border-primary-color' : ''}`}
            onClick={() => handleNavigation(option.name, option.path)}
          >
            <span className="mr-2">{option.icon}</span> {/* Add icon before the text */}
            {option.name}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
