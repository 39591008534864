import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import styles from './ApproveInstructor.module.css';
import ClipLoader from 'react-spinners/ClipLoader';


const ApproveInstructor = () => {
  const [instructors, setInstructors] = useState([]);
  const [instructorSearchTerm, setInstructorSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchInstructors = async () => {
      setLoading(true); // Start loading
      try {
        const csrfToken = await fetchCsrfToken();
        const instructorResponse = await api.get('/admin/unverified-instructors', {
          headers: { 'CSRF-Token': csrfToken },
        });
        setInstructors(instructorResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false); // End loading
      }
    };
  
    fetchInstructors();
  }, []);
  

  const handleApprove = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to approve this instructor.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.patch(`/admin/instructor/${id}/approve`, null, {
            headers: { 'CSRF-Token': csrfToken },
          });
          setInstructors(instructors.filter(instructor => instructor._id !== id));
          Swal.fire('Approved!', 'The instructor has been approved.', 'success');
        } catch (err) {
          console.error('Error approving instructor:', err);
          Swal.fire('Error!', 'There was an error approving the instructor.', 'error');
        }
      }
    });
  };

  const handleDisapprove = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to disapprove this Mentor.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, disapprove it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.patch(`/admin/instructor/${id}/disapprove`, null, {
            headers: { 'CSRF-Token': csrfToken },
          });
          setInstructors(instructors.filter(instructor => instructor._id !== id));
          Swal.fire('Disapproved!', 'The Mentor has been disapproved.', 'success');
        } catch (err) {
          console.error('Error disapproving Mentor:', err);
          Swal.fire('Error!', 'There was an error disapproving the Mentor.', 'error');
        }
      }
    });
  };

  const handleDownloadCV = (cv) => {
    const link = document.createElement('a');
    link.href = `${cv}`;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredInstructors = instructors.filter(instructor =>
    instructor.firstName.toLowerCase().includes(instructorSearchTerm.toLowerCase()) ||
    instructor.lastName.toLowerCase().includes(instructorSearchTerm.toLowerCase()) ||
    instructor.email.toLowerCase().includes(instructorSearchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h2>Approve Mentor</h2>
      <input
        type="text"
        placeholder="Search Mentors..."
        value={instructorSearchTerm}
        onChange={(e) => setInstructorSearchTerm(e.target.value)}
        className={styles.searchBar}
      />
  
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={'#123abc'} loading={loading} size={50} />
        </div>
      ) : (
        <>
          {filteredInstructors.length > 0 ? (
            <ul className={styles.list}>
              {filteredInstructors.map(instructor => (
                <li key={instructor._id} className={styles.listItem}>
                  <div className={styles.instructorInfo}>
                    <p>{instructor.firstName} {instructor.lastName}</p>
                    <p className={styles.emailDetail}>{instructor.email}</p>
                  </div>
                  <div className={styles.buttonGroup}>
                    <button onClick={() => handleDownloadCV(instructor.cv)} className={styles.downloadButton}>View CV</button>
                    <button onClick={() => handleApprove(instructor._id)} className={styles.approveButton}>Approve</button>
                    <button onClick={() => handleDisapprove(instructor._id)} className={styles.disapproveButton}>Disapprove</button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No Mentor requests right now.</p>
          )}
        </>
      )}
    </div>
  );
  
};

export default ApproveInstructor;
