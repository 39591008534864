import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './VerifyEmail.module.css';
import { BASE_URL } from '../../Constants';

const VerifyEmail = () => {
  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar); // Use the class from module CSS

    // Cleanup by removing class when component unmounts
    return () => {
      document.body.classList.remove(styles.customScrollbar); // Use the class from module CSS
    };
  }, []);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (token) {
        try {
          const response = await axios.get(`${BASE_URL}/students/verify-email?token=${token}`);
          setMessage(response.data.msg);
          if (response.data.msg === 'Email verified successfully') {
            setTimeout(() => {
              navigate('/sign-in');
            }, 3000); // Redirect to signup page after 3 seconds
          }
        } catch (error) {
          setMessage('Email verification failed or the link has expired.');
        }
      } else {
        setMessage('Invalid verification link.');
      }
    };

    verifyEmail();
  }, []); // Empty dependency array to run only once

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Email Verification</h1>
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export default VerifyEmail;
