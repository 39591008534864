import React, { useEffect, useState } from 'react';
import api from '../../../utils/auth';
import { fetchCsrfToken } from '../../../utils/csrf';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';
import ChatComponent from '../../../components/Chat/ChatComponent';
import Modal from './Modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MyInstructors = () => {
  const [instructors, setInstructors] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [userId, setUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;

  useEffect(() => {
    const fetchChatConnections = async () => {
      const csrfToken = await fetchCsrfToken();
      try {
        const response = await api.get('/studentChatConnections/connections', {
          headers: {
            'CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
        setInstructors(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat connections:', error);
        setLoading(false);
      }
    };

    fetchChatConnections();
  }, []);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await api.get('/user/user-role');
        setUserId(response.data.id);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    if (instructors.length > 0) {
      const unsubscribeList = [];

      instructors.forEach((instructor) => {
        const q = query(
          collection(db, 'messages', instructor.chatRoomId, 'chat'),
          where('read', '==', false)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const unreadMessages = snapshot.docs.filter((doc) => doc.data().senderId !== userId).length;
          setUnreadCounts((prev) => ({
            ...prev,
            [instructor.chatRoomId]: unreadMessages,
          }));
        });

        unsubscribeList.push(unsubscribe);
      });

      return () => unsubscribeList.forEach((unsubscribe) => unsubscribe());
    }
  }, [instructors, userId]);

  useEffect(() => {
    if (instructors.length > 0 && !selectedInstructor) {
      const sortedInstructors = [...instructors].sort((a, b) => {
        return (unreadCounts[b.chatRoomId] || 0) - (unreadCounts[a.chatRoomId] || 0);
      });
      setSelectedInstructor(sortedInstructors[0]);
    }
  }, [instructors, unreadCounts, selectedInstructor]);

  const handleInstructorClick = (instructor) => {
    setSelectedInstructor(instructor);
    setIsModalOpen(window.innerWidth < 770);
  };

  const filteredInstructors = instructors.filter((instructor) =>
    `${instructor.firstName} ${instructor.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-5 mt-5 flex flex-col md:flex-row h-[calc(100vh-120px)] max-w-[1400px] max-h-[85vh] mx-auto border border-border-color rounded-lg">
      <div className="w-full md:w-1/5 p-4 border-r border-border-color">
        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
            className="w-full p-2 border border-border-color rounded"
          />
        </div>
        {loading ? (
          <ul className="list-none p-0">
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <li key={index} className="flex items-center p-3 mb-2">
                  <Skeleton circle={true} height={32} width={32} className="mr-2" />
                  <Skeleton width={120} height={16} />
                </li>
              ))}
          </ul>
        ) : filteredInstructors.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-full text-center">
            <p>No active chats with instructors yet.</p>
            <p className="text-sm text-gray-500">Start booking sessions to initiate conversations.</p>
            <div className="mt-4">
              <button
                className="bg-primary-color text-white py-2 px-4 rounded-md hover:bg-primary-color-hover mr-2"
                onClick={() => window.location.href = '/student/browse-mentors'}
              >
                Browse Mentors
              </button>
              <button
                className="bg-secondary-color text-primary-color py-2 px-4 rounded-md hover:bg-secondary-color-hover"
                onClick={() => window.location.href = '/student/browse-sessions'}
              >
                Browse Sessions
              </button>
            </div>
          </div>
        ) : (
          <ul className="list-none p-0">
            {filteredInstructors.map((instructor) => (
              <li
                key={instructor.chatRoomId}
                className={`flex items-center p-3 mb-2 cursor-pointer rounded ${
                  selectedInstructor && selectedInstructor.chatRoomId === instructor.chatRoomId
                    ? 'bg-gray-200'
                    : 'hover:bg-gray-200'
                }`}
                onClick={() => handleInstructorClick(instructor)}
              >
                <img
                  src={instructor.profilePicture ? `${instructor.profilePicture}` : defaultProfileImage}
                  alt={`${instructor.firstName} ${instructor.lastName}`}
                  onError={(e) => {
                    e.target.onerror = null; e.target.src = defaultProfileImage;
                  }}
                  className="rounded-full mr-2 w-8 h-8"
                />
                <div className="flex-grow">{instructor.firstName} {instructor.lastName}</div>
                {unreadCounts[instructor.chatRoomId] > 0 && (
                  <span className="bg-primary-color text-white px-2 py-1 rounded-full text-xs ml-2">
                    {unreadCounts[instructor.chatRoomId]}
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-full md:w-4/5 flex justify-center items-center">
        {selectedInstructor ? (
          <ChatComponent connectionId={selectedInstructor.chatRoomId} instructor={selectedInstructor} hideVideoChat />
        ) : (
          <div className="flex flex-col justify-center items-center h-full text-center">
            <p>No active chat available.</p>
            <p className="text-sm text-gray-500">Start booking sessions to begin conversations with your instructors.</p>
          </div>
        )}
      </div>

      {/* Modal for smaller screens */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
          {selectedInstructor && (
            <ChatComponent connectionId={selectedInstructor.chatRoomId} instructor={selectedInstructor} hideVideoChat />
          )}
        </Modal>
      )}
    </div>
  );
};

export default MyInstructors;
