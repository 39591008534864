import React, { useState } from 'react';
import AllRequests from '../../../components/InstructorForums/AllRequests';
import MyAnsweredRequests from '../../../components/InstructorForums/MyAnsweredRequests';
import PendingRequests from '../../../components/InstructorForums/Pending';

const InstructorForum = () => {
  const [selectedTab, setSelectedTab] = useState('all');

  return (
    <div className="max-w-[1400px] m-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">
        Help Forum
      </div>
      <div className="flex justify-start mb-4">
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'all' ? 'text-primary-color border-b-2 border-primary-color' : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('all')}
        >
          All Requests
        </button>
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'answered' ? 'text-primary-color border-b-2 border-primary-color' : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('answered')}
        >
          Answered
        </button>
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'pending' ? 'text-primary-color border-b-2 border-primary-color' : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('pending')}
        >
          Pending
        </button>
      </div>
      <div className="mt-6">
        {selectedTab === 'all' && <AllRequests />}
        {selectedTab === 'answered' && <MyAnsweredRequests />}
        {selectedTab === 'pending' && <PendingRequests />}
      </div>
    </div>
  );
};

export default InstructorForum;