import React, { useState, useRef } from 'react';
import api from '../utils/auth';
import ATSAnalysis from './ATSAnalysis'; // Import the ATS component
import ATSAnalysisResults from './ATSAnalysisResults'; // Enhanced component for ATS results
import useUserStore from '../store/useStudentStore';
import { useNavigate } from 'react-router-dom';

const CVClinic = () => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userData } = useUserStore();
  const [analysisType, setAnalysisType] = useState('general');
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // Get user planId from userStore
  const planId = userData.planId;


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('Please upload a CV file.');
      return;
    }

    const formData = new FormData();
    formData.append('cv', file);
    formData.append('analysisType', analysisType);

    try {
      setLoading(true);
      const response = await api.post('/students/upload-cv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setFeedback(response.data.feedback);
      fileInputRef.current.value = ''; // Clear the file input after upload
    } catch (error) {
      alert('Error uploading CV. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Helper function to clean up markdown-style data (remove '**', etc.)
  const cleanText = (text) => {
    if (!text) return ''; // Guard against undefined or null values
    return text.replace(/\*\*/g, '')
               .replace(/^\d+\.\s*/g, '') // Remove leading numbers
               .replace(/[-–—]/g, '') // Remove hyphens or dashes
               .trim();
  };

  // Handle empty bullets (ignore empty items)
  const renderListItems = (items) => {
    return items
      .filter((item) => cleanText(item)) // Filter out empty items
      .map((item, index) => <li key={index}>{cleanText(item)}</li>);
  };

  // Conditionally render upgrade message if planId is null
  if (!planId) {
    return (
      <div className="max-w-[1400px] mx-auto p-5">
        <div className="text-2xl font-bold text-primary-color mb-5">CV Clinic</div>
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5" role="alert">
          <p className="font-bold">Upgrade Required</p>
          <p>To access the CV Clinic features, please upgrade your subscription plan.</p>
          <button
          className="mt-4 bg-primary-color py-2 px-4 rounded-md text-white"
          onClick={() => navigate('/student/payment')} // Navigate to the upgrade page
          >
            Upgrade Now
          </button>
        </div>

      </div>
    );
  }

  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold text-primary-color mb-5">CV Clinic</div>

      {/* Warning note */}
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-5" role="alert">
        <p className="font-bold">Notice:</p>
        <p>If the results seem unexpected, please try to redo the scan after a few seconds.</p>
      </div>

      <div className="flex justify-center gap-4 mb-5">
        <button
          className={`bg-transparent ${analysisType === 'general' ? 'border-b-4 border-primary-color font-bold' : ''} text-lg p-2 cursor-pointer`}
          onClick={() => setAnalysisType('general')}
        >
          General Analysis
        </button>
        <button
          className={`bg-transparent ${analysisType === 'ats' ? 'border-b-4 border-primary-color font-bold' : ''} text-lg p-2 cursor-pointer`}
          onClick={() => setAnalysisType('ats')}
        >
          ATS Analysis
        </button>
      </div>

      {analysisType === 'general' && (
        <div className="flex justify-center">
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
          />
          <button onClick={handleButtonClick} disabled={loading} className="bg-primary-color text-white py-2 px-4 rounded">
            {loading ? 'Uploading...' : 'Upload CV'}
          </button>
        </div>
      )}

      {analysisType === 'ats' && (
        <ATSAnalysis setFeedback={setFeedback} />
      )}

      {/* Display results separately for General and ATS analysis */}
      {feedback && analysisType === 'general' && (
        <div className="mt-5">
          <div className="bg-white p-5 shadow rounded-lg">
            <h2 className="text-xl font-bold mb-4">Feedback Summary</h2>

            {/* Profile Summary */}
            <div className="mb-5">
              <h3 className="font-semibold">Profile Summary</h3>
              <p>{cleanText(feedback.profileSummary)}</p>
            </div>

            {/* Strengths */}
            <div className="mb-5">
              <h3 className="font-semibold">Strengths</h3>
              <ul className="list-disc ml-5">
                {renderListItems(feedback.strengths || [])}
              </ul>
            </div>

            {/* Weaknesses */}
            <div className="mb-5">
              <h3 className="font-semibold">Weaknesses</h3>
              <ul className="list-disc ml-5">
                {renderListItems(feedback.weaknesses || [])}
              </ul>
            </div>

            {/* Recommendations Table */}
            <div className="mb-5">
              <h3 className="font-semibold">Recommendations Table</h3>
              <table className="table-auto w-full text-left border">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Problem</th>
                    <th className="border px-4 py-2">Suggested Improvement</th>
                  </tr>
                </thead>
                <tbody>
                  {feedback.recommendationsProblematic?.length > 0 && feedback.recommendationsImprovements?.length > 0
                    ? feedback.recommendationsProblematic.map((problem, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2">{cleanText(problem)}</td>
                          <td className="border px-4 py-2">{cleanText(feedback.recommendationsImprovements[index] || 'No improvement provided.')}</td>
                        </tr>
                      ))
                    : (
                      <tr>
                        <td className="border px-4 py-2" colSpan="2">No recommendations provided.</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Render ATS analysis results using a different component */}
      {feedback && analysisType === 'ats' && (
        <ATSAnalysisResults feedback={feedback} />
      )}
    </div>
  );
};

export default CVClinic;
