import React from 'react';
import NavBar from './../../components/StudentNavbar/Navbar';
import Header from './../../components/StudentHeader/Header';

const StudentScreenLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header />
      <div className="flex flex-1">
        <NavBar />
        <main className="flex-1 p-5 mt-20 bg-gray-100 lg:ml-[clamp(160px,20vw,250px)]">
          {/* Applied margin-left only for lg and above */}
          <div className="max-w-full mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default StudentScreenLayout;
