import React, { useState } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert';
import AllRequests from '../../../components/StudentForums/AllRequests';
import YourRequests from '../../../components/StudentForums/YourRequests';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../../utils/auth';

const Tags = [
  "Transportation", "Education", "Engineering", "Healthcare", "Business", "Finance", "Marketing", "Sales",
  "Services", "Public Services", "Repair", "Maintenance", "Aviation", "Accountancy", "Insurance", "Food and Beverage",
  "Hospitality", "Tourism", "Recreation", "Information Technology", "Management", "Software Development", "Human Resources",
  "Legal", "Social Work", "Art and Design", "Communications", "Construction", "Environmental", "Science", "Technology",
  "Pharmaceuticals", "Real Estate", "Retail", "Security", "Logistics", "Manufacturing", "Nonprofit", "Project Management",
  "Research", "Sports", "Digital Marketing", "Data Analysis", "Cybersecurity", "Machine Learning", "Event Management",
  "Customer Service", "Graphic Design", "Consulting", "Entrepreneurship", "Agricultural Science", "Biotechnology", "Blockchain",
  "Cloud Computing", "Compliance", "Corporate Strategy", "Creative Writing", "Crisis Management", "E-commerce", "Educational Technology",
  "Elder Care", "Electrical Engineering", "Energy Sector", "Fashion Design", "Film Production", "Game Development", "Government Relations",
  "Health Informatics", "Humanitarian Aid", "Interior Design", "International Business", "Journalism", "Labor Relations",
  "Leadership Development", "Market Research", "Materials Science", "Mental Health", "Mobile App Development", "Nanotechnology",
  "Network Security", "Occupational Therapy", "Patent Law", "Performance Arts", "Petroleum Engineering", "Philanthropy",
  "Photography", "Physical Therapy", "Political Science", "Public Health", "Quality Assurance", "Renewable Energy", "Risk Management",
  "Robotics", "Social Media Management", "Supply Chain Management", "Urban Planning", "Veterinary Medicine", "Video Production",
  "Virtual Reality", "Web Design"
];

const tagOptions = Tags.map(tag => ({ value: tag, label: tag }));

const StudentForum = () => {
  const [selectedTab, setSelectedTab] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formError, setFormError] = useState('');
  const [formData, setFormData] = useState({
    helpRequest: '',
    mainChallenge: '',
    triedSoFar: '',
    desiredOutcome: '',
    tags: [],
  });
  const [buttonloading, setButtonLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTagChange = (selectedOptions) => {
    setFormData({ ...formData, tags: selectedOptions || [] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const { helpRequest, mainChallenge, triedSoFar, desiredOutcome, tags } = formData;

    if (!helpRequest || !mainChallenge || !triedSoFar || !desiredOutcome) {
      setFormError('Please fill in all required fields.');
      setButtonLoading(false);
      return;
    }

    setFormError('');

    Swal({
      title: "Submit Help Request",
      text: "Would you like to submit this help request?",
      icon: null, // Removed the icon
      buttons: true,
      dangerMode: false, // Set to false to remove the 'danger' mode
    }).then(async (willSubmit) => {
      if (willSubmit) {
        const helpRequestData = {
          helpRequest,
          mainChallenge,
          triedSoFar,
          desiredOutcome,
          tags: tags.map(tag => tag.value),
        };

        try {
          const response = await api.post('/help-forum/create', helpRequestData);
          if (response.status === 201) {
            Swal("Success", "Your help request has been submitted successfully!", "success");
            setIsModalOpen(false);
          } else {
            throw new Error('Failed to submit help request');
          }
        } catch (error) {
          console.error('Error submitting help request:', error);
          Swal("Error", "Failed to submit help request.", "error");
        } finally {
          setButtonLoading(false);
        }
      }
    });
  };

  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">
        Help Forum
      </div>
      <div className="flex flex-col md:flex-row justify-between mb-4">
        <div className="flex mb-4 md:mb-0">
          <button
            className={`py-2.5 px-4 text-base font-medium ${
              selectedTab === 'all' ? 'text-primary-color border-b-2 border-primary-color' : 'text-gray-700 border-b border-border-color'
            }`}
            onClick={() => setSelectedTab('all')}
          >
            All Requests
          </button>
          <button
            className={`py-2.5 px-4 text-base font-medium ${
              selectedTab === 'your' ? 'text-primary-color border-b-2 border-primary-color' : 'text-gray-700 border-b border-border-color'
            }`}
            onClick={() => setSelectedTab('your')}
          >
            Your Requests
          </button>
        </div>
        <button className="py-2 px-4 border border-primary-color text-primary-color rounded-md hover:bg-primary-color hover:text-white transition duration-300" onClick={() => setIsModalOpen(true)}>
          Apply For Help
        </button>
      </div>
      <div className="mt-6">
        {buttonloading ? (
          <>
            <Skeleton height={30} count={1} className="mb-2" />
            <Skeleton height={80} count={3} />
          </>
        ) : (
          <>
            {selectedTab === 'all' && <AllRequests />}
            {selectedTab === 'your' && <YourRequests />}
          </>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl overflow-y-auto max-h-[80vh] relative">
            <span className="absolute top-4 right-4 text-gray-500 text-2xl cursor-pointer" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h2 className="text-xl font-bold mb-4">Add Help Request</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block font-bold mb-2">Describe help request in one sentence</label>
                <input
                  type="text"
                  name="helpRequest"
                  value={formData.helpRequest}
                  onChange={handleInputChange}
                  minLength="50"
                  maxLength="100"
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">What is your main challenge</label>
                <textarea
                  name="mainChallenge"
                  value={formData.mainChallenge}
                  onChange={handleInputChange}
                  rows="6"
                  minLength="100"
                  maxLength="500"
                  required
                  className="w-full p-2 border border-gray-300 rounded resize-none"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">What have you tried so far</label>
                <textarea
                  name="triedSoFar"
                  value={formData.triedSoFar}
                  onChange={handleInputChange}
                  rows="6"
                  minLength="100"
                  maxLength="500"
                  required
                  className="w-full p-2 border border-gray-300 rounded resize-none"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">What's the desired outcome of this request</label>
                <textarea
                  name="desiredOutcome"
                  value={formData.desiredOutcome}
                  onChange={handleInputChange}
                  rows="6"
                  minLength="100"
                  maxLength="500"
                  required
                  className="w-full p-2 border border-gray-300 rounded resize-none"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">Tags (select 1 to 3)</label>
                <Select
                  isMulti
                  name="tags"
                  options={tagOptions}
                  onChange={handleTagChange}
                  value={formData.tags}
                  closeMenuOnSelect={false}
                  placeholder="Select tags..."
                  menuPlacement="auto"
                  menuShouldScrollIntoView={false}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              {/* Error message placed right above the submit button */}
              {formError && <p className="text-red-500 mb-4">{formError}</p>}
              <button
                type="submit"
                className="py-2 px-4 bg-primary-color text-white rounded-md hover:bg-primary-color-hover transition duration-300"
                onClick={handleSubmit}
              >
                {buttonloading ? (
                  <span className="flex items-center gap-2">
                    Submit Request
                    <span className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full text-white"></span>
                  </span>
                ) : (
                  'Submit Request'
                )}
              </button>            
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentForum;