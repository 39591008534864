import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import api from '../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import useUserStore from '../store/useStudentStore';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const plans = [
  {
    id: process.env.REACT_APP_PATHFINDER_PLAN_ID,
    name: 'Pathfinder Plan',
    priceQuarterly: '£60/quarter',
    priceMonthly: '£20/month',
    benefits: [
      'Help Forum',
      'Browse Upcoming Sessions',
      'Book 1-1 Calls with Mentors',
      'Access to Live Group Calls',
      'CV Clinic',
      'Access to Mentors Booking',
      'Recorded Sessions',
    ],
  },
  {
    id: process.env.REACT_APP_PRO_PLAN_ID,
    name: 'Pro Plan',
    priceQuarterly: '£105/quarter',
    priceMonthly: '£35/month',
    benefits: [
      'Help Forum',
      'Browse Upcoming Sessions',
      'Book 1-1 Calls with Mentors',
      '2 x Accountability Calls',
      'Access to Live Group Calls',
      'Unlimited Challenges',
      'CV Clinic',
      'Access to Mentors Booking',
      'Recorded Sessions',
    ],
  },
];

const PlanSelection = ({ onSelectPlan }) => (
  <div className="flex justify-center items-center h-auto md:h-screen max-w-[1400px] mx-auto px-4">
    <div className="border border-gray-300 max-w-[1200px] mx-auto my-12 p-4 md:p-8 bg-white rounded-lg text-center relative pb-16 md:mb-5">
      <h2 className="mb-6 text-2xl font-bold mt-8">Choose Your Plan</h2>
      <div className="flex flex-col md:flex-row justify-around gap-5 md:gap-2">
        {plans.map((plan, planIndex) => (
          <div
            key={plan.id}
            className={`relative border border-gray-300 rounded-lg p-5 w-full md:w-[350px] mb-8 md:mb-5 ${
              planIndex === 1 ? 'bg-[#107F9A] text-white' : 'bg-white text-black'
            } flex flex-col gap-4 cursor-pointer transition-transform transform hover:translate-y-[-5px]`}
            onClick={() => onSelectPlan(plan)}
          >
            <h3 className={`text-xl ${planIndex === 1 ? 'text-white' : 'text-black'}`}>{plan.name}</h3>
            <p className="text-2xl">{plan.priceQuarterly}</p>
            <p className="text-sm opacity-70">{plan.priceMonthly}</p>
            <ul className="list-none text-left">
              {plan.benefits.map((benefit, benefitIndex) => (
                <li
                  key={benefitIndex}
                  className={`text-sm mb-2 flex items-center ${
                    planIndex === 1 ? 'text-white' : 'text-[#107F9A]'
                  }`}
                >
                  <FontAwesomeIcon icon={faCheck} className="mr-2" /> {benefit}
                </li>
              ))}
            </ul>
            <button
              className={`absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 py-2 px-8 rounded ${
                planIndex === 1
                  ? 'bg-white text-[#107F9A] hover:bg-gray-200 border border-[#107F9A]'
                  : 'bg-[#107F9A] text-white hover:bg-[#0a6e86]'
              }`}
            >
              Get Started
            </button>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const SubscriptionPayment = ({ plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { userData, setUserData } = useUserStore();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      console.error('Stripe or Elements is not loaded');
      return;
    }
  
    setIsSubmitting(true);
    const cardElement = elements.getElement(CardElement);
  
    if (!cardElement) {
      console.error('CardElement is not found');
      setIsSubmitting(false);
      return;
    }
  
    const confirm = await Swal.fire({
      title: 'Confirm Subscription',
      text: 'Are you sure you want to subscribe to this plan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, subscribe now!',
    });
  
    if (confirm.isConfirmed) {
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
  
        if (error) {
          Swal.fire('Payment Error', 'Invalid payment method. Please try again.', 'error');
          setIsSubmitting(false);
          return;
        }
  
        const response = await api.post('/create-subscription', {
          paymentMethodId: paymentMethod.id,
          planId: plan.id,
        });
  
        if (response.data.requiresAction) {
          const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
            response.data.paymentIntentClientSecret
          );
  
          if (confirmError) {
            Swal.fire(
              'Payment Failed',
              confirmError.message.includes('insufficient funds')
                ? 'Insufficient funds. Please try another card.'
                : '3D Secure authentication failed. Please try again.',
              'error'
            );
            setIsSubmitting(false);
            return;
          }
  
          if (paymentIntent.status === 'succeeded') {
            await api.post('/finalize-subscription', { paymentIntentId: paymentIntent.id });
            Swal.fire('Success', 'You have successfully subscribed!', 'success');
          } else {
            Swal.fire('Payment Failed', 'Unable to process your payment. Try another card.', 'error');
          }
        } else if (response.data.error) {
          Swal.fire('Subscription Error', response.data.error, 'error');
        }
  
        setUserData({ ...userData, planId: plan.id });
        navigate('/student/dashboard');
      } catch (error) {
        Swal.fire('Subscription Failed', error.response?.data?.error || 'Please try again.', 'error');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen max-w-[1200px] mx-auto px-4">
      <div className="border border-gray-200 max-w-[500px] w-full mx-auto my-12 p-8 bg-white rounded-lg shadow-md relative">
        {/* Back Button */}
        <button
          className="absolute top-6 left-6 text-[#107F9A] hover:text-[#055a79] font-medium text-lg flex items-center"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
        </button>

        {/* Plan Title */}
        <h2 className="mb-4 mt-4 text-gray-800 text-2xl font-semibold text-center">
          Upgrade to {plan.name}
        </h2>
        <p className="text-lg text-gray-600 text-center mb-6">{plan.priceQuarterly}</p>

        {/* Card Form */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-5">
          {/* Card Details Label */}
          <label className="flex flex-col text-left text-gray-800 font-medium">
            Card Details
            <div className="p-4 mt-3 border border-gray-300 rounded-md bg-gray-50 shadow-sm">
              <CardElement
                className="custom-card-input"
                options={{
                  style: {
                    base: {
                      fontSize: '18px',
                      color: '#424770',
                      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    complete: { color: '#4caf50' },
                    invalid: { color: '#f44336', iconColor: '#f44336' },
                  },
                }}
              />
            </div>
          </label>

          {/* Subscribe Button */}
          <button
            type="submit"
            disabled={isSubmitting || !stripe}
            className="bg-[#107F9A] text-white py-3 rounded-lg font-semibold hover:bg-[#0a6e86] transition-all disabled:bg-gray-300 disabled:cursor-not-allowed text-lg"
          >
            Subscribe Now
          </button>
        </form>
      </div>
    </div>
  );
};

const PaymentPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  return (
    <Elements stripe={stripePromise}>
      {!selectedPlan ? (
        <PlanSelection onSelectPlan={(plan) => setSelectedPlan(plan)} />
      ) : (
        <SubscriptionPayment plan={selectedPlan} />
      )}
    </Elements>
  );
};

export default PaymentPage;