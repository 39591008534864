import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';

const allSkills = [
  "Transportation", "Education", "Engineering", "Healthcare", "Business", "Finance", "Marketing", "Sales",
  "Services", "Public Services", "Repair", "Maintenance", "Aviation", "Accountancy", "Insurance", "Food and Beverage",
  "Hospitality", "Tourism", "Recreation", "Information Technology", "Management", "Software Development", "Human Resources",
  "Legal", "Social Work", "Art and Design", "Communications", "Construction", "Environmental", "Science", "Technology",
  "Pharmaceuticals", "Real Estate", "Retail", "Security", "Logistics", "Manufacturing", "Nonprofit", "Project Management",
  "Research", "Sports", "Digital Marketing", "Data Analysis", "Cybersecurity", "Machine Learning", "Event Management",
  "Customer Service", "Graphic Design", "Consulting", "Entrepreneurship", "Agricultural Science", "Biotechnology", "Blockchain",
  "Cloud Computing", "Compliance", "Corporate Strategy", "Creative Writing", "Crisis Management", "E-commerce", "Educational Technology",
  "Elder Care", "Electrical Engineering", "Energy Sector", "Fashion Design", "Film Production", "Game Development", "Government Relations",
  "Health Informatics", "Humanitarian Aid", "Interior Design", "International Business", "Journalism", "Labor Relations",
  "Leadership Development", "Market Research", "Materials Science", "Mental Health", "Mobile App Development", "Nanotechnology",
  "Network Security", "Occupational Therapy", "Patent Law", "Performance Arts", "Petroleum Engineering", "Philanthropy",
  "Photography", "Physical Therapy", "Political Science", "Public Health", "Quality Assurance", "Renewable Energy", "Risk Management",
  "Robotics", "Social Media Management", "Supply Chain Management", "Urban Planning", "Veterinary Medicine", "Video Production",
  "Virtual Reality", "Web Design"
];

const predefinedSessionGoals = [
  "Improve communication skills", "Enhance leadership abilities", "Gain technical expertise",
  "Develop problem-solving skills", "Learn time management", "Understand project management",
  "Master financial planning", "Acquire marketing strategies", "Enhance team collaboration",
  "Build self-confidence", "Legal Consultation", "Job Finding Assistance"
];

const InstructorSessionForm = ({ instructorId, closeModal }) => {
  const [sessionName, setSessionName] = useState('');
  const [date, setDate] = useState(new Date());
  const [availability, setAvailability] = useState({
    fromHour: '03',
    fromPeriod: 'PM',
    toHour: '04',
    toPeriod: 'PM'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [addedSkills, setAddedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState(allSkills); // Start with all skills visible
  const [description, setDescription] = useState('');
  const [sessionGoals, setSessionGoals] = useState([]);
  const [prerequisites, setPrerequisites] = useState('');
  const [formError, setFormError] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (searchTerm) {
      setFilteredSkills(
        allSkills
          .filter(skill =>
            skill.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .slice(0, 5)
      );
    } else {
      setFilteredSkills(allSkills); // Show all skills when search term is empty
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setDropdownVisible(true); // Show dropdown when input is focused
  };

  const handleAddSkill = skill => {
    if (addedSkills.length < 5 && !addedSkills.includes(skill)) {
      setAddedSkills([...addedSkills, skill]);
      setSearchTerm('');
      setDropdownVisible(false);
    }
  };

  const handleRemoveSkill = skill => {
    setAddedSkills(addedSkills.filter(s => s !== skill));
  };

  const handleAvailabilityChange = (field, value) => {
    let newAvailability = {
      ...availability,
      [field]: value
    };

    // Automatically adjust 'to' time if 'from' time changes
    if (field === 'fromHour' || field === 'fromPeriod') {
      let fromHour = parseInt(newAvailability.fromHour, 10);
      if (newAvailability.fromPeriod === 'PM' && fromHour !== 12) fromHour += 12;
      if (newAvailability.fromPeriod === 'AM' && fromHour === 12) fromHour = 0;

      let toHour = fromHour + 1;
      if (toHour === 12) {
        newAvailability.toHour = '12';
        newAvailability.toPeriod = newAvailability.fromPeriod === 'AM' ? 'PM' : 'AM';
      } else if (toHour > 12) {
        newAvailability.toHour = (toHour - 12).toString().padStart(2, '0');
        newAvailability.toPeriod = 'PM';
      } else {
        newAvailability.toHour = toHour.toString().padStart(2, '0');
        newAvailability.toPeriod = newAvailability.fromPeriod;
      }
    }

    setAvailability(newAvailability);
  };

  const handleAddSessionGoal = goal => {
    if (!sessionGoals.includes(goal)) {
      setSessionGoals([...sessionGoals, goal]);
    }
  };

  const handleRemoveSessionGoal = goal => {
    setSessionGoals(sessionGoals.filter(g => g !== goal));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!sessionName || !date || !description || sessionGoals.length === 0 || !prerequisites) {
      setFormError('Please fill in all required fields.');
      return;
    }

    setFormError('');

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to submit this session request?', // Updated text
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const sessionData = {
          sessionName,
          date,
          availability,
          skills: addedSkills,
          description,
          sessionGoals,
          prerequisites,
          instructorId,
          isVerified: false
        };

        try {
          const csrfToken = await fetchCsrfToken();
          const response = await api.post('/instructor-sessions', sessionData, {
            headers: {
              'Content-Type': 'application/json',
              'CSRF-Token': csrfToken
            }
          });

          if (response.status === 201) {
            Swal.fire('Success!', 'Your session request has been submitted for review. You will be notified via email within 48-72 hours.', 'success')
              .then(() => {
                closeModal(); // Close the modal after success
              });

            // Reset form state
            setSessionName('');
            setDate(new Date());
            setAvailability({
              fromHour: '03',
              fromPeriod: 'PM',
              toHour: '04',
              toPeriod: 'PM'
            });
            setAddedSkills([]);
            setDescription('');
            setSessionGoals([]);
            setPrerequisites('');
          } else if (response.status === 400 && response.data.message) {
            // Display specific backend error message
            Swal.fire('Error', response.data.message, 'error');
          } else {
            throw new Error('Failed to submit session request');
          }
        } catch (error) {
          console.error('Error submitting session request:', error);
          Swal.fire('Error', 'Failed to submit session request. You may have booked a session for this month already.', 'error');
        }
      }
    });
  };
  
  

  const renderTimeSelect = (fieldHour, fieldPeriod, isFrom) => (
    <div className="flex items-center space-x-1">
      <input
        type="number"
        min="1"
        max="12"
        value={availability[fieldHour]}
        onChange={e => handleAvailabilityChange(fieldHour, e.target.value)}
        className="w-14 p-2 border border-gray-300 rounded text-center"
      />
      <select
        value={availability[fieldPeriod]}
        onChange={e => handleAvailabilityChange(fieldPeriod, e.target.value)}
        className="p-2 border border-gray-300 rounded"
      >
        {isFrom ? (
          <>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </>
        ) : (
          <>
            {availability.fromPeriod === 'AM' && <option value="AM">AM</option>}
            <option value="PM">PM</option>
          </>
        )}
      </select>
    </div>
  );

  const minDate = new Date();
  minDate.setDate(minDate.getDate());
  const maxDate = new Date(minDate);
  maxDate.setDate(minDate.getDate() + 30); // 30 days from the min date

  return (
    <div className="p-5 max-w-2xl mx-auto">
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="mt-4">
          <label htmlFor="sessionName" className="text-base font-medium">
            Enter session title:
          </label>
          <input
            type="text"
            id="sessionName"
            value={sessionName}
            onChange={e => setSessionName(e.target.value)}
            className="mt-2 w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mt-4">
          <label htmlFor="date" className="text-base font-medium">
            Date:
          </label>
          <input
            type="date"
            id="date"
            value={date.toISOString().split('T')[0]}
            min={minDate.toISOString().split('T')[0]}
            max={maxDate.toISOString().split('T')[0]}
            onChange={e => setDate(new Date(e.target.value))}
            className="mt-2 w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mt-4">
          <label htmlFor="time" className="text-base font-medium">
            Time:
          </label>
          <div className="flex flex-col md:flex-row mt-2 items-center">
            {renderTimeSelect('fromHour', 'fromPeriod', true)}
            <span className="mx-2">to</span>
            {renderTimeSelect('toHour', 'toPeriod', false)}
          </div>
          <p className="mt-2 text-yellow-700 bg-yellow-100 p-2 rounded border-l-4 border-yellow-500">
            * Please carefully select your availability times. Ensure your "from" time is before your "to" time.
          </p>
        </div>

        <div className="mt-4">
          <label htmlFor="skills" className="text-base font-medium">
            Skills (up to 5):
          </label>
          <div className="relative mt-2" ref={dropdownRef}>
            <input
              type="text"
              placeholder="Search for a skill..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              onFocus={handleFocus}
              className="w-full p-2 border border-gray-300 rounded"
            />
            {isDropdownVisible && filteredSkills.length > 0 && (
              <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded mt-1 max-h-40 overflow-y-auto z-10">
                {filteredSkills.map(skill => (
                  <div
                    key={skill}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleAddSkill(skill)}
                  >
                    {skill}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {addedSkills.map(skill => (
              <div
                key={skill}
                className="inline-block bg-teal-100 text-teal-600 px-3 py-1 rounded-full cursor-pointer border border-teal-600"
                onClick={() => handleRemoveSkill(skill)}
              >
                {skill}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="sessionGoals" className="text-base font-medium">
            Session Goals:
          </label>
          <div className="relative mt-2">
            <select
              value=""
              onChange={e => handleAddSessionGoal(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="" disabled>Select a goal...</option>
              {predefinedSessionGoals.map(goal => (
                <option key={goal} value={goal}>
                  {goal}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {sessionGoals.map(goal => (
              <div
                key={goal}
                className="inline-block bg-teal-100 text-teal-600 px-3 py-1 rounded-full cursor-pointer border border-teal-600"
                onClick={() => handleRemoveSessionGoal(goal)}
              >
                {goal}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="description" className="text-base font-medium">
            Enter session description:
          </label>
          <textarea
            id="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            className="mt-2 w-full p-2 border border-gray-300 rounded h-24 resize-none"
          ></textarea>
        </div>

        <div className="mt-4">
          <label htmlFor="prerequisites" className="text-base font-medium">
            Prerequisites:
          </label>
          <textarea
            id="prerequisites"
            value={prerequisites}
            onChange={e => setPrerequisites(e.target.value)}
            className="mt-2 w-full p-2 border border-gray-300 rounded h-24 resize-none"
          ></textarea>
        </div>

        {formError && <div className="text-red-500 text-sm mb-2">{formError}</div>}
        <div className="flex justify-end mt-4">
          <button type="submit" className="bg-primary-color hover:bg-primary-color-hover text-white py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default InstructorSessionForm;