import React, { useState, useEffect } from 'react';
import { AiOutlineSolution } from 'react-icons/ai';

const ComingSoon = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }); // Simulate loading for 2 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="max-w-[1400px] mx-auto p-5 flex flex-col items-center justify-center h-[80vh] bg-background-color text-center">
      {loading ? (
        <div className="animate-pulse space-y-4">
          <div className="h-12 w-12 bg-gray-300 rounded-full mx-auto"></div>
          <div className="h-8 w-48 bg-gray-300 rounded-md mx-auto"></div>
          <div className="h-4 w-64 bg-gray-300 rounded-md mx-auto"></div>
        </div>
      ) : (
        <>
          <AiOutlineSolution size={80} className="text-primary-color mb-6" />
          <h1 className="text-3xl sm:text-4xl font-bold text-text-color mb-4">Job Portal Coming Soon!</h1>
          <p className="text-lg sm:text-xl text-secondary-text-color max-w-md">
            We are working hard to bring you a new and improved job portal experience. Stay tuned!
          </p>
        </>
      )}
    </div>
  );
};

export default ComingSoon;
