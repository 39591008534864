import React from 'react';
import StudentSettingsLayout from '../../../layouts/StudentSettingsLayout/StudentSettingsLayout';

const StudentSettings = () => {
  return (
    <StudentSettingsLayout />
  );
};

export default StudentSettings;
