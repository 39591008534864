import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import moment from 'moment';
import useUserStore from '../../store/useStudentStore'; // Import Zustand store

const Education = () => {
  const { userData, setUserData } = useUserStore(); // Get userData and setUserData from Zustand
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentEducationId, setCurrentEducationId] = useState(null);
  const [degree, setDegree] = useState('');
  const [institution, setInstitution] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [currentlyStudying, setCurrentlyStudying] = useState(false);
  const [description, setDescription] = useState('');
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [educations, setEducations] = useState(userData.education || []); // Set initial state from Zustand store
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingSubmit, setLoadingSubmit] = useState(false); // Loading state for button

  const fieldOfStudyOptions = [
    'Computer Science', 'Engineering', 'Business', 'Education', 'Health Sciences', 'Arts',
    'Social Sciences', 'Law', 'Natural Sciences', 'Mathematics', 'Humanities', 'Other'
  ];

  const educationLevelOptions = [
    'High School', 'Associate Degree', 'Bachelor\'s Degree', 'Master\'s Degree', 'PhD', 'Certificate'
  ];

  const formatDate = (date) => {
    if (!date) return 'Present';
    return moment(date).format('MMMM YYYY'); // Format date to 'Month Year'
  };

  // Fetch the education data from the backend on component mount if necessary
  useEffect(() => {
    const fetchEducations = async () => {
      if (!educations.length) { // Fetch only if educations are not present
        setLoading(true);
        try {
          const csrfToken = await fetchCsrfToken();
          const response = await api.get(`/studentsEducation/getEducationData`, {
            headers: { 'CSRF-Token': csrfToken },
          });

          // Compare fetched data with current Zustand data to avoid unnecessary updates
          if (JSON.stringify(userData.education) !== JSON.stringify(response.data)) {
            setEducations(response.data);

            // Update Zustand store only if data has changed
            setUserData({
              ...userData,
              education: response.data,
            });
          }
        } catch (error) {
          console.error('Error fetching education data:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    
    fetchEducations(); 
  }, [setUserData, userData.education, educations]);

  const validateForm = () => {
    const newErrors = {};
    if (!degree) newErrors.degree = true;
    if (!institution) newErrors.institution = true;
    if (!from) newErrors.from = true;
    if (!currentlyStudying && !to) newErrors.to = true;
    if (!fieldOfStudy) newErrors.fieldOfStudy = true;
    if (!educationLevel) newErrors.educationLevel = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addEducation = async () => {
    if (!validateForm()) return;

    const newEducation = {
      degree,
      institution,
      from,
      to,
      currentlyStudying,
      description,
      fieldOfStudy,
      educationLevel
    };

    setLoadingSubmit(true); // Show loader when submitting
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.post(`/studentsEducation/education`, newEducation, {
        headers: { 'CSRF-Token': csrfToken },
      });
      const updatedEducations = [...educations, response.data];
      setEducations(updatedEducations);
      setUserData({
        ...userData,
        education: updatedEducations, // Update Zustand store
      });
      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error adding education:', error);
    } finally {
      setLoadingSubmit(false); // Hide loader after completion
    }
  };

  const updateEducation = async () => {
    if (!validateForm()) return;

    const updatedEducation = {
      degree,
      institution,
      from,
      to,
      currentlyStudying,
      description,
      fieldOfStudy,
      educationLevel
    };

    setLoadingSubmit(true); // Show loader when submitting
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.put(`/studentsEducation/education/${currentEducationId}`, updatedEducation, {
        headers: { 'CSRF-Token': csrfToken },
      });
      const updatedEducations = educations.map(edu => edu._id === currentEducationId ? response.data : edu);
      setEducations(updatedEducations);
      setUserData({
        ...userData,
        education: updatedEducations, // Update Zustand store
      });
      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error updating education:', error);
    } finally {
      setLoadingSubmit(false); // Hide loader after completion
    }
  };

  const deleteEducation = async (id) => {
    try {
      const csrfToken = await fetchCsrfToken();
      await api.delete(`/studentsEducation/education/${id}`, {
        headers: { 'CSRF-Token': csrfToken },
      });
      const updatedEducations = educations.filter(edu => edu._id !== id);
      setEducations(updatedEducations);
      setUserData({
        ...userData,
        education: updatedEducations, // Update Zustand store
      });
    } catch (error) {
      console.error('Error deleting education:', error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this education?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEducation(id);
        swal("Deleted!", "Your education has been deleted.", "success");
      } else {
        swal("Cancelled", "Your education is safe.", "error");
      }
    });
  };

  const handleEdit = (education) => {
    setIsEdit(true);
    setCurrentEducationId(education._id);
    setDegree(education.degree);
    setInstitution(education.institution);
    setFrom(education.from);
    setTo(education.to);
    setCurrentlyStudying(education.currentlyStudying);
    setDescription(education.description);
    setFieldOfStudy(education.fieldOfStudy);
    setEducationLevel(education.educationLevel);
    setModalIsOpen(true);
  };

  const handleAdd = () => {
    setIsEdit(false);
    clearForm();
    setModalIsOpen(true);
  };

  const clearForm = () => {
    setDegree('');
    setInstitution('');
    setFrom('');
    setTo('');
    setCurrentlyStudying(false);
    setDescription('');
    setFieldOfStudy('');
    setEducationLevel('');
    setErrors({});
  };

  const handleSave = () => {
    if (isEdit) {
      updateEducation();
    } else {
      addEducation();
    }
  };

  const EducationSkeleton = () => (
    <div className="border border-black/20 p-6 mb-4 rounded-2xl bg-white">
      <Skeleton width={120} height={20} className="mb-2" />
      <Skeleton width={`60%`} height={15} className="mb-2" />
      <Skeleton width={`50%`} height={15} className="mb-2" />
      <Skeleton width={`80%`} height={15} />
    </div>
  );

  return (
    <div className="max-w-[700px] p-5">
      <div className="flex justify-between mb-6">
        <div>
          <div className="text-2xl font-bold">Education</div>
          <p className="text-sm text-gray-600 mb-2">Your educational background.</p>
        </div>
        <button className="bg-white text-primary-color text-sm font-normal border border-primary-color px-0 cursor-pointer rounded-lg mb-5 hover:bg-primary-color-hover hover:text-white md:px-4" onClick={handleAdd}>+ Add Education</button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="absolute max-h-[80vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 box-border rounded-lg outline-none w-[900px] max-w-[90%] m-auto overflow-y-auto"
        overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-[3000]"
        contentLabel="Add Education"
      >
        <div className="mt-2 flex justify-between items-center border-b border-border-color pb-2">
          <div className="mx-auto">
            <h2 className="m-0 text-lg font-bold">{isEdit ? "Edit Education" : "Add Education"}</h2>
          </div>
          <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => setModalIsOpen(false)} />
        </div>
        <form className="mt-4">
          <div className="flex flex-wrap gap-4">
            <div className="flex-1 flex flex-col mb-4 w-full">
              <label className="font-bold text-base">Degree</label>
              <input 
                type="text" 
                value={degree} 
                onChange={(e) => setDegree(e.target.value)} 
                className={`mt-2 p-3 border rounded-md text-sm w-full ${errors.degree ? 'border-red-500' : 'border-border-color'}`} 
              />
            </div>
            <div className="flex-2 flex flex-col mb-4 w-full">
              <label className="font-bold text-base">Institution</label>
              <input 
                type="text" 
                value={institution} 
                onChange={(e) => setInstitution(e.target.value)} 
                className={`mt-2 p-3 border rounded-md text-sm w-full ${errors.institution ? 'border-red-500' : 'border-border-color'}`} 
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="flex-1 flex flex-col mb-4 w-full">
              <label className="font-bold text-base">From</label>
              <input 
                type="date" 
                value={from} 
                onChange={(e) => setFrom(e.target.value)} 
                className={`mt-2 p-3 border rounded-md text-sm w-full ${errors.from ? 'border-red-500' : 'border-border-color'}`} 
              />
            </div>
            <div className="flex-1 flex flex-col mb-4 w-full">
              <label className="font-bold text-base">To</label>
              <input 
                type="date" 
                value={to} 
                onChange={(e) => setTo(e.target.value)} 
                disabled={currentlyStudying}
                className={`mt-2 p-3 border rounded-md text-sm w-full ${errors.to ? 'border-red-500' : 'border-border-color'} ${currentlyStudying ? 'bg-gray-200 cursor-not-allowed' : ''}`} 
              />
            </div>
          </div>
          <div className="flex items-center mb-4">
            <label className="flex items-center text-base">
              <input type="checkbox" checked={currentlyStudying} onChange={(e) => setCurrentlyStudying(e.target.checked)} className="mr-2" />
              Currently studying
            </label>
          </div>
          <div className="flex flex-col mb-4 w-full">
            <label className="font-bold text-base">Field of Study</label>
            <select 
              value={fieldOfStudy} 
              onChange={(e) => setFieldOfStudy(e.target.value)} 
              className={`mt-2 p-3 border rounded-md text-sm w-full ${errors.fieldOfStudy ? 'border-red-500' : 'border-border-color'}`}
            >
              <option value="">Select field of study</option>
              {fieldOfStudyOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col mb-4 w-full">
            <label className="font-bold text-base">Education Level</label>
            <select 
              value={educationLevel} 
              onChange={(e) => setEducationLevel(e.target.value)} 
              className={`mt-2 p-3 border rounded-md text-sm w-full ${errors.educationLevel ? 'border-red-500' : 'border-border-color'}`}
            >
              <option value="">Select education level</option>
              {educationLevelOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col mb-4 w-full">
            <label className="font-bold text-base">Description</label>
            <textarea rows={4} value={description} onChange={(e) => setDescription(e.target.value)} className="mt-2 p-3 border rounded-md text-sm w-full border-border-color"></textarea>
          </div>
          <div className="flex justify-end gap-2 my-10">
            <button
              type="button"
              onClick={handleSave}
              className="bg-primary-color text-white py-4 px-6 rounded-md cursor-pointer hover:bg-primary-color-hover"
              disabled={loadingSubmit} // Disable button while loading
            >
              <div className="flex justify-center items-center">
                {loadingSubmit ? (
                  <div className="loader" style={{ width: '24px', height: '24px' }}></div> // Show loader
                ) : (
                  isEdit ? "Save Changes" : "Add Education"
                )}
              </div>
            </button>
          </div>
        </form>
      </Modal>

      {loading ? (
        <>
          {[...Array(3)].map((_, index) => (
            <EducationSkeleton key={index} />
          ))}
        </>
      ) : (
        educations.map((edu) => (
          <div key={edu._id} className="border border-black/20 p-6 mb-4 rounded-2xl bg-white">
            <div className="flex justify-between items-center text-primary-color font-semibold text-lg mb-2">
              <h3 className="m-0 truncate">{edu.degree}</h3>
              <div>
                <FontAwesomeIcon icon={faEdit} className="cursor-pointer text-primary-color ml-3" onClick={() => handleEdit(edu)} />
                <FontAwesomeIcon icon={faTrash} className="cursor-pointer text-primary-color ml-3" onClick={() => handleDelete(edu._id)} />
              </div>
            </div>
            <p className="text-sm text-black opacity-40 mb-2 truncate">{edu.institution}</p>
            <p className="text-sm text-black opacity-40 mb-3">{formatDate(edu.from)} - {edu.to ? formatDate(edu.to) : 'Present'}</p>
            <p className="text-sm text-black opacity-40 mb-3">Field of Study: {edu.fieldOfStudy}</p>
            <p className="text-sm text-black opacity-40 mb-3">Education Level: {edu.educationLevel}</p>
            <p className="text-sm text-black opacity-40 mb-3 overflow-hidden text-ellipsis whitespace-pre-wrap">{edu.description}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default Education;