import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import styles from './ProtectedRoute.module.css'; // Import as styles

const ProtectedRoute = ({ roles }) => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get('/user/user-role', {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setUserRole(response.data.role);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user role:', error);
        // setLoading(false);
      }
    };
    fetchRole();
  }, []);

  const handleLogout = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      await api.get(`/auth/logout`, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (!userRole || !roles.includes(userRole)) {
    handleLogout();
    return <Navigate to="/sign-in" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
