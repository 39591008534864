import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import styles from './ManageChallenges.module.css';

const CheckpointEditor = ({ index, checkpoint, onUpdate }) => {
  const [title, setTitle] = useState(checkpoint.title || '');
  const [description, setDescription] = useState(checkpoint.quillContent || '');
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(checkpoint.fileUrl || '');

  useEffect(() => {
    setTitle(checkpoint.title || '');
    setDescription(checkpoint.quillContent || '');
    setFile(null);
    setFileUrl(checkpoint.fileUrl || '');
  }, [checkpoint]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size <= 50 * 1024 * 1024) {
      setFile(selectedFile);
      setFileUrl('');
      onUpdate({ ...checkpoint, file: selectedFile }); // Update checkpoint with new file
    } else if (selectedFile) {
      Swal.fire('Error', 'File size must be less than 50MB.', 'error');
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    onUpdate({ ...checkpoint, title: newTitle });
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    onUpdate({ ...checkpoint, quillContent: value });
  };

  return (
    <div className={styles.formGroup}>
      <label>{`Checkpoint ${index + 1} Title`}</label>
      <input
        type="text"
        value={title}
        onChange={handleTitleChange}
        required
        className={styles.inputField}
      />
      <label>{`Checkpoint ${index + 1} Description`}</label>
      <ReactQuill
        value={description}
        onChange={handleDescriptionChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
          ]
        }}
        className={styles.richTextEditor}
      />
      <label>Upload File</label>
      <input
        type="file"
        onChange={handleFileChange}
        className={styles.inputField}
      />
      {file && <p>Selected file: {file.name}</p>}
      {fileUrl && <p>Existing file: <a href={fileUrl} target="_blank" rel="noopener noreferrer">View File</a></p>}
    </div>
  );
};

export default CheckpointEditor;
