import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import styles from './OneOnOneSessions.module.css';

const OneOnOneSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [sessionSearchTerm, setSessionSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Loader state

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true); // Start loading
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get('/admin/one-on-one-sessions', {
          headers: { 'CSRF-Token': csrfToken },
        });
        console.log(response.data);
        setSessions(response.data);
      } catch (err) {
        console.error('Error fetching one-on-one sessions:', err);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchSessions();
  }, []);

  const handlePending = async (id, isPending) => {
    Swal.fire({
      title: 'Are you sure?',
      text: isPending ? 'Do you want to resolve this session from pending status?' : 'Do you want to put this session in pending status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: isPending ? 'Yes, resolve it!' : 'Yes, set as pending!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          const route = isPending ? `/admin/one-on-one-session/${id}/resolve-pending` : `/admin/one-on-one-session/${id}/pending`;
          await api.patch(route, null, {
            headers: { 'CSRF-Token': csrfToken },
          });

          // Update the session's pending status locally
          setSessions(sessions.map(session => session._id === id ? { ...session, pending: !isPending } : session));

          Swal.fire(isPending ? 'Resolved!' : 'Pending!', isPending ? 'The session has been resolved from pending.' : 'The session has been set to pending.', 'success');
        } catch (err) {
          console.error('Error updating session pending status:', err);
          Swal.fire('Error!', 'There was an error updating the session pending status.', 'error');
        }
      }
    });
  };

  const filteredSessions = sessions.filter(session =>
    session.studentId.firstName.toLowerCase().includes(sessionSearchTerm.toLowerCase()) ||
    session.studentId.lastName.toLowerCase().includes(sessionSearchTerm.toLowerCase()) ||
    session.studentId.email.toLowerCase().includes(sessionSearchTerm.toLowerCase()) ||
    session.instructorId.firstName.toLowerCase().includes(sessionSearchTerm.toLowerCase()) ||
    session.instructorId.lastName.toLowerCase().includes(sessionSearchTerm.toLowerCase()) ||
    session.instructorId.email.toLowerCase().includes(sessionSearchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <div className={styles.heading}>One-on-One Sessions</div>
      <input
        type="text"
        placeholder="Search sessions..."
        value={sessionSearchTerm}
        onChange={(e) => setSessionSearchTerm(e.target.value)}
        className={styles.searchBar}
      />
      
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={'#123abc'} loading={loading} size={50} />
        </div>
      ) : (
        <>
          {filteredSessions.length === 0 ? (
            <p>No one-on-one sessions right now.</p>
          ) : (
            <ul className={styles.list}>
              {filteredSessions.map(session => (
                <li key={session._id} className={styles.listItem}>
                  <div className={styles.sessionInfo}>
                    <p>Student: {session.studentId.firstName} {session.studentId.lastName} ({session.studentId.email})</p>
                    <p>Instructor: {session.instructorId.firstName} {session.instructorId.lastName} ({session.instructorId.email})</p>
                    <p>Start Time: {moment(session.startUTC).tz('Admin/Timezone').format('dddd, MMMM Do YYYY, hh:mm A')}</p>
                    <p>End Time: {moment(session.endUTC).tz('Admin/Timezone').format('dddd, MMMM Do YYYY, hh:mm A')}</p>
                  </div>
                  <div className={styles.buttonGroup}>
                    <button onClick={() => handlePending(session._id, session.pending)} className={styles.pendingButton}>
                      {session.pending ? 'Resolve' : 'Pending'}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default OneOnOneSessions;
