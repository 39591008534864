import { create } from 'zustand';

const useUserStore = create((set) => ({
  userData: {
    _id: '',
    accessGranted: false,
    addedInterest: [],
    currentSkills: [],
    desiredSkills: [],
    email: '',
    firstName: '',
    lastName: '',
    isPremium: false,
    timezone: '',
    isVerified: false,
    location: '',
    notificationPreferences: {
      appEmail: false,
      appWhatsApp: false,
      reminderEmail: false,
      reminderWhatsApp: false,
    },
    phone: '',
    profilePicture: '',
    role: '',
    sessionGoals: [],
    education: [], // Added field for education
    freeSessions: null,
    freeGeneralSessions: null,
    planId: null,
    enrollChallenge:null,
    industry: null,
    subscription: null,
  },
  
  setUserData: (data) => set(() => ({
    userData: {
      _id: data._id || '',
      accessGranted: data.accessGranted || false,
      addedInterest: data.addedInterest || [],
      currentSkills: data.currentSkills || [],
      desiredSkills: data.desiredSkills || [],
      email: data.email || '',
      firstName: data.firstName || '',
      timezone: data.timezone || '',
      lastName: data.lastName || '',
      isPremium: data.isPremium || false,
      isVerified: data.isVerified || false,
      location: data.location || '',
      notificationPreferences: data.notificationPreferences || {
        appEmail: false,
        appWhatsApp: false,
        reminderEmail: false,
        reminderWhatsApp: false,
      },
      phone: data.phone || '',
      profilePicture: data.profilePicture || '',
      role: data.role || '',
      sessionGoals: data.sessionGoals || [],
      education: data.education || [], // Ensure education is being set
      freeSessions: data.freeSessions,
      freeGeneralSessions: data.freeGeneralSessions,
      planId: data.planId,
      enrollChallenge: data.enrollChallenge,
      industry: data.industry || null,
      subscription: data.subscription || null,
    },
  })),
}));

export default useUserStore;
