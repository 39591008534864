import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import useUserStore from '../../store/useStudentStore'; // Import the Zustand store

const industryOptions = [
  { value: 'Tech', label: 'Tech' },
  { value: 'Education', label: 'Education' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Transportation', label: 'Transportation' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Government', label: 'Government' },
  { value: 'Nonprofit', label: 'Nonprofit' },
  { value: 'Media', label: 'Media' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Law', label: 'Law' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Food and Beverage', label: 'Food and Beverage' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Environmental Services', label: 'Environmental Services' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Public Services', label: 'Public Services' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Arts and Design', label: 'Arts and Design' },
  { value: 'Other', label: 'Other' }
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? 'var(--primary-color)' : provided.borderColor,
    boxShadow: state.isFocused ? `0 0 0 1px var(--primary-color)` : provided.boxShadow,
    '&:hover': {
      borderColor: 'var(--primary-color)',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--primary-color-light)' : provided.backgroundColor,
    color: state.isSelected ? 'var(--primary-color)' : provided.color,
    '&:hover': {
      backgroundColor: 'var(--primary-color-light)',
      color: 'var(--primary-color)',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--primary-color-light)',
    border: '1px solid var(--primary-color)',
    borderRadius: '4px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--primary-color)',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'var(--primary-color)',
    '&:hover': {
      backgroundColor: 'var(--primary-color-light)',
      color: 'var(--primary-color)',
    },
  }),
};

const StudentIndustry = () => {
  const { userData, setUserData } = useUserStore(); // Get user data from store
  const [currentIndustries, setCurrentIndustries] = useState([]);
  const [desiredIndustry, setDesiredIndustry] = useState('');
  const [goals, setGoals] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const [loadingSubmit, setLoadingSubmit] = useState(false); // Loading state for button

  useEffect(() => {
    // Fetch from store instead of making an API call
    setCurrentIndustries(userData.industry?.currentIndustries?.map(ind => ({ value: ind, label: ind })) || []);
    setDesiredIndustry(userData.industry?.desiredIndustry || '');
    setGoals(userData.industry?.goals || '');
    setLoading(false); // End loading state
  }, [userData]);

  const handleSaveChanges = async () => {
    setLoadingSubmit(true); // Show loader when submitting
    try {
      const csrfToken = await fetchCsrfToken();
      const newIndustryPreferences = {
        currentIndustries: currentIndustries.map(industry => industry.value),
        desiredIndustry,
        goals
      };

      // Update Zustand store
      setUserData({
        ...userData,
        industry: newIndustryPreferences,
      });

      // API call to update backend
      await api.post('/studentsIndustry/industry', newIndustryPreferences, {
        headers: { 'CSRF-Token': csrfToken }
      });

      swal("Success", "Industries updated successfully!", "success");
    } catch (error) {
      console.error('Error updating industries:', error);
    } finally {
      setLoadingSubmit(false); // Hide loader after completion
    }
  };

  if (loading) {
    return (
      <div className="max-w-[700px] p-5">
        <h2 className="text-left text-2xl font-bold"><Skeleton width={200} /></h2>
        <p className="text-sm text-gray-600 mb-11"><Skeleton count={2} /></p>
        <div className="mb-5">
          <label className="block mb-2 text-lg font-bold"><Skeleton width={150} /></label>
          <Skeleton height={38} />
        </div>
        <div className="mb-5">
          <label className="block mb-2 text-lg font-bold"><Skeleton width={150} /></label>
          <Skeleton height={38} />
        </div>
        <div className="mb-5">
          <label className="block mb-2 text-lg font-bold"><Skeleton width={150} /></label>
          <Skeleton height={88} />
        </div>
        <Skeleton width={120} height={40} />
      </div>
    );
  }

  return (
    <div className="max-w-[700px] p-5">
      <h2 className="text-left text-2xl font-bold">Industry Preferences</h2>
      <p className="text-sm text-gray-600 mb-11">Specify your current and desired industries to help us better match you with opportunities.</p>

      <div className="mb-5">
        <label className="block mb-2 text-lg font-bold">Current Industries</label>
        <Select
          isMulti
          value={currentIndustries}
          onChange={setCurrentIndustries}
          options={industryOptions}
          styles={customStyles}
          className="bg-primary-color w-full rounded-lg"
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 text-lg font-bold">Desired Industry</label>
        <Select
          value={industryOptions.find(option => option.value === desiredIndustry)}
          onChange={(selectedOption) => setDesiredIndustry(selectedOption ? selectedOption.value : '')}
          options={industryOptions}
          styles={customStyles}
          className="bg-primary-color w-full rounded-lg"
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 text-lg font-bold">Goals</label>
        <textarea 
          rows={4} 
          value={goals} 
          onChange={(e) => setGoals(e.target.value)}
          className="w-full p-3 border border-border-color rounded-lg text-sm"
        ></textarea>
      </div>

      <div className="pt-2 flex justify-end">
        <button
          onClick={handleSaveChanges}
          className="mt-5 px-5 py-3 bg-primary-color text-white text-lg rounded-lg hover:bg-primary-color-hover"
          disabled={loadingSubmit} // Disable button while loading
          style={{ width: '200px', height: '50px' }} // Maintain consistent button dimensions
        >
          <div className="flex justify-center items-center">
            {loadingSubmit ? (
              <div className="loader" style={{ width: '24px', height: '24px' }}></div>
            ) : (
              'Save Changes'
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default StudentIndustry;
