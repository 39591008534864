import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './AdminVideoRecordings.module.css';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';

const AdminVideoRecordings = () => {
  const [selectedTab, setSelectedTab] = useState('General Recordings');
  const [recordings, setRecordings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false); // Add loader state

  const fetchRecordings = async (url) => {
    setLoading(true); // Start loading
    const csrfToken = await fetchCsrfToken();
    try {
      const response = await api.get(url, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });
      setRecordings(response.data);
    } catch (error) {
      console.error('Error fetching recordings:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    switch (selectedTab) {
      case 'General Recordings':
        fetchRecordings('/admin/recordings/general');
        break;
      case 'Chat Recordings':
        fetchRecordings('/admin/recordings/chat');
        break;
      case 'OneOnOne Recordings':
        fetchRecordings('/admin/recordings/one-on-one');
        break;
      default:
        break;
    }
  }, [selectedTab]);

  const filteredRecordings = recordings.filter((recording) => {
    const instructorName = recording.instructorId
      ? `${recording.instructorId.firstName} ${recording.instructorId.lastName}`.toLowerCase()
      : '';
    const studentName = recording.studentId
      ? `${recording.studentId.firstName} ${recording.studentId.lastName}`.toLowerCase()
      : '';
    return (
      instructorName.includes(searchTerm.toLowerCase()) ||
      studentName.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h2>Video Recordings</h2>
      </div>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${selectedTab === 'General Recordings' ? styles.activeTab : ''}`}
          onClick={() => setSelectedTab('General Recordings')}
        >
          General Recordings
        </button>
        <button
          className={`${styles.tab} ${selectedTab === 'Chat Recordings' ? styles.activeTab : ''}`}
          onClick={() => setSelectedTab('Chat Recordings')}
        >
          Chat Recordings
        </button>
        <button
          className={`${styles.tab} ${selectedTab === 'OneOnOne Recordings' ? styles.activeTab : ''}`}
          onClick={() => setSelectedTab('OneOnOne Recordings')}
        >
          OneOnOne Recordings
        </button>
      </div>
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search by Mentor or Student name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={'#123abc'} loading={loading} size={50} />
        </div>
      ) : (
        <div className={styles.recordingsContainer}>
          {filteredRecordings.length > 0 ? (
            filteredRecordings.map((recording) => (
              <div key={recording._id} className={styles.recording}>
                <div className={styles.recordingDetails}>
                  <h3>
                    Mentor: {recording.instructorId.firstName} {recording.instructorId.lastName}
                  </h3>
                  {recording.studentId && (
                    <p>
                      Student: {recording.studentId.firstName} {recording.studentId.lastName}
                    </p>
                  )}
                  <p>Recorded At: {new Date(recording.recordedAt).toLocaleString()}</p>
                  {recording.sessionName && (
                    <>
                      <p>Session Name: {recording.sessionName}</p>
                      <p>Description: {recording.sessionDescription}</p>
                    </>
                  )}
                </div>
                <a href={recording.fileUrl} className={styles.downloadButton} download>
                  Watch Recorded Session
                </a>
              </div>
            ))
          ) : (
            <p>No recordings found.</p>
          )}
        </div>
      )}

    </div>
  );
};

export default AdminVideoRecordings;
