import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import Pagination from '../paginationControl'; // Assuming you have a Pagination component

const ChatRecordings = () => {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecordings, setTotalRecordings] = useState(0); // Total number of recordings
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const limit = 6; // Number of recordings per page

  useEffect(() => {
    const fetchRecordings = async () => {
      const csrfToken = await fetchCsrfToken();
      try {
        const response = await api.get(`/recordings/chat?page=${currentPage}&limit=${limit}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setRecordings(response.data.formattedRecordings);
        setTotalRecordings(response.data.totalRecordings); // Set total recordings for pagination
      } catch (error) {
        console.error('Error fetching chat recordings:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchRecordings();
  }, [currentPage]); // Refetch data when `currentPage` changes

  const totalPages = Math.ceil(totalRecordings / limit); // Calculate total pages

  return (
    <div>
    <div className="max-h-[575px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {loading ? (
        // Skeleton loaders for loading state
        [...Array(6)].map((_, index) => (
          <div
            key={index}
            className="flex flex-col justify-between border border-border-color rounded-lg p-4 bg-white shadow-md relative"
          >
            <div className="flex-1">
              <Skeleton width="60%" height={20} className="mb-2" />
              <Skeleton width="80%" height={20} className="mb-2" />
              <Skeleton width="100%" height={20} className="mb-2" />
            </div>
            <div className="flex justify-end mt-4 lg:mt-12">
              <Skeleton width={150} height={40} />
            </div>
          </div>
        ))
      ) : recordings.length > 0 ? (
        recordings.map((recording) => (
          <div
            key={recording._id}
            className="flex flex-col justify-between border border-border-color rounded-lg p-4 bg-white shadow-md relative"
          >
            <div className="flex-1">
              <div className="text-sm text-black opacity-50 mb-2">
                {new Date(recording.recordedAt).toDateString()} at{' '}
                {new Date(recording.recordedAt).toLocaleTimeString()}
              </div>
              <div className="flex flex-col justify-between mt-2">
                <div className="text-lg font-semibold mb-1">
                  {recording.studentName}
                </div>
                <div className="text-sm text-black opacity-40 mb-2">
                  Chat Session
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4 lg:mt-12">
              <a
                href={recording.fileUrl}
                className="text-primary-color px-4 py-2 bg-white border border-primary-color rounded-md transition duration-3000 hover:text-white hover:bg-primary-color"
                download
              >
                Watch Recorded Session
              </a>
            </div>
          </div>
        ))
      ) : (
        <p>No recordings available.</p>
      )}

      
    </div>
    {/* Pagination Controls */}
    {!loading && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default ChatRecordings;
