import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faTimesCircle, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import useUserStore from '../../store/useStudentStore';
import api from '../../utils/auth'; // Import your Axios instance for API calls

// Define your plans with correct prices
const plans = [
  {
    id: process.env.REACT_APP_PATHFINDER_PLAN_ID,
    name: 'Pathfinder Plan',
    amount: 2000 * 3, // Quarterly price
  },
  {
    id: process.env.REACT_APP_PRO_PLAN_ID,
    name: 'Pro Plan',
    amount: 3500 * 3, // Quarterly price
  },
];

// Function to get the next available plan for upgrading or downgrading
const getNextPlan = (currentPlan, direction) => {
  const currentIndex = plans.findIndex(p => p.id === currentPlan?.id);
  if (direction === 'upgrade' && currentIndex < plans.length - 1) {
    return plans[currentIndex + 1];
  } else if (direction === 'downgrade' && currentIndex > 0) {
    return plans[currentIndex - 1];
  }
  return null;
};

const Billing = () => {
  const { userData, setUserData } = useUserStore();
  const [subscription, setSubscription] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [previousPlan, setPreviousPlan] = useState(null);
  const [previousPlanEndDate, setPreviousPlanEndDate] = useState(null);
  const [loading, setLoading] = useState(true); // General loading state
  const [actionLoading, setActionLoading] = useState({ cancel: false, upgrade: false, downgrade: false }); // Action-specific loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const response = await api.get('/subscription/details');
        const { subscription, currentPlan, previousPlan, previousPlanEndDate } = response.data;
        setSubscription(subscription);
        setCurrentPlan(currentPlan);
        setPreviousPlan(previousPlan);
        setPreviousPlanEndDate(previousPlanEndDate);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, [userData]);

  const handleUpgradeClick = () => {
    navigate('/student/payment'); // Navigate to the payment page
  };

  const handleCancelSubscription = async () => {
    const confirm = await Swal.fire({
      title: 'Cancel Subscription',
      text: 'Are you sure you want to cancel your subscription? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
    });
  
    if (confirm.isConfirmed) {
      try {
        setActionLoading((prev) => ({ ...prev, cancel: true }));
  
        // Call backend API to handle subscription cancellation
        await api.post('/subscription/cancel');
  
        Swal.fire('Cancelled', 'Your subscription has been cancelled.', 'success');
        setSubscription(null); // Reset UI for canceled subscription
        setCurrentPlan(null); // Reset the current plan
  
        // Update Zustand store to reflect the subscription cancellation
        setUserData({
          ...userData,
          subscription: null,
        });
  
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        Swal.fire('Error', 'There was an issue cancelling your subscription. Please try again.', 'error');
      } finally {
        setActionLoading((prev) => ({ ...prev, cancel: false }));
      }
    }
  };

  const handleUpgradeOrDowngrade = async (newPlan, action) => {
    const actionText = action === 'upgrade' ? 'upgrading to' : 'downgrading to';
  
    if (!newPlan) {
      Swal.fire('Error', 'Invalid plan selected.', 'error');
      return;
    }
  
    const confirm = await Swal.fire({
      title: `${action.charAt(0).toUpperCase() + action.slice(1)} Subscription`,
      html: `
        <div style="text-align: left;">
          <p>You are currently on the <strong>${currentPlan ? currentPlan.name : 'Unknown'}</strong> plan.</p>
          <p><strong>Next Billing Date:</strong> ${subscription?.current_period_end ? new Date(subscription.current_period_end * 1000).toLocaleDateString() : 'N/A'}</p>
          <p>You are about to ${actionText} the <strong>${newPlan.name}</strong> plan.</p>
          <p><strong>Note:</strong> Payment for the remaining period will be prorated and adjusted in your next billing cycle.</p>
        </div>
      `,
      icon: action === 'upgrade' ? 'success' : 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${action}`,
      cancelButtonText: 'No, keep current plan',
    });
  
    if (confirm.isConfirmed) {
      try {
        setActionLoading((prev) => ({ ...prev, [action]: true }));
  
        // Use Stripe's proration settings to adjust the subscription
        const response = await api.post(`/subscription/${action}`, { 
          newPlanId: newPlan.id, 
          prorate: true // Ensure proration is considered 
        });
  
        if (response?.status === 200) {
          // Update local state after successful downgrade/upgrade
          setCurrentPlan(newPlan);
          setSubscription((prev) => ({
            ...prev,
            items: {
              ...prev.items,
              data: [{ plan: newPlan }],
            },
          }));
  
          // Update Zustand store with new subscription details
          let updatedUserData = { ...userData, subscription: { currentPlan: newPlan } };
  
          if (newPlan.id === process.env.REACT_APP_PRO_PLAN_ID) {
            updatedUserData.freeSessions = 2;
            updatedUserData.freeGeneralSessions = -1;
            updatedUserData.enrollChallenge = -1;
            updatedUserData.planId = newPlan.id;
          } else if (newPlan.id === process.env.REACT_APP_PATHFINDER_PLAN_ID) {
            updatedUserData.freeSessions = 0;
            updatedUserData.freeGeneralSessions = -1;
            updatedUserData.enrollChallenge = 0;
            updatedUserData.planId = newPlan.id;
          } else {
            updatedUserData.freeSessions = 0;
            updatedUserData.freeGeneralSessions = 0;
            updatedUserData.enrollChallenge = 0;
          }
  
          setUserData(updatedUserData);
  
          Swal.fire('Success', `Your subscription has been ${action}d to the ${newPlan.name} plan.`, 'success');
        } else {
          Swal.fire('Error', `There was an issue with your ${action}. Please try again.`, 'error');
        }
      } catch (error) {
        console.error(`Error ${action}ing subscription:`, error);
        Swal.fire('Error', `There was an issue ${action}ing your subscription. Please try again.`, 'error');
      } finally {
        setActionLoading((prev) => ({ ...prev, [action]: false }));
      }
    }
  };

  const displayPreviousPlanDetails = () => {
    if (previousPlan && previousPlanEndDate) {
      return (
        <div className="bg-yellow-100 p-4 rounded-md">
          <p className="text-sm text-yellow-700">
            You have canceled your <strong>{previousPlan.name}</strong> plan, but you can continue to enjoy its benefits until{' '}
            <strong>{new Date(previousPlanEndDate).toLocaleDateString()}</strong>.
          </p>
        </div>
      );
    }
    return null;
  };

  const displaySubscriptionEnded = () => (
    <div className="bg-red-100 p-4 rounded-md">
      <p className="text-sm text-red-700">
        Your subscription has ended. Please select a new plan to continue enjoying our services.
      </p>
    </div>
  );

  const displayNewUserCard = () => (
    <div className="bg-gray-100 p-4 rounded-md">
      <p className="text-sm text-gray-700">
        You are not subscribed to any plan yet. Please select a plan to get started with our services.
      </p>
      <button className="bg-primary-color text-white py-2 px-4 rounded hover:bg-primary-color-hover mt-2" onClick={handleUpgradeClick}>
        Subscribe Now
      </button>
    </div>
  );

  const BillingSkeleton = () => (
    <div className="flex flex-col items-start rounded-xl max-w-[700px] p-5">
      <Skeleton height={30} width={150} className="mb-4" />
      <Skeleton height={20} width={`100%`} className="mb-4" />
      <div className="bg-white rounded-xl border border-black/20 w-full max-w-[600px] p-6">
        <Skeleton height={25} width={`60%`} className="mb-5" />
        <Skeleton height={20} width={`100%`} className="mb-2" />
        <Skeleton height={20} width={`100%`} className="mb-2" />
        <Skeleton height={20} width={`100%`} className="mb-2" />
        <Skeleton height={20} width={`100%`} className="mb-2" />
        <div className="flex flex-col sm:flex-row justify-end mt-5 gap-2">
          <Skeleton height={40} width={`100%`} className="sm:w-auto sm:mr-2" />
          <Skeleton height={40} width={`100%`} className="sm:w-auto" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-left rounded-xl max-w-[700px] p-5">
      {loading ? (
        <BillingSkeleton />
      ) : (
        <>
          <h2 className="text-2xl font-bold text-text-color">Billing Settings</h2>
          <p className="text-sm text-gray-600 text-left mb-8">
            Manage your subscription and billing details easily. If you need any help, feel free to reach out to our support team.
          </p>

          {subscription ? (
            <div className="bg-white rounded-xl border border-border-color w-full max-w-[600px] p-6">
              <div className="flex items-center mb-5">
                <FontAwesomeIcon icon={faCreditCard} className="text-2xl mr-2 text-primary-color" />
                <h3 className="text-lg text-text-color">Subscription Details</h3>
              </div>

              <div className="mb-5">
                <p className="text-lg text-gray-700">
                  <strong>Current Plan:</strong> {currentPlan ? currentPlan.name : 'Loading...'}
                </p>
                <p className="text-lg text-gray-700">
                  <strong>Next Billing Date:</strong>{' '}
                  {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                </p>
              </div>

              {displayPreviousPlanDetails()}

              <div className="flex justify-end mt-5">
                <button
                  className="bg-red-600 text-white border-none py-2 px-4 rounded-md cursor-pointer flex items-center transition-colors duration-300 hover:bg-red-500 mr-2"
                  onClick={handleCancelSubscription}
                  disabled={actionLoading.cancel}
                >
                  {actionLoading.cancel ? (
                    <span className="loader"></span>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Cancel Subscription
                    </>
                  )}
                </button>
                {currentPlan && currentPlan.name !== 'Pro Plan' && (
                  <button
                    className="bg-green-600 text-white border-none py-2 px-4 rounded-md cursor-pointer flex items-center transition-colors duration-300 hover:bg-green-500 mr-2"
                    onClick={() => handleUpgradeOrDowngrade(getNextPlan(currentPlan, 'upgrade'), 'upgrade')}
                    disabled={actionLoading.upgrade}
                  >
                    {actionLoading.upgrade ? (
                      <span className="loader"></span>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faArrowUp} className="mr-2" /> Upgrade Subscription
                      </>
                    )}
                  </button>
                )}
                {currentPlan && currentPlan.name !== 'Pathfinder Plan' && (
                  <button
                    className="bg-yellow-600 text-white border-none py-2 px-4 rounded-md cursor-pointer flex items-center transition-colors duration-300 hover:bg-yellow-500"
                    onClick={() => handleUpgradeOrDowngrade(getNextPlan(currentPlan, 'downgrade'), 'downgrade')}
                    disabled={actionLoading.downgrade}
                  >
                    {actionLoading.downgrade ? (
                      <span className="loader"></span>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faArrowDown} className="mr-2" /> Downgrade Subscription
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : userData.subscription === null ? (
            displayNewUserCard()
          ) : (
            displaySubscriptionEnded()
          )}
        </>
      )}
    </div>
  );
};

export default Billing;