import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../utils/auth';
import RequestModal from '../RequestModal/RequestModal';
import Pagination from '../paginationControl';
import Skeleton from 'react-loading-skeleton';

const AllRequests = () => {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loadingRequestId, setLoadingRequestId] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true); // Start loading
      try {
        const response = await api.get(`/help-forum/minimal?page=${currentPage}&limit=6`);
        setRequests(response.data.requests);
        setTotalPages(Math.ceil(response.data.total / 6)); // Use total from API response to calculate total pages
      } catch (error) {
        console.error('Error fetching paginated requests:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchRequests();
  }, [currentPage]);

  const handleOpenModal = async (requestId) => {
    setLoadingRequestId(requestId); // Set loading state
    try {
      const response = await api.get(`/help-forum/detailed/${requestId}`);
      setSelectedRequest(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching detailed request:', error);
    } finally {
      setLoadingRequestId(null); // Reset loading state
    }
  };

  const getProfileImage = (profilePicture) => {
    return profilePicture ? profilePicture : `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`; // Default profile image path
  };

  return (
    <div className="flex flex-col gap-5 max-h-[665px] overflow-y-auto">
      {loading ? (
        // Skeleton loading placeholders
        Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="p-5 border border-border-color rounded-lg bg-white relative shadow-sm">
            <Skeleton circle={true} height={40} width={40} className="mb-4" />
            <Skeleton height={20} width={`60%`} className="mb-4" />
            <Skeleton count={3} />
          </div>
        ))
      ) : (
        // Render requests once loaded
        requests.map((request) => (
          <div key={request._id} className="p-5 border border-border-color rounded-lg bg-white relative transition-all duration-300 ease-in-out shadow-sm">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                {request.student ? (
                  <>
                    <img
                      src={getProfileImage(request.student.profilePicture)}
                      alt="Profile"
                      className="w-10 h-10 rounded-full bg-gray-300"
                    />
                    <div>
                      <h3 className="font-bold">{request.helpRequest}</h3>
                      <p className="text-sm text-gray-600">{`${request.student.firstName} ${request.student.lastName}`}</p>
                    </div>
                  </>
                ) : (
                  <div>No student information available</div>
                )}
              </div>
              {request.answers.length > 0 && (
                <div className="bg-gray-100 px-3 py-1 rounded-full text-primary-color border border-primary-color text-sm font-semibold">
                  Answered
                </div>
              )}
            </div>
            <div className="flex gap-2 flex-wrap mb-2">
              {request.tags.map((tag, index) => (
                <span key={index} className="bg-gray-100 px-2 py-1 rounded-full text-primary-color border border-primary-color text-sm font-medium">
                  {tag}
                </span>
              ))}
            </div>
            <div className="flex flex-col gap-1 text-sm text-gray-700 mb-4">
              <p><strong>{request.answers.length}</strong> Answers</p>
              <p><strong>{request.uniqueInstructorsCount}</strong> Mentors answered</p>
            </div>
            <div className="flex justify-center text-center border-t border-border-color pt-3">
              {loadingRequestId === request._id ? (
                <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full text-primary-color"></div>
              ) : (
                <button
                  onClick={() => handleOpenModal(request._id)}
                  className="text-primary-color hover:text-primary-color-hover transition duration-300"
                >
                  View More
                </button>
              )}
            </div>
          </div>
        ))
      )}
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      {selectedRequest && (
        <RequestModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          request={selectedRequest}
        />
      )}
    </div>
  );
};

AllRequests.propTypes = {
  requests: PropTypes.array.isRequired,
};

export default AllRequests;
