import React from 'react';
import PropTypes from 'prop-types';

const SubscriptionCard = ({ email, onUpgradeClick }) => {
  return (
    <div className="relative rounded-lg overflow-hidden w-full mb-5 shadow-md h-[300px] sm:h-[250px] md:h-[300px]">
      <div className="relative w-full h-full">
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/subscribe.jpg`} 
          className="w-full h-full object-cover" 
          alt="subscriptionimage" 
        />
        <div className="absolute top-0 left-0 w-full h-full bg-overlay-bg"></div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center px-4">
        <div className="text-center text-white p-2 sm:p-4 md:p-5">
          <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-2">Upgrade Your Plan</h2>
          <p className="text-sm sm:text-base mb-3 sm:mb-5">
            Please upgrade your plan to enjoy features including unlimited access to sessions, 1-on-1 mentorship, CV clinic, and recruitment.
          </p>
          <div className="bg-white p-2 sm:p-3 md:p-4 rounded-lg shadow-md inline-block text-center">
            <div className="text-sm sm:text-lg font-bold text-gray-800 mb-2">From 20£/month</div>
            <button 
              className="bg-primary-color text-white border-none rounded py-1 sm:py-2 px-3 sm:px-5 cursor-pointer"
              onClick={onUpgradeClick}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionCard.propTypes = {
  email: PropTypes.string.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
};

export default SubscriptionCard;
