import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import api from '../../utils/auth';
import styles from './Analytics.module.css';

const AdminAnalytics = () => {
  const [premiumCount, setPremiumCount] = useState(0);
  const [pathfinderCount, setPathfinderCount] = useState(0);
  const [freeCount, setFreeCount] = useState(0);
  const [monthlyRegistrations, setMonthlyRegistrations] = useState(0);
  const [totalRegistrations, setTotalRegistrations] = useState(0);
  const [activeInstructors, setActiveInstructors] = useState(0);
  const [avgMonthlySessions, setAvgMonthlySessions] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await api.get('/admin/analytics');
        setPremiumCount(response.data.premiumCount);
        setPathfinderCount(response.data.pathfinderCount);
        setFreeCount(response.data.freeCount);
        setMonthlyRegistrations(response.data.monthlyRegistrations);
        setTotalRegistrations(response.data.totalRegistrations);
        setActiveInstructors(response.data.activeInstructors);
        setAvgMonthlySessions(response.data.avgMonthlySessions);
      } catch (error) {
        console.error('Error fetching analytics:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAnalytics();
  }, []);

  const calculateMonthlyEarnings = () => {
    const pathfinderEarnings = (pathfinderCount * 60) / 3; // Quarterly divided by 3 for monthly
    const proEarnings = (premiumCount * 105) / 3; // Quarterly divided by 3 for monthly
    return pathfinderEarnings + proEarnings;
  };

  return (
    <div className={styles.analyticsContainer}>
      <h2 className={styles.analyticsHeader}>Admin Dashboard</h2>
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={'#123abc'} loading={loading} size={50} />
        </div>
      ) : (
        <>
          <div className={styles.statsContainer}>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Total Premium Students</h3>
              <p className={styles.statBoxValue}>{premiumCount}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Total Pathfinder Students</h3>
              <p className={styles.statBoxValue}>{pathfinderCount}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Total Free Students</h3>
              <p className={styles.statBoxValue}>{freeCount}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Monthly Earnings</h3>
              <p className={styles.statBoxValue}>£{calculateMonthlyEarnings().toFixed(2)}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Monthly Registrations</h3>
              <p className={styles.statBoxValue}>{monthlyRegistrations}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Total Registrations</h3>
              <p className={styles.statBoxValue}>{totalRegistrations}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Active Instructors</h3>
              <p className={styles.statBoxValue}>{activeInstructors}</p>
            </div>
            <div className={styles.statBox}>
              <h3 className={styles.statBoxTitle}>Avg Monthly Sessions</h3>
              <p className={styles.statBoxValue}>{avgMonthlySessions.toFixed(2)}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminAnalytics;