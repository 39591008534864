import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

const ChallengeDetailsModal = ({ challenge, onClose }) => {
  const modalRef = useRef(null); // Create a ref for the outer modal container

  // Function to handle clicks outside of the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(); // Close the modal when clicking outside
    }
  };

  useEffect(() => {
    // Add event listener for outside clicks when the modal is open
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener when the modal is closed
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={modalRef} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100000]">
      <div className="bg-white w-full max-w-[600px] md:w-3/5 rounded-lg overflow-y-auto max-h-[90%] p-5 relative shadow-lg">
        <button 
          onClick={onClose} 
          className="absolute top-2.5 right-2.5 bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center border-none cursor-pointer hover:bg-red-600 transition"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        
        <img src={challenge.image} alt={DOMPurify.sanitize(challenge.title)} className="w-full rounded-lg object-cover mb-5 h-[250px]" />

        <div className="text-left mb-5">
          <h2 className="text-2xl font-bold mb-2.5">{challenge.title}</h2>
          <p 
            className="text-base text-gray-600 mb-5 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(challenge.description) }}
          />
        </div>

        <div className="flex flex-col gap-3.5">
          {challenge.checkpoints.map((checkpoint, index) => {
            const isCompleted = challenge.milestones && challenge.milestones[index] && challenge.milestones[index].fileUrl;

            return (
              <div key={index} className="flex items-start py-2.5 border-b border-border-color">
                <div className="flex items-center justify-center mr-3.5 bg-gray-200 rounded-full p-1.5 w-10 h-10">
                  <FontAwesomeIcon 
                    icon={isCompleted ? faCheckCircle : faTimes} 
                    className={isCompleted ? 'text-green-600 text-2xl' : 'text-red-600 text-2xl'}
                  />
                </div>
                <div className="flex-grow">
                  <div className="text-lg font-bold mb-1.5">
                    {checkpoint.title}
                  </div>
                  <div 
                    className="text-sm text-gray-500 break-words"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(checkpoint.description) }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChallengeDetailsModal;
