import React, { useState, useEffect } from 'react';
import { LiveKitRoom, VideoConference, RoomAudioRenderer, useTracks, ParticipantTile } from '@livekit/components-react';
import { Track } from "livekit-client";
import '@livekit/components-styles';
import { useParams, useLocation } from 'react-router-dom';
import api from '../../utils/auth';
import './VideoChatMessage.css';  // Import your custom CSS file
import styles from './loader.module.css'; // Import the loader module

const VideoChatMessage = () => {
  const serverUrl = process.env.REACT_APP_LIVEKIT_SERVER_URL;
  const { token } = useParams(); // Use token instead of connectionId
  const [livekitToken, setLivekitToken] = useState('');
  const [role, setRole] = useState('participant'); // Default role
  const [isConnecting, setIsConnecting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingId, setRecordingId] = useState(null);
  const connectionId = new URLSearchParams(useLocation().search).get('connectionId'); // Retrieve connectionId from URL

  useEffect(() => {
    const connectToRoom = async () => {
      setIsConnecting(true);
      try {


        const response = await api.get('/videochat/get-participant-token-message', {
          params: { token },
          withCredentials: true,
        });

        const data = response.data;

        if (typeof data.token === 'string') {
          setLivekitToken(data.token);
          setRole(data.role); // Assuming role is returned in the response
        } else {
          console.error('Token is not a string:', data.token);
        }
      } catch (error) {
        console.error('Error fetching participant token:', error);
        setIsConnecting(false);
      }
    };

    connectToRoom();
  }, [token, connectionId]);

  const startRecording = async () => {
    try {
      const response = await api.post('/videochat/start-recording-chat', {
        room: connectionId,  // Pass the connectionId as the room ID
        connectionId: connectionId,  // Use the connectionId retrieved from the URL
      });
      const data = response.data;
      if (data.recordingId) {
        setRecordingId(data.recordingId);
        setIsRecording(true);
      }
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = async () => {
    try {
      const response = await api.post('/videochat/stop-recording', {
        recordingId,
      });

      const data = response.data;

      if (data.message) {
        setIsRecording(false);
        setRecordingId(null);
      }
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };

  if (!token) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className={styles.loader}></div> {/* Use the loader from the module */}
      </div>
    );
  }

  return (
    <LiveKitRoom
      token={livekitToken}
      serverUrl={serverUrl} // Use the environment variable here
      data-lk-theme="default"
      style={{ height: '100vh' }}
    >
      <MyVideoConference />
      <RoomAudioRenderer />
      {role === 'instructor' && (
        <div className="custom-control-bar">
          <div className="custom-button-group">
            <button
              className="custom-button"
              onClick={startRecording}
              style={{ display: !isRecording ? 'block' : 'none' }}
            >
              Start Recording
            </button>
            <button
              className="custom-button"
              onClick={stopRecording}
              style={{ display: isRecording ? 'block' : 'none' }}
            >
              Stop Recording
            </button>
          </div>
        </div>
      )}
    </LiveKitRoom>
  );
};

function MyVideoConference() {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );

  return (
    <VideoConference tracks={tracks}>
      {tracks.map(track => (
        <ParticipantTile
          key={track.participant.sid}
          track={track}
          isLocal={track.participant.isLocal}
          showControls={track.participant.isLocal}
        />
      ))}
    </VideoConference>
  );
}

export default VideoChatMessage;
