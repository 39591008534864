import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import Pagination from '../paginationControl'; // Assuming you have a Pagination component

const ChatRecordings = () => {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecordings, setTotalRecordings] = useState(0); // Total recordings for pagination
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const limit = 6; // Set the limit per page

  useEffect(() => {
    const fetchRecordings = async () => {
      setLoading(true); // Start loading
      const csrfToken = await fetchCsrfToken();
      try {
        const response = await api.get(`/recordings/chat-student?page=${currentPage}&limit=${limit}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setRecordings(response.data.recordings); // Set recordings data
        setTotalRecordings(response.data.totalRecordings); // Set total number of recordings
      } catch (error) {
        console.error('Error fetching chat recordings:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchRecordings();
  }, [currentPage]); // Refetch recordings when page changes

  const totalPages = Math.ceil(totalRecordings / limit); // Calculate total pages

  const renderSkeletonLoader = () => (
    <div className="flex flex-col justify-between border border-border-color rounded-lg p-4 bg-white shadow-md relative">
      <div className="flex-1">
        <Skeleton width={150} className="mb-1" />
        <div className="flex flex-col justify-between mt-1">
          <Skeleton width={100} height={20} className="mb-1" />
          <Skeleton width={120} height={20} className="mb-1" />
        </div>
      </div>
      <div className="flex justify-end mt-4 lg:mt-8">
        <Skeleton width={120} height={36} />
      </div>
    </div>
  );

  return (
    <div>
    <div className="max-h-[575px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {loading
        ? Array(6)
            .fill(0)
            .map((_, index) => <div key={index}>{renderSkeletonLoader()}</div>)
        : recordings.map((recording) => (
            <div
              key={recording._id}
              className="flex flex-col justify-between border border-border-color rounded-lg p-4 bg-white shadow-md relative"
            >
              <div className="flex-1">
                <div className="text-sm text-black opacity-50 mb-1">
                  {new Date(recording.recordedAt).toDateString()} at{' '}
                  {new Date(recording.recordedAt).toLocaleTimeString()}
                </div>
                <div className="flex flex-col justify-between mt-1">
                  <div className="text-lg font-semibold mb-1">
                    {recording.instructorId?.firstName} {recording.instructorId?.lastName}
                  </div>
                  <div className="text-sm text-black opacity-40 mb-1">Chat Session</div>
                </div>
              </div>
              <div className="flex justify-end mt-4 lg:mt-8">
                <a
                  href={recording.fileUrl}
                  className="text-primary-color px-4 py-2 bg-white border border-primary-color rounded-md transition duration-3000 hover:text-white hover:bg-primary-color"
                  download
                >
                  Watch Recorded Session
                </a>
              </div>
            </div>
          ))}

      
    </div>
    {/* Pagination Controls */}
    {!loading && totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      )}
    </div>
  );
};

export default ChatRecordings;
