// src/utils/api.js
import axios from 'axios';
import { BASE_URL } from '../Constants';
import { fetchCsrfToken } from './csrf';

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const csrfToken = await fetchCsrfToken();
      console.log('Setting CSRF Token in request header:', csrfToken);
      config.headers['CSRF-Token'] = csrfToken; // Use X-CSRF-Token header,
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
    return config;
  },
  (error) => {
    console.error('Request setup error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Response error:', error.response.status, error.response.data);
    }
    return Promise.reject(error);
  }
);

export default api;
