import React from 'react';
import InstructionSettingLayout from '../../../layouts/InstructorSettingsLayout/InstructorSettingsLayout';

const InstructorSettings = () => {



  return (
    <div>
      <InstructionSettingLayout />

    </div>
  );
};

export default InstructorSettings;
