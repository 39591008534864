import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineBook,
  AiOutlineQuestionCircle,
  AiOutlineSetting,
} from 'react-icons/ai';

const NavBar = () => {
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    switch (pathname) {
      case '/mentor/dashboard':
        setSelected('Home');
        break;
      case '/mentor/chat':
        setSelected('My Students');
        break;
      case '/mentor/session':
        setSelected('My Sessions');
        break;
      case '/mentor/content':
        setSelected('Content Library');
        break;
      case '/mentor/forum':
        setSelected('View Help Forums');
        break;
      case '/mentor/settings':
      case '/mentor/settings/profile':
      case '/mentor/settings/skills':
      case '/mentor/settings/experience':
      case '/mentor/settings/1-on-1':
      case '/mentor/settings/services':
      case '/mentor/settings/notifications':
      case '/mentor/settings/billing':
        setSelected('Settings');
        break;
      default:
        setSelected('Home');
    }
  }, [location]);

  const handleNavigation = (option, path) => {
    setSelected(option);
    navigate(path);
  };

  const navOptions = [
    { name: 'Home', path: '/mentor/dashboard', icon: <AiOutlineHome /> },
    { name: 'My Students', path: '/mentor/chat', icon: <AiOutlineUser /> },
    { name: 'My Sessions', path: '/mentor/session', icon: <AiOutlineCalendar /> },
    { name: 'Content Library', path: '/mentor/content', icon: <AiOutlineBook /> },
    { name: 'View Help Forums', path: '/mentor/forum', icon: <AiOutlineQuestionCircle /> },
    { name: 'Settings', path: '/mentor/settings/profile', icon: <AiOutlineSetting /> },
  ];

  return (
    <nav
      className="fixed top-20 left-0 h-screen bg-gray-50 border-r border-border-color transition-all duration-300 lg:block hidden"
      style={{ width: 'clamp(160px, 20vw, 250px)' }}
    >
      <ul className="list-none p-0 m-0">
        {navOptions.map(option => (
          <li
            key={option.name}
            className={`px-6 py-4 flex items-center cursor-pointer text-base font-normal text-black hover:bg-gray-200 ${
              selected === option.name ? 'bg-gray-200 text-primary-color border-r-4 border-primary-color' : ''
            }`}
            onClick={() => handleNavigation(option.name, option.path)}
          >
            <span className="mr-2">{option.icon}</span> {/* Add icon before the text */}
            {option.name}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
