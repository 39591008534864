import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getName } from 'country-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faMapMarkerAlt, faClock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../utils/auth';
import BookingModal from './BookingModal';
import ServiceDetailsModal from './ServiceDetailsModal'; // Import the new modal
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './InstructorProfileView.module.css'; // Import the CSS module for sanitized content
import Swal from 'sweetalert2';

const StudentInstructorProfileView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [instructor, setInstructor] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [studentTimezone, setStudentTimeZone] = useState(null);
  const [localTime, setLocalTime] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [selectedService, setSelectedService] = useState(null); // For service modal

  const handleServiceClick = (service) => {
    setSelectedService(service); // Set the clicked service for the modal
  };

  const closeServiceModal = () => {
    setSelectedService(null); // Close the modal by resetting selectedService
  };

  useEffect(() => {
    const fetchInstructor = async () => {
      try {
        const response = await api.get(`/students/${id}/profile`);
        setInstructor(response.data);
        setStudentTimeZone(response.data.studentTimezone);
        setLoading(false); // Set loading to false after fetching data

        if (response.data.timezone) {
          const timeInInstructorTimezone = moment().tz(response.data.timezone).format('hh:mm A');
          setLocalTime(timeInInstructorTimezone);

          const intervalId = setInterval(() => {
            const updatedTime = moment().tz(response.data.timezone).format('hh:mm A');
            setLocalTime(updatedTime);
          }, 60000);

          return () => clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error fetching Mentor profile:', error);
        setLoading(false); // Ensure loading is set to false even if there's an error
      }
    };

    fetchInstructor();
  }, [id]);

  const handleServicePurchase = (service) => {
    if (!instructor.isPremium) {
      Swal.fire({
        title: 'Free Plan',
        text: "You're currently using the free plan. Upgrade to a paid plan to avail services of this mentor.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Upgrade',
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/student/payment'); // Replace with actual payment page link
        }
      });
    } else {
      navigate('/student/service-payment', { state: { service, instructor } });
    }
  };

  const handleBooking = () => {
    setShowModal(true);
  };

  const handleSkillClick = (skill) => {
    navigate('/student/browse-mentors', { state: { filterSkill: skill } });
  };

  if (loading) {
    // Skeleton loader for loading state
    return (
      <div className="max-w-[1400px] px-5 md:px-10 py-8 rounded-md text-gray-800 bg-white border border-border-color m-auto">
        {/* Skeleton Loader */}
        <div className="flex flex-col md:flex-row justify-between items-start mb-5">
          <div className="flex flex-row md:flex-col md:items-start mb-5 md:mb-0">
            <Skeleton circle height={100} width={100} />
            <div className="flex-1 ml-5">
              <Skeleton width={200} height={30} />
              <Skeleton width={150} height={20} />
              <Skeleton width={100} height={20} />
              <Skeleton width={80} height={20} />
              <Skeleton width={80} height={20} />
            </div>
          </div>
          <div className="border border-border-color bg-gray-100 rounded-lg p-5 w-full md:w-72 text-center shadow-md md:ml-0">
            <Skeleton circle height={100} width={100} />
            <Skeleton width={150} height={30} />
            <Skeleton width={200} height={40} />
            <Skeleton width={200} height={40} />
          </div>
        </div>
        <div className="my-5">
          <Skeleton height={30} width={150} />
          <Skeleton height={20} count={5} />
        </div>
        <div className="my-10">
          <Skeleton height={30} width={200} />
          <Skeleton height={100} />
        </div>
        <div className="my-10">
          <Skeleton height={30} width={200} />
          <Skeleton height={200} />
        </div>
      </div>
    );
  }

  const { firstName, lastName, headline, location, mentorship, addedSkills, bio, services = [], profilePicture, timezone, rating, ratings } = instructor;
  const locationFullName = getName(location) || location;

  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;
  const defaultLandscape = `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.jpg`;

  return (
    <div className="max-w-[1400px] px-5 md:px-10 py-8 rounded-md text-gray-800 bg-white border border-border-color m-auto">
      <div className="flex flex-col md:flex-row justify-between items-start mb-5">
        <div className="flex flex-row md:flex-col md:items-start mb-5 md:mb-0">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-[#107F9A] cursor-pointer mb-5 mr-5 md:mr-0 text-2xl"
            onClick={() => navigate(-1)}
          />
          <div className="flex flex-1 items-start">
            <div className="flex-shrink-0">
              <img
                src={profilePicture ? `${profilePicture}` : defaultProfileImage}
                alt="Profile"
                className="w-24 h-24 md:w-36 md:h-36 rounded-full object-cover"
              />
            </div>
            <div className="flex-1 ml-5">
              <h1 className="text-3xl font-bold text-primary-color">{firstName} {lastName}</h1>
              <p className="text-lg text-gray-500 mt-1">{headline}</p>
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <FontAwesomeIcon icon={faStar} className="text-[#107F9A] mr-2" />
                {rating ? (
                  <>
                    {rating.toFixed(1)}/5&nbsp;
                    <span style={{ opacity: 0.6 }}>({ratings.length})</span>
                  </>
                ) : (
                  <span>No ratings yet</span>
                )}
              </div>

              <div className="flex items-center mt-2 text-sm text-gray-500">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#107F9A] mr-2" /> {locationFullName}
              </div>
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <FontAwesomeIcon icon={faClock} className="text-[#107F9A] mr-2" /> {localTime} ({timezone})
              </div>
            </div>
          </div>
        </div>
        <div className="border border-border-color bg-gray-100 rounded-lg p-5 w-full md:w-72 text-center shadow-md md:ml-0">
          <div className="flex flex-col items-center mb-5">
            <img
              src={profilePicture ? `${profilePicture}` : defaultProfileImage}
              alt="Profile"
              className="w-20 h-20 md:w-24 md:h-24 rounded-full object-cover mb-2"
            />
            <h2 className="text-xl">{firstName} {lastName}</h2>
          </div>
          <button
            className="w-full bg-[#107F9A] text-white hover:bg-[#107F9A] rounded py-2 text-lg"
            onClick={handleBooking}
          >
            Book a call for {mentorship ? `£${mentorship.price}` : 'N/A'}/h
          </button>
        </div>
      </div>
      <div className="my-5">
        <h2 className="text-3xl font-bold text-primary-color mb-3">Skills</h2>
        <div className="flex flex-wrap gap-2">
          {addedSkills.map(skill => (
            <span
              key={skill}
              className="bg-gray-100 border border-[#107F9A] rounded-full px-4 py-2 text-sm text-[#107F9A] cursor-pointer hover:bg-[#107F9A] hover:text-white"
              onClick={() => handleSkillClick(skill)}
            >
              {skill}
            </span>
          ))}
        </div>
      </div>
      <div className="my-10">
        <h2 className="text-3xl font-bold text-primary-color mb-3">About Me</h2>
        <div
          className={styles['bio-content']} // Use custom CSS for sanitized content
          dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(bio, { 
              ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'], 
              ALLOWED_ATTR: ['href'] 
            }) 
          }}
        />
      </div>
      <div className="my-10">
        <h2 className="text-3xl font-bold text-primary-color mb-3">Services Offered by Mentor</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {services.length > 0 ? (
            services.map((service, index) => (
              <div
                className="bg-white border border-border-color rounded-md overflow-hidden relative shadow-sm"
                key={index}
                onClick={() => handleServiceClick(service)} // Open service modal
              >
                {service.image && (
                  <img
                    src={service.image || defaultLandscape}
                    alt={service.serviceTitle}
                    className="w-full h-48 object-cover bg-gray-100"
                  />
                )}
                <div className="p-5">
                  <h3 className="text-lg">{service.serviceTitle}</h3>
                  <p className="mt-2 text-sm text-gray-600 truncate">{service.serviceDescription}</p>
                  <button
                    className="w-full bg-[#107F9A] text-white rounded py-2 mt-4 text-lg hover:bg-[#107F9A]"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent modal from opening
                      handleServicePurchase(service); // Navigate to purchase
                    }}
                  >
                    Book for £{service.price}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No services offered at this time.</p>
          )}
        </div>
      </div>

      {selectedService && (
        <ServiceDetailsModal
          service={selectedService}
          onBookService={() => handleServicePurchase(selectedService)} // Enable booking in modal
          onClose={closeServiceModal} // Close modal
        />
      )}

      {showModal && (
        <BookingModal
          mentorship={mentorship}
          instructor={instructor}
          studentTimezone={studentTimezone}
          freeSessions={instructor.freeSessions}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default StudentInstructorProfileView;