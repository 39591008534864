import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ProfileCompletionSection = ({ userData, handleRedirect, profileCompletion }) => {
  const tasks = [
    {
      icon: faTimesCircle,
      text: 'Add at least one experience',
      path: '/experience',
      condition: !(userData?.experiences && userData.experiences.length > 0),
    },
    {
      icon: faTimesCircle,
      text: 'Add at least one payment method',
      path: '/billing',
      condition: !(userData?.payments && userData.payments.length > 0),
    },
    {
      icon: faTimesCircle,
      text: 'Set your timezone',
      path: '/profile',
      condition: !userData?.timezone,
    },
    {
      icon: faTimesCircle,
      text: 'Set your location',
      path: '/profile',
      condition: !userData?.location,
    },
    {
      icon: faTimesCircle,
      text: 'Fill in your bio',
      path: '/profile',
      condition: !userData?.bio,
    },
    {
      icon: faTimesCircle,
      text: 'Add at least one skill',
      path: '/skills',
      condition: !(userData?.addedSkills && userData.addedSkills.length > 0),
    },
    {
      icon: faTimesCircle,
      text: 'Add at least one education entry',
      path: '/education',
      condition: !(userData?.education && userData.education.length > 0),
    },
    {
      icon: faTimesCircle,
      text: 'Add at least one achievement',
      path: '/achievements',
      condition: !(userData?.achievements && userData.achievements.length > 0),
    },
    {
      icon: faTimesCircle,
      text: 'Upload profile picture',
      path: '/profile',
      condition: !userData?.profilePicture,
    },
    {
      icon: faTimesCircle,
      text: 'Add headline',
      path: '/profile',
      condition: !userData?.headline,
    },
    {
      icon: faTimesCircle,
      text: 'Specify area of expertise',
      path: '/profile',
      condition: !userData?.areaOfExpertise,
    },
    {
      icon: faTimesCircle,
      text: 'Input pricing',
      path: '/1-on-1',
      condition: !(userData?.mentorship && userData.mentorship.price !== null),
    },
    {
      icon: faTimesCircle,
      text: 'Set any day as your availability',
      path: '/1-on-1',
      condition: !(userData?.mentorship && userData.mentorship.availability && Object.keys(userData.mentorship.availability).some(day => userData.mentorship.availability[day].length > 0)),
    },
  ];

  const incompleteTasks = tasks.filter(task => task.condition);

  return (
    <div className="max-w-[1400px] mx-auto bg-white rounded-lg shadow-md p-5 my-5">
      <h2 className="text-2xl font-bold mb-4">Complete Your Profile</h2>
      <div className="w-full bg-gray-200 rounded-full h-3 mb-4">
        <div
          className="bg-primary-color h-3 rounded-full transition-width duration-300"
          style={{ width: `${profileCompletion}%` }}
        ></div>
      </div>
      <p className="text-base text-gray-600 mb-5">
        Please complete the following steps to fully set up your profile:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {tasks.map((task, index) => (
          <div
            key={index}
            className={`bg-gray-100 border border-gray-300 rounded-md shadow-sm p-4 cursor-pointer transition-transform duration-300 ease-in-out flex items-start text-left hover:shadow-md ${
              task.condition ? 'hover:bg-gray-200' : 'bg-white'
            }`}
            onClick={() => handleRedirect(task.path)}
          >
            <FontAwesomeIcon
              icon={task.condition ? faTimesCircle : faCheckCircle}
              className={`text-3xl mr-4 ${
                task.condition ? 'text-gray-400' : 'text-primary-color'
              }`}
            />
            <div className="flex flex-col">
              <div className="text-lg font-semibold mb-1">{task.text}</div>
              <div className="text-sm text-gray-500">
                {task.condition
                  ? `Ensure you have at least one ${task.text.toLowerCase().replace('set your ', '')} to enhance your profile and attract more opportunities.`
                  : 'Completed'}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ProfileCompletionSection.propTypes = {
  userData: PropTypes.object.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  profileCompletion: PropTypes.number.isRequired,
};

export default ProfileCompletionSection;
