import React, { useState } from 'react';
import GeneralRecordings from '../../../components/StudentRecordings/GeneralRecordings';
import OneOnOneRecordings from '../../../components/StudentRecordings/OneOnOneRecordings';
import ChatRecordings from '../../../components/StudentRecordings/ChatRecordings';
import useUserStore from '../../../store/useStudentStore';
import { useNavigate } from 'react-router-dom';

const ContentLibraryStudent = () => {
  const [selectedTab, setSelectedTab] = useState('General Recordings');
  const {userData} = useUserStore();
  const navigate = useNavigate(); // Initialize the useNavigate hook


// Conditionally render upgrade message if planId is null
if (!userData.planId) {
  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">My Content</div>
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5" role="alert">
        <p className="font-bold">Upgrade Required</p>
        <p>To access the content library, please upgrade your subscription plan.</p>
        <button
          className="mt-4 bg-primary-color py-2 px-4 rounded-md text-white"
          onClick={() => navigate('/student/payment')} // Navigate to the upgrade page
        >
          Upgrade Now
        </button>
      </div>
    </div>
  );
}

  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">
        My Content
      </div>
      <div className="flex border-border-color border-b-0">
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'General Recordings'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('General Recordings')}
        >
          General Recordings
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'OneOnOne Recordings'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('OneOnOne Recordings')}
        >
          OneOnOne Recordings
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'Chat Recordings'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('Chat Recordings')}
        >
          Chat Recordings
        </button>
      </div>
      <div className="mt-6">
        {selectedTab === 'General Recordings' && <GeneralRecordings />}
        {selectedTab === 'OneOnOne Recordings' && <OneOnOneRecordings />}
        {selectedTab === 'Chat Recordings' && <ChatRecordings />}
      </div>
    </div>
  );
};

export default ContentLibraryStudent;
