import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { LiveKitRoom, VideoConference, RoomAudioRenderer, useTracks, ParticipantTile } from '@livekit/components-react';
import { Track } from "livekit-client";
import '@livekit/components-styles';
import styles from './VideoChatGeneral.module.css';
import Swal from 'sweetalert2';
import { db, storage } from '../../firebase';
import { collection, addDoc, query, orderBy, onSnapshot, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faCompress, faMicrophoneSlash, faMicrophone, faUserSlash } from '@fortawesome/free-solid-svg-icons';

const VideoChatGeneral = () => {
  const serverUrl = process.env.REACT_APP_LIVEKIT_SERVER_URL;
  const { sessionId } = useParams();
  const [userRole, setUserRole] = useState('');
  const [sessionDetails, setSessionDetails] = useState({});
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [token, setToken] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingId, setRecordingId] = useState(null);
  const [selectedChatTab, setSelectedChatTab] = useState('general');
  const [selectedTab, setSelectedTab] = useState('description');
  const [participants, setParticipants] = useState([]);
  const [showParticipants, setShowParticipants] = useState(false);

  
  // Chat related states
  const [messages, setMessages] = useState([]);
  const [priorityMessages, setPriorityMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const messageEndRef = useRef(null);


  useEffect(() => {
    if (sessionId) {
      const fetchFiles = () => {
        const filesQuery = query(collection(db, 'sessions', sessionId, 'files'), orderBy('timestamp'));
  
        const unsubscribeFiles = onSnapshot(filesQuery, (snapshot) => {
          const filesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessionDetails((prevDetails) => ({
            ...prevDetails,
            files: filesData,  // Set the files in the sessionDetails state
          }));
        });
  
        return unsubscribeFiles;  // Return to unsubscribe on component unmount
      };
  
      const unsubscribeFiles = fetchFiles();
  
      return () => unsubscribeFiles();
    }
  }, [sessionId]);
  

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await api.get('/user/user-role');
        setUserId(response.data.id);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    const fetchUserRole = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get(`/auth/current-user`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    const fetchSessionDetails = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get(`/general-session/details/${sessionId}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setSessionDetails(response.data);
        setNotes(response.data.notes);
      } catch (error) {
        console.error('Error fetching session details:', error);
      }
    };

    const connectToRoom = async () => {
      setIsConnecting(true);
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get(`/videochat/get-participant-token-general`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
          params: { sessionId }
        });

        const data = response.data;
        if (typeof data.token === 'string') {
          setToken(data.token);
          setUserRole(data.role);
        } else {
          console.error('Token is not a string:', data.token);
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        setIsConnecting(false);
      }
    };

    fetchUserId();
    fetchUserRole();
    fetchSessionDetails();
    connectToRoom();
  }, [sessionId]);

  // Chat: Listen to Firebase messages
  useEffect(() => {
    if (sessionId) {
      const generalQuery = query(collection(db, 'messages', sessionId, 'chat'), orderBy('timestamp'));
      const priorityQuery = query(collection(db, 'messages', sessionId, 'priority'), orderBy('timestamp'));

      const unsubscribeGeneral = onSnapshot(generalQuery, (snapshot) => {
        const messagesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(messagesData);
      });

      const unsubscribePriority = onSnapshot(priorityQuery, (snapshot) => {
        const priorityMessagesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPriorityMessages(priorityMessagesData);
      });

      return () => {
        unsubscribeGeneral();
        unsubscribePriority();
      };
    }
  }, [sessionId]);

  // Listen to Firebase notes
  useEffect(() => {
    if (sessionId) {
      const q = query(collection(db, 'sessions', sessionId, 'notes'), orderBy('timestamp'));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const notesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotes(notesData);
      });

      return () => unsubscribe();
    }
  }, [sessionId]);

  // Scroll to the latest message within the chat container
  useEffect(() => {
    const chatContainer = document.querySelector(`.${styles.messages}`);
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages, priorityMessages]);

  const handleNoteSubmit = async () => {
    if (!newNote.trim()) {
      console.error('Empty note not submitted');
      return;
    }

    try {
      await addDoc(collection(db, 'sessions', sessionId, 'notes'), {
        note: newNote,
        timestamp: new Date(),
      });
      setNewNote(''); // Clear the note input after submitting
    } catch (error) {
      console.error('Error submitting note:', error);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    
    if (!fileToUpload) {
      Swal.fire('Error', 'No file selected', 'error');
      return;
    }
  
    try {
      const fileName = `${sessionId}_${Date.now()}_${fileToUpload.name}`; // Use a unique name for the file
      const storageRef = ref(storage, `uploads/${sessionId}/${fileName}`);
      
      // Upload the file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, fileToUpload);
      
      // Get the download URL after the file is uploaded
      const downloadURL = await getDownloadURL(snapshot.ref);
  
      // Save the file metadata to Firestore
      await addDoc(collection(db, 'sessions', sessionId, 'files'), {
        filename: fileToUpload.name,
        url: downloadURL,
        timestamp: new Date(),
      });
  
      setFileToUpload(null); // Clear the file input after uploading
      Swal.fire('Success', 'File uploaded successfully', 'success');
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire('Error', 'Failed to upload the file', 'error');
    }
  };
  
  const startRecording = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.post(`/videochat/start-recording-general`, {
        room: sessionId,
        sessionId: sessionId,
      }, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });

      const data = response.data;
      if (data.recordingId) {
        setRecordingId(data.recordingId);
        setIsRecording(true);
      }
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.post(`/videochat/stop-recording`, {
        recordingId,
      }, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });

      const data = response.data;
      if (data.message) {
        setIsRecording(false);
        setRecordingId(null);
      }
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
  
    if (!userId) {
      console.error('User ID not found');
      return;
    }
  
    if (!message.trim()) {
      console.error('Empty message not sent');
      return;
    }
  
    // Check if the user is in the priority chat
    if (selectedChatTab === 'priority') {
      try {
        // Fetch messages from Firestore where the senderId is the current user
        const messagesRef = collection(db, 'messages', sessionId, 'priority');
        const userMessagesQuery = query(messagesRef, where('senderId', '==', userId));
        const querySnapshot = await getDocs(userMessagesQuery);
  
        // If the user is a student and already has one message, don't allow them to send another
        if (userRole === 'student' && !querySnapshot.empty) {
          Swal.fire('Info', 'You can only send one message in the priority chat.', 'info');
          return;
        }
      } catch (error) {
        console.error('Error checking message limit:', error);
      }
    }
  
    const newMessage = {
      senderId: userId,
      message,
      read: false,
      timestamp: new Date(),
    };
  
    try {
      await addDoc(collection(db, 'messages', sessionId, selectedChatTab === 'general' ? 'chat' : 'priority'), newMessage);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  
    setMessage('');
  };
  

  const handleChatTabChange = (tab) => {
    setSelectedChatTab(tab);
  };

  // List, Mute, and Remove Participants
  const listParticipants = async () => {
    try {
      if (!showParticipants) {
        const response = await api.post('/videochat/list-participants', { roomName: sessionId });
        console.log(response.data);
        setParticipants(response.data);
      }
      setShowParticipants(!showParticipants); // Toggle participants list visibility
    } catch (error) {
      console.error('Error listing participants:', error);
    }
  };
  

// Periodically fetch participants without toggling the participant list visibility
useEffect(() => {
  const fetchParticipants = async () => {
    try {
      const response = await api.post('/videochat/list-participants', { roomName: sessionId });
      setParticipants(response.data);  // Update the participants list without affecting showParticipants state
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };

  if (userRole === 'instructor') {
    const intervalId = setInterval(fetchParticipants, 10000);  // Fetch every 10 seconds

    return () => clearInterval(intervalId);  // Clean up on component unmount
  }
}, [userRole, sessionId]);  // Dependency array to re-run when userRole or sessionId changes



  // Mute the participant, unmute is no longer available
const muteParticipant = async (identity, mute) => {
  // Find the participant in the list
  const participant = participants.find(p => p.identity === identity);

  if (!participant) {
    console.error('Participant not found');
    return;
  }

  // Find the audio track to mute
  const audioTrack = participant.tracks.find(track => track.type === 'AUDIO');
  const trackSid = audioTrack ? audioTrack.sid : null;

  if (!trackSid) {
    console.error('Audio track SID not found for participant:', participant.identity);
    Swal.fire('Error', 'Audio track SID not found for mute action.', 'error');
    return;
  }

  console.log('Muting Participant:', { identity, trackSid, mute: true }); // Debugging log for mute action

  try {
    const response = await api.post('/videochat/mute-participant', {
      roomName: sessionId,
      identity,
      trackSid,
      mute: true, // Always set to mute
    });

    console.log('Mute API Response:', response.data); // Log API response for debugging
    Swal.fire('Success', `Participant muted successfully`, 'success');

    // Update participant list
    setParticipants((prevParticipants) =>
      prevParticipants.map((participant) =>
        participant.identity === identity ? { ...participant, muted: true } : participant
      )
    );
  } catch (error) {
    console.error('Error muting participant:', error); // Log error
  }
};

  
  

// Remove participant and update participant list
const removeParticipant = async (identity) => {
  try {
    await api.post('/videochat/remove-participant', { roomName: sessionId, identity });
    Swal.fire('Success', 'Participant removed successfully', 'success');

    // Remove participant from the list
    setParticipants((prevParticipants) =>
      prevParticipants.filter((participant) => participant.identity !== identity)
    );
  } catch (error) {
    console.error('Error removing participant:', error);
  }
};


  if (!token) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <div className={styles.videoChatContainer}>
      <div className={styles.title}>General Live Session - {sessionDetails.sessionName}</div>

      <div className={styles.contentContainer}>
        <div className={styles.videoContainer}>
          <LiveKitRoom
            token={token}
            serverUrl={serverUrl}
            data-lk-theme="default"
            style={{ height: '90vh' }}
            className={`${userRole !== 'instructor' ? 'custom-control-bar-student' : ''} hide-chat-toggle`}
          >
            <MyVideoConference 
              role={userRole} 
              listParticipants={listParticipants} 
              startRecording={startRecording}
              stopRecording={stopRecording}
              isRecording={isRecording}
            />            
            <RoomAudioRenderer />
          </LiveKitRoom>
        </div>


        {userRole === 'instructor' && showParticipants && participants.length > 0 && (
          <div className={styles.participantList}>
            <h3 style={{ color: 'white' }}>Participants</h3>
            <ul>
              {participants
                .filter(participant => participant.identity !== userId) // Filter out the instructor
                .map(participant => (
                  <li key={participant.identity}>
                    <span className={styles.participantName}>{participant.name || participant.identity}</span>
                    <div className={styles.participantActions}>
                      <FontAwesomeIcon
                        icon={faMicrophoneSlash}
                        className={styles.muteIcon}
                        title="Mute"
                        onClick={() => muteParticipant(participant.identity, true)} // Ensure 'true' is passed
                        />
                      <FontAwesomeIcon
                        icon={faUserSlash}
                        className={styles.removeIcon}
                        title="Remove"
                        onClick={() => removeParticipant(participant.identity)}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}




<div className={styles.chatContainer}>
  <div className={styles.chatHeader}>
    <div className={`${styles.chatTab} ${selectedChatTab === 'general' ? styles.activeChatTab : ''}`} onClick={() => handleChatTabChange('general')}>
      General Chat
    </div>
    <div className={`${styles.chatTab} ${selectedChatTab === 'priority' ? styles.activeChatTab : ''}`} onClick={() => handleChatTabChange('priority')}>
      Priority Chat
    </div>
  </div>

  {selectedChatTab === 'priority' && userRole === 'student' && (
    <div className={styles.priorityChatInfo}>
      <p>You can only send one message in the priority chat.</p>
    </div>
  )}

  <div className={styles.messages}>
    {(selectedChatTab === 'general' ? messages : priorityMessages).map((msg) => (
      <div
        key={msg.id || msg.timestamp}
        className={`${styles.message} ${msg.senderId === userId ? styles.myMessage : styles.theirMessage}`}
      >
        {msg.senderId !== userId && (
          <img
            src={sessionDetails.instructorProfilePicture ? `${sessionDetails.instructorProfilePicture}` : `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`}
            alt={`${sessionDetails.instructorFirstName} ${sessionDetails.instructorLastName}`}
            className={styles.messageProfilePicture}
          />
        )}
        <div className={styles.messageContent}>
          <span className={styles.messageText}>{msg.message}</span>
          <span className={styles.timestamp}>{new Date(msg.timestamp?.seconds * 1000).toLocaleTimeString()}</span>
        </div>
      </div>
    ))}
    <div ref={messageEndRef} />
  </div>

  <form onSubmit={sendMessage} className={styles.messageForm}>
    <input
      type="text"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      className={styles.messageInput}
      placeholder="Type a message..."
    />
    <button type="submit" className={styles.sendButton}>Send</button>
  </form>
</div>

      </div>

      <div className={styles.detailsContainer}>
        <div className={styles.tabSection}>
          <div className={styles.tabs}>
            <button
              className={`${styles.tab} ${selectedTab === 'description' ? styles.activeTab : ''}`}
              onClick={() => setSelectedTab('description')}
            >
              Description
            </button>
            <button
              className={`${styles.tab} ${selectedTab === 'attachments' ? styles.activeTab : ''}`}
              onClick={() => setSelectedTab('attachments')}
            >
              File Attachments
            </button>
          </div>
          <div className={styles.tabContent}>
            {selectedTab === 'description' && (
              <div className={styles.description}>
                <p>{sessionDetails.description}</p>
              </div>
            )}
            {selectedTab === 'attachments' && (
              <div className={styles.fileAttachments}>
                <div className={styles.fileGrid}>
                  {sessionDetails.files?.map(file => (
                    <a key={file.id} href={file.url} target="_blank" rel="noopener noreferrer" className={styles.fileButton} download>
                      {file.filename}
                    </a>
                  ))}
                </div>
                {userRole === 'instructor' && (
                  <div>
                    <form onSubmit={handleFileUpload} className={styles.fileUploadForm}>
                      <input type="file" onChange={e => setFileToUpload(e.target.files[0])} className={styles.fileInput} />
                      <button type="submit" className={styles.uploadButton}>Upload</button>
                    </form>
                  </div>
                )}
              </div>
            )}

          </div>
        </div>

        <div className={styles.notesSection}>
        <h2 className={styles.notesHeaderMain}>Notes</h2>
        <div>
          {notes.map((note) => (
            <div key={note.id} className={styles.noteBubble}>
              <p>{note.note}</p>
            </div>
          ))}
        </div>
        {userRole === 'instructor' && (
          <div>
            <textarea 
              value={newNote} 
              onChange={e => setNewNote(e.target.value)} 
              className={styles.textarea} 
              placeholder="Write your note here..."
            />
            <div className={styles.noteButton}>
              <button onClick={handleNoteSubmit} className={styles.submitButton}>Submit</button>
            </div>
          </div>
        )}
      </div>

      </div>
    </div>
  );
};

function MyVideoConference({ role, listParticipants, startRecording, stopRecording, isRecording }) {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: true }
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className={isFullScreen ? styles.fullScreen : styles.minScreen}>
      <VideoConference tracks={tracks}>
        {tracks.map(track => (
          <ParticipantTile
            key={track.participant.sid}
            track={track}
            isLocal={track.participant.isLocal}
            showControls={true}
          />
        ))}
      </VideoConference>

      {/* Full Screen Button */}
      <button className={styles.fullScreenButton} onClick={toggleFullScreen}>
        <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
      </button>

      {/* Start/Stop Recording Button */}
      {role === 'instructor' && (

      <div className={styles.recordingButtonGroup}>
        <button
          className={`${styles.recordingButton} ${styles.startRecording}`}
          onClick={startRecording}
          style={{ display: !isRecording ? 'block' : 'none' }}
        >
          Start Recording
        </button>
        <button
          className={`${styles.recordingButton} ${styles.stopRecording}`}
          onClick={stopRecording}
          style={{ display: isRecording ? 'block' : 'none' }}
        >
          Stop Recording
        </button>
      </div>
      )}


      {/* List Participants button, only shown to instructors */}
      {role === 'instructor' && (
        <button className={styles.participantsButton} onClick={listParticipants}>
          List Participants
        </button>
      )}
    </div>
  );
}


export default VideoChatGeneral;
