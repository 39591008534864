import React, { useEffect, useState } from 'react';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { FaCalendarAlt, FaClock, FaUser, FaChalkboardTeacher } from 'react-icons/fa';
import GeneralModal from '../GeneralSessionModal/GeneralSessionModal';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from '../paginationControl'; // Importing Pagination component

const GeneralSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [timezone, setTimezone] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for skeleton loader

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSessions, setTotalSessions] = useState(0);
  const limit = 6;

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true); // Start loading
      const csrfToken = await fetchCsrfToken();
      try {
        const generalResponse = await api.get(`/my-bookings/general-upcoming?page=${currentPage}&limit=${limit}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });

        const sessions = generalResponse.data.sessions.map((session) => ({ ...session, type: 'general' }));
        sessions.sort((a, b) => new Date(a.availability.start) - new Date(b.availability.start));

        setSessions(sessions);
        setTimezone(generalResponse.data.timezone); // Set timezone from the response
        setTotalSessions(generalResponse.data.totalSessions); // Set total sessions for pagination
      } catch (error) {
        console.error('Error fetching general sessions:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchSessions();
  }, [currentPage]); // Refetch sessions when the page changes

  const formatSessionDate = (utcDate) => {
    if (!timezone) return ''; // Fallback if timezone is not set
    return moment(utcDate).tz(timezone).format('dddd, MMMM Do, YYYY');
  };

  const formatSessionTime = (startUTC, endUTC) => {
    if (!timezone) return ''; // Fallback if timezone is not set
    const startTime = moment(startUTC).tz(timezone).format('hh:mm A');
    const endTime = moment(endUTC).tz(timezone).format('hh:mm A');
    return `${startTime} - ${endTime}`;
  };

  const openModal = (session) => {
    setSelectedSession(session);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSession(null);
  };

  const renderSession = (session) => (
    <div key={session._id} className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className="absolute left-4 top-3 bottom-3 w-1 bg-red-400"></div>
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-center mb-4">
          <div className="text-lg font-bold text-primary-color">{session.sessionName}</div>
          {session.pending && (
            <div className="flex items-center bg-red-400 text-white py-1 px-3 rounded-full">
              <FaClock className="mr-2" /> Pending
            </div>
          )}
        </div>

        <div className="flex items-center text-gray-600 mb-2">
          <FaCalendarAlt className="mr-2 text-primary-color" />
          <span>{formatSessionDate(session.availability.start)}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaClock className="mr-2 text-primary-color" />
          <span>{formatSessionTime(session.availability.start, session.availability.end)}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaChalkboardTeacher className="mr-2 text-primary-color" />
          <p className="text-base text-gray-700 mb-2.5">{session.instructorId.firstName} {session.instructorId.lastName}</p>
        </div>

        <button
          className="mt-4 px-4 py-2 bg-primary-color text-white rounded-md hover:bg-primary-color-hover transition duration-300"
          onClick={() => openModal(session)}
        >
          View Details
        </button>
      </div>
    </div>
  );

  const renderSkeletonLoader = () => (
    <div className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-start mb-2">
          <Skeleton width={200} />
        </div>
        <div className="flex flex-col justify-between mt-2">
          <Skeleton width={150} height={20} className="mb-2" />
          <Skeleton width={100} height={20} />
        </div>
      </div>
    </div>
  );

  const totalPages = Math.ceil(totalSessions / limit); // Calculate total pages

  return (
    <div>
    <div className="max-h-[575px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
      {loading
        ? Array(6)
            .fill(0)
            .map((_, index) => <div key={index}>{renderSkeletonLoader()}</div>)
        : sessions.map((session) => renderSession(session))}

      

      <GeneralModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        session={selectedSession}
        showApplyButton={false} // Do not show the apply button in GeneralSessions
        timezone={timezone} // Pass timezone to the modal for proper date/time formatting
      />
    </div>
    {/* Pagination Controls */}
    {!loading && totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      )}
    </div>
  );
};

export default GeneralSessions;
