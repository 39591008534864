import React from 'react';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import DOMPurify from 'dompurify'; // Import DOMPurify


const GeneralModal = ({ isOpen, onRequestClose, session, onApply, showApplyButton, timezone }) => {
  if (!session) return null;

  const formatSessionDate = (utcDate) => {
    return moment(utcDate).tz(timezone).format('dddd, MMMM Do, YYYY');
  };

  const formatSessionTime = (startUTC, endUTC) => {
    const startTime = moment(startUTC).tz(timezone).format('hh:mm A');
    const endTime = moment(endUTC).tz(timezone).format('hh:mm A');
    return `${startTime} - ${endTime}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Session Details Modal"
      className="relative w-full max-w-[90%] sm:max-w-[900px] p-5 bg-white rounded-lg shadow-lg transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 overflow-y-auto max-h-[90vh]"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-[90000]"
    >
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row items-center gap-5 mb-5">
          <div className="flex justify-center items-center w-full sm:w-auto">
            <img
              src={session.imagePath ? session.imagePath : `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.png`}
              alt="Session"
              className="w-full sm:w-[300px] h-[200px] sm:h-[300px] rounded-lg object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.jpg`;
              }}
            />
          </div>
          <div className="flex-1">
            <h2 className="text-2xl font-bold text-primary mb-2.5">{session.sessionName}</h2>
            <p className="text-base text-gray-700 mb-2.5">Date: {formatSessionDate(session.startUTC || session.availability.start)}</p>
            <p className="text-base text-gray-700 mb-2.5">{session.instructorId.firstName} {session.instructorId.lastName}</p>
            <p className="text-base text-gray-700 mb-2.5">
              {formatSessionTime(session.startUTC || session.availability.start, session.endUTC || session.availability.end)}
            </p>
            <div className="flex flex-wrap gap-1.5 mb-2.5">
              {session.skills.map(skill => (
                <span key={skill} className="inline-block bg-primary-color text-white px-2.5 py-1 rounded-md opacity-70">{skill}</span>
              ))}
            </div>
            <p className="text-base text-gray-700">Registered students: {session.registeredStudents.length}</p>
          </div>
        </div>
        <div className="mt-5">
          <h3 className="text-lg font-semibold text-gray-700 mb-2.5">Session Goals:</h3>
          <div
            className="text-base text-gray-700 mb-5"
            dangerouslySetInnerHTML={{ 
              __html: DOMPurify.sanitize(session.sessionGoals.join(', '), {
                ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                ALLOWED_ATTR: ['href']
              })
            }}
          />
          <h3 className="text-lg font-semibold text-gray-700 mb-2.5">Prerequisites:</h3>
          <div
            className="text-base text-gray-700 mb-5"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(session.prerequisites, {
                ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                ALLOWED_ATTR: ['href']
              })
            }}
          />
          <h3 className="text-lg font-semibold text-gray-700 mb-2.5">Description:</h3>
          <div
            className="text-base text-gray-700 mb-5"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(session.description, {
                ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                ALLOWED_ATTR: ['href']
              })
            }}
          />
        </div>
        {showApplyButton && (
          <button onClick={onApply} className="bg-primary text-white py-3 px-6 rounded-md cursor-pointer text-lg mt-5 hover:bg-primary-hover">
            Apply Now
          </button>
        )}
        <button onClick={onRequestClose} className="bg-red-600 text-white py-2.5 px-4 rounded-md cursor-pointer mt-5">
          Close
        </button>
      </div>
    </Modal>
  );
};

export default GeneralModal;
