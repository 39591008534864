import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import swal from 'sweetalert';
import api from '../../utils/auth';
import moment from 'moment-timezone';  // Import moment-timezone

const EnterPhone = () => {
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const navigate = useNavigate();

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phone) {
      setPhoneError('Please enter a valid phone number');
      return;
    }
  
    // Get the student's timezone from their local machine
    const timezone = moment.tz.guess();
  
    try {
      // Fetch the CSRF token
      const csrfResponse = await api.get('/auth/csrf-token');
      const csrfToken = csrfResponse.data.csrfToken;
  
      // Include the CSRF token in the request headers
      const response = await api.post(
        '/auth/save-phone',
        { phone, timezone },
        {
          withCredentials: true,
          headers: {
            'CSRF-Token': csrfToken,
          },
        }
      );
  
      if (response.status === 200) {
        swal("Success", "Phone number updated successfully!", "success").then(() => {
          navigate('/student/dashboard');
        });
      } else {
        throw new Error('Failed to update phone number');
      }
    } catch (error) {
      console.error('Error saving phone number:', error);
      setPhoneError('Failed to save phone number. Please try again.');
    }
  };
  

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#f7f9fc] px-5">
      <h1 className="text-2xl mb-5 text-[#333]">Enter Your Phone Number</h1>
      <p className="text-base mb-5 text-[#555] text-center max-w-xs">
        Please provide your phone number to stay updated with alerts, reminders, and important notifications.
      </p>
      <form className="w-full max-w-sm bg-white p-5 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <div className="w-full mb-2.5">
          <PhoneInput
            country={'us'}
            value={phone}
            onChange={handlePhoneChange}
            inputClass="w-full px-4 py-2 text-base border border-border-color rounded-md"
            buttonClass="rounded-l-md border border-border-color"
          />
        </div>
        {phoneError && <p className="text-red-500 text-sm mb-2.5">{phoneError}</p>}
        <button type="submit" className="bg-primary-color hover:bg-primary-color-hover text-white py-2.5 rounded-md text-base w-full">
          Save
        </button>
      </form>
    </div>
  );
};

export default EnterPhone;
