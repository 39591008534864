import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../utils/auth';
import RequestModal from '../RequestModal/RequestModal';
import Pagination from '../paginationControl'; // Import the Pagination component

const PendingRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingRequestId, setLoadingRequestId] = useState(null); // State for loading specific request
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`; // Default profile image path
  const [selectedRequest, setSelectedRequest] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/help-forum/unanswered?page=${currentPage}&limit=6`);
        setRequests(response.data.requests);
        setTotalPages(Math.ceil(response.data.total / 6)); // Calculate total pages
      } catch (error) {
        console.error('Error fetching unanswered requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [currentPage]); // Refetch when currentPage changes

  const handleOpenModal = async (requestId) => {
    setLoadingRequestId(requestId); // Set loading state for the specific request
    try {
      const response = await api.get(`/help-forum/detailed/${requestId}`);
      setSelectedRequest(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching detailed request:', error);
    } finally {
      setLoadingRequestId(null); // Reset loading state
    }
  };

  const getProfileImage = (profilePicture) => {
    return profilePicture ? profilePicture : defaultProfileImage;
  };

  return (
    <div className="max-h-[665px] overflow-y-auto flex flex-col gap-5">
      {loading ? (
        // Display skeleton loaders while loading
        [...Array(6)].map((_, index) => (
          <div
            key={index}
            className="p-5 border border-border-color rounded-lg bg-white relative transition-all duration-300 ease-in-out shadow-md pb-3"
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2.5">
                <Skeleton circle={true} height={40} width={40} />
                <div>
                  <Skeleton height={20} width={150} />
                  <Skeleton height={15} width={100} />
                </div>
              </div>
              <Skeleton width={70} height={25} />
            </div>
            <div className="flex gap-2 mt-2">
              <Skeleton height={20} width={50} />
              <Skeleton height={20} width={50} />
            </div>
            <div className="flex flex-col gap-1 mt-2 text-sm text-gray-800">
              <Skeleton height={15} width={120} />
              <Skeleton height={15} width={150} />
            </div>
            <div className="flex justify-center text-center mt-2 border-t border-border-color pt-2">
              <Skeleton width={80} height={30} />
            </div>
          </div>
        ))
      ) : (
        requests.map((request) => (
          <div
            key={request._id}
            className="p-5 border border-border-color rounded-lg bg-white relative transition-all duration-300 ease-in-out shadow-md pb-3"
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2.5">
                <img
                  src={getProfileImage(request.student?.profilePicture)}
                  alt="Profile"
                  className="w-10 h-10 rounded-full bg-gray-300"
                />
                <div>
                  <h3 className="text-lg font-semibold">{request.helpRequest}</h3>
                  <p className="text-sm text-gray-600">
                    {request.student
                      ? `${request.student.firstName} ${request.student.lastName}`
                      : 'Student information not available'}
                  </p>
                </div>
              </div>
              {request.answers.length > 0 && (
                <div className="bg-gray-100 p-2 rounded-full text-primary-color border border-primary-color text-sm font-semibold">
                  Answered
                </div>
              )}
            </div>
            <div className="flex gap-2 mt-2">
              {request.tags.map((tag, index) => (
                <span key={index} className="bg-gray-100 p-1 rounded-full text-primary-color border border-primary-color text-sm font-medium">
                  {tag}
                </span>
              ))}
            </div>
            <div className="flex flex-col gap-1 mt-2 text-sm text-gray-800">
              <p>
                <strong>{request.answers.length}</strong> Answers
              </p>
              <p>
                <strong>{request.uniqueInstructorsCount}</strong> Mentors answered
              </p>
            </div>
            <div className="flex justify-center text-center mt-2 border-t border-border-color pt-2">
              {loadingRequestId === request._id ? (
                <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full text-primary-color"></div>
              ) : (
                <button
                  onClick={() => handleOpenModal(request._id)}
                  className="flex justify-center items-center bg-none border-none text-primary-color cursor-pointer p-2 text-sm transition-colors duration-300 hover:text-primary-color-hover"
                >
                  View More
                </button>
              )}
            </div>
          </div>
        ))
      )}
      
      {/* Pagination Controls */}
      {!loading && totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      )}

      {selectedRequest && (
        <RequestModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          request={selectedRequest}
        />
      )}
    </div>
  );
};

export default PendingRequests;
