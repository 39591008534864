import React, { useState, useEffect } from 'react';
import NewChallenges from '../../../components/StudentChallenges/NewChallenges';
import MyChallenges from '../../../components/StudentChallenges/MyChallenges';
import PastChallenges from '../../../components/StudentChallenges/PastChallenges';
import ChallengeDetailsModal from '../../../components/StudentChallenges/ChallengeDetailsModal';
import api from '../../../utils/auth'; // Import the api object

const Challenges = () => {
  const [selectedTab, setSelectedTab] = useState('new');
  const [pastChallenges, setPastChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedTab === 'past') {
      // Fetch past challenges when the "Past Challenges" tab is selected
      const fetchPastChallenges = async () => {
        try {
          const response = await api.get('/student-challenges/challenges/past');
          setPastChallenges(response.data);
        } catch (error) {
          console.error('Error fetching past challenges:', error);
        }
      };

      fetchPastChallenges();
    }
  }, [selectedTab]);

  const handleViewMore = (challenge) => {
    setSelectedChallenge(challenge);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  return (
    <div className="p-5 max-w-[1400px] mx-auto h-[85vh] flex flex-col">
      {/* Fixed header */}
      <div className="text-2xl font-bold mb-5 text-primary-color">Challenges</div>
      <div className="flex border-border-color border-b-0">
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'new'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('new')}
        >
          New Challenges
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'mine'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('mine')}
        >
          My Challenges
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'past'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('past')}
        >
          Past Challenges
        </button>
      </div>
      {/* Scrollable content */}
      <div className="flex-grow overflow-y-auto mt-6 h-[45vh]">
        {selectedTab === 'new' && <NewChallenges onViewMore={handleViewMore} />}
        {selectedTab === 'mine' && <MyChallenges onViewMore={handleViewMore} />}
        {selectedTab === 'past' && <PastChallenges pastChallenges={pastChallenges} onViewMore={handleViewMore} />}
      </div>

      {/* Modal for Challenge Details */}
      {showModal && selectedChallenge && (
        <ChallengeDetailsModal challenge={selectedChallenge} onClose={closeModal} />
      )}
    </div>
  );
};

export default Challenges;
