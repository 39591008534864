import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone'; // Import moment-timezone
import { fetchCsrfToken } from '../../../utils/csrf';
import api from '../../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InstructorSessionForm from '../../../components/InstructorSessionForm/InstructorSessionForm';
import DashboardCard from '../../../components/InstructorHome/DashboardCard';
import ProfileCompletionSection from '../../../components/InstructorHome/ProfileCompletionSection';
import {
  faChalkboardTeacher,
  faHandsHelping,
  faUser,
  faCheckCircle,
  faHourglassHalf
} from '@fortawesome/free-solid-svg-icons';

const InstructorDashboard = () => {
  const [userData, setUserData] = useState(null);
  const [generalSessions, setGeneralSessions] = useState([]);
  const [oneOnOneSessions, setOneOnOneSessions] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAllServicesModal, setShowAllServicesModal] = useState(false);
  const defaultLandscape = `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.jpg`;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get('/instructorsDashboard', {
          headers: {
            'CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
  
        const {
          generalSessions = [],
          oneOnOneSessions = [],
          services = [],
          ...userDetails
        } = response.data;
  
        setUserData(userDetails);
        
        // Get the instructor's timezone from userData
        const timezone = userDetails.timezone || moment.tz.guess();
  
        // Filter and format upcoming general sessions using the instructor's timezone
        const upcomingGeneralSessions = generalSessions.filter(session =>
          moment.utc(session.availability.start).tz(timezone).isAfter(moment())
        ).map((session) => {
          const sessionStart = moment.utc(session.availability.start).tz(timezone).format('hh:mm A');
          const sessionEnd = moment.utc(session.availability.end).tz(timezone).format('hh:mm A');
          return {
            ...session,
            formattedDate: moment.utc(session.availability.start).tz(timezone).format('dddd, MMMM Do, YYYY'),
            formattedTime: `${sessionStart} - ${sessionEnd}`,
          };
        });
        setGeneralSessions(upcomingGeneralSessions);
  
        // Filter and format upcoming one-on-one sessions
        const upcomingOneOnOneSessions = oneOnOneSessions.filter(session =>
          moment.utc(session.startUTC).tz(timezone).isAfter(moment())
        ).map((session) => {
          const sessionDate = moment.utc(session.startUTC).tz(timezone).toDate();
          return {
            ...session,
            sessionDate,
          };
        });
        setOneOnOneSessions(upcomingOneOnOneSessions);
  
        setServices(services);
  
        // Calculate profile completion based on data
        const tasksCompleted = [
          userDetails.experiences && userDetails.experiences.length > 0,
          userDetails.payments && userDetails.payments.length > 0,
          userDetails.location,
          userDetails.timezone,
          userDetails.bio,
          userDetails.addedSkills && userDetails.addedSkills.length > 0,
          userDetails.education && userDetails.education.length > 0,
          userDetails.achievements && userDetails.achievements.length > 0,
          userDetails.profilePicture,
          userDetails.headline,
          userDetails.areaOfExpertise,
          userDetails.mentorship && userDetails.mentorship.price !== null,
          userDetails.mentorship && userDetails.mentorship.availability && Object.keys(userDetails.mentorship.availability).some(day => userDetails.mentorship.availability[day].length > 0)
        ];
  
        const completedTasksCount = tasksCompleted.filter(Boolean).length;
        const totalTasks = tasksCompleted.length;
        const completionPercentage = (completedTasksCount / totalTasks) * 100;
  
        setProfileCompletion(completionPercentage);
  
      } catch (error) {
        console.error('Error fetching instructor dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const handleRedirect = (path) => {
    navigate(`/mentor/settings${path}`);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleAllServicesModal = () => {
    setShowAllServicesModal(!showAllServicesModal);
  };

  const handleHelpForumClick = () => {
    navigate('/mentor/forum');
  };

  const renderSkeleton = () => (
    <div className="bg-white rounded-lg shadow-md p-5 mb-5 border border-gray-300">
      <div className="bg-gray-200 h-6 w-1/3 mb-4 rounded animate-pulse"></div>
      <div className="bg-gray-200 h-4 w-2/3 mb-4 rounded animate-pulse"></div>
      <div className="bg-gray-200 h-4 w-full rounded animate-pulse"></div>
    </div>
  );

  const renderEmptyState = (message) => (
    <div className="text-center py-8 border-dashed border-2 border-gray-300 rounded-lg">
      <p className="text-gray-600">{message}</p>
    </div>
  );

  const renderGeneralSessions = () => {
    if (generalSessions.length === 0) {
      return renderEmptyState("No upcoming general sessions.");
    }
  
    return generalSessions.map((session) => {
      const statusIcon = session.isVerified === true
        ? <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
        : <FontAwesomeIcon icon={faHourglassHalf} className="text-yellow-500" />;
      const statusText = session.isVerified === true ? 'Approved' : 'Waiting for Approval';
  
      return (
        <div key={session._id} className="bg-white p-6 rounded shadow-lg border border-gray-300 w-full lg:max-w-[1400px] flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={session.imagePath || defaultLandscape} alt={session.sessionName} className="w-20 h-20 object-cover rounded mr-4" />
            <div>
              <h3 className="text-2xl font-bold text-gray-800">{session.sessionName}</h3>
              <p className="text-md text-gray-600">{session.formattedDate} at {session.formattedTime}</p>
              <div className="flex flex-wrap mt-2">
                {session.skills.map((skill, index) => (
                  <span key={index} className="inline-block bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full mr-2 mb-2">{skill}</span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faUser} className="text-primary-color mr-2" />
              <p className="text-sm text-gray-600">{session.registeredStudents.length} Students Registered</p>
            </div>
            <div className="flex items-center space-x-2">
              {statusIcon}
              <p className="text-md font-semibold">{statusText}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderOneOnOneSessions = () => {
      if (oneOnOneSessions.length === 0) {
        return renderEmptyState(
          <>
            No one-on-one sessions scheduled. Improve your chances by contributing to the{" "}
            <a
              href="/mentor/forum"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              Help Forum
            </a>.
          </>
        );
      }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {oneOnOneSessions.slice(0, 4).map((session) => {
          const day = session.sessionDate.getDate();
          const formattedDay = day < 10 ? `0${day}` : day;
          return (
            <div key={session._id} className="flex flex-col items-center justify-center p-4 bg-white shadow-md rounded-lg h-[240px] border border-gray-300">
              <div className="text-center mb-3">
                <span className="block text-4xl font-bold text-blue-600">{formattedDay}</span>
                <span className="text-sm uppercase text-gray-500">{session.sessionDate.toLocaleString('default', { month: 'short' })}</span>
              </div>
              <div className="text-center">
                <p className="text-md font-semibold text-gray-700">{`${session.studentId?.firstName} ${session.studentId?.lastName}`}</p>
                <p className="text-sm text-gray-500">{session.sessionDate.toLocaleTimeString()}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderServices = () => {
    if (services.length === 0) {
      return renderEmptyState("No ongoing services.");
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.slice(0, 3).map((service) => (
          <div key={service._id} className="bg-white p-4 rounded shadow-sm border border-gray-300 flex flex-col">
            <img src={service.serviceId?.image || defaultLandscape} alt={service.serviceId?.serviceTitle} className="w-full h-32 object-cover rounded mb-4" />
            <div className="flex-grow">
              <h3 className="text-lg font-bold text-gray-700">{service.serviceId?.serviceTitle}</h3>
              <p className="text-sm text-gray-500">Booked by: {service.studentId?.firstName} {service.studentId?.lastName}</p>
              <p className="text-sm text-gray-500 mb-2">Price: ${service.serviceId?.price}</p>
              <div className="mb-2">
                {service.serviceId?.industrySkills && service.serviceId.industrySkills.map((tag, index) => (
                  <span key={index} className="inline-block bg-gray-200 text-gray-600 text-xs px-2 py-1 rounded-full mr-2">{tag}</span>
                ))}
              </div>
            </div>
          </div>
        ))}
        {services.length > 3 && (
          <button className="mt-4 text-primary-color" onClick={toggleAllServicesModal}>
            View All Services
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="p-5 max-w-[1400px] mx-auto flex flex-col">
      <h1 className="text-2xl font-bold mb-5 text-primary-color">Welcome, {userData?.firstName}</h1>

      {loading ? (
        <>
          {renderSkeleton()}
          {renderSkeleton()}
          {renderSkeleton()}
        </>
      ) : (
        profileCompletion < 100 ? (
          <ProfileCompletionSection
            userData={userData}
            handleRedirect={handleRedirect}
            profileCompletion={profileCompletion}
          />
        ) : (
          <>
            {/* Cards for actions */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
              <DashboardCard
                icon={faChalkboardTeacher}
                title="Hold a Session"
                description="Schedule a new mentoring session to engage with your students."
                onClick={toggleModal}
              />
              <DashboardCard
                icon={faHandsHelping}
                title="Help Students in Forum"
                description="Join the help forum to assist students with their queries and challenges."
                onClick={handleHelpForumClick}
              />
            </div>

            {/* Upcoming General Sessions */}
            <div className="bg-white rounded-lg shadow-md p-5 mb-5">
              <h2 className="text-xl font-bold mb-3 text-gray-700">Upcoming General Sessions</h2>
              {renderGeneralSessions()}
            </div>

            {/* Upcoming One-on-One Sessions */}
            <div className="bg-white rounded-lg shadow-md p-5 mb-5">
              <h2 className="text-xl font-bold mb-3 text-gray-700">Upcoming One-on-One Sessions</h2>
              {renderOneOnOneSessions()}
            </div>

            {/* Services Section */}
            <div className="bg-white rounded-lg shadow-md p-5">
              <h2 className="text-xl font-bold mb-3 text-gray-700">Services</h2>
              {renderServices()}
            </div>
          </>
        )
      )}

      {/* Modal for InstructorSessionForm */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-[600px] max-h-[80vh] overflow-y-auto">
            <button className="text-gray-500 text-right mb-2" onClick={toggleModal}>
              Close
            </button>
            <InstructorSessionForm instructorId={userData?._id} />
          </div>
        </div>
      )}

      {/* Modal for Viewing All Services */}
      {showAllServicesModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-[800px] max-h-[80vh] overflow-y-auto">
            <button className="text-gray-500 text-right mb-2" onClick={toggleAllServicesModal}>
              Close
            </button>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {services.map((service) => (
                <div key={service._id} className="bg-white p-4 rounded shadow-sm border border-gray-300 flex flex-col">
                  <img src={service.serviceId?.image || defaultLandscape} alt={service.serviceId?.serviceTitle} className="w-full h-32 object-cover rounded mb-4" />
                  <div className="flex-grow">
                    <h3 className="text-lg font-bold text-gray-700">{service.serviceId?.serviceTitle}</h3>
                    <p className="text-sm text-gray-500">Booked by: {service.studentId?.firstName} {service.studentId?.lastName}</p>
                    <p className="text-sm text-gray-500 mb-2">Price: ${service.serviceId?.price}</p>
                    <div className="mb-2">
                      {service.serviceId?.industrySkills && service.serviceId.industrySkills.map((tag, index) => (
                        <span key={index} className="inline-block bg-gray-200 text-gray-600 text-xs px-2 py-1 rounded-full mr-2">{tag}</span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstructorDashboard;
