import React, { useState } from 'react';
import AdminNavBar from '../../components/Admin/AdminNavbar';
import UserManagement from '../../components/Admin/UserManagement';
import SessionsRequest from '../../components/Admin/SessionRequest';
import ApproveInstructor from '../../components/Admin/ApproveInstructor';
import InstructorServices from '../../components/Admin/InstructorServices';
import AdminReports from '../../components/Admin/AdminReports';
import CreateChallenge from '../../components/Admin/CreateChallenge';
import ManageChallenges from '../../components/Admin/ManageChallenges';
import ChallengeSubmissions from '../../components/Admin/ChallengeSubmissions';
import AdminVideoRecordings from '../../components/Admin/AdminVideoRecordings';
import Analytics from '../../components/Admin/Analytics'; // New Analytics Component
import OneOnOneSessions from '../../components/Admin/OneonOneSession'; // Import the new component
import styles from './AdminPage.module.css';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
  const [activeComponent, setActiveComponent] = useState('UserManagement');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Analytics': // New case for Analytics
      return <Analytics />;
      case 'UserManagement':
        return <UserManagement />;
      case 'SessionsRequest':
        return <SessionsRequest />;
      case 'ApproveInstructor':
        return <ApproveInstructor />;
      case 'InstructorServices':
        return <InstructorServices />;
      case 'VideoRecordings':
        return <AdminVideoRecordings />;
      case 'Reports':
        return <AdminReports />;
      case 'CreateChallenge':
        return <CreateChallenge />;
      case 'ManageChallenges':
        return <ManageChallenges />;
      case 'ChallengeSubmissions':
        return <ChallengeSubmissions />;
      case 'OneOnOneSessions':
        return <OneOnOneSessions />; // Render the new component
      case 'Logout':
        handleLogout();
        break;
      default:
        return <UserManagement />;
    }
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    swal({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willLogout) => {
      if (willLogout) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.post(`/auth/logout`, {
            headers: {
              'CSRF-Token': csrfToken,
            },
          });
          navigate('/sign-in');
        } catch (error) {
          console.error('Error during logout:', error);
        }
      }
    });
  };

  return (
    <div className={styles.adminPage}>
      <AdminNavBar setActiveComponent={setActiveComponent} />
      <div className={styles.mainContent}>
        {renderComponent()}
      </div>
    </div>
  );
};

export default AdminPage;
