import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar-edit';
import Modal from 'react-modal';
import Select from 'react-select';
import moment from 'moment-timezone';
import swal from 'sweetalert';
import countryList from 'react-select-country-list';
import api from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './../buttonloader.css'; // Assuming you have a loader CSS file
import useInstructorStore from '../../store/useInstructorStore';
const timezoneOptions = moment.tz.names().map((timezone) => ({
  value: timezone,
  label: `(UTC${moment.tz(timezone).utcOffset() / 60 >= 0 ? '+' : ''}${
    moment.tz(timezone).utcOffset() / 60
  }) ${timezone}`,
}));

const MyProfile = () => {
  const { instructorData, setInstructorData } = useInstructorStore();

  const [profile, setProfile] = useState(instructorData); // Load from store initially


  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;
  const uploadIcon = `${process.env.PUBLIC_URL}/assets/icons/uploadIcon.svg`;

  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);
  const [showAvatarEditor, setShowAvatarEditor] = useState(false);
  const [profileImage, setProfileImage] = useState(
    instructorData.profilePicture ? instructorData.profilePicture : defaultProfileImage
  );
  // Populate profile data when instructorData is available in the store

  useEffect(() => {
    // Check if the instructorData is available and set the profile image accordingly
    if (instructorData.profilePicture) {
      setProfileImage(instructorData.profilePicture);
    } else {
      setProfileImage(defaultProfileImage);
    }
  }, [instructorData.profilePicture]); // Run this effect when instructorData changes
  useEffect(() => {
    if (instructorData._id) {
      // Set all the profile data from the store
      setProfile({
        firstName: instructorData.firstName || '',
        lastName: instructorData.lastName || '',
        email: instructorData.email || '',
        bio: instructorData.bio || '',
        profilePicture: instructorData.profilePicture || defaultProfileImage,
        phone: instructorData.phone || '',
        location: instructorData.location || '',
        timezone: instructorData.timezone || '',
        headline: instructorData.headline || '',
        areaOfExpertise: instructorData.areaOfExpertise || '',
        emailVerified: instructorData.emailVerified, // Set emailVerified correctly
      });
      setLoading(false); // Stop loading once data is set
    }
  }, [instructorData]); // Run this effect when the store data changes
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [profileError, setProfileError] = useState({ firstName: '', lastName: '' });
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const token = localStorage.getItem('jwtToken');
  const countryOptions = countryList().getData();
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [loadingSubmit, setLoadingSubmit] = useState(false); // Save Changes Button
  const [loadingProfilePicSubmit, setLoadingProfilePicSubmit] = useState(false); // Profile Pic Save Button
  const [loadingEmailSubmit, setLoadingEmailSubmit] = useState(false); // Email Submit Button
  const [loadingPasswordSubmit, setLoadingPasswordSubmit] = useState(false); // Password Submit Button
  const [loadingPhoneSubmit, setLoadingPhoneSubmit] = useState(false); // Phone Submit Button

  const navigate = useNavigate();

  const fetchCsrfToken = async () => {
    const response = await api.get('/csrf-token');
    return response.data.csrfToken;
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value || '',
    }));
  };

  const handleBioChange = (value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      bio: value,
    }));
  };

  const handleLocationChange = (selectedOption) => {
    setProfile({ ...profile, location: selectedOption.value });
  };

  const handleTimezoneChange = (selectedOption) => {
    setProfile({ ...profile, timezone: selectedOption.value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate input
    if (!profile.firstName || !profile.lastName) {
      setProfileError({
        ...profileError,
        firstName: !profile.firstName ? 'First name is required' : '',
        lastName: !profile.lastName ? 'Last name is required' : '',
      });
      return;
    }
  
    console.log(profile);
  
    setLoadingSubmit(true); // Show loader for Save Changes button
  
    try {
      const csrfToken = await fetchCsrfToken();
  
      // Check which fields have changed
      const updatedFields = {};
      if (profile.firstName !== instructorData.firstName) updatedFields.firstName = profile.firstName;
      if (profile.lastName !== instructorData.lastName) updatedFields.lastName = profile.lastName;
      if (profile.email !== instructorData.email) updatedFields.email = profile.email;
      if (profile.bio !== instructorData.bio) updatedFields.bio = profile.bio;
      if (profile.phone !== instructorData.phone) updatedFields.phone = profile.phone;
      if (profile.location !== instructorData.location) updatedFields.location = profile.location;
      if (profile.timezone !== instructorData.timezone) updatedFields.timezone = profile.timezone;
      if (profile.headline !== instructorData.headline) updatedFields.headline = profile.headline;
      if (profile.areaOfExpertise !== instructorData.areaOfExpertise)
        updatedFields.areaOfExpertise = profile.areaOfExpertise;
      if (profile.profilePicture !== instructorData.profilePicture)
        updatedFields.profilePicture = profile.profilePicture;
  
      // If nothing has changed, return early
      if (Object.keys(updatedFields).length === 0) {
        swal('No Changes', 'No changes to update', 'info');
        setLoadingSubmit(false);
        return;
      }
  
      // Send API request with updated fields only
      await api.put('/instructors/update', updatedFields, {
        headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken },
      });
  
      // Update the store with the changed fields
      setInstructorData({
        ...instructorData,
        ...updatedFields, // Only update fields that have changed
      });
  
      swal('Success', 'Profile updated successfully!', 'success').then(() => {
        // window.location.reload();
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      swal('Error', 'Failed to update profile', 'error');
    } finally {
      setLoadingSubmit(false); // Hide loader for Save Changes button
    }
  };
  

  const handleViewProfile = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.get('/instructors/check-profile-completion', {
        headers: {
          Authorization: `Bearer ${token}`,
          'CSRF-Token': csrfToken,
        },
      });

      if (response.data.isProfileComplete) {
        navigate(`/mentor/view-profile/${profile._id}`);
      } else {
        swal(
          'Incomplete Profile',
          'Please complete all profile fields before viewing your profile.',
          'error'
        );
      }
    } catch (error) {
      console.error('Error checking profile completion:', error);
      swal('Error', 'Failed to check profile completion.', 'error');
    }
  };

  const openPhoneModal = () => {
    setNewPhone(profile.phone);
    setPhoneError('');
    setShowPhoneModal(true);
  };

  const closePhoneModal = () => {
    setPhoneError('');
    setShowPhoneModal(false);
    setNewPhone(profile.phone);
  };

  const handlePhoneChange = (value) => {
    setNewPhone(value);
  };

  const handlePhoneSubmit = async () => {
    if (!newPhone) {
      setPhoneError('Please enter a valid phone number');
      return;
    }
  
    setLoadingPhoneSubmit(true); // Show loader for Phone submit button
  
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.put(
        '/instructors/change-phone',
        { phone: newPhone },
        {
          headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken },
        }
      );
  
      if (response.status === 200) {
        swal('Success', 'Phone number updated successfully!', 'success').then(() => {
          // Update phone in both the profile and Zustand store
          setProfile((prevProfile) => ({
            ...prevProfile,
            phone: newPhone,
          }));
          
          // Update phone in Zustand store
          setInstructorData((prevData) => ({
            ...prevData,
            phone: newPhone,
          }));
          
          setShowPhoneModal(false);
        });
      } else {
        throw new Error('Failed to update phone number');
      }
    } catch (error) {
      console.error('Error changing phone number:', error);
      setPhoneError('Failed to change phone number. Please try again.');
    } finally {
      setLoadingPhoneSubmit(false); // Hide loader for Phone submit button
    }
  };
  

  const handleChangePassword = () => {
    setShowPasswordModal(true);
  };

  const handleUploadProfilePicture = () => {
    setShowAvatarEditor(true);
  };

  const onClose = () => {
    setPreview(null);
    setShowAvatarEditor(false);
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  const onSave = async () => {
    setLoadingProfilePicSubmit(true); // Show loader for Profile Pic save button

    const formData = new FormData();
    formData.append('profilePicture', preview);

    try {
      const csrfToken = await fetchCsrfToken();
      await api.post(
        '/instructors/upload-profile-picture',
        { profilePicture: preview },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'CSRF-Token': csrfToken,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      swal('Error', 'Failed to upload profile picture', 'error');
    } finally {
      setLoadingProfilePicSubmit(false); // Hide loader for Profile Pic save button
    }
  };

  const openEmailModal = () => {
    setShowEmailModal(true);
  };

  const closeEmailModal = () => {
    setShowEmailModal(false);
    setNewEmail('');
    setEmailError('');
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleResendVerification = async () => {
    try {
      const response = await api.post('/auth/resend-verification-email', {
        email: profile.email,
        role: 'instructor' // or 'student', depending on the profile
      });
  
      // If the response is successful, show the success swal message
    swal('Success', 'Verification email has been resent successfully!', 'success');
  } catch (error) {
    // If there is an error, show the error swal message
    console.error('Error resending verification email:', error);
    swal('Error', 'Failed to resend verification email. Please try again.', 'error');
  }
};


  const handleEmailSubmit = async () => {
    if (emailError || !newEmail) {
      setEmailError('Please enter a valid email');
      return;
    }

    setLoadingEmailSubmit(true); // Show loader for Email submit button

    try {
      const csrfToken = await fetchCsrfToken();
      await api.put(
        '/instructors/change-email',
        { newEmail },
        {
          headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken },
        }
      );
      swal(
        'Success',
        'Email updated successfully! Kindly verify your email by clicking on the link sent to your new email.',
        'success'
      ).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error('Error changing email:', error);
      setEmailError('Failed to change email. Email already exists.');
    } finally {
      setLoadingEmailSubmit(false); // Hide loader for Email submit button
    }
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordError('');
  };

  const handlePasswordSubmit = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setPasswordError('All fields are required');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords do not match!');
      return;
    }

    setLoadingPasswordSubmit(true); // Show loader for Password submit button

    try {
      const csrfToken = await fetchCsrfToken();
      await api.put(
        '/instructors/change-password',
        {
          currentPassword,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken },
        }
      );

      swal('Success', 'Password updated successfully!', 'success');
      closePasswordModal();
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError(
        'Failed to change password. Please check your current password and try again.'
      );
    } finally {
      setLoadingPasswordSubmit(false); // Hide loader for Password submit button
    }
  };

  const ProfileSkeleton = () => (
    <div className="max-w-[700px] p-5">
      <div className="text-2xl font-bold"><Skeleton width={200} /></div>
      <div className="flex flex-col md:flex-row mt-2 mb-4 md:mt-8 md:mb-10">
        <div className="relative mb-4 md:mb-0">
          <Skeleton circle={true} height={130} width={130} />
        </div>
        <div className="flex flex-col md:flex-row md:justify-center md:items-center md:ml-6">
          <Skeleton width={120} height={40} />
          <Skeleton width={120} height={40} style={{ marginLeft: '1rem' }} />
        </div>
      </div>
      <form className="flex flex-col">
        <div className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:gap-6">
          <div className="flex-1 md:mb-4">
            <Skeleton height={20} />
            <Skeleton height={40} />
          </div>
          <div className="flex-1 md:mb-4">
            <Skeleton height={20} />
            <Skeleton height={40} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:gap-6">
          <div className="flex-1 mb-2 md:mb-4">
            <Skeleton height={20} />
            <Skeleton height={40} />
          </div>
          <div className="flex-1 mb-2 md:mb-4">
            <Skeleton height={20} />
            <Skeleton height={40} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:gap-6">
          <div className="flex-1 mb-2 md:mb-4">
            <Skeleton height={20} />
            <Skeleton height={40} />
          </div>
          <div className="flex-1 mb-2 md:mb-4">
            <Skeleton height={20} />
            <Skeleton height={40} />
          </div>
        </div>
      </form>
    </div>
  );
  
  return (
    <div className="max-w-[700px] p-5">
      {loading ? (
        <ProfileSkeleton />
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <>
          <div className="text-2xl font-bold">My Profile</div>
          <div className="flex flex-col md:flex-row mt-2 mb-4 md:mt-8 md:mb-10">
            <div className="relative mb-4 md:mb-0">
              <img
                src={profileImage}
                alt="Profile"
                className="w-[90px] h-[90px] md:w-[130px] md:h-[130px] rounded-full object-cover transition-opacity duration-300"
              />
              <div
                className="absolute inset-0 w-[90px] h-[90px] md:w-[130px] md:h-[130px] rounded-full bg-black bg-opacity-50 flex justify-center items-center opacity-0 transition-opacity duration-300 cursor-pointer z-[3000] hover:opacity-100"
                onClick={handleUploadProfilePicture}
              >
                <img
                  src={uploadIcon}
                  alt="uploadIcon"
                  className="text-white w-[40px] h-[40px] md:w-[60px] md:h-[60px]"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-center md:items-center md:ml-6">
              <button
                className="px-6 py-2 md:py-4 mb-2 md:mb-0 border border-primary-color bg-white text-primary-color rounded-md cursor-pointer transition-colors duration-300 font-medium text-[14px] md:mr-4 hover:bg-primary-color-hover hover:text-white"
                onClick={handleChangePassword}
              >
                Change Password
              </button>
              <button
                className="px-6 py-2 md:py-4 border border-primary-color bg-white text-primary-color rounded-md cursor-pointer transition-colors duration-300 font-medium text-[14px] hover:bg-primary-color-hover hover:text-white"
                onClick={handleViewProfile}
              >
                View Profile
              </button>
            </div>
          </div>
  
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:gap-6">
              <div className="flex-1 md:mb-4">
                <label htmlFor="firstName" className="block mb-2 font-medium text-[16px]">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={profile.firstName}
                  onChange={handleInputChange}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
                {profileError.firstName && (
                  <p className="text-red-500 text-[14px]">{profileError.firstName}</p>
                )}
              </div>
              <div className="flex-1 md:mb-4">
                <label htmlFor="lastName" className="block mb-2 font-medium text-[16px]">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={profile.lastName}
                  onChange={handleInputChange}
                  className="w-full border border-border-color rounded-md mb-2 text-[14px] box-border p-3 md:mb-0"
                />
                {profileError.lastName && (
                  <p className="text-red-500 text-[14px]">{profileError.lastName}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:gap-6">
              <div className="flex-1 mb-2 md:mb-4">
                <label htmlFor="headline" className="block mb-2 font-medium text-[16px]">
                  Headline
                </label>
                <input
                  type="text"
                  id="headline"
                  name="headline"
                  value={profile.headline}
                  onChange={handleInputChange}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
              </div>
              <div className="flex-1 mb-2 md:mb-4">
                <label htmlFor="areaOfExpertise" className="block mb-2 font-medium text-[16px]">
                  Area of Expertise
                </label>
                <input
                  type="text"
                  id="areaOfExpertise"
                  name="areaOfExpertise"
                  value={profile.areaOfExpertise}
                  onChange={handleInputChange}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-0 items-start md:gap-6">
              <div className="flex-1 mb-2 md:mb-4">
                <label htmlFor="email" className="block mb-2 font-medium text-[16px]">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profile.email}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3 opacity-50 pointer-events-none"
                  disabled
                  onChange={handleInputChange}
                />
              <div className="flex items-center mt-2">
              {profile.emailVerified ? (
    <span className="text-primary-color text-sm font-medium">
      <FontAwesomeIcon icon={faCheckCircle} /> Verified
    </span>
  ) : (
    <span className="text-red-500 text-sm font-medium">
      <FontAwesomeIcon icon={faTimesCircle} /> Unverified
    </span>
  )}
              {!profile.emailVerified && (
                <button
                  className="ml-4 text-primary-color text-sm underline"
                  onClick={handleResendVerification}
                >
                  Resend Email Verification
                </button>
              )}
            </div>
              </div>
              <div className="flex justify-end mt-0 mb-2 md:mt-[44px]">
                <a
                  href="#"
                  className="text-[16px] text-primary-color cursor-pointer font-medium"
                  onClick={openEmailModal}
                >
                  Change Email
                </a>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-0 items-start md:gap-6">
              <div className="flex-1 mb-2 md:mb-4">
                <label htmlFor="phone" className="block mb-2 font-medium text-[16px]">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={profile.phone || ''}
                  onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                  disabled
                />
              </div>
              <div className="flex justify-end mt-0 mb-2 md:mt-[44px]">
                <a
                  href="#"
                  className="text-[16px] text-primary-color cursor-pointer font-medium"
                  onClick={openPhoneModal}
                >
                  Change Phone
                </a>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:gap-6">
              <div className="flex-1 mb-2 md:mb-4">
                <label htmlFor="location" className="block mb-2 font-medium text-[16px]">
                  Location
                </label>
                <Select
                  id="location"
                  name="location"
                  value={countryOptions.find((option) => option.value === profile.location)}
                  onChange={handleLocationChange}
                  options={countryOptions}
                />
              </div>
              <div className="flex-1 mb-2 md:mb-4">
                <label htmlFor="timezone" className="block mb-2 font-medium text-[16px]">
                  Timezone
                </label>
                <Select
                  id="timezone"
                  name="timezone"
                  value={timezoneOptions.find((option) => option.value === profile.timezone)}
                  onChange={handleTimezoneChange}
                  options={timezoneOptions}
                />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="bio" className="block mb-2 font-medium text-[16px]">
                Bio
              </label>
              <div className="bg-white border rounded-lg overflow-hidden">
                <ReactQuill
                  id="bio"
                  theme="snow"
                  value={profile.bio}
                  onChange={handleBioChange}
                  className="h-[400px] overflow-y-auto"
                  style={{ maxHeight: '400px' }}
                />
              </div>
            </div>
            <div className="pt-12 flex justify-end">
              <button
                type="submit"
                className="px-6 py-4 bg-primary-color text-white rounded-md cursor-pointer text-[14px] font-medium hover:bg-primary-color-hover"
                disabled={loadingSubmit}
              >
                <div className="flex justify-center items-center">
                  {loadingSubmit ? (
                    <div className="loader" style={{ width: '24px', height: '24px' }}></div>
                  ) : (
                    "Save Changes"
                  )}
                </div>
              </button>
            </div>
          </form>
  
          {/* Modals */}
          {showAvatarEditor && (
            <Modal
              isOpen={showAvatarEditor}
              onRequestClose={onClose}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg w-[400px] max-w-[90%] mx-auto"
              overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-[3000]"
            >
              <div className="flex justify-between mb-6">
                <div className="mx-auto">
                  <h2 className="font-medium text-[18px]">Edit Profile Picture</h2>
                </div>
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-[24px]" onClick={onClose} />
              </div>
              <Avatar width={360} height={200} onCrop={onCrop} onClose={onClose} src={src} />
              <button
                className="mt-3 py-4 bg-primary-color text-white rounded-md cursor-pointer text-[16px] w-full"
                onClick={onSave}
                disabled={loadingProfilePicSubmit}
              >
                <div className="flex justify-center items-center">
                  {loadingProfilePicSubmit ? (
                    <div className="loader" style={{ width: '24px', height: '24px' }}></div>
                  ) : (
                    "Save"
                  )}
                </div>
              </button>
            </Modal>
          )}
          {showEmailModal && (
            <Modal
              isOpen={showEmailModal}
              onRequestClose={closeEmailModal}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-lg w-[400px] max-w-[90%] mx-auto"
              overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-[3000]"
            >
              <div className="flex justify-between mb-5">
                <div className="mx-auto">
                  <h2 className="font-medium text-[18px]">Change Email</h2>
                </div>
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-[24px]" onClick={closeEmailModal} />
              </div>
              <div className="flex flex-col gap-4">
                <label htmlFor="newEmail" className="font-medium">
                  New Email
                </label>
                <input
                  type="email"
                  id="newEmail"
                  name="newEmail"
                  value={newEmail}
                  onChange={handleEmailChange}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
                {emailError && <p className="text-red-500 text-[14px]">{emailError}</p>}
                <button
                  className="mt-3 py-4 bg-primary-color text-white rounded-md cursor-pointer text-[16px] w-full"
                  onClick={handleEmailSubmit}
                  disabled={loadingEmailSubmit}
                >
                  <div className="flex justify-center items-center">
                    {loadingEmailSubmit ? (
                      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
                    ) : (
                      "Submit"
                    )}
                  </div>
                </button>
              </div>
            </Modal>
          )}
          {showPhoneModal && (
            <Modal
              isOpen={showPhoneModal}
              onRequestClose={closePhoneModal}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-lg w-[400px] max-w-[90%] mx-auto"
              overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-30"
            >
              <div className="flex justify-between mb-5">
                <div className="mx-auto">
                  <h2 className="font-medium text-[18px]">Change Phone Number</h2>
                </div>
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-[24px]" onClick={closePhoneModal} />
              </div>
              <div className="flex flex-col">
                <PhoneInput
                  country={'us'}
                  value={newPhone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: '100%',
                    padding: '20px',
                    fontSize: '16px',
                    paddingLeft: '48px',
                  }}
                />
                {phoneError && <p className="text-red-500 text-sm mt-2">{phoneError}</p>}
                <button className="bg-primary-color text-white border-none py-3 text-base cursor-pointer mt-5 rounded" onClick={handlePhoneSubmit}>Submit</button>
              </div>
            </Modal>
          )}
          {showPasswordModal && (
            <Modal
              isOpen={showPasswordModal}
              onRequestClose={closePasswordModal}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg w-[400px] max-w-[90%] mx-auto"
              overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-[3000]"
            >
              <div className="flex justify-between mb-5">
                <div className="mx-auto">
                  <h2 className="font-medium text-[18px]">Change Password</h2>
                </div>
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-[24px]" onClick={closePasswordModal} />
              </div>
              <div className="flex flex-col gap-4">
                <label htmlFor="currentPassword" className="font-medium">
                  Current Password
                </label>
                <input
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
                <label htmlFor="newPassword" className="font-medium">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
                <label htmlFor="confirmNewPassword" className="font-medium">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  className="w-full border border-border-color rounded-md text-[14px] box-border p-3"
                />
                {passwordError && <p className="text-red-500 text-[14px] mt-2">{passwordError}</p>}
                <button
                  className="mt-3 py-4 bg-primary-color text-white rounded-md cursor-pointer text-[16px] w-full"
                  onClick={handlePasswordSubmit}
                  disabled={loadingPasswordSubmit}
                >
                  <div className="flex justify-center items-center">
                    {loadingPasswordSubmit ? (
                      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
                    ) : (
                      "Submit"
                    )}
                  </div>
                </button>
              </div>
            </Modal>
            
          )}
        </>
      )}
    </div>
  );
};

export default MyProfile;
