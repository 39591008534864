import React, { useState } from 'react';
import StudentSettingsNavbar from '../../components/StudentSettings/StudentSettingsNavbar';
import { useNavigate } from 'react-router-dom';
import MyProfile from '../../components/StudentSettings/MyProfile';
import AreaOfInterest from '../../components/StudentSettings/StudentSkills';
import Notification from '../../components/StudentSettings/Notifications';
import Billing from '../../components/StudentSettings/Billing';
import StudentEducation from '../../components/StudentSettings/StudentEducation';
import StudentIndustry from '../../components/StudentSettings/StudentIndustry';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import useUserStore from '../../store/useStudentStore';

const StudentSettingsLayout = () => {
  const [selectedOption, setSelectedOption] = useState('My Profile');
  const navigate = useNavigate();
  
  const { userData } = useUserStore();

  const handleLogout = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      await api.post(`/auth/logout`, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });
      navigate('/sign-in');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const calculateProfileCompletion = () => {
    const fieldsToCheck = [
      userData.firstName,
      userData.lastName,
      userData.email,
      userData.location,
      userData.timezone,
      userData.profilePicture,
      userData.currentSkills.length > 0, // Check if skills are added
      userData.desiredSkills.length > 0, // Check if skills are added
      userData.sessionGoals.length > 0, // Check if session goals are added
      userData.education.length > 0, // Check if education is added
      userData.industry?.currentIndustries?.length > 0, // Check if current industries are set
      userData.industry?.desiredIndustry?.length > 0, // Check if desired industries are set
      userData.industry?.goals, // Check if industry goals are set
    ];

    // Calculate percentage of fields filled
    const filledFields = fieldsToCheck.filter(Boolean).length;
    const totalFields = fieldsToCheck.length;

    return Math.round((filledFields / totalFields) * 100); // Returns percentage of profile completion
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'My Profile':
        return <MyProfile />;
      case 'Area of Interest':
        return <AreaOfInterest />;
      case 'Notification':
        return <Notification />;
      case 'Billing':
        return <Billing />;
      case 'Education':
        return <StudentEducation />;
      case 'Preferences':
        return <StudentIndustry />;
      default:
        return <MyProfile />;
    }
  };

  const profileCompletion = calculateProfileCompletion(); // Calculate profile completion percentage

  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold pb-5 text-primary-color">
        Settings
      </div>
      {profileCompletion < 100 && (
        <div className="w-full mb-5 bg-gray-200 rounded-full h-4">
          <div
            className="bg-primary-color h-4 rounded-full flex "
            style={{ width: `${profileCompletion}%`, paddingLeft: '8px' }}
          >
            <span className="text-xs font-medium text-white">
              {profileCompletion}% Complete
            </span>
          </div>
        </div>
      )}


      <div className="flex flex-row min-h-[calc(100vh-250px)] border border-border-color p-5 rounded-md bg-background-color">
        <StudentSettingsNavbar
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleLogout={handleLogout}
        />
        <div className="flex-1 w-full bg-white ml-8 mt-5 md:mt-0 md:ml-24">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default StudentSettingsLayout;
