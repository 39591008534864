import React, { useState, useEffect } from 'react';
import { FaTrophy } from 'react-icons/fa';
import ChallengeDetailsModal from './ChallengeDetailsModal';
import DOMPurify from 'dompurify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PastChallenges = ({ pastChallenges }) => {
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      // Simulate a delay for loading state (e.g., fetching data)
      setTimeout(() => {
        setLoading(false); // Set loading to false after data is "fetched"
      }, 1000); // Adjust time as needed
    };

    fetchData();
  }, []);

  const handleViewMore = (challengeId, milestones) => {
    setSelectedChallenge({ ...challengeId, milestones });
    setShowModal(true);
  };

  const formatTimeSinceEnd = (endDate) => {
    const now = new Date();
    const difference = now - new Date(endDate);

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `${days} day(s) ago`;
    } else if (hours > 0) {
      return `${hours} hour(s) ago`;
    } else {
      return `${minutes} minute(s) ago`;
    }
  };

  if (loading) {
    // Skeleton loader for loading state
    return (
      <div className="max-w-[1400px] mx-auto">
        <div className="flex flex-wrap gap-4">
          {[...Array(2)].map((_, index) => (
            <div key={index} className="bg-white rounded-lg p-2.5 shadow-md w-full sm:w-[calc(50%-1rem)] lg:w-[calc(25%-1rem)] relative flex flex-col justify-between min-h-[300px]">
              <Skeleton height={150} className="rounded-lg" />
              <div className="mt-2.5 flex-grow flex flex-col">
                <Skeleton height={20} width="60%" className="mb-2" />
                <Skeleton height={15} width="80%" className="mb-1" />
                <Skeleton height={15} width="50%" className="mb-1" />
                <Skeleton height={15} width="70%" className="mb-1" />
                <Skeleton height={10} width="30%" className="mt-2" />
              </div>
              <div className="mt-2 flex justify-between items-center gap-2.5">
                <Skeleton height={40} width="45%" />
                <Skeleton height={40} width="45%" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1400px] mx-auto">
      <div className="flex flex-wrap gap-4">
        {pastChallenges.map(({ challengeId, milestones }) => (
          <div key={challengeId._id} className="bg-white rounded-lg p-2.5 shadow-md w-full sm:w-[calc(50%-1rem)] lg:w-[calc(25%-1rem)] relative flex flex-col justify-between min-h-[300px]">
            <img src={challengeId.image} alt={DOMPurify.sanitize(challengeId.title)} className="w-full h-[150px] rounded-lg object-cover" />
            <div className="mt-2.5 flex-grow flex flex-col">
              <h3 
                className="text-base font-semibold"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(challengeId.title) }}
              ></h3>
              <div className="text-[13px] mt-2">
                <p className="text-primary-color mb-1">
                  {new Date(challengeId.startDate).toLocaleDateString()} - {new Date(challengeId.endDate).toLocaleDateString()}
                </p>
                <p className="text-gray-700 mb-1">Checkpoints: {challengeId.checkpoints.length}</p>
                <p className="text-gray-700 mb-1">Rewards:</p>
                <ul className="list-none p-0 m-0 text-gray-700">
                  {challengeId.rewards.map((reward, index) => (
                    <li key={index} className="flex items-center mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                      <FaTrophy className="mr-1 text-[#ffd700]" /> {reward}
                    </li>
                  ))}
                </ul>
                <p className="text-gray-700 mt-2">
                  Ended: {formatTimeSinceEnd(challengeId.endDate)}
                </p>
              </div>
            </div>
            <div className="mt-2 flex justify-between items-center gap-2.5">
              <button 
                onClick={() => handleViewMore(challengeId, milestones)} 
                className="bg-gray-300 text-black py-2 px-4 rounded-md cursor-pointer flex-grow text-center hover:opacity-80"
              >
                View More
              </button>
              {milestones.length === challengeId.checkpoints.length ? (
                <span className="bg-green-600 text-white py-1 px-2 rounded-full text-sm font-bold">
                  Completed
                </span>
              ) : (
                <span className="bg-red-600 text-white py-1 px-2 rounded-full text-sm font-bold">
                  Incomplete
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
      {showModal && selectedChallenge && (
        <ChallengeDetailsModal
          challenge={selectedChallenge}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default PastChallenges;
