import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faVideo, faClock } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

const UpcomingSessionsModal = ({ closeModal }) => {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [timezone, setTimezone] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      const csrfToken = await fetchCsrfToken();
      try {
        const response = await api.get('/videochat/upcoming-sessions', {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        setSessions(response.data.sessions || []);
        setTimezone(response.data.timezone || '');
      } catch (error) {
        console.error('Error fetching upcoming sessions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSessions([...sessions]);
    }, 60000);

    return () => clearInterval(interval);
  }, [sessions]);

  const checkIfSessionCanBeJoined = (startUTC, endUTC) => {
    const now = moment().tz(timezone);
    const sessionStart = moment(startUTC).tz(timezone);
    const sessionEnd = moment(endUTC).tz(timezone);
    const joinAllowedTime = sessionStart.clone().subtract(60, 'minutes');
    // console.log('Now:', now.format());
    // console.log('Session Start:', sessionStart.format());
    // console.log('Session End:', sessionEnd.format());
    // console.log('Join Allowed Time:', joinAllowedTime.format());
    return now.isBetween(joinAllowedTime, sessionEnd, null, '[)');
  };

  const handleJoinSession = async (sessionId, isOneOnOne) => {
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.post('/videochat/check-enrollment', { sessionId }, {
        headers: {
          'CSRF-Token': csrfToken,
        },
      });

      if (response.data.isEnrolled) {
        const videoChatPage = isOneOnOne ? `/one-on-one/${sessionId}` : `/general/${sessionId}`;
        navigate(videoChatPage);
      } else {
        swal("Error", "You are not enrolled in this session.", "error");
      }
    } catch (error) {
      console.error('Error joining session:', error);
      swal("Error", "An error occurred while trying to join the session.", "error");
    }
  };

  const formatSessionDate = (utcDate) => {
    return moment(utcDate).tz(timezone).format('dddd, MMMM Do, YYYY');
  };

  const formatSessionTime = (startUTC, endUTC) => {
    const startTime = moment(startUTC).tz(timezone).format('hh:mm A');
    const endTime = moment(endUTC).tz(timezone).format('hh:mm A');
    return `${startTime} - ${endTime}`;
  };

  const isOneOnOneSession = (session) => {
    return session.studentId && session.instructorId;
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-[80000]">
      <div className="bg-white p-5 rounded-lg w-full max-w-[500px] max-h-[80%] overflow-y-auto">
        <div className="flex justify-between items-center mb-5">
          <h2 className="text-lg font-bold">Upcoming Sessions</h2>
          <button className="bg-none border-none cursor-pointer" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {loading ? (
          // Skeleton loader for loading state
          <div className="flex flex-col gap-4">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="bg-[#f5f5f5] p-3 rounded-lg">
                <Skeleton height={20} width="80%" className="mb-2" />
                <Skeleton height={15} width="60%" />
                <Skeleton height={15} width="50%" className="mt-2" />
                <Skeleton height={15} width="50%" />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {sessions.length > 0 ? (
              sessions.map((session) => (
                <div key={session._id} className="flex justify-between items-center bg-[#f5f5f5] p-3 rounded-lg relative">
                  <div className="flex flex-col gap-1">
                    {isOneOnOneSession(session) ? (
                      <>
                        <h3 className="font-bold">{`${session.instructorId.firstName} ${session.instructorId.lastName}`}</h3>
                        <p>One on One Session</p>
                        <div className='text-sm text-gray-600'>{formatSessionDate(session.startUTC)}</div>
                        <div className='text-sm text-gray-600'>{formatSessionTime(session.startUTC, session.endUTC)}</div>
                      </>
                    ) : (
                      <>
                        <h3 className="font-bold">{session.sessionName}</h3>
                        <p>General Session</p>
                        <div className='text-sm text-gray-600'>{formatSessionDate(session.availability.start)}</div>
                        <div className='text-sm text-gray-600'>{formatSessionTime(session.availability.start, session.availability.end)}</div>
                      </>
                    )}
                    {session.pending && (
                      <div className="flex items-center text-red-500 mt-2">
                        <FontAwesomeIcon icon={faClock} className="mr-1" />
                        <span>Pending</span>
                      </div>
                    )}
                  </div>
                  <div className="absolute bottom-2 right-2">
                    {checkIfSessionCanBeJoined(
                      isOneOnOneSession(session) ? session.startUTC : session.availability.start,
                      isOneOnOneSession(session) ? session.endUTC : session.availability.end
                    ) ? (
                      <button
                        className="bg-[#007bff] text-white border-none p-2 rounded-md cursor-pointer text-sm"
                        onClick={() => handleJoinSession(session._id, isOneOnOneSession(session))}
                      >
                        <FontAwesomeIcon icon={faVideo} /> Join Now
                      </button>
                    ) : (
                      <button
                        className="bg-[#cccccc] text-white border-none p-2 rounded-md cursor-not-allowed text-sm"
                        disabled
                        title="You will be able to join this session 20 mins before the starting time"
                      >
                        Join Now
                      </button>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No upcoming sessions available.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingSessionsModal;
