import React from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTools, faBriefcase, faGraduationCap, faTrophy, faHandsHelping, faConciergeBell, faBell, faCreditCard, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const InstructorSettingsNavbar = ({ handleLogout }) => {
  const navigate = useNavigate();

  const options = [
    { name: 'My Profile', path: '/mentor/settings/profile', icon: faUser },
    { name: 'Edit Skills', path: '/mentor/settings/skills', icon: faTools },
    { name: 'Experience', path: '/mentor/settings/experience', icon: faBriefcase },
    { name: 'Education', path: '/mentor/settings/education', icon: faGraduationCap },
    { name: 'Achievements', path: '/mentor/settings/achievements', icon: faTrophy },
    { name: '1-on-1', path: '/mentor/settings/1-on-1', icon: faHandsHelping },
    { name: 'Services', path: '/mentor/settings/services', icon: faConciergeBell },
    { name: 'Notification', path: '/mentor/settings/notifications', icon: faBell },
    { name: 'Billing', path: '/mentor/settings/billing', icon: faCreditCard },
    { name: 'Log out', icon: faSignOutAlt, className: 'text-[#ff7a60] hover:text-[#ff7a60]' }
  ];

  const onNavItemClick = (option) => {
    if (option.name === 'Log out') {
      swal({
        title: "Are you sure?",
        text: "You will be logged out of your account!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willLogout) => {
        if (willLogout) {
          handleLogout();
        }
      });
    } else {
      navigate(option.path);
    }
  };

  return (
    <nav className="w-[40px] md:w-[126px] pl-1 md:pl-3 flex flex-col mt-[72px]">
      {options.map(option => (
        <div
          key={option.name}
          className={`flex flex-col items-left font-normal text-base py-2 md:py-2.5 px-1 md:px-4 md:w-[126px] cursor-pointer text-black rounded-lg mb-2 md:mb-4 hover:bg-[#cce6e0] hover:text-primary-color ${option.className || ''}`}
          onClick={() => onNavItemClick(option)}
        >
          {/* Icon visible on mobile (sm) only */}
          <div className="sm:flex md:hidden justify-center w-full">
            <FontAwesomeIcon icon={option.icon} className="text-xl" />
          </div>
          {/* Text visible on md screens and up */}
          <div className="hidden md:block">{option.name}</div>
        </div>
      ))}
    </nav>
  );
};

export default InstructorSettingsNavbar;
