import React, { useState, useEffect } from 'react';
import { LiveKitRoom, VideoConference, RoomAudioRenderer, useTracks, ParticipantTile } from '@livekit/components-react';
import { Track } from "livekit-client";
import '@livekit/components-styles';
import { useParams } from 'react-router-dom';
import api from '../../utils/auth';
import './VideoChatMessage.css';  // Import your custom CSS file
import styles from './loader.module.css'; // Import the loader module


const VideoChatOneonOne = () => {
  const serverUrl = process.env.REACT_APP_LIVEKIT_SERVER_URL;

  const { sessionId } = useParams();
  const [token, setToken] = useState('');
  const [role, setRole] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingId, setRecordingId] = useState(null);

  useEffect(() => {


    const connectToRoom = async () => {
      setIsConnecting(true);
      try {
        const response = await api.get(`/videochat/get-participant-token`, {
          params: { sessionId },
          withCredentials: true, // Include cookies in the request
        });

        const data = response.data;

        if (typeof data.token === 'string') {
          setToken(data.token);
          setRole(data.role); // Assuming the role is returned in the response
        } else {
          console.error('Token is not a string:', data.token);
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        setIsConnecting(false);
      }
    };

    connectToRoom(); // Call the function to connect to the room
  }, [sessionId]);

  const startRecording = async () => {
    try {
      const response = await api.post('/videochat/start-recording-OneonOne', {
        room: sessionId,  // Use sessionId as the room identifier
        sessionId: sessionId,  // Pass the sessionId for one-on-one
      });
      const data = response.data;
      if (data.recordingId) {
        setRecordingId(data.recordingId);
        setIsRecording(true);
      }
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = async () => {
    try {
      const response = await api.post('/videochat/stop-recording', {
        recordingId,
      });
      const data = response.data;
      if (data.message) {
        setIsRecording(false);
        setRecordingId(null);
      }
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };

  if (!token) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className={styles.loader}></div> {/* Use the loader from the module */}
      </div>
    );
  }

  return (
    <LiveKitRoom
      token={token}
      serverUrl={serverUrl} // Use the environment variable here
      data-lk-theme="default"
      style={{ height: '100vh' }}
    >
      <MyVideoConference />
      <RoomAudioRenderer />
      {role === 'instructor' && ( // Only show the controls if the user is an instructor
        <div className="custom-control-bar">
        <div className="custom-button-group">
          <button
            className="custom-button"
            onClick={startRecording}
            style={{ display: !isRecording ? 'block' : 'none' }}
          >
            Start Recording
          </button>
          <button
            className="custom-button"
            onClick={stopRecording}
            style={{ display: isRecording ? 'block' : 'none' }}
          >
            Stop Recording
          </button>
        </div>
      </div>
      )}
    </LiveKitRoom>
  );
};

function MyVideoConference() {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );

  return (
    <VideoConference tracks={tracks}>
      <ParticipantTile />
    </VideoConference>
  );
}

export default VideoChatOneonOne;
