import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar-edit';
import Modal from 'react-modal';
import Select from 'react-select';
import moment from 'moment-timezone';
import swal from 'sweetalert';
import countryList from 'react-select-country-list';
import api from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import phone input styling
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './../buttonloader.css'
import useUserStore from '../../store/useStudentStore';

const timezoneOptions = moment.tz.names().map(timezone => ({
  value: timezone,
  label: `(UTC${moment.tz(timezone).utcOffset() / 60 >= 0 ? '+' : ''}${moment.tz(timezone).utcOffset() / 60}) ${timezone}`
}));

const MyProfile = () => {


  const userData = useUserStore((state) => state.userData); // Access the Zustand store data
  const setUserData = useUserStore((state) => state.setUserData); // Get the setUserData function from Zustand


  useEffect(() => {
    console.log('Stored user data in Zustand:', userData); // Log the stored data to console
  }, [userData]); // This will log whenever userData is updated

  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: '',
    isVerified: false,
    location: '',
    timezone: ''
  });



  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`; // Default profile image path
  const uploadIcon = `${process.env.PUBLIC_URL}/assets/icons/uploadIcon.svg`;

  const [preview, setPreview] = useState(null);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [src, setSrc] = useState(null);
  const [showAvatarEditor, setShowAvatarEditor] = useState(false);
  const [profileImage, setProfileImage] = useState(defaultProfileImage);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [profileError, setProfileError] = useState({ firstName: '', lastName: '' });
  const token = localStorage.getItem('jwtToken');
  const countryOptions = countryList().getData();
  const [loading, setLoading] = useState(true); // Loading state
  const [loadingEmailSubmit, setLoadingEmailSubmit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false); // State to track form submission
  const [loadingPhoneSubmit, setLoadingPhoneSubmit] = useState(false);
  const [loadingPasswordSubmit, setLoadingPasswordSubmit] = useState(false);
  const [loadingProfilePicSubmit, setLoadingProfilePicSubmit] = useState(false);

  const [error, setError] = useState(null); // Error state

  const fetchCsrfToken = async () => {
    const response = await api.get('/csrf-token');
    return response.data.csrfToken;
  };

  useEffect(() => {
    setLoading(true);
  if (userData) {
    setProfile({
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      email: userData.email || '',
      profilePicture: userData.profilePicture || defaultProfileImage,
      isVerified: userData.isVerified || false,
      location: userData.location || '',
      timezone: userData.timezone || '',
      phone: userData.phone || ''  // Add phone here

    });
    setProfileImage(userData.profilePicture ? `${userData.profilePicture}` : defaultProfileImage); // Set profile image
    setLoading(false);  // Ensure loading is set to false here

  }
}, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if ((name === 'firstName' || name === 'lastName') && /[0-9]/.test(value)) {
      setProfileError({ ...profileError, [name]: 'Name should not contain any number or special character' });
    } else {
      setProfileError({ ...profileError, [name]: '' });
      setProfile({ ...profile, [name]: value });
    }
  };

  const handleLocationChange = (selectedOption) => {
    setProfile({ ...profile, location: selectedOption.value });
  };

  const handleTimezoneChange = (selectedOption) => {
    setProfile({ ...profile, timezone: selectedOption.value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check for empty fields
    if (!profile.firstName || !profile.lastName) {
      setProfileError({
        ...profileError,
        firstName: !profile.firstName ? 'First name is required' : '',
        lastName: !profile.lastName ? 'Last name is required' : ''
      });
      return;
    }
  
    // Compare changes
    const updatedProfileData = {
      ...(profile.firstName !== userData.firstName && { firstName: profile.firstName }),
      ...(profile.lastName !== userData.lastName && { lastName: profile.lastName }),
      ...(profile.profilePicture !== userData.profilePicture && { profilePicture: profile.profilePicture }),
      ...(profile.location !== userData.location && { location: profile.location }),
      ...(profile.timezone !== userData.timezone && { timezone: profile.timezone }),
      ...(profile.phone !== userData.phone && { phone: profile.phone }),
    };
  
    // If no data has changed, prevent API call
    if (Object.keys(updatedProfileData).length === 0) {
      swal("Info", "No changes to update", "info");
      return;
    }
  
    try {
      setLoadingSubmit(true); // Show loader
  
      const csrfToken = await fetchCsrfToken();
      
      // API call with updated data only
      const response = await api.put('/students/update', updatedProfileData, {
      });
  
      // Assuming the API call was successful (check response status)
      if (response.status === 200) {
        // Update Zustand store with the modified profile data from the frontend
        setUserData({
          ...userData,
          ...updatedProfileData,  // Merge the updated fields
        });
  
        swal("Success", "Profile updated successfully!", "success");
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      swal("Error", "Failed to update profile", "error");
    } finally {
      setLoadingSubmit(false); // Stop showing loader
    }
  };
  

  const handleChangePassword = () => {
    setShowPasswordModal(true);
  };

  const handleUploadProfilePicture = () => {
    setShowAvatarEditor(true);
  };

  const onClose = () => {
    setPreview(null);
    setShowAvatarEditor(false);
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  const onSave = async () => {
    setLoadingProfilePicSubmit(true); // Show loader when saving
  
    try {
      const csrfToken = await fetchCsrfToken();
      await api.post('/students/upload-profile-picture', { profilePicture: preview }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'CSRF-Token': csrfToken,
        }
      });
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      swal("Error", "Failed to upload profile picture", "error");
    } finally {
      setLoadingProfilePicSubmit(false); // Hide loader after completion
    }
  };
  

  const openEmailModal = () => {
    setShowEmailModal(true);
  };

  const closeEmailModal = () => {
    setShowEmailModal(false);
    setNewEmail('');
    setEmailError('');
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleEmailSubmit = async () => {
    if (emailError || !newEmail) {
      setEmailError('Please enter a valid email');
      return;
    }
  
    setLoadingEmailSubmit(true); // Show loader
  
    try {
      const csrfToken = await fetchCsrfToken();
      await api.put('/students/change-email', { newEmail }, {
        headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken }
      });
      swal("Success", "Email updated successfully! Kindly verify your email by clicking on the link sent to your new email.", "success").then(() => {
        window.location.reload(); // Refresh the page
      });
    } catch (error) {
      console.error('Error changing email:', error);
      setEmailError('Failed to change email. Email already exists.');
    } finally {
      setLoadingEmailSubmit(false); // Hide loader
    }
  };
  

  const closePasswordModal = () => {
    setShowPasswordModal(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordError('');
  };

  const handlePasswordSubmit = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setPasswordError('All fields are required');
      return;
    }
  
    if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords do not match!');
      return;
    }
  
    setLoadingPasswordSubmit(true); // Show loader
  
    try {
      const csrfToken = await fetchCsrfToken();
      await api.put('/students/change-password', {
        currentPassword,
        newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken }
      });
  
      swal("Success", "Password updated successfully!", "success");
      closePasswordModal();
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError('Failed to change password. Please check your current password and try again.');
    } finally {
      setLoadingPasswordSubmit(false); // Hide loader
    }
  };

  const handleResendVerification = async () => {
    try {
      const response = await api.post('/auth/resend-verification-email', {
        email: profile.email,
        role: 'student' // or 'student', depending on the profile
      });
  
      // If the response is successful, show the success swal message
    swal('Success', 'Verification email has been resent successfully!', 'success');
  } catch (error) {
    // If there is an error, show the error swal message
    console.error('Error resending verification email:', error);
    swal('Error', 'Failed to resend verification email. Please try again.', 'error');
  }
};
  
  

  const openPhoneModal = () => {
    setNewPhone(profile.phone); // Initialize with current phone number
    setPhoneError(''); // Clear any previous errors
    setShowPhoneModal(true);
  };

  const closePhoneModal = () => {
    setPhoneError('');
    setShowPhoneModal(false);
    setNewPhone(profile.phone); // Reset to the current phone number
  };

  const handlePhoneChange = (value) => {
    setNewPhone(value);
  };

  const handlePhoneSubmit = async () => {
    if (!newPhone) {
      setPhoneError('Please enter a valid phone number');
      return;
    }
  
    setLoadingPhoneSubmit(true); // Show loader
  
    try {
      const csrfToken = await fetchCsrfToken();
      const response = await api.put('/students/change-phone', { phone: newPhone }, {
        headers: { Authorization: `Bearer ${token}`, 'CSRF-Token': csrfToken }
      });
  
      if (response.status === 200) {
        // Update both the local profile state and Zustand store with the new phone number
        setProfile({ ...profile, phone: newPhone });
  
        // Update Zustand with the new phone number
        setUserData({
          ...userData,
          phone: newPhone, // Update just the phone number
        });
  
        swal("Success", "Phone number updated successfully!", "success").then(() => {
          setShowPhoneModal(false); // Close the modal after success
        });
      } else {
        throw new Error('Failed to update phone number');
      }
    } catch (error) {
      console.error('Error changing phone number:', error);
      setPhoneError('Failed to change phone number. Please try again.');
    } finally {
      setLoadingPhoneSubmit(false); // Hide loader
    }
  };
  
  

  const ProfileSkeleton = () => (
    <div className="max-w-[700px] p-4 sm:p-5">
      <div className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">
        <Skeleton width={150} className="sm:w-[200px]" />
      </div>
      <div className="flex flex-col sm:flex-row items-center mt-6 sm:mt-8 mb-8 sm:mb-10">
        <div className="relative mb-3 sm:mb-0 sm:mr-6">
          <Skeleton circle={true} height={80} width={80} className="sm:h-[130px] sm:w-[130px]" />
        </div>
        <Skeleton width={100} height={30} className="w-full sm:w-auto sm:h-[40px]" />
      </div>
      <form className="flex flex-col">
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-6 mb-4">
          <div className="flex-1 mb-3 sm:mb-4">
            <Skeleton height={16} className="w-3/4" />
            <Skeleton height={36} className="w-full mt-2" />
          </div>
          <div className="flex-1 mb-3 sm:mb-4">
            <Skeleton height={16} className="w-3/4" />
            <Skeleton height={36} className="w-full mt-2" />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-6 mb-4">
          <div className="flex-1 mb-3 sm:mb-4">
            <Skeleton height={16} className="w-3/4" />
            <Skeleton height={36} className="w-full mt-2" />
          </div>
          <div className="flex-1 mb-3 sm:mb-4">
            <Skeleton height={16} className="w-3/4" />
            <Skeleton height={36} className="w-full mt-2" />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-6 mb-4">
          <div className="flex-1 mb-3 sm:mb-4">
            <Skeleton height={16} className="w-3/4" />
            <Skeleton height={36} className="w-full mt-2" />
          </div>
          <div className="flex-1 mb-3 sm:mb-4">
            <Skeleton height={16} className="w-3/4" />
            <Skeleton height={36} className="w-full mt-2" />
          </div>
        </div>
      </form>
    </div>
  );
  


  return (
    <div className="max-w-[700px] p-5">
      {loading ? (
        <ProfileSkeleton />
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <>
      <div className="text-2xl font-bold">
        My Profile
      </div>
      <div className="flex flex-col sm:flex-row items-center mt-8 mb-10">
        <div className="relative mr-6">
          <img src={profileImage} alt="Profile" className="w-[100px] sm:w-[130px] h-[100px] sm:h-[130px] rounded-full object-cover transition-opacity duration-300" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100px] sm:w-[130px] h-[100px] sm:h-[130px] rounded-full bg-black bg-opacity-50 flex justify-center items-center opacity-0 transition-opacity duration-300 cursor-pointer z-10 hover:opacity-100" onClick={handleUploadProfilePicture}>
            <img src={uploadIcon} alt="uploadIcon" className="text-white w-[50px] sm:w-[60px] h-[50px] sm:h-[60px]" />
          </div>
        </div>
        <button
          className="mt-4 sm:mt-0 px-6 py-4 border border-primary-color bg-white text-primary-color rounded-lg cursor-pointer transition duration-300 hover:bg-primary-color-hover hover:text-white text-sm font-normal mr-4"
          onClick={handleChangePassword}
        >
          Change Password
        </button>
      </div>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="flex flex-col sm:flex-row justify-between gap-6">
          <div className="mb-0 flex-1 md:mb-4">
            <label htmlFor="firstName" className="block mb-2 font-medium text-base">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={profile.firstName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg text-sm p-3"
            />
            {profileError.firstName && <p className="text-red-500 text-sm">{profileError.firstName}</p>}
          </div>
          <div className="mb-4 flex-1">
            <label htmlFor="lastName" className="block mb-2 font-medium text-base">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={profile.lastName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg text-sm p-3"
            />
            {profileError.lastName && <p className="text-red-500 text-sm">{profileError.lastName}</p>}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between gap-0 md:gap-6">
          <div className="flex-1 md:mb-4">
            <label htmlFor="email" className="block mb-2 font-medium text-base">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={profile.email}
              className="w-full border border-gray-300 rounded-lg text-sm p-3 opacity-50 pointer-events-none"
              onChange={handleInputChange}
              disabled
            />
            <div className="flex items-center mt-2">
              {profile.isVerified ? (
                <span className="text-primary-color text-sm font-medium">
                  <FontAwesomeIcon icon={faCheckCircle} /> Verified
                </span>
              ) : (
                <span className="text-red-500 text-sm font-medium">
                  <FontAwesomeIcon icon={faTimesCircle} /> Unverified
                </span>
              )}
              {!profile.isVerified && (
                <button
                  className="ml-4 text-primary-color text-sm underline"
                  onClick={handleResendVerification}
                >
                  Resend Email Verification
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-end mb-4 md:mt-4">
            <a href="#" className="text-primary-color mt-0 text-base cursor-pointer font-medium md:mt-6" onClick={openEmailModal}>Change Email</a>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between gap-0 md:gap-6">
          <div className="flex-1 md:mb-4">
            <label htmlFor="phone" className="block mb-2 font-medium text-base">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={profile.phone || ''}
              onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
              disabled
              className="w-full border border-gray-300 rounded-lg text-sm p-3 opacity-50 pointer-events-none"
            />
          </div>
          <div className="flex mt-0 justify-end mb-4 md:mt-4">
            <a href="#" className="text-primary-color mt-0 text-base cursor-pointer font-medium md:mt-6" onClick={openPhoneModal}>Change Phone</a>
          </div>
        </div>

        {showPhoneModal && (
          <Modal
            isOpen={showPhoneModal}
            onRequestClose={closePhoneModal}
            className="bg-white p-5 max-w-[400px] m-auto rounded-lg "
            overlayClassName="bg-black bg-opacity-60 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-[3000]"
          >
            <div className="flex justify-between mb-5">
              <div className="m-auto">
                <h2 className="text-lg font-semibold">Change Phone Number</h2>
              </div>
              <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-xl" onClick={closePhoneModal} />
            </div>
            <div className="flex flex-col">
              <PhoneInput
                country={'us'}
                value={newPhone}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: '100%',
                  padding: '20px',
                  fontSize: '16px',
                  paddingLeft:'48px',
                }}
              />
              {phoneError && <p className="text-red-500 text-sm mt-2">{phoneError}</p>}
              <button
  className="mt-3 px-5 py-3 border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:bg-primary-color-hover"
  disabled={loadingPhoneSubmit}
  style={{ width: '200px', height: '50px' }}
  onClick={handlePhoneSubmit}
>
  <div className="flex justify-center items-center">
    {loadingPhoneSubmit ? (
      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
    ) : (
      "Submit"
    )}
  </div>
</button>
            </div>
          </Modal>
        )}

        <div className="flex flex-col sm:flex-row justify-between gap-6">
          <div className="mb-0 flex-1 md:4">
            <label htmlFor="location" className="block mb-2 font-medium text-base">Location</label>
            <Select
              id="location"
              name="location"
              value={countryOptions.find(option => option.value === profile.location)}
              onChange={handleLocationChange}
              options={countryOptions}
            />
          </div>
          <div className="mb-4 flex-1">
            <label htmlFor="timezone" className="block mb-2 font-medium text-base">Timezone</label>
            <Select
              id="timezone"
              name="timezone"
              value={timezoneOptions.find(option => option.value === profile.timezone)}
              onChange={handleTimezoneChange}
              options={timezoneOptions}
            />
          </div>
        </div>
        <div className="pt-2 flex justify-end">
  <button
    type="submit"
    className="mt-5 px-5 py-3 border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:bg-primary-color-hover"
    disabled={loadingSubmit} // Disable button when loading
    style={{ width: '200px', height: '50px' }} // Maintain consistent button dimensions
  >
    <div className="flex justify-center items-center">
      {loadingSubmit ? (
        <div className="loader" style={{ width: '24px', height: '24px' }}></div> // Adjust loader size here
      ) : (
        "Save Changes"
      )}
    </div>
  </button>
</div>


      </form>
      {showAvatarEditor && (
        <Modal
        isOpen={showAvatarEditor}
        onRequestClose={onClose}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 sm:p-6 rounded-lg outline-none w-[90%] sm:w-[600px] max-w-full m-auto"
        overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-50"
      >
        <div className="flex justify-between items-center mb-4 sm:mb-6">
          <div className="m-auto">
            <h2 className="text-lg font-semibold">Edit Profile Picture</h2>
          </div>
          <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-xl" onClick={onClose} />
        </div>
        <div className='flex justify-center'>
        <Avatar
          width={250} // Adjusted width for mobile
          height={150} // Adjusted height for mobile
          onCrop={onCrop}
          onClose={onClose}
          src={src}
        />
        </div>
        <button
  className="mt-3 px-5 py-3 border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:bg-primary-color-hover"
  disabled={loadingProfilePicSubmit} // Disable button when loading
  style={{ width: '200px', height: '50px' }} // Maintain consistent button dimensions
  onClick={onSave}
>
  <div className="flex justify-center items-center">
    {loadingProfilePicSubmit ? (
      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
    ) : (
      "Save"
    )}
  </div>
</button>
      </Modal>
      
      )}
      {showEmailModal && (
        <Modal
          isOpen={showEmailModal}
          onRequestClose={closeEmailModal}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-lg outline-none w-[400px] max-w-[90%] m-auto"
          overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-[3000]"
        >
          <div className="flex justify-between items-center mb-5">
            <div className="m-auto">
              <h2 className="text-lg font-semibold ">Change Email</h2>
            </div>
            <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-xl" onClick={closeEmailModal} />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="newEmail" className="block font-medium text-base">New Email</label>
            <input
              type="email"
              id="newEmail"
              name="newEmail"
              value={newEmail}
              onChange={handleEmailChange}
              className="w-full border border-border-color rounded-lg text-sm p-3"
            />
            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
            <button
  className="mt-3 px-5 py-3 border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:bg-primary-color-hover"
  disabled={loadingEmailSubmit}
  style={{ width: '200px', height: '50px' }}
  onClick={handleEmailSubmit}
>
  <div className="flex justify-center items-center">
    {loadingEmailSubmit ? (
      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
    ) : (
      "Submit"
    )}
  </div>
</button>
          </div>
        </Modal>
      )}
      {showPasswordModal && (
        <Modal
          isOpen={showPasswordModal}
          onRequestClose={closePasswordModal}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg outline-none w-[400px] max-w-[90%] m-auto"
          overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-50"
        >
          <div className="flex justify-between items-center mb-5">
            <div className="m-auto">
              <h2 className="text-lg font-semibold">Change Password</h2>
            </div>
            <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-xl" onClick={closePasswordModal} />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="currentPassword" className="block mb-2 font-medium text-base">Current Password</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full border border-border-color rounded-lg text-sm p-3 mb-2"
            />
            <label htmlFor="newPassword" className="block mb-2 font-medium text-base">New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full border border-border-color rounded-lg text-sm p-3 mb-2"
            />
            <label htmlFor="confirmNewPassword" className="block mb-2 font-medium text-base">Confirm New Password</label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="w-full border border-border-color rounded-lg text-sm p-3 mb-2"
            />
            {passwordError && <p className="text-red-500 text-sm mt-2">{passwordError}</p>}
            <button
  className="mt-3 px-5 py-3 border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:bg-primary-color-hover"
  disabled={loadingPasswordSubmit}
  style={{ width: '200px', height: '50px' }}
  onClick={handlePasswordSubmit}
>
  <div className="flex justify-center items-center">
    {loadingPasswordSubmit ? (
      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
    ) : (
      "Submit"
    )}
  </div>
</button>
          </div>
        </Modal>
      )}
      </>
    )}
  </div>
);

};

export default MyProfile;
