import React, { useEffect, useState } from 'react';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import countryList from 'react-select-country-list';
import { FaChevronDown } from 'react-icons/fa';
import Pagination from '../paginationControl'; // Ensure this is the path where you store the Pagination component
import Rating from 'react-rating-stars-component'; // Import star rating component

const generateTimeSlots = () => {
  const times = [];
  for (let i = 1; i <= 12; i++) {
    const hour = i.toString().padStart(2, '0');
    times.push({ value: `${hour} AM`, label: `${hour} AM` });
    times.push({ value: `${hour} PM`, label: `${hour} PM` });
  }
  return times;
};


const timeSlots = generateTimeSlots();
const daysOfWeek = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];
const countryOptions = countryList().getData();
const allSkills = [
  "Transportation", "Education", "Engineering", "Healthcare", "Business", "Finance", "Marketing", "Sales",
  "Services", "Public Services", "Repair", "Maintenance", "Aviation", "Accountancy", "Insurance", "Food and Beverage",
  "Hospitality", "Tourism", "Recreation", "Information Technology", "Management", "Software Development", "Human Resources",
  "Legal", "Social Work", "Art and Design", "Communications", "Construction", "Environmental", "Science", "Technology",
  "Pharmaceuticals", "Real Estate", "Retail", "Security", "Logistics", "Manufacturing", "Nonprofit", "Project Management",
  "Research", "Sports", "Digital Marketing", "Data Analysis", "Cybersecurity", "Machine Learning", "Event Management",
  "Customer Service", "Graphic Design", "Consulting", "Entrepreneurship", "Agricultural Science", "Biotechnology", "Blockchain",
  "Cloud Computing", "Compliance", "Corporate Strategy", "Creative Writing", "Crisis Management", "E-commerce", "Educational Technology",
  "Elder Care", "Electrical Engineering", "Energy Sector", "Fashion Design", "Film Production", "Game Development", "Government Relations",
  "Health Informatics", "Humanitarian Aid", "Interior Design", "International Business", "Journalism", "Labor Relations",
  "Leadership Development", "Market Research", "Materials Science", "Mental Health", "Mobile App Development", "Nanotechnology",
  "Network Security", "Occupational Therapy", "Patent Law", "Performance Arts", "Petroleum Engineering", "Philanthropy",
  "Photography", "Physical Therapy", "Political Science", "Public Health", "Quality Assurance", "Renewable Energy", "Risk Management",
  "Robotics", "Social Media Management", "Supply Chain Management", "Urban Planning", "Veterinary Medicine", "Video Production",
  "Virtual Reality", "Web Design"
];

const BrowseMentors = () => {
  const location = useLocation();
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;
  const [mentors, setMentors] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [rateRange, setRateRange] = useState([0, 100]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedFromTime, setSelectedFromTime] = useState(null);
  const [selectedToTime, setSelectedToTime] = useState(null);
  const [locations, setLocations] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isHourlyRateOpen, setIsHourlyRateOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const limit = 10;

  useEffect(() => {
    if (location.state && location.state.filterSkill) {
      setSelectedSkills([location.state.filterSkill]);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchMentors = async () => {
      setLoading(true);
      const csrfToken = await fetchCsrfToken();
  
      const queryParams = new URLSearchParams({
        skills: selectedSkills.join(','),
        rateRange: rateRange.join(','),
        days: selectedDays.join(','),
        fromTime: selectedFromTime ? selectedFromTime.value : '',
        toTime: selectedToTime ? selectedToTime.value : '',
        locations: locations.join(','),
        page: currentPage,
        limit: limit,
      }).toString();
  
      try {
        const response = await api.get(`/studentFetchInstructor?${queryParams}`, {
          headers: {
            'CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
  
        setMentors(response.data.instructors);
        setTotalPages(Math.ceil(response.data.totalInstructors / limit));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching mentors:', error);
        setLoading(false);
      }
    };
  
    fetchMentors();
  }, [selectedSkills, rateRange, selectedDays, selectedFromTime, selectedToTime, locations, currentPage]);

  const handleSkillsChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleDayChange = (selectedOptions) => {
    setSelectedDays(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleLocationChange = (selectedOptions) => {
    setLocations(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleTimeChange = (field, value) => {
    if (field === 'fromTime') {
      setSelectedFromTime(value);
    } else {
      setSelectedToTime(value);
    }
  };

  const handleMinRateChange = (e) => {
    const value = Math.min(Math.max(e.target.value, 0), 100);
    setRateRange([value, rateRange[1]]);
  };

  const handleMaxRateChange = (e) => {
    const value = Math.min(Math.max(e.target.value, 0), 100);
    setRateRange([rateRange[0], value]);
  };

  const handleViewProfile = (mentorId) => {
    navigate(`/student/browse-mentors/view-profile/${mentorId}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderSkeletonLoader = () => (
    <div className="border border-border-color p-5 rounded-xl text-center bg-white transition-transform duration-300 flex flex-col items-center justify-between relative w-[calc(100%-8px)] max-w-[280px]">
      <div className="w-24 h-24 rounded-full bg-gray-200 animate-pulse mb-3"></div>
      <div className="h-6 bg-gray-200 animate-pulse w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-200 animate-pulse w-1/2 mb-3"></div>
      <div className="flex gap-2 justify-center my-5 flex-wrap">
        <div className="bg-gray-200 animate-pulse px-2 py-1 rounded-full w-16 h-6"></div>
        <div className="bg-gray-200 animate-pulse px-2 py-1 rounded-full w-16 h-6"></div>
        <div className="bg-gray-200 animate-pulse px-2 py-1 rounded-full w-16 h-6"></div>
      </div>
      <div className="w-full py-3 rounded-md border bg-gray-200 animate-pulse h-10"></div>
    </div>
  );

  
  return (
    <div className="p-5 max-w-[1400px] mx-auto">
      <div className="text-2xl font-bold text-primary-color mb-4">Browse Mentors</div>
      <p className="mb-5 text-gray-600">Find our list of expert mentors across various fields</p>

      {/* Search Section */}
      <div className="relative mb-2 flex items-center justify-between">
        <input
          type="text"
          placeholder="Search for a Mentor"
          className="w-full p-3 rounded border border-border-color"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          className="text-blue-600 underline ml-4"
          onClick={() => {
            setSelectedSkills([]);
            setRateRange([0, 100]);
            setSelectedDays([]);
            setSelectedFromTime(null);
            setSelectedToTime(null);
            setLocations([]);
          }}
        >
          Reset Filters
        </button>
      </div>

      {/* Toggle Filters Button for Mobile */}
      <button
        className="block md:hidden bg-[#107F9A] text-white py-2 px-4 rounded mb-5"
        onClick={() => setIsFilterVisible(true)}
      >
        Toggle Filters
      </button>

      {/* Filters Section for Medium and Large Screens */}
      <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-6 gap-4 mb-5">
        {/* Skills Filter */}
        <div className="md:col-span-1 lg:col-span-1">
          <label className="block text-sm font-semibold text-[#107F9A] mb-2">Skills</label>
          <Select
            isMulti
            className="z-[3000]"
            value={selectedSkills.map(skill => ({ value: skill, label: skill }))}
            onChange={handleSkillsChange}
            options={allSkills.map(skill => ({ value: skill, label: skill }))}
            placeholder="Skills"
          />
        </div>

        {/* Location Filter */}
        <div className="md:col-span-1 lg:col-span-1">
          <label className="block text-sm font-semibold text-[#107F9A] mb-2">Location</label>
          <Select
            isMulti
            className="z-[3000]"
            value={locations.map(location => ({ value: location, label: countryOptions.find(c => c.value === location).label }))}
            onChange={handleLocationChange}
            options={countryOptions}
            placeholder="Location"
          />
        </div>

        {/* Days Filter */}
        <div className="md:col-span-1 lg:col-span-1">
          <label className="block text-sm font-semibold text-[#107F9A] mb-2">Availability</label>
          <Select
            isMulti
            className="z-[3000]"
            value={selectedDays.map(day => ({ value: day, label: daysOfWeek.find(d => d.value === day).label }))}
            onChange={handleDayChange}
            options={daysOfWeek}
            placeholder="Days"
          />
        </div>

        {/* Hourly Rate Filter */}
        <div className="md:col-span-1 lg:col-span-1 relative">
          <label className="block text-sm font-semibold text-[#107F9A] mb-2">Hourly Rate (£)</label>
          <div className="relative">
            <button
              className="w-full py-2 px-3 bg-white border border-border-color rounded text-left flex items-center justify-between"
              onClick={() => setIsHourlyRateOpen(!isHourlyRateOpen)}
            >
              £{rateRange[0]} - £{rateRange[1]}
              <FaChevronDown className="ml-2" />
            </button>
            {isHourlyRateOpen && (
              <div className="absolute z-[3000] bg-white p-4 border border-border-color rounded shadow-md mt-2 w-full">
                <Slider
                  range
                  min={0}
                  max={100}
                  value={rateRange}
                  onChange={setRateRange}
                  tipFormatter={value => `£${value}`}
                />
                <div className="flex justify-between mt-2">
                  <div className="flex flex-col items-center">
                    <input
                      type="number"
                      value={rateRange[0]}
                      onChange={handleMinRateChange}
                      className="w-16 text-center border border-border-color rounded-md p-1 bg-white"
                      min={0}
                      max={100}
                    />
                    <span className="text-xs text-gray-500 mt-1">Min</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="number"
                      value={rateRange[1]}
                      onChange={handleMaxRateChange}
                      className="w-16 text-center border border-border-color rounded-md p-1 bg-white"
                      min={0}
                      max={100}
                    />
                    <span className="text-xs text-gray-500 mt-1">Max</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Time Filter */}
        <div className="md:col-span-1 lg:col-span-1">
          <label className="block text-sm font-semibold text-[#107F9A] mb-2">From Time</label>
          <Select
            className="z-[3000]"
            value={selectedFromTime}
            onChange={value => handleTimeChange('fromTime', value)}
            options={timeSlots}
            placeholder="From Time"
          />
        </div>

        <div className="md:col-span-1 lg:col-span-1">
          <label className="block text-sm font-semibold text-[#107F9A] mb-2">To Time</label>
          <Select
            className="z-[3000]"
            value={selectedToTime}
            onChange={value => handleTimeChange('toTime', value)}
            options={timeSlots}
            placeholder="To Time"
          />
        </div>
      </div>
      

      {/* Mentor Grid */}
      {/* Mentor Grid */}
<div className="flex flex-wrap gap-4 justify-start max-w-[1400px] mx-auto">
  {loading
    ? Array(6)
        .fill(0)
        .map((_, index) => <div key={index}>{renderSkeletonLoader()}</div>)
    : mentors.length > 0 ? mentors.map((mentor) => {
        const profileImage = mentor.profilePicture ? `${mentor.profilePicture}` : defaultProfileImage;
        return (
            <div
              key={mentor._id || mentor.instructorId}
              className="border border-border-color p-5 rounded-xl text-center bg-white transition-transform duration-300 flex flex-col items-center justify-between relative w-[calc(100%-8px)] max-w-[280px] shadow-lg hover:shadow-xl transform hover:scale-105"
              style={{ minHeight: '380px' }}  // Ensure a consistent minimum height for the card
            >
              {/* Profile Image */}
              <img
                src={profileImage}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover mb-3"
              />

              {/* Mentor Name */}
              <div className="text-black text-lg font-semibold truncate">
                {mentor.firstName} {mentor.lastName}
              </div>

              {/* Mentor Expertise */}
              <div className="text-black text-sm font-normal opacity-60 truncate max-w-full pt-1">
                {mentor.areaOfExpertise || 'Expertise not provided'}
              </div>

              {/* Star Rating */}
              <div className="flex items-center my-2">
                <Rating
                  value={mentor.rating || 0}
                  count={5}
                  size={20}
                  edit={false}
                  isHalf={true}
                  activeColor="#107F9A"
                />
                <span className="ml-2 text-sm text-gray-600">
                  {mentor.rating ? mentor.rating.toFixed(1) : '0.0'} ({mentor.ratingsCount || 0})
                </span>
              </div>

              {/* Skills */}
              <div className="flex gap-2 justify-center my-5 flex-wrap min-h-[60px] items-center">
              {mentor.addedSkills && mentor.addedSkills.length > 0 ? (
                mentor.addedSkills.slice(0, 3).map((skill, index) => (
                  <span
                    key={index}
                    className="bg-[#107F9A1A] px-3 py-1 rounded-full text-[#107F9A] border border-[#107F9A] text-sm font-medium"
                    style={{ whiteSpace: 'nowrap' }} // Ensures text doesn't break
                  >
                    {skill}
                  </span>
                ))
              ) : (
                <span className="bg-[#107F9A1A] px-3 py-1 rounded-full text-[#107F9A] border border-[#107F9A] text-sm font-medium">
                  Skills not provided
                </span>
              )}
            </div>

              {/* Price Section */}
              <div className="text-lg font-bold text-[#107F9A] mt-auto mb-3">
                £{mentor.price}/hour
              </div>

              {/* View More Button */}
              <button
                className="w-full py-3 rounded-md border border-[#107F9A] bg-white text-[#107F9A] transition duration-300 text-lg hover:bg-[#107F9A] hover:text-white"
                onClick={() => handleViewProfile(mentor._id || mentor.instructorId)}
              >
                View More
              </button>
            </div>
                    );
                  }) : (
                    <div>No mentors found</div>
                  )}
            </div>
{/* Pagination Section */}
{!loading && totalPages > 1 && (
  <div className="flex justify-center mt-4">
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  </div>
)}


      {/* Filter Modal for Small Screens */}
      {isFilterVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[4000] flex justify-center items-center">
          <div className="bg-white p-5 rounded-md w-11/12 max-w-lg z-[5000]">
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-semibold text-[#107F9A] mb-2">Skills</label>
                <Select
                  isMulti
                  className="z-[6004]"
                  value={selectedSkills.map(skill => ({ value: skill, label: skill }))}
                  onChange={handleSkillsChange}
                  options={allSkills.map(skill => ({ value: skill, label: skill }))}
                  placeholder="Select Skills"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-[#107F9A] mb-2">Location</label>
                <Select
                  isMulti
                  className="z-[6003]"
                  value={locations.map(location => ({ value: location, label: countryOptions.find(c => c.value === location).label }))}
                  onChange={handleLocationChange}
                  options={countryOptions}
                  placeholder="Select Location"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-[#107F9A] mb-2">Availability</label>
                <Select
                  isMulti
                  className="z-[6002]"
                  value={selectedDays.map(day => ({ value: day, label: daysOfWeek.find(d => d.value === day).label }))}
                  onChange={handleDayChange}
                  options={daysOfWeek}
                  placeholder="Select Days"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-[#107F9A] mb-2">Hourly Rate (£)</label>
                <Slider
                  range
                  min={0}
                  max={100}
                  value={rateRange}
                  onChange={setRateRange}
                  tipFormatter={value => `£${value}`}
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-[#107F9A] mb-2">From Time</label>
                <Select
                  className="z-[6001]"
                  value={selectedFromTime}
                  onChange={value => handleTimeChange('fromTime', value)}
                  options={timeSlots}
                  placeholder="Select From Time"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-[#107F9A] mb-2">To Time</label>
                <Select
                  className="z-[6000]"
                  value={selectedToTime}
                  onChange={value => handleTimeChange('toTime', value)}
                  options={timeSlots}
                  placeholder="Select To Time"
                />
              </div>
            </div>
            <button className="mt-4 w-full bg-[#107F9A] text-white py-2 rounded-md" onClick={() => setIsFilterVisible(false)}>Close Filters</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrowseMentors;