import React, { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment'; // Import moment
import useInstructorStore from '../../store/useInstructorStore'; // Import Zustand store
import { fetchCsrfToken } from '../../utils/csrf'; // Import your CSRF utility
import api from '../../utils/auth'; // Import API utility

const Achievement = () => {
  const { instructorData, setInstructorData } = useInstructorStore(); // Access Zustand store
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentAchievementId, setCurrentAchievementId] = useState(null);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [achievementCategory, setAchievementCategory] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

    // Simulate fetching data with loading
    useEffect(() => {
      // Simulate an API call to fetch achievements (if needed)
      setTimeout(() => {
        setLoading(false); // Set loading to false after fetching is complete
      }, 100); // Simulate 1 second of loading time
    }, []);

  const achievementCategoryOptions = [
    'Teaching', 'Research', 'Publications', 'Certifications', 'Awards', 'Other'
  ];

  const formatDate = (date) => {
    return moment(date).format('MMMM YYYY'); // Format date to 'Month Year'
  };

  const validateForm = () => {
    const newErrors = {};
    if (!title) newErrors.title = true;
    if (!date) newErrors.date = true;
    if (!achievementCategory) newErrors.achievementCategory = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addAchievement = async () => {
    if (!validateForm()) return;

    const newAchievement = {
      title,
      date,
      description,
      achievementCategory
    };

    try {
      const csrfToken = await fetchCsrfToken(); // Get CSRF token
      const response = await api.post(`/instructorsAchievement/achievement`, newAchievement, {
        headers: { 'CSRF-Token': csrfToken },
      });

      // Update the store with the new achievement
      setInstructorData({
        ...instructorData,
        achievements: [...instructorData.achievements, response.data]
      });

      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error adding achievement:', error);
    }
  };

  const updateAchievement = async () => {
    if (!validateForm()) return;

    const updatedAchievement = {
      title,
      date,
      description,
      achievementCategory
    };

    try {
      const csrfToken = await fetchCsrfToken(); // Get CSRF token
      const response = await api.put(`/instructorsAchievement/achievement/${currentAchievementId}`, updatedAchievement, {
        headers: { 'CSRF-Token': csrfToken },
      });

      // Update the store with the updated achievement
      setInstructorData({
        ...instructorData,
        achievements: instructorData.achievements.map((ach) =>
          ach._id === currentAchievementId ? response.data : ach
        )
      });

      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error updating achievement:', error);
    }
  };

  const deleteAchievement = async (id) => {
    try {
      const csrfToken = await fetchCsrfToken(); // Get CSRF token
      await api.delete(`/instructorsAchievement/achievement/${id}`, {
        headers: { 'CSRF-Token': csrfToken },
      });

      // Update the store to remove the deleted achievement
      setInstructorData({
        ...instructorData,
        achievements: instructorData.achievements.filter((ach) => ach._id !== id)
      });
    } catch (error) {
      console.error('Error deleting achievement:', error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this achievement?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAchievement(id);
        swal("Deleted!", "Your achievement has been deleted.", "success");
      } else {
        swal("Cancelled", "Your achievement is safe.", "error");
      }
    });
  };

  const handleEdit = (achievement) => {
    setIsEdit(true);
    setCurrentAchievementId(achievement._id);
    setTitle(achievement.title);
    setDate(achievement.date);
    setDescription(achievement.description);
    setAchievementCategory(achievement.achievementCategory);
    setModalIsOpen(true);
  };

  const handleAdd = () => {
    setIsEdit(false);
    clearForm();
    setModalIsOpen(true);
  };

  const clearForm = () => {
    setTitle('');
    setDate('');
    setDescription('');
    setAchievementCategory('');
    setErrors({});
  };

  const handleSave = () => {
    if (isEdit) {
      updateAchievement();
    } else {
      addAchievement();
    }
  };

  const AchievementSkeleton = () => (
    <div className="max-w-[700px] p-5">
      <div className="flex flex-col md:flex-row justify-between mb-6">
        <div className="mb-4 md:mb-0">
          <div className="text-2xl font-bold">
            <Skeleton width={200} height={28} />
          </div>
          <Skeleton width={250} height={20} style={{ marginTop: '5px' }} />
        </div>
        <Skeleton width={160} height={40} />
      </div>

      {[...Array(1)].map((_, index) => (
        <div key={index} className="border border-[var(--black-overlay)] p-6 mb-4 rounded-[16px] bg-white">
          <div className="text-[#1673bb] text-[18px] font-semibold flex justify-between items-center">
            <h3 className="m-0 text-[18px] font-semibold pb-2">
              <Skeleton width={150} />
            </h3>
            <div className="flex space-x-4">
              <Skeleton width={20} height={20} circle />
              <Skeleton width={20} height={20} circle />
            </div>
          </div>
          <Skeleton width={200} height={15} style={{ marginBottom: '10px' }} />
          <Skeleton width={100} height={15} />
          <Skeleton width={200} height={15} style={{ marginTop: '10px' }} />
          <Skeleton width={200} height={15} />
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-[700px] p-5">
      {loading ? (
        <AchievementSkeleton />
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between mb-6">
            <div className="mb-4 md:mb-0">
              <div className="text-2xl font-bold">
                Achievements
              </div>
              <p className="text-[14px] text-gray-600">
                Your achievements and awards.
              </p>
            </div>
            <button className="bg-white text-[var(--primary-color)] text-[14px] font-normal border border-[var(--primary-color)] px-6 py-3 cursor-pointer rounded-[8px] mb-4 md:mb-0 hover:bg-[var(--primary-color-hover)] transition delay-3000 hover:text-white" onClick={handleAdd}>
              + Add Achievement
            </button>
          </div>
          
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className="absolute max-h-[90vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 box-border rounded-[8px] outline-none w-full md:w-[600px] max-w-[90%] m-auto overflow-y-auto"
            overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.75)] z-[3000]"
            contentLabel="Add Achievement"
          >
            <div className="mt-2 flex justify-between items-center border-b border-[#ddd] pb-2">
              <div>
                <h2 className="m-0">{isEdit ? "Edit Achievement" : "Add Achievement"}</h2>
              </div>
              <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => setModalIsOpen(false)} />
            </div>
            <form className="mt-4">
              <div className="flex flex-col md:flex-row gap-4 flex-wrap">
                <div className="flex-1 flex flex-col mb-4 box-border w-full">
                  <label>Title</label>
                  <input 
                    type="text" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                    className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.title ? 'border-red-500' : ''}`}
                  />
                </div>
                <div className="flex-1 flex flex-col mb-4 box-border w-full">
                  <label>Date</label>
                  <input 
                    type="date" 
                    value={date} 
                    onChange={(e) => setDate(e.target.value)} 
                    className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.date ? 'border-red-500' : ''}`}
                  />
                </div>
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>Description</label>
                <textarea 
                  rows={4} 
                  value={description} 
                  onChange={(e) => setDescription(e.target.value)} 
                  className="mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full"
                ></textarea>
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>Achievement Category</label>
                <select 
                  value={achievementCategory} 
                  onChange={(e) => setAchievementCategory(e.target.value)} 
                  className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.achievementCategory ? 'border-red-500' : ''}`}
                >
                  <option value="">Select achievement category</option>
                  {achievementCategoryOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end gap-2 mt-10 mb-10">
                <button type="button" onClick={handleSave} className="bg-[var(--primary-color)] text-white px-6 py-3 rounded-[8px] cursor-pointer hover:bg-[var(--primary-color-hover)]">
                  {isEdit ? "Save Changes" : "Add Achievement"}
                </button>
              </div>
            </form>
          </Modal>

          {instructorData.achievements.map((ach) => (
            <div key={ach._id} className="border border-[var(--black-overlay)] p-6 mb-4 rounded-[16px] bg-white">
              <div className="text-[#1673bb] text-[18px] font-semibold flex justify-between items-center">
                <h3 className="m-0 text-[18px] font-semibold pb-2">{ach.title}</h3>
                <div className="flex space-x-4">
                  <FontAwesomeIcon icon={faEdit} className="cursor-pointer text-[#1673bb]" onClick={() => handleEdit(ach)} />
                  <FontAwesomeIcon icon={faTrash} className="cursor-pointer text-[#1673bb]" onClick={() => handleDelete(ach._id)} />
                </div>
              </div>
              <p className="text-[14px] text-black font-normal opacity-40 pb-2">{formatDate(ach.date)}</p>
              <p className="text-[14px] text-black font-normal opacity-40">Category: {ach.achievementCategory}</p>
              <p className="pt-2 text-[14px] overflow-hidden text-ellipsis display-webkit-box webkit-line-clamp-3 webkit-box-orient-vertical whitespace-pre-wrap">{ach.description}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Achievement;
