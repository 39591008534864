import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './InstructorSignUp.module.css';
import api from '../../utils/auth';
import 'font-awesome/css/font-awesome.min.css';
import { ClipLoader } from 'react-spinners';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Password strength validation criteria
const passwordCriteria = {
  minLength: 8,
  hasUppercase: /[A-Z]/,
  hasLowercase: /[a-z]/,
  hasNumber: /\d/,
  hasSpecialChar: /[!@#$%^&*]/,
};

const InstructorSignUp = () => {
  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar);
    return () => {
      document.body.classList.remove(styles.customScrollbar);
    };
  }, []);

  const [timezone, setTimezone] = useState('');
  const onboardingImage = `${process.env.PUBLIC_URL}/assets/images/onboarding.jpg`;
  const backbutton = `${process.env.PUBLIC_URL}/assets/icons/back-button.png`;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({});
  const [cv, setCv] = useState(null);
  const [cvError, setCvError] = useState('');
  const [errors, setErrors] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically detect and set the user's timezone
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(detectedTimezone);
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleCvChange = (e) => {
    const file = e.target.files[0];
    const validExtensions = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        setCvError('File size exceeds 3MB. Please upload a smaller file.');
        setCv(null);
      } else if (!validExtensions.includes(file.type)) {
        setCvError('Please upload a CV in PDF or Word (.docx) format.');
        setCv(null);
      } else {
        setCv(file);
        setCvError('');
      }
    } else {
      setCv(null);
      setCvError('');
    }
  };

  const validatePasswordStrength = (password) => {
    const newPasswordStrength = {
      minLength: password.length >= passwordCriteria.minLength,
      hasUppercase: passwordCriteria.hasUppercase.test(password),
      hasLowercase: passwordCriteria.hasLowercase.test(password),
      hasNumber: passwordCriteria.hasNumber.test(password),
      hasSpecialChar: passwordCriteria.hasSpecialChar.test(password),
    };
    setPasswordStrength(newPasswordStrength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePasswordStrength(newPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!firstName.trim()) {
      newErrors.firstName = 'First name should not be empty or contain only spaces';
    } else if (!/^[A-Za-z]+(?: [A-Za-z]+)?$/.test(firstName)) {
      newErrors.firstName = 'First name should contain only letters';
    }

    if (!lastName.trim()) {
      newErrors.lastName = 'Last name should not be empty or contain only spaces';
    } else if (!/^[A-Za-z]+(?: [A-Za-z]+)?$/.test(lastName)) {
      newErrors.lastName = 'Last name should contain only letters';
    }

    if (!email.trim()) {
      newErrors.email = 'Email address should not be empty or contain only spaces';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!phone.trim()) {
      newErrors.phone = 'Phone number should not be empty or contain only spaces';
    }

    if (!cv) {
      newErrors.cv = 'Please upload your CV.';
    }

    if (!password) {
      newErrors.password = 'Password should not be empty';
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (!termsChecked) {
      newErrors.terms = 'You must agree to the terms and conditions';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('firstName', firstName.trim());
        formData.append('lastName', lastName.trim());
        formData.append('email', email.trim());
        formData.append('phone', phone.trim());
        formData.append('password', password);
        formData.append('cv', cv);
        formData.append('timezone', timezone);

        const response = await api.post('/instructors/register', formData);
        navigate('/mentor/authentication');
      } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.msg === 'Email already exists') {
          setGeneralError('This email is already registered. Please try another or sign in.');
        } else {
          setGeneralError('An error occurred. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const handleSignInClick = () => {
    navigate('/sign-in');
  };

  return (
    <>
      {loading && (
        <div className={styles.spinnerOverlay}>
          <ClipLoader color="var(--primary-color)" loading={loading} size={50} />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <div
            className={styles.goBackIcon}
            onClick={handleGoBack}
            style={{ cursor: 'pointer' }}
          >
            <img src={backbutton} alt="Go Back" />
          </div>
          <div className={styles.header}>
            <h1 className={styles.h1}>Sign up as a Mentor</h1>
            <p className={styles.subHeading}>
              Choose an account to get started with our platform and transform your career.
            </p>
            <form className={styles.signupForm} onSubmit={handleSubmit}>
              <div className={styles.formRow}>
                <div className={`${styles.formGroup} ${styles.firstRow}`}>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    className={styles.inputField}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errors.firstName && <p className={styles.errorMessage}>{errors.firstName}</p>}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    className={styles.inputField}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errors.lastName && <p className={styles.errorMessage}>{errors.lastName}</p>}
                </div>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  className={styles.inputField}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <p className={styles.errorMessage}>{errors.email}</p>}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="phone">Phone Number</label>
                <PhoneInput
                  country={'us'}
                  value={phone}
                  onChange={setPhone}
                  inputStyle={{
                    width: '100%',
                    padding: '20px',
                    fontSize: '16px',
                    paddingLeft: '48px',
                  }}
                />
                {errors.phone && <p className={styles.errorMessage}>{errors.phone}</p>}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.cvLabel} htmlFor="cv">Upload CV</label>
                <input
                  type="file"
                  id="cv"
                  className={styles.inputField}
                  onChange={handleCvChange}
                />
                {cvError && <p className={styles.errorMessage}>{cvError}</p>}
                {errors.cv && <p className={styles.errorMessage}>{errors.cv}</p>}
              </div>

              <div className={styles.formRow}>
                <div className={`${styles.formGroup} ${styles.firstRow}`}>
                  <label htmlFor="password" className={styles.passwordLabel}>Password</label>
                  <div className={styles.passwordWrapper}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      className={styles.inputField}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <i
                      className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles.passwordToggle}`}
                      onClick={togglePasswordVisibility}
                    ></i>
                  </div>
                  <PasswordStrengthMeter passwordStrength={passwordStrength} />
                  {errors.password && <p className={styles.errorMessage}>{errors.password}</p>}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="confirmPassword" className={styles.passwordLabel}>Confirm Password</label>
                  <div className={styles.passwordWrapper}>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      className={styles.inputField}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <i
                      className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles.passwordToggle}`}
                      onClick={toggleConfirmPasswordVisibility}
                    ></i>
                  </div>
                  {errors.confirmPassword && <p className={styles.errorMessage}>{errors.confirmPassword}</p>}
                </div>
              </div>

              <div className={styles.policyRow}>
                <input
                  type="checkbox"
                  id="agreePolicy"
                  checked={termsChecked}
                  onChange={handleTermsChange}
                />
                <label htmlFor="agreePolicy" className={styles.policyRowLabel}>
                  By clicking sign up, you agree with our <a href="https://www.navigrated.com/terms-and-conditions/" className={styles.policyRowLink}>Terms and Conditions</a> and our <a href="https://www.navigrated.com/privacy-policy/" className={styles.policyRowLink}>Privacy Policy</a>
                </label>
              </div>
              {errors.terms && <p className={styles.errorMessage}>{errors.terms}</p>}
              <div className={styles.buttonSpace}></div>
              {generalError && <p className={styles.signupErrorMessage}>{generalError}</p>}
              <button type="submit" className={styles.continueButton}>Sign up</button>
              <p className={styles.signIn}>
                Already have an account? <span onClick={handleSignInClick} className={styles.signInLink} style={{ cursor: 'pointer' }}>Sign in</span>
              </p>
            </form>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageContainer}>
              <img src={onboardingImage} alt="Onboarding" />
              <div className={styles.overlay}></div>
              <div className={styles.blackOverlay}></div>
              <div className={styles.textOverlay}>
                <h2>Why Choose Navigrated?</h2>
                <p>
                  Our mission with Navigrated is to help international students and professionals secure industry-relevant jobs and build successful ventures in the UK - through the power of community mentorship.
                </p>
                <ul className={styles.featuresList}>
                  <li className={styles.featuresListItem}>
                    <div className={styles.featureHeader}>
                      <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
                      <span className={styles.boldText}><b>Expert-led sessions</b></span>
                    </div>
                    <div className={styles.nonBoldText}>
                      on landing sponsored jobs, entrepreneurship, and overcoming immigration challenges.
                    </div>
                  </li>
                  <li className={styles.featuresListItem}>
                    <div className={styles.featureHeader}>
                      <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
                      <span className={styles.boldText}><b>Mentor-led daily group calls</b></span>
                    </div>
                    <div className={styles.nonBoldText}>
                      Gain insights from professionals who have built careers at companies like Deloitte, KPMG, Loreal, Rolls Royce, etc.
                    </div>
                  </li>
                  <li className={styles.featuresListItem}>
                    <div className={styles.featureHeader}>
                      <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
                      <span className={styles.boldText}><b>CV reviews</b></span>
                    </div>
                    <div className={styles.nonBoldText}>
                      Tools and challenges built to help you stand out in the competitive job market.
                    </div>
                  </li>
                  <li className={styles.featuresListItem}>
                    <div className={styles.featureHeader}>
                      <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
                      <span className={styles.boldText}><b>Build your network</b></span>
                    </div>
                    <div className={styles.nonBoldText}>
                      Attend both virtual and in-person events to expand your professional circle.
                    </div>
                  </li>
                  <li className={styles.featuresListItem}>
                    <div className={styles.featureHeader}>
                      <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
                      <span className={styles.boldText}><b>Success stories</b></span>
                    </div>
                    <div className={styles.nonBoldText}>
                      Be inspired by real experiences from mentors who’ve faced and overcome the same challenges you are now facing.
                    </div>
                  </li>
                  <li className={styles.featuresListItem}>
                    <div className={styles.featureHeader}>
                      <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
                      <span className={styles.boldText}><b>Exclusive insights</b></span>
                    </div>
                    <div className={styles.nonBoldText}>
                      Access templates, resources, and webinars designed to fast-track your UK career journey.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PasswordStrengthMeter = ({ passwordStrength }) => (
  <div className={styles.passwordStrengthMeter}>
    <p className={passwordStrength.minLength ? styles.criteriaMet : styles.criteria}>
      8 characters minimum
    </p>
    <p className={passwordStrength.hasUppercase ? styles.criteriaMet : styles.criteria}>
      At least one uppercase letter
    </p>
    <p className={passwordStrength.hasLowercase ? styles.criteriaMet : styles.criteria}>
      At least one lowercase letter
    </p>
    <p className={passwordStrength.hasNumber ? styles.criteriaMet : styles.criteria}>
      At least one number
    </p>
    <p className={passwordStrength.hasSpecialChar ? styles.criteriaMet : styles.criteria}>
      At least one special character (!@#$%^&*)
    </p>
  </div>
);

export default InstructorSignUp;