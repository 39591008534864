import React from 'react';
import { useEffect } from 'react';
import styles from './InstructorAuthenticate.module.css'; // Import CSS module
import 'font-awesome/css/font-awesome.min.css';

const InstructorAuthenticate = () => {

  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar); // Use the class from module CSS

    // Cleanup by removing class when component unmounts
    return () => {
      document.body.classList.remove(styles.customScrollbar); // Use the class from module CSS
    };
  }, []);

  const onboardingImage = `${process.env.PUBLIC_URL}/assets/images/onboarding.jpg`; 
  const backbutton = `${process.env.PUBLIC_URL}/assets/icons/back-button.png`; 
  const emailAuthSvg = `${process.env.PUBLIC_URL}/assets/svgs/reviewfile.svg`; 

  return (
    <div className={styles.container}>
      {/* Left Column */}
      <div className={styles.leftColumn}>
        <a href="/" className={styles.goBackIcon}>
          <img src={backbutton} alt="Go Back" />
          {/* will lead to landing page developed in future*/}
        </a>
        <div className={styles.header}>
          <div className={styles.progressBar}>
            <div className={`${styles.progressStep} ${styles.active}`}></div>
            <div className={`${styles.progressStep} ${styles.active}`}></div>
            <div className={`${styles.progressStep} ${styles.active}`}></div>
          </div>
          <h1 className={styles.h1}>Registration Complete</h1>
          {/* Add the SVG icon below the heading */}
          <div className={styles.svgWrapper}>
            <div className={styles.svgBackground}>
              <img src={emailAuthSvg} alt="Email Authentication" className={styles.emailAuthSvg} />
            </div>
          </div>
          <div className={styles.svgHead}>
            <h2>Account Under Review</h2>
            <div className={styles.text}>You'll gain access to your profile once our team has reviewed it.</div>
            
          </div>
          <p className={styles.note}>Note: You'll receive an email upon approval. Also click on the link that is sent to your registered mail to verify your email.</p>
          <button type="button" className={styles.continueButton} disabled>
  <i className="fa fa-lock" aria-hidden="true"></i> Continue
</button>

        </div>
      </div>

      {/* Right Column */}
      <div className={styles.rightColumn}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img src={onboardingImage} alt="Onboarding" />
            <div className={styles.overlay}></div>
            <div className={styles.blackOverlay}></div>
            <div className={styles.textOverlay}>
  <h2>What is Navigrated?</h2>
  <p>
    Our mission with Navigrated is to help international students and professionals secure industry-relevant jobs and build successful ventures in the UK - through the power of community mentorship.
  </p>
  <ul className={styles.featuresList}>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Expert-led sessions</b></span>
      </div>
      <div className={styles.nonBoldText}>
        on landing sponsored jobs, entrepreneurship, and overcoming immigration challenges.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Mentor-led daily group calls</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Gain insights from professionals who have built careers at companies like Deloitte, KPMG, Loreal, Rolls Royce, etc.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>CV reviews</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Tools and challenges built to help you stand out in the competitive job market.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Build your network</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Attend both virtual and in-person events to expand your professional circle.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Success stories</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Be inspired by real experiences from mentors who’ve faced and overcome the same challenges you are now facing.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Exclusive insights</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Access templates, resources, and webinars designed to fast-track your UK career journey.
      </div>
    </li>
  </ul>
</div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorAuthenticate;
