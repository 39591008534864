import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import styles from './Services.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useInstructorStore from '../../store/useInstructorStore';
Modal.setAppElement('#root'); // Required for accessibility

const allSkills = [
  "Career Development Coaching", "Life Coaching", "Interview Preparation", "CV Review", "One-to-one support",
  "Cover Letters", "Profile Assessment", "Job Interview preparation", "Mock interview", "LinkedIn Networking",
  "Personal Branding", "Entrepreneurship Guidance", "Sponsored Job-Searching", "Accountability coach",
  "Deciding my Career Path", "Confidence Building", "Business in UK", "Global Talent Route", "Innovator Founder Route",
  "Networking Strategies", "Work Visa Guidance", "Start-up Advice", "Cultural Adaptation", "Industry Insights",
  "Professional Etiquette", "Goal Setting", "Time Management", "Stress Management", "Work-Life Balance",
  "Professional Certifications", "Business Development", "Sales Strategies", "Negotiation Skills",
  "Leadership Training", "Team Management", "Conflict Resolution", "Presentation Skills",
  "Effective Communication", "Digital Marketing Strategies", "SEO Optimization", "E-commerce Strategies",
  "Project Planning", "Risk Management", "Financial Planning", "Investment Strategies", "Budget Management",
  "Market Analysis", "Product Development", "Innovation Coaching", "Software Development", "IT Security Advice",
  "Data Analytics", "Machine Learning Insights", "Artificial Intelligence Trends", "Cloud Computing",
  "Mobile Development", "User Experience Design", "Graphic Design Tips", "Freelancing scaling",
  "Job Contract Negotiation", "Public Speaking", "Research Methodologies", "Graduate School Applications",
  "Postdoctoral Opportunities", "PhD proposals and admission", "MRes admissions"
];

const Services = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentServiceId, setCurrentServiceId] = useState(null);
  const [serviceTitle, setServiceTitle] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [servicePrice, setServicePrice] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [addedSkills, setAddedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState(allSkills);
  const [recommendedSkills, setRecommendedSkills] = useState([]);
  const [originalRecommendedSkills, setOriginalRecommendedSkills] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false); // New loading state for form submission
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const defaultLandscape = `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.jpg`;

  const { instructorData, setInstructorData } = useInstructorStore(); // Access store data

  // Fetch services from the store
  const services = instructorData.service;

  const openModal = () => {
    if (!isEditMode && services.length >= 3) {
      swal("Limit Reached", "You can add up to three services only", "error");
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setCurrentServiceId(null);
    setServiceTitle('');
    setServiceDescription('');
    setServicePrice('');
    setSearchTerm('');
    setAddedSkills([]);
    setFilteredSkills(allSkills);
    setImagePreview(null);
    setResizedImage(null);
    setErrors({});
  };

  useEffect(() => {
    setLoading(false); // Assume store is already populated, no API call needed
  }, [services]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFilteredSkills(
      allSkills.filter(skill => skill.toLowerCase().includes(value.toLowerCase()))
    );
    setDropdownVisible(true); 
  };

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleAddSkill = (skill) => {
    if (addedSkills.length < 3 && !addedSkills.includes(skill)) {
      setAddedSkills([...addedSkills, skill]);
      setRecommendedSkills(recommendedSkills.filter(s => s !== skill));
      setSearchTerm('');
      setDropdownVisible(false);
    }
  };

  const handleRemoveSkill = (skill) => {
    setAddedSkills(addedSkills.filter(s => s !== skill));
    if (originalRecommendedSkills.includes(skill) && !recommendedSkills.includes(skill)) {
      setRecommendedSkills([...recommendedSkills, skill]);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePreview(event.target.result);
        resizeImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resizeImage = (dataUrl) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 277;
      canvas.height = 172;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      setResizedImage(canvas.toDataURL('image/jpeg'));
    };
    img.src = dataUrl;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!serviceTitle) newErrors.serviceTitle = 'Service title is required';
    if (!serviceDescription) newErrors.serviceDescription = 'Service description is required';
    if (!servicePrice) newErrors.servicePrice = 'Service price is required'; 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setSubmitLoading(true); // Set loading to true when the submit starts

    try {
      const csrfToken = await fetchCsrfToken();

      const data = {
        serviceTitle,
        serviceDescription,
        price: servicePrice,
        industrySkills: addedSkills,
      };

      if (resizedImage) {
        data.image = resizedImage;
      }

      let updatedServices;

      if (isEditMode) {
        const response = await api.put(`/instructor-services/${currentServiceId}`, data, {
          headers: {
            'CSRF-Token': csrfToken,
          }
        });
        updatedServices = services.map(service => service._id === currentServiceId ? response.data : service);
      } else {
        const response = await api.post(`/instructor-services`, data, {
          headers: {
            'CSRF-Token': csrfToken,
          }
        });
        updatedServices = [...services, response.data];
      }

      // Update the store with new services list
      setInstructorData({ ...instructorData, service: updatedServices });
      closeModal();
    } catch (error) {
      console.error('Error saving service:', error.response ? error.response.data : error.message);
    } finally {
      setSubmitLoading(false); // Set loading to false when the submit finishes
    }
  };

  const handleEdit = (service) => {
    setCurrentServiceId(service._id);
    setServiceTitle(service.serviceTitle);
    setServiceDescription(service.serviceDescription);
    setServicePrice(service.price);
    setAddedSkills(service.industrySkills);
    setImagePreview(service.image ? `${service.image}` : defaultLandscape);
    setResizedImage(service.image ? `${service.image}` : defaultLandscape);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleDelete = async (serviceId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this service?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          const csrfToken = await fetchCsrfToken();

          await api.delete(`/instructor-services/${serviceId}`, {
            headers: {
              'CSRF-Token': csrfToken,
            }
          });
          
          const updatedServices = services.filter(service => service._id !== serviceId);
          
          // Update the store with the new services list
          setInstructorData({ ...instructorData, service: updatedServices });

          swal("Deleted!", "The service has been deleted.", "success");
        } catch (error) {
          console.error('Error deleting service:', error.response ? error.response.data : error.message);
          swal("Error!", "There was an error deleting the service.", "error");
        }
      } else {
        swal("Cancelled", "Your service is safe.", "info");
      }
    });
  };

  const ServicesSkeleton = () => (
    <div className="max-w-[700px] p-5">
      <div className="flex flex-col md:flex-row justify-between mb-6">
        <div className="mb-4 md:mb-0">
          <Skeleton width={200} height={24} />
          <Skeleton width={300} height={20} />
        </div>
        <Skeleton width={150} height={40} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {Array(2).fill().map((_, index) => (
          <div className="bg-white border border-gray-200 rounded-lg overflow-hidden relative" key={index}>
            <div className="absolute top-2 right-2 flex gap-2 bg-[rgba(16,127,154,0.8)] p-2 rounded-lg">
              <Skeleton circle={true} height={32} width={32} />
              <Skeleton circle={true} height={32} width={32} />
            </div>
            <div className="min-h-[240px] bg-gray-100">
              <Skeleton height={240} />
            </div>
            <div className="p-5">
              <Skeleton width={100} height={20} />
              <Skeleton width={200} height={20} />
              <div className="flex flex-wrap gap-2 mb-2">
                {Array(3).fill().map((_, skillIndex) => (
                  <Skeleton width={80} height={24} key={skillIndex} />
                ))}
              </div>
              <Skeleton width={100} height={24} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="max-w-[700px] p-5">
      {loading ? (
        <ServicesSkeleton />
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between mb-6">
            <div className="mb-4 md:mb-0">
              <div className="text-[24px] font-bold">Add Services</div>
              <p className="text-[14px] text-gray-600 mb-5">You can offer services to customers for a price.</p>
            </div>
            <button
              className="bg-white text-[var(--primary-color)] text-[14px] font-normal border border-[var(--primary-color)] px-6 py-3 cursor-pointer rounded-[8px] mb-4 md:mb-0 hover:bg-[var(--primary-color-hover)] transition delay-3000 hover:text-white"
              onClick={openModal}
            >
              + Add new Services
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {services.map((service) => (
              <div className="bg-white border border-gray-200 rounded-lg overflow-hidden relative" key={service._id}>
                <div className="absolute top-2 right-2 flex gap-2 bg-[rgba(16,127,154,0.8)] p-2 rounded-lg">
                  <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(service)} className="cursor-pointer text-white text-[16px]" />
                  <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(service._id)} className="cursor-pointer text-white text-[16px]" />
                </div>
                <div className="min-h-[240px] bg-gray-100">
                <img
                  src={service.image ? `${service.image}` : defaultLandscape}
                  alt={service.serviceTitle}
                  className="w-full h-52 object-cover"
                  onError={(e) => { e.target.src = defaultLandscape; }}
                />
                </div>
                <div className="p-5">
                  <div className="text-[16px] font-semibold mb-2">{service.serviceTitle}</div>
                  <p className="text-[14px] text-gray-600 mb-2 line-clamp-3">{service.serviceDescription}</p>
                  <div className="flex flex-wrap gap-2 mb-2">
                    {service.industrySkills.map((skill) => (
                      <button key={skill} className="bg-gray-100 border border-[var(--primary-color)] text-[var(--primary-color)] px-2 py-1 rounded-full text-[12px]">
                        {skill}
                      </button>
                    ))}
                  </div>
                  <div className="text-[18px] font-bold text-[var(--primary-color)] text-right">£{service.price}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-6 w-full max-w-[700px] h-auto max-h-[90vh] overflow-y-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-50"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-[24px] font-bold">{isEditMode ? 'Edit Service' : 'Add New Service'}</h2>
          <button className="text-2xl" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="space-y-4">
          <div className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg h-[172px] cursor-pointer relative hover:bg-gray-100">
            <input type="file" accept="image/*" onChange={handleImageUpload} className="absolute inset-0 opacity-0" />
            {imagePreview ? (
              <>
                <img src={resizedImage || imagePreview} alt="Preview" className="w-[277px] h-[172px] object-cover rounded-lg" />
                <FontAwesomeIcon icon={faUpload} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-500" />
              </>
            ) : (
              <span className="text-gray-500">Add an image</span>
            )}
          </div>
          <div>
            <div className="text-[16px] font-medium mb-2">Enter service title</div>
            <input
              type="text"
              value={serviceTitle}
              onChange={(e) => setServiceTitle(e.target.value)}
              className={`w-full p-2 border rounded-lg ${errors.serviceTitle ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.serviceTitle && <p className="text-red-500 text-sm mt-1">{errors.serviceTitle}</p>}
          </div>
          <div>
            <div className="text-[16px] font-medium mb-2">Enter service description</div>
            <textarea
              value={serviceDescription}
              onChange={(e) => setServiceDescription(e.target.value)}
              className={`w-full p-2 border rounded-lg ${errors.serviceDescription ? 'border-red-500' : 'border-gray-300'}`}
            ></textarea>
            {errors.serviceDescription && <p className="text-red-500 text-sm mt-1">{errors.serviceDescription}</p>}
          </div>
          <div>
            <div className="text-[16px] font-medium mb-2">Enter service price</div>
            <input
              type="number"
              value={servicePrice}
              onChange={(e) => setServicePrice(e.target.value)}
              className={`w-full p-2 border rounded-lg ${errors.servicePrice ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.servicePrice && <p className="text-red-500 text-sm mt-1">{errors.servicePrice}</p>}
          </div>
          <div>
            <div className="text-[16px] font-medium mb-2">Add industry skills related to service</div>
            <div className="relative">
              <input
                type="text"
                placeholder="Ex. Search for a skill like Business Management"
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={handleFocus}
                className="w-full p-2 border rounded-lg"
              />
              {isDropdownVisible && (
                <div className="absolute top-full left-0 w-full bg-white border border-t-0 rounded-b-lg shadow-lg z-10 max-h-40 overflow-y-auto">
                  {filteredSkills.map((skill) => (
                    <div key={skill} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleAddSkill(skill)}>
                      {skill}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {addedSkills.map((skill) => (
              <div key={skill} className="bg-blue-100 text-primary-color px-3 py-1 rounded-full cursor-pointer" onClick={() => handleRemoveSkill(skill)}>
                {skill}
              </div>
            ))}
          </div>
          {addedSkills.length >= 3 && <p className="text-red-500 text-sm mt-1">You can only add up to 3 skills.</p>}
          <div className="mt-4 bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h3 className="text-lg font-semibold mb-2">Recommended Skills</h3>
            <div className="flex flex-wrap gap-2">
              {recommendedSkills.length > 0 ? (
                recommendedSkills.map((skill) => (
                  <div key={skill} className="bg-gray-200 text-primary-color px-3 py-1 rounded-full cursor-pointer" onClick={() => handleAddSkill(skill)}>
                    + {skill}
                  </div>
                ))
              ) : (
                <p>No recommended skills available.</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className={`bg-[var(--primary-color)] text-white px-6 py-3 rounded-lg hover:bg-[var(--primary-color-hover)] ${submitLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleSubmit}
            disabled={submitLoading} // Disable button while loading
          >
            {submitLoading ? 'Submitting...' : isEditMode ? 'Save Changes' : 'Submit'} {/* Loading text */}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Services;