import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../../utils/auth';
import { getName } from 'country-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faMapMarkerAlt, faClock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import styles from './InstructorProfileView.module.css'; // Import the CSS module for sanitized content

const InstructorProfileView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [instructor, setInstructor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInstructor = async () => {
      try {
        const response = await api.get(`/instructors/${id}/profile`);
        setInstructor(response.data);
      } catch (error) {
        console.error('Error fetching instructor profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInstructor();
  }, [id]);

  if (loading) {
    return (
      <div className="max-w-[1400px] m-auto p-5 bg-white rounded-lg border border-border-color text-gray-800 mt-5">
        {/* Back Button Skeleton */}
        <Skeleton width={80} height={30} className="mb-5" />
        <div className="flex flex-col lg:flex-row justify-between items-start mb-5">
          <div className="flex items-start mb-5 lg:mb-0">
            <Skeleton circle={true} height={144} width={144} className="mr-5" />
            <div>
              <Skeleton height={30} width={200} />
              <Skeleton height={20} width={150} className="mt-2" />
              <Skeleton height={20} width={100} className="mt-2" />
              <Skeleton height={20} width={150} className="mt-2" />
            </div>
          </div>
          <div className="border border-border-color bg-white rounded-lg p-5 w-full lg:w-[300px] text-center shadow-md">
            <Skeleton circle={true} height={96} width={96} className="mb-3" />
            <Skeleton height={30} width={150} className="mb-5" />
            <Skeleton height={40} width="100%" className="mb-3" />
            <Skeleton height={40} width="100%" />
          </div>
        </div>
        <div className="my-5">
          <Skeleton height={30} width={150} className="mb-4" />
          <Skeleton height={20} width="100%" className="mb-2" count={3} />
        </div>
        <div className="my-10">
          <Skeleton height={30} width={150} className="mb-4" />
          <Skeleton height={20} width="100%" className="mb-2" count={4} />
        </div>
        <div className="my-10">
          <Skeleton height={30} width={250} className="mb-4" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="bg-white border border-border-color rounded-lg overflow-hidden shadow-sm">
                <Skeleton height={208} width="100%" />
                <div className="p-5">
                  <Skeleton height={20} width="100%" className="mb-2" />
                  <Skeleton height={15} width="80%" className="mb-3" />
                  <Skeleton height={15} width="70%" className="mb-3" />
                  <Skeleton height={15} width="60%" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const { firstName, lastName, headline, location, mentorship, addedSkills, bio, services = [], profilePicture, rating, ratings } = instructor;
  const locationFullName = getName(location) || location;
  const localTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;
  const defaultLandscape = `${process.env.PUBLIC_URL}/assets/images/defaultLandscape.jpg`;

  return (
    <div className="max-w-[1400px] m-auto p-5 bg-white rounded-lg border border-border-color text-gray-800 mt-5">
      {/* Back Button */}
      <button 
        className="flex items-center text-[#107F9A] hover:text-[#0A5F74] transition-colors duration-200 mb-5"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
      </button>

      <div className="flex flex-col lg:flex-row justify-between items-start mb-5">
        <div className="flex items-start mb-5 lg:mb-0">
          <div className="flex-shrink-0">
            <img
              src={profilePicture ? `${profilePicture}` : defaultProfileImage}
              alt="Profile"
              className="w-36 h-36 rounded-full object-cover"
            />
          </div>
          <div className="ml-5">
            <h1 className="text-2xl font-bold">{firstName} {lastName}</h1>
            <p className="text-lg text-gray-600 mt-1">{headline}</p>
            <div className="flex items-center mt-2 text-sm text-gray-600">
              <FontAwesomeIcon icon={faStar} className="text-[#107F9A] mr-2" />
                {rating ? (
                  <>
                    {rating.toFixed(1)}/5&nbsp;
                    <span style={{ opacity: 0.6 }}>({ratings.length})</span>
                  </>
                ) : (
                  <span>No ratings yet</span>
                )}
            </div>
            <div className="flex items-center mt-2 text-sm text-gray-600">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#107F9A] mr-2" /> {locationFullName}
            </div>
            <div className="flex items-center mt-2 text-sm text-gray-600">
              <FontAwesomeIcon icon={faClock} className="text-[#107F9A] mr-2" /> {localTime}
            </div>
          </div>
        </div>
        <div className="border border-border-color bg-white rounded-lg p-5 w-full lg:w-[300px] text-center shadow-md">
          <div className="flex flex-col items-center mb-5">
            <img
              src={profilePicture ? `${profilePicture}` : defaultProfileImage}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover mb-2"
            />
            <h2 className="text-xl font-bold">{firstName} {lastName}</h2>
          </div>
          <button className="w-full py-2 mt-3 bg-[#107F9A] text-white rounded-md font-medium hover:bg-[#0A5F74] transition duration-200">Book a call for ${mentorship ? mentorship.price : 'N/A'}/h</button>
        </div>
      </div>

      <div className="my-5">
        <h2 className="text-xl font-bold mb-4">Skills</h2>
        <div className="flex flex-wrap gap-2">
          {addedSkills.map(skill => (
            <span key={skill} className="bg-gray-200 border border-[#107F9A] rounded-full px-4 py-2 text-[#107F9A]">{skill}</span>
          ))}
        </div>
      </div>

      <div className="my-10">
        <h2 className="text-xl font-bold mb-4">About Me</h2>
        <div
          className={styles['bio-content']} // Use custom CSS for sanitized content
          dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(bio, { 
              ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'], 
              ALLOWED_ATTR: ['href'] 
            }) 
          }}
        />
      </div>

      <div className="my-10">
        <h2 className="text-xl font-bold mb-4">Services Offered by Mentor</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {services.length > 0 ? services.map((service, index) => (
            <div key={index} className="bg-white border border-border-color rounded-lg overflow-hidden shadow-sm">
              <img
                src={service.image ? `${service.image}` : defaultLandscape}
                alt={service.serviceTitle}
                className="w-full h-52 object-cover"
                onError={(e) => { e.target.src = defaultLandscape; }}
              />
              <div className="p-5">
                <h3 className="text-lg font-bold mb-2">{service.serviceTitle}</h3>
                <p className="text-sm text-gray-600 mb-3">{service.serviceDescription}</p>
                <div className="flex flex-wrap gap-2">
                  {service.industrySkills.map((skill, idx) => (
                    <span key={idx} className="bg-white border border-[#107F9A] text-[#107F9A] px-3 py-1 rounded-full text-sm">{skill}</span>
                  ))}
                </div>
              </div>
            </div>
          )) : <p className="text-gray-600">No services offered at this time.</p>}
        </div>
      </div>
    </div>
  );
};

export default InstructorProfileView;
