import React, { useState } from 'react';
import GeneralRecordings from './../../../components/InstructorRecordings/GeneralRecordings';
import OneOnOneRecordings from './../../../components/InstructorRecordings/OneOnOneRecordings';
import ChatRecordings from './../../../components/InstructorRecordings/ChatRecordings';

const ContentLibraryInstructor = () => {
  const [selectedTab, setSelectedTab] = useState('General Recordings');

  return (
    <div className="max-w-[1400px] m-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">
        My Content
      </div>
      {/* Remove border-b class from this div */}
      <div className="flex justify-start mb-4">
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'General Recordings'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('General Recordings')}
        >
          General Recordings
        </button>
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'OneOnOne Recordings'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('OneOnOne Recordings')}
        >
          OneOnOne Recordings
        </button>
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'Chat Recordings'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('Chat Recordings')}
        >
          Chat Recordings
        </button>
      </div>
      <div className="mt-6">
        {selectedTab === 'General Recordings' && <GeneralRecordings />}
        {selectedTab === 'OneOnOne Recordings' && <OneOnOneRecordings />}
        {selectedTab === 'Chat Recordings' && <ChatRecordings />}
      </div>
    </div>
  );
};

export default ContentLibraryInstructor;
