import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import api from '../../../utils/auth';
import { fetchCsrfToken } from '../../../utils/csrf';
import SubscriptionCard from '../../../components/SubscriptionCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faQuestionCircle, faUserPlus, faClipboardList, faPlayCircle, faUser } from '@fortawesome/free-solid-svg-icons';

const StudentDashboard = () => {
  const [userData, setUserData] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [challenges, setChallenges] = useState({ ongoing: [], completed: [] });
  const [services, setServices] = useState([]);
  const [sessionDetails, setSessionDetails] = useState({ bookedSessions: 0, freeSessions: 0, totalSessions: 0 });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const tokenFromUrl = new URLSearchParams(window.location.search).get('token');

    if (tokenFromUrl) {
      localStorage.setItem('jwtToken', tokenFromUrl);
      navigate('/student/dashboard', { replace: true });
    }

    const fetchData = async () => {
      const csrfToken = await fetchCsrfToken();
      try {
        const response = await api.get('/studentsDashboard', {
          headers: {
            'CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });

        console.log(response.data);

        setUserData(response.data.user);
        setSessionDetails(response.data.sessionDetails);

        // Extract sessions and convert to student's timezone
        const timezone = response.data.user.timezone || moment.tz.guess();
        const formattedSessions = response.data.sessions.map((session) => {
          const sessionStart = moment.utc(session.startUTC).tz(timezone).format('hh:mm A');
          const sessionEnd = moment.utc(session.endUTC).tz(timezone).format('hh:mm A');
          return {
            ...session,
            formattedDate: moment.utc(session.startUTC).tz(timezone).format('dddd, MMMM Do, YYYY'),
            formattedTime: `${sessionStart} - ${sessionEnd}`,
            instructorName: session.instructorId ? `${session.instructorId.firstName} ${session.instructorId.lastName}` : 'Instructor',
          };
        });
        setSessions(formattedSessions);

        // Set challenges from the response
        setChallenges(response.data.challenges);

        // Extract services and format them
        const formattedServices = response.data.services.map(service => {
          return {
            ...service.serviceId,
            instructor: service.instructorId,
            paymentIntentId: service.paymentIntentId,
            purchaseDate: moment(service.purchaseDate).format('MMMM Do, YYYY'),
            isActive: service.isActive,
            servicePurchaseId: service._id,
          };
        });
        setServices(formattedServices);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleUpgradeClick = () => {
    navigate('/student/payment', { state: { email: userData?.email } });
  };

  const endService = (service) => {
    Swal.fire({
      title: 'End Service Confirmation',
      text: `To confirm ending this service, please enter the service name: "${service.serviceTitle}"`,
      icon: 'warning',
      input: 'text',
      inputPlaceholder: 'Enter service name',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to enter the service name!';
        } else if (value !== service.serviceTitle) {
          return 'The service name does not match. Please enter it exactly as shown.';
        }
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, end it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const csrfToken = await fetchCsrfToken();
        try {
          await api.put('/studentsDashboard/endService', { serviceId: service.servicePurchaseId }, {
            headers: {
              'CSRF-Token': csrfToken,
            },
            withCredentials: true,
          });

          // Immediately remove the service from the list
          setServices(prevServices => prevServices.filter(s => s.servicePurchaseId !== service.servicePurchaseId));

          Swal.fire(
            'Ended!',
            'The service has been ended.',
            'success'
          );
        } catch (error) {
          console.error('Error ending service:', error);
          Swal.fire(
            'Error!',
            'There was an error ending the service.',
            'error'
          );
        }
      }
    });
  };

  if (loading) {
    return (
      <div className="max-w-[1400px] m-auto p-5">
        {/* Skeleton Loader */}
        <div className="bg-gray-200 h-6 w-1/2 mb-4 rounded-md animate-pulse"></div>
        <div className="bg-gray-200 h-4 w-1/4 mb-4 rounded-md animate-pulse"></div>
        <div className="bg-gray-200 h-32 mb-5 rounded-md animate-pulse"></div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="max-w-[1400px] m-auto p-5">
        <p className="text-center text-gray-600">Loading user data...</p>
      </div>
    );
  }

  return (
    <div className="max-w-[1400px] m-auto p-5">
      <h1 className="text-2xl font-bold mb-5 text-primary-color">Welcome, {userData.firstName} {userData.lastName}</h1>

      {/* Subscription Card */}
      {!userData.isPremium && (
        <SubscriptionCard email={userData.email} onUpgradeClick={handleUpgradeClick} />
      )}

      {/* Render all sections below the subscription card for freemium users */}
      <div>
        {/* Session Booking Counter */}
        <div className="bg-secondary-background-color rounded-lg shadow p-6 mb-5">
          <h2 className="text-xl font-semibold mb-2 text-text-color">Session Overview</h2>
          {sessionDetails.bookedSessions > 0 ? (
            <>
              <p className="text-text-color mb-4">
                You have booked {sessionDetails.bookedSessions} total sessions.
              </p>
              <p className="text-text-color mb-4">
                You have {sessionDetails.freeSessions} free session request slots available.
              </p>
            </>
          ) : (
            <>
              <p className="text-text-color mb-4">You have no sessions booked yet.</p>
              <p className="text-text-color mb-4">
                You have {sessionDetails.freeSessions} free session request slots available.
              </p>
            </>
          )}
          <button className="bg-primary-color text-white py-2 px-4 rounded hover:bg-primary-color-hover transition-colors" onClick={() => navigate('/student/browse-mentors')}>
            <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
            Find a mentor and send a session request
          </button>
        </div>

        {/* Help Request Section */}
        <div className="bg-secondary-background-color rounded-lg shadow p-6 flex justify-between items-center mb-5">
          <div>
            <h2 className="text-xl font-semibold mb-2 flex items-center text-text-color">
              <FontAwesomeIcon icon={faQuestionCircle} className="text-primary-color mr-2" />
              Not sure where to start?
            </h2>
            <p className="mb-4 text-text-color">Create a help request and have mentors apply.</p>
          </div>
          <button className="border border-primary-color text-primary-color py-2 px-4 rounded hover:bg-hover-border-color hover:text-white transition-colors" onClick={() => navigate('/student/forum')}>
            <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
            Create Help Request
          </button>
        </div>

        {/* Dashboard Sections in a Grid Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">

          {/* Upcoming Sessions Section */}
          <div className="bg-secondary-background-color rounded-lg shadow p-6 h-80 overflow-auto">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2 flex items-center text-text-color">
                <FontAwesomeIcon icon={faCalendarAlt} className="text-primary-color mr-2" />
                Upcoming Sessions
              </h2>
              <button 
                className="text-sm text-primary-color underline"
                onClick={() => navigate('/student/my-bookings')}
              >
                View Bookings
              </button>
            </div>
            {sessions.length > 0 ? (
              <ul className="space-y-4">
                {sessions.map((session) => (
                  <li key={session._id} className="border border-border-color rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow bg-white">
                    <div className="flex items-center mb-2">
                      <FontAwesomeIcon icon={faUser} className="text-primary-color mr-2" />
                      <span className="text-lg font-semibold text-text-color">{session.instructorName}</span>
                    </div>
                    <div className="text-sm text-gray-700 mb-2">{session.formattedTime}</div>
                    <div className="text-sm text-gray-500">{session.formattedDate}</div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center py-8 border-dashed border-2 border-border-color rounded-lg">
                <p className="mb-4 text-text-color">You have no upcoming sessions booked.</p>
                <button className="border border-primary-color text-primary-color py-2 px-4 rounded hover:bg-hover-border-color hover:text-white transition-colors" onClick={() => navigate('/student/browse-sessions')}>
                  <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                  Browse Session
                </button>
              </div>
            )}
          </div>

          {/* Challenges Section */}
          <div className="bg-secondary-background-color rounded-lg shadow p-6 h-80">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2 flex items-center text-text-color">
                <FontAwesomeIcon icon={faClipboardList} className="text-primary-color mr-2" />
                Your Challenges
              </h2>
              <button 
                className="text-sm text-primary-color underline"
                onClick={() => navigate('/student/challenges')}
              >
                View Challenges
              </button>
            </div>
            <div className="flex justify-around items-center h-full">
              <div className="text-center">
                <p className="text-6xl font-extrabold text-primary-color">{challenges.completed.length}</p>
                <p className="text-text-color mt-2">Completed Challenges</p>
              </div>
              <div className="text-center">
                <p className="text-6xl font-extrabold text-primary-color">{challenges.ongoing.length}</p>
                <p className="text-text-color mt-2">Ongoing Challenges</p>
              </div>
            </div>
          </div>

          {/* Ongoing Services Section */}
          <div className="bg-secondary-background-color rounded-lg shadow p-6 h-80 overflow-auto">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2 flex items-center text-text-color">
                <FontAwesomeIcon icon={faPlayCircle} className="text-primary-color mr-2" />
                Ongoing Services
              </h2>
              <button 
                className="text-sm text-primary-color underline"
                onClick={() => navigate('/student/chat')}
              >
                Start Chatting
              </button>
            </div>
            {services.length > 0 ? (
              <ul className="space-y-4">
                {services.map((service) => (
                  <li key={service.servicePurchaseId} className="border border-border-color rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow bg-white">
                    <div className="flex items-center mb-2">
                      {service.instructor && (
                        <img src={service.instructor.profilePicture} alt="Instructor" className="w-10 h-10 rounded-full mr-2" />
                      )}
                      <div>
                        <h3 className="text-lg font-bold text-text-color">{service.serviceTitle}</h3>
                        {service.instructor && (
                          <p className="text-sm text-gray-500">{`${service.instructor.firstName} ${service.instructor.lastName}`}</p>
                        )}
                      </div>
                    </div>
                    <p className="text-text-color font-semibold">Price: ${service.price}</p>
                    {service.isActive && (
                      <button 
                        className="text-error-color text-sm underline mt-2"
                        onClick={() => endService(service)}
                      >
                        End Service
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center py-8 border-dashed border-2 border-border-color rounded-lg">
                <p className="mb-4 text-text-color">You have no ongoing services.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
