import React, { useEffect, useState, useRef } from 'react';
import { collection, addDoc, query, orderBy, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faFileAlt, faVideo, faTimesCircle, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import api from '../../utils/auth';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ChatComponent = ({ connectionId, student, instructor, hideVideoChat }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const messageEndRef = useRef(null);

  const user = student || instructor;

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await api.get('/user/user-role');
        setUserId(response.data.id);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    if (connectionId) {
      const q = query(collection(db, 'messages', connectionId, 'chat'), orderBy('timestamp'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messagesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(messagesData);
        setLoading(false);

        messagesData.forEach(async (msg) => {
          if (!msg.read && msg.senderId !== userId) {
            const messageDoc = doc(db, 'messages', connectionId, 'chat', msg.id);
            await updateDoc(messageDoc, { read: true });
          }
        });
      });

      return () => unsubscribe();
    }
  }, [connectionId, userId]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!userId) {
      console.error('User ID not found');
      return;
    }

    if (!message.trim() && !file) {
      console.error('Empty message not sent');
      return;
    }

    setUploading(true);
    let fileUrl = null;
    if (file) {
      try {
        const fileRef = ref(storage, `chat_files/${Date.now()}_${file.name}`);
        const snapshot = await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploading(false);
        return;
      }
    }

    const newMessage = {
      senderId: userId,
      message,
      fileUrl,
      fileName: file ? file.name : null,
      read: false,
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, 'messages', connectionId, 'chat'), newMessage);
    } catch (error) {
      console.error('Error sending message:', error);
    }

    setMessage('');
    setFile(null);
    setFileName('');
    setUploading(false);
  };

  const sendVideoChatLink = async () => {
    if (!userId) {
      console.error('User ID not found');
      return;
    }
  
    try {
      const response = await api.post('/videochat/generate-token', {
        connectionId: connectionId,
      });
  
      const data = response.data;
  
      if (data.token) {
        const videoChatLink = `${window.location.origin}/video-chat/${data.token}?connectionId=${connectionId}`;
        const message = `Click on this <a href="${videoChatLink}" target="_blank" style="color: blue; text-decoration: underline;">link</a> to join the video chat`;
  
        const newMessage = {
          senderId: userId,
          message,  // HTML formatted message
          timestamp: new Date(),
        };
  
        // Update chat messages state and Firestore
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        await addDoc(collection(db, 'messages', connectionId, 'chat'), newMessage);
  
        // Optionally open the link in a new tab automatically
        window.open(videoChatLink, '_blank');
      }
    } catch (error) {
      console.error('Error generating video chat link:', error);
    }
  };
  

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const removeFile = () => {
    setFile(null);
    setFileName('');
  };

  return (
    <div className="flex flex-col h-full bg-secondary-background-color rounded-lg md:w-[calc(100%)] border border-border-color w-full">
      <div className="flex items-center p-2 border-b border-border-color rounded-t-lg">
        <img
          src={user.profilePicture ? `${user.profilePicture}` : `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`}
          alt={`${user.firstName} ${user.lastName}`}
          className="rounded-full mr-2 w-10 h-10"
        />
        <span className="text-lg font-semibold flex-grow">{`${user.firstName} ${user.lastName}`}</span>
        {!hideVideoChat && (
          <FontAwesomeIcon
            icon={faVideo}
            className="text-xl cursor-pointer mr-4"
            onClick={() => sendVideoChatLink()}
          />
        )}
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        {loading ? (
          Array(10)
            .fill(0)
            .map((_, index) => (
              <div key={index} className="flex items-center p-2 my-1 rounded-lg">
                <Skeleton circle={true} height={32} width={32} className="mr-2" />
                <Skeleton width={150} height={20} />
              </div>
            ))
        ) : messages.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-full text-center text-gray-500">
            <FontAwesomeIcon icon={faCommentDots} className="text-5xl mb-4" />
            <p>No messages yet. Start the conversation now!</p>
          </div>
        ) : (
          messages.map((msg) => (
            <div
              key={msg.id || msg.timestamp}
              className={`flex items-start p-3 my-2 rounded-lg border ${
                msg.senderId === userId
                  ? 'ml-auto bg-white border-gray-300 text-right'
                  : 'mr-auto bg-white border-gray-300 text-left'
              }`}
              style={{ maxWidth: '70%', minWidth: '100px', width: 'fit-content', wordBreak: 'break-word' }}
            >
              {msg.senderId !== userId && (
                <img
                  src={user.profilePicture ? `${user.profilePicture}` : `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`}
                  alt={`${user.firstName} ${user.lastName}`}
                  className="rounded-full mr-2 w-8 h-8"
                />
              )}
              <div className="flex flex-col">
                {msg.fileUrl ? (
                  <a href={msg.fileUrl} download={msg.fileName} className="flex items-center text-blue-500 hover:underline">
                    <FontAwesomeIcon icon={faFileAlt} className="w-4 h-4 mr-1" />
                    {msg.fileName}
                  </a>
                ) : (
                  <div
                    className="text-sm break-words"
                    dangerouslySetInnerHTML={{ __html: msg.message }}
                  />

                )}
                <span className="text-xs text-gray-500 mt-1">
                  {new Date(msg.timestamp?.seconds * 1000).toLocaleTimeString()}
                </span>
              </div>
            </div>
          ))
        )}
        <div ref={messageEndRef} />
      </div>
      <form onSubmit={sendMessage} className="flex items-center p-2 border-t border-border-color">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-grow p-2 mr-2 border border-border-color rounded"
          placeholder="Type a message..."
        />
        <label htmlFor="file-upload" className="cursor-pointer">
          <FontAwesomeIcon icon={faPaperclip} className="w-6 h-6 mr-2" />
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          className="hidden"
        />
        {file && (
          <div className="flex items-center ml-2">
            <span className="text-sm text-gray-700">{fileName}</span>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-red-500 ml-2 cursor-pointer"
              onClick={removeFile}
            />
          </div>
        )}
        <button type="submit" className="p-2 bg-primary-color text-white rounded-md hover:bg-primary-color-hover">
          {uploading ? 'Uploading...' : 'Send'}
        </button>
      </form>
    </div>
  );
};

export default ChatComponent;
