import React, { useState } from 'react';
import PastSessions from './../../../components/InstructorSessions/PastSessions';
import UpcomingSessions from './../../../components/InstructorSessions/UpcomingSessions';

const InstructorSession = () => {
  const [selectedTab, setSelectedTab] = useState('upcoming');

  return (
    <div className="max-w-[1400px] m-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">
        My Sessions
      </div>
      {/* Tabs without responsive rows */}
      <div className="flex justify-start mb-4">
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'upcoming'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('upcoming')}
        >
          Upcoming Sessions
        </button>
        <button
          className={`py-2 px-4 text-base font-medium ${
            selectedTab === 'past'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('past')}
        >
          Past Sessions
        </button>
      </div>
      <div className="mt-6">
        {selectedTab === 'upcoming' && <UpcomingSessions />}
        {selectedTab === 'past' && <PastSessions />}
      </div>
    </div>
  );
};

export default InstructorSession;
