import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, onSnapshot, doc, updateDoc } from 'firebase/firestore';

const Notifications = ({ userId }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const q = query(collection(db, `users/${userId}/notifications`));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const notificationsArray = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setNotifications(notificationsArray);
        });

        return () => {
            unsubscribe(); // Cleanup on unmount
        };
    }, [userId]);

    const handleNotificationClick = async (notificationId) => {
        const notificationRef = doc(db, `users/${userId}/notifications`, notificationId);
        await updateDoc(notificationRef, { read: true });
    };

    return (
        <div className="absolute top-10 right-0 w-[300px] max-h-[400px] bg-white rounded-lg shadow-lg overflow-y-auto z-[3000] py-2">
            <h3 className="text-lg font-bold mx-2 text-gray-800">Notifications</h3>
            <ul className="list-none p-0 m-0">
                {notifications.map((notification) => (
                    <li
                        key={notification.id}
                        className={`p-2.5 mb-2 bg-white border-l-4 rounded-sm shadow-md ${!notification.read ? 'bg-[#e0f7fa]' : ''} border-blue-500 hover:bg-gray-100 cursor-pointer`}
                        onClick={() => handleNotificationClick(notification.id)}
                    >
                        <strong className="text-sm font-semibold text-blue-500 m-0">{notification.title}</strong>
                        <p className="mt-1 text-xs text-gray-600">{notification.body}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Notifications;
