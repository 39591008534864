import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMapMarkerAlt, faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

const BookingModal = ({ mentorship, instructor, studentTimezone, freeSessions, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableHours, setAvailableHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState(null);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    const adjustedAvailability = {};

    Object.keys(mentorship.availability).forEach(day => {
      const dayAvailability = mentorship.availability[day];

      if (dayAvailability.length > 0) {
        dayAvailability.forEach(slot => {
          const startTime = moment.utc(slot.startUTC);
          const endTime = moment.utc(slot.endUTC);


          if (startTime.isAfter(endTime)) {
            handleMidnightWrapAround(adjustedAvailability, startTime, endTime, studentTimezone);
          } else {
            processTimeRange(adjustedAvailability, startTime, endTime, studentTimezone);
          }
        });
      }
    });

    if (selectedDate) {
      const dayName = selectedDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
      setAvailableHours(adjustedAvailability[dayName] || []);
    }

  }, [selectedDate, mentorship.availability, studentTimezone]);


  // Function to check if a given time slot is booked
// Function to check if a given time slot is booked
const isSlotBooked = (hour, selectedDate) => {
  return instructor.bookedSessions.some(session => {
    const sessionStart = moment.utc(session.startUTC).tz(studentTimezone);
    const sessionEnd = moment.utc(session.endUTC).tz(studentTimezone);

    // Check if the selected date matches the session date
    const isSameDate = sessionStart.isSame(selectedDate, 'day');
    
    // Check if the hour is within the booked session's time
    return isSameDate && hour >= sessionStart.hour() && hour < sessionEnd.hour();
  });
};



  const handleMidnightWrapAround = (adjustedAvailability, startTime, endTime, studentTimezone) => {
    const midnight = startTime.clone().endOf('day').utc();
    processTimeRange(adjustedAvailability, startTime, midnight, studentTimezone);

    const nextDayMidnight = endTime.clone().startOf('day').utc();
    processTimeRange(adjustedAvailability, nextDayMidnight, endTime, studentTimezone);
  };

  const processTimeRange = (adjustedAvailability, startTime, endTime, studentTimezone) => {
    let currentTime = startTime.clone();
    while (currentTime.isBefore(endTime)) {
      addTimeSlot(adjustedAvailability, currentTime, studentTimezone);
      currentTime.add(1, 'hour');
    }
  };

  const addTimeSlot = (adjustedAvailability, currentTime, studentTimezone) => {
    const studentTime = currentTime.clone().tz(studentTimezone);
    const dayName = studentTime.format('dddd').toLowerCase();

    if (!adjustedAvailability[dayName]) {
      adjustedAvailability[dayName] = [];
    }


    adjustedAvailability[dayName].push({
      start: studentTime.hour(),
      end: (studentTime.hour() + 1) % 24,
    });
  };

  const convertTo12HourFormat = (hour) => {
    const period = hour >= 12 ? 'PM' : 'AM';
    const adjustedHour = hour % 12 || 12; // Adjust hour to 12-hour format
    return { hour: adjustedHour, period };
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowError(false); // Reset error when date changes
  };

  const handleHourClick = (hour) => {
    setSelectedHour(hour);
    setShowError(false); // Reset error when time is selected
  };

  const handleProceedToPayment = (event) => {
    event.preventDefault();
    if (selectedHour) {
      navigate('/student/session-payment', { 
        state: { 
          mentorship, 
          instructor, 
          selectedDate, 
          selectedHour, 
          studentTimezone  // Pass the student timezone
        } 
      });
    } else {
      setShowError(true);
    }
  };

  const handleUseFreeSession = (event) => {
    event.preventDefault();
    navigate('/student/session-payment', {
      state: {
        mentorship,
        instructor,
        selectedDate,
        selectedHour,
        studentTimezone,
        useFreeSession: true,  // Indicate it's a free session
      },
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[90000]">
      <div className="bg-white rounded-lg w-[600px] p-5 shadow-lg relative ">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-semibold pb-2">Book A Call</div>
          <FontAwesomeIcon icon={faTimes} className="cursor-pointer text-lg" onClick={onClose} />
        </div>

        {/* Conditionally render the message if instructor does not offer free sessions */}
        {!instructor.freeSession && (
          <div className="text-sm text-red-500 pb-4">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            This instructor is not offering free sessions.
          </div>
        )}

        <div className="text-sm text-green-600 flex items-center pb-4">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          You can book {freeSessions} free session{freeSessions !== 1 ? 's' : ''} this month
        </div>
        <div className="text-sm font-normal opacity-40 pb-6">Book a one-hour call with the selected mentor.</div>
        <div className="flex flex-col">
          <div className="flex items-center border border-border-color rounded-lg p-5 mb-10">
            <img src={`${instructor.profilePicture}`} alt="Profile" className="rounded-full w-12 h-12 mr-4" />
            <div>
              <h3 className="text-lg">{instructor.firstName} {instructor.lastName}</h3>
              <p className="text-sm text-gray-500">{new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} . local time</p>
              <p className="text-sm text-gray-500"><FontAwesomeIcon icon={faMapMarkerAlt} /> {instructor.location}</p>
            </div>
            <div className="ml-auto text-2xl font-bold text-[#107F9A]">
              {mentorship.price}£
            </div>
          </div>
          <div className="flex justify-between overflow-hidden mb-5">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              minDate={new Date()}
              inline
              className="flex-shrink-0 w-[40%]"  // Make calendar narrower
            />
            <div className="flex flex-col justify-start p-2 border border-border-color rounded-lg flex-grow ml-4">  {/* Adjust width of availability hours */}
              <div className="text-lg font-semibold mb-2">Availability Hours</div>
              {availableHours.length > 0 ? (
                <div className="flex flex-col items-center max-h-48 overflow-y-auto border border-gray-300 rounded-lg p-2 w-full">
                  {availableHours.map((hour, index) => {
                    const { hour: startHour, period: startPeriod } = convertTo12HourFormat(hour.start);
                    const { hour: endHour, period: endPeriod } = convertTo12HourFormat(hour.end);

                    const isBooked = isSlotBooked(hour.start, selectedDate); // Check if the slot is booked on the selected date

                    return (
                      <div
                        key={index}
                        className={`w-4/5 p-2 rounded text-center mb-1 border border-border-color flex justify-center items-center transition-none ${
                          isBooked ? 'bg-gray-300 cursor-not-allowed' : selectedHour && selectedHour.start === hour.start ? 'bg-[#107F9A] text-white' : 'hover:bg-gray-100'
                        }`}
                        onClick={() => !isBooked && handleHourClick(hour)}
                      >
                        {startHour} {startPeriod} - {endHour} {endPeriod}
                      </div>
                    );
                  })}


                </div>
              ) : (
                <div className="text-center text-gray-500">No available hours for the selected date.</div>
              )}
            </div>
          </div>
        </div>
        {showError && <div className="text-red-500 text-center mt-2">Please select any available time first</div>}
        <div className="pt-10 flex space-x-4">
          {/* Only show the free session button if the instructor allows free sessions and the student has free sessions */}
          <button
            className={`py-4 px-6 rounded flex-grow-0 font-medium text-lg ${
              freeSessions > 0 && instructor.freeSession ? 'bg-green-500 text-white hover:bg-green-600' : 'bg-gray-400 text-white cursor-not-allowed'
            }`}
            onClick={handleUseFreeSession}
            disabled={freeSessions === 0 || !instructor.freeSession}
          >
            Use Free Session
          </button>
          <button
            className="bg-[#107F9A] text-white py-4 px-6 rounded flex-grow font-medium text-lg hover:bg-[#106f8a]"
            onClick={handleProceedToPayment}
          >
            <FontAwesomeIcon icon={faArrowRight} /> Proceed to Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;