import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import styles from './SignIn.module.css';
import api from '../../utils/auth';
import 'font-awesome/css/font-awesome.min.css';
import { ClipLoader } from 'react-spinners';
import { BASE_URL } from '../../Constants';
import { Link } from 'react-router-dom';


const SignIn = () => {
  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar); // Use the class from module CSS

    // Cleanup by removing class when component unmounts
    return () => {
      document.body.classList.remove(styles.customScrollbar); // Use the class from module CSS
    };
  }, []);


  const onboardingImage = `${process.env.PUBLIC_URL}/assets/images/onboarding.jpg`; 
  const backbutton = `${process.env.PUBLIC_URL}/assets/icons/back-button.png`; 
  const logo = `${process.env.PUBLIC_URL}/assets/logos/Navigrated_logo.png`; 

  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState('student');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [imageLoading, setImageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'Email address should not be empty';
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!password) {
      newErrors.password = 'Password should not be empty';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getForgotPasswordLink = () => {
    if (role === 'student') {
      return '/student/forgot-password';
    } else if (role === 'instructor') {
      return '/mentor/forgot-password';
    } else {
      console.error(`Unsupported role: ${role}`);
      return '#'; // Fallback if role is unsupported
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await api.post(`/auth/login`, { email, password, role });

        setGeneralError('');
        if (role === 'student') {
          navigate('/student/dashboard');
        } else {
          navigate('/mentor/dashboard');
        }
      } catch (error) {
        if (error.response) {
          const errorMsg = error.response.data.msg;
          if (error.response.status === 400 || error.response.status === 403) {
            setGeneralError(errorMsg);
            if (error.response.status === 403) {
              Swal.fire('Error!', errorMsg, 'error');
            }
          } else {
            console.error('There was an error submitting the form:', error);
          }
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const handleSignUpClick = () => {
    navigate('/');
  };

  const handleGoogleSignIn = () => {
    window.location.href = `${BASE_URL}/auth/google`;
  };

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.spinnerOverlay}>
          <ClipLoader color="var(--primary-color)" loading={loading} size={50} />
        </div>
      )}
      <div className={styles.leftColumn}>
        <div className={styles.goBackIcon} onClick={handleGoBack} style={{ cursor: 'pointer' }}>
          <img src={backbutton} alt="Go Back" />
        </div>
        <div className={styles.header}>
          <div className={styles.logoDiv}>
            {imageLoading && (
              <div className={styles.logoSpinnerOverlay}>
                <ClipLoader color="var(--primary-color)" loading={imageLoading} size={50} />
              </div>
            )}
            <img
              src={logo}
              alt="Logo"
              className={`${styles.logo} ${imageLoading ? styles.hiddenImage : styles.visibleImage}`}
              onLoad={() => setImageLoading(false)}
            />
          </div>
          <div className={styles.roleSwitch}>
            <button
              className={`${styles.roleButton} ${role === 'student' ? styles.activeRole : ''}`}
              onClick={() => setRole('student')}
            >
              Student
            </button>
            <button
              className={`${styles.roleButton} ${role === 'instructor' ? styles.activeRole : ''}`}
              onClick={() => setRole('instructor')}
            >
              Mentor
            </button>
          </div>
          <h1 className={styles.h1}>Sign in as a {role.charAt(0).toUpperCase() + role.slice(1)}</h1>
          <form className={styles.signupForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                className={styles.inputField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className={styles.errorMessage}>{errors.email}</p>}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.passwordLabel}>
                Password
              </label>
              <div className={styles.passwordWrapper}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className={styles.inputField}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles.passwordToggle}`}
                  onClick={togglePasswordVisibility}
                ></i>
              </div>
              {errors.password && <p className={styles.errorMessage}>{errors.password}</p>}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to={getForgotPasswordLink()} className={styles.forgotPassword}>Forgot password?</Link>
              </div>
            </div>
            {errors.terms && <p className={styles.errorMessage}>{errors.terms}</p>}
            <div className={styles.buttonSpace}></div>
            {generalError && <p className={styles.signupErrorMessage}>{generalError}</p>}
            <button type="submit" className={styles.continueButton}>
              Sign in
            </button>
            {role === 'student' && (
              <button
                type="button"
                className={styles.googleSignupButton}
                onClick={handleGoogleSignIn}
              >
                <i className={`fa fa-google ${styles.googleSignupButtonIcon}`}></i> Sign in with Google
              </button>
            )}
            <p className={styles.signIn}>
              Don't have an account?{' '}
              <span onClick={handleSignUpClick} className={styles.signInLink} style={{ cursor: 'pointer' }}>
                Sign up
              </span>
            </p>
          </form>
        </div>
      </div>



      {/* Right Column */}
      <div className={styles.rightColumn}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img src={onboardingImage} alt="Onboarding" />
            <div className={styles.overlay}></div>
            <div className={styles.blackOverlay}></div>
            <div className={styles.textOverlay}>
  <h2>Why Choose Navigrated?</h2>
  <p>
    Our mission with Navigrated is to help international students and professionals secure industry-relevant jobs and build successful ventures in the UK - through the power of community mentorship.
  </p>
  <ul className={styles.featuresList}>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Expert-led sessions</b></span>
      </div>
      <div className={styles.nonBoldText}>
        on landing sponsored jobs, entrepreneurship, and overcoming immigration challenges.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Mentor-led daily group calls</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Gain insights from professionals who have built careers at companies like Deloitte, KPMG, Loreal, Rolls Royce, etc.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>CV reviews</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Tools and challenges built to help you stand out in the competitive job market.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Build your network</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Attend both virtual and in-person events to expand your professional circle.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Success stories</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Be inspired by real experiences from mentors who’ve faced and overcome the same challenges you are now facing.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Exclusive insights</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Access templates, resources, and webinars designed to fast-track your UK career journey.
      </div>
    </li>
  </ul>
</div>




          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
