import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import api from '../../utils/auth';
import { FaFilePdf, FaFileAlt, FaFileImage, FaPlayCircle } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MyChallenges = () => {
  const [enrolledChallenges, setEnrolledChallenges] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [uploading, setUploading] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalDimensions, setModalDimensions] = useState({ width: '60%', height: '60%' });
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [descriptionContent, setDescriptionContent] = useState('');
  const [modalPosition, setModalPosition] = useState({ top: '50%', left: '50%' });
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchEnrolledChallenges = useCallback(async () => {
    try {
      const response = await api.get('/student-challenges/challenges/enrolled');
      const { ongoing } = response.data;
      setEnrolledChallenges(ongoing);
    } catch (error) {
      console.error('Error fetching enrolled challenges:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEnrolledChallenges();
  }, [fetchEnrolledChallenges]);

  const formatTimeUntilEnd = (endDate) => {
    const now = moment();
    const difference = moment(endDate).diff(now);
    const duration = moment.duration(difference);

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (difference <= 0) {
      return 'Challenge Ended';
    } else {
      return `Ends in ${days}d ${hours}h ${minutes}m`;
    }
  };

  const getFileType = (fileUrl) => {
    const extension = fileUrl.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'];
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv'];

    if (imageExtensions.includes(extension)) return 'image';
    if (videoExtensions.includes(extension)) return 'video';
    if (extension === 'pdf') return 'pdf';
    return 'file';
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'image':
        return <FaFileImage className="text-5xl text-primary-color" />;
      case 'video':
        return <FaPlayCircle className="text-5xl text-primary-color" />;
      case 'pdf':
        return <FaFilePdf className="text-5xl text-primary-color" />;
      default:
        return <FaFileAlt className="text-5xl text-primary-color" />;
    }
  };

  const handleFilePreview = (checkpoint) => {
    setModalContent(checkpoint);
    setShowModal(true);
  };

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    adjustModalDimensions(naturalWidth, naturalHeight);
  };

  const handleVideoLoad = (e) => {
    const video = e.target;
    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;
    adjustModalDimensions(videoWidth, videoHeight);
  };

  const adjustModalDimensions = (width, height) => {
    const maxWidth = window.innerWidth * 0.9;
    const maxHeight = window.innerHeight * 0.9;
    let newWidth = width;
    let newHeight = height;

    if (width > maxWidth || height > maxHeight) {
      const widthRatio = maxWidth / width;
      const heightRatio = maxHeight / height;
      const bestRatio = Math.min(widthRatio, heightRatio);
      newWidth = width * bestRatio;
      newHeight = height * bestRatio;
    }

    setModalDimensions({ width: `${newWidth}px`, height: `${newHeight}px` });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, challengeId) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [challengeId]: file,
    }));
  };

  const handleFileChange = (e, challengeId) => {
    const file = e.target.files[0];
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [challengeId]: file,
    }));
  };

  const handleTaskUpload = async (challengeId, currentMilestoneIndex) => {
    if (!selectedFiles[challengeId] || uploading[challengeId]) return;

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to upload this file?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, upload it!',
    });

    if (result.isConfirmed) {
      setUploading((prev) => ({ ...prev, [challengeId]: true }));

      Swal.fire({
        title: 'Uploading...',
        text: 'Please wait while your file is being uploaded.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const formData = new FormData();
      formData.append('milestoneFile', selectedFiles[challengeId]);
      formData.append('milestoneIndex', currentMilestoneIndex);
      formData.append('challengeId', challengeId);

      try {
        await api.post('/student-challenges/challenges/submit-milestone', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        Swal.fire('Uploaded!', 'Your task has been successfully uploaded.', 'success');

        setSelectedFiles((prevFiles) => ({
          ...prevFiles,
          [challengeId]: null,
        }));

        setUploading((prev) => ({ ...prev, [challengeId]: false }));

        fetchEnrolledChallenges();
      } catch (error) {
        console.error('Error uploading task:', error);
        Swal.fire('Error', 'There was a problem uploading your task. Please try again later.', 'error');
        setUploading((prev) => ({ ...prev, [challengeId]: false }));
      }
    }
  };

  const handleDescriptionClick = (quillContent, challengeTitle, milestoneTitle) => {
    setDescriptionContent({
        quillContent: quillContent,
        challengeTitle: challengeTitle,
        milestoneTitle: milestoneTitle
    });
    setShowDescriptionModal(true);
};


  if (loading) {
    // Skeleton loader for loading state
    return (
      <div className="">
        <div className="flex flex-wrap gap-5">
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              className="bg-white w-full sm:w-[220px] md:w-[300px] lg:w-[500px] rounded-lg p-5 shadow-md flex flex-col min-h-[550px] relative overflow-hidden"
            >
              <Skeleton height={200} className="rounded-lg" />
              <Skeleton height={20} width={`60%`} className="my-2" />
              <Skeleton height={10} width={`40%`} className="mb-2" />
              <Skeleton height={10} width={`80%`} className="mb-2" />
              <Skeleton height={15} width={`50%`} className="mb-2" />
              <Skeleton height={15} width={`70%`} className="mb-2" />
              <Skeleton height={15} width={`100%`} className="mb-2" />
              <Skeleton height={15} width={`90%`} className="mb-2" />
              <Skeleton height={40} className="mt-3" />
            </div>
          ))}
        </div>
      </div>
    );
  }
  

  return (
    <div className="max-w-8xl mx-auto px-4">
      <div className="flex flex-wrap gap-5 justify-start">
        {enrolledChallenges.map(({ challengeId, milestones, isCompleted }) => {
          const completedMilestones = milestones.length;
          const totalMilestones = challengeId.checkpoints.length;
          const currentMilestoneIndex = completedMilestones;
          const hasStarted = new Date(challengeId.startDate) <= new Date();
          const formattedTime = formatTimeUntilEnd(new Date(challengeId.endDate));
          const isButtonDisabled = !hasStarted || uploading[challengeId._id];

          const currentCheckpoint = challengeId.checkpoints[currentMilestoneIndex];
          const fileType = currentCheckpoint?.fileUrl ? getFileType(currentCheckpoint.fileUrl) : null;

          return (
            <div
              key={challengeId._id}
              className={`bg-white rounded-lg p-5 shadow-md flex flex-col min-h-[550px] w-full sm:w-[320px] md:w-[350px] relative overflow-hidden ${

                isCompleted ? 'opacity-50' : ''
              }`}
            >
              {isCompleted && (
                <div className="absolute top-2 right-2 bg-primary-color text-white px-4 py-1 rounded-full font-semibold text-xs shadow-md">
                  Completed
                </div>
              )}

              <div className="h-[200px] w-full overflow-hidden mb-2.5 rounded-lg flex items-center justify-center bg-gray-200">
                {currentCheckpoint?.fileUrl ? (
                  <div className="cursor-pointer" onClick={() => handleFilePreview(currentCheckpoint)}>
                    {fileType === 'image' ? (
                      <img
                        src={currentCheckpoint.fileUrl}
                        alt="Checkpoint Resource"
                        className="text-[80px] text-primary-color"
                        onLoad={handleImageLoad}
                      />
                    ) : (
                      getFileIcon(fileType)
                    )}
                  </div>
                ) : (
                  <img src={challengeId.image} alt={challengeId.title} className="w-[300px] h-[300px] object-cover" />
                )}
              </div>

              <h3 className="text-lg font-bold text-left mb-1">{challengeId.title}</h3>

              <div className="w-full h-2 bg-gray-300 rounded-full mb-2.5">
                <div
                  className="h-full bg-primary-color rounded-full"
                  style={{ width: `${(completedMilestones / totalMilestones) * 100}%` }}
                />
              </div>

              <div className="flex-1">
                <h3>{currentCheckpoint?.title || 'No more milestones'}</h3>
                <div className="flex justify-between mb-2.5 text-sm">
                  <span>{formattedTime}</span>
                  <span>{completedMilestones} Completed</span>
                </div>

                <span
                  className="inline-block text-primary-color cursor-pointer underline text-sm mb-2.5"
                  onClick={() => handleDescriptionClick(currentCheckpoint?.quillContent, challengeId.title, currentCheckpoint?.title)}
              >
                  Show Task Objectives
              </span>


                <div className="flex-grow overflow-y-auto pr-1.5 max-h-[200px] mb-3.5">
                  {challengeId.checkpoints.map((checkpoint, index) => (
                    <div key={index} className="flex items-center relative pl-10 mb-2.5">
                      <div className="relative w-8 h-8 flex items-center justify-center">
                        {index < currentMilestoneIndex ? (
                          <span className="flex items-center justify-center text-sm w-8 h-8 bg-primary-color text-white rounded-full">
                            ✔
                          </span>
                        ) : index === currentMilestoneIndex ? (
                          <span className="flex items-center justify-center w-5 h-5 bg-white border-2 border-primary-color rounded-full"></span>
                        ) : (
                          <span className="text-gray-600 text-sm">🔒</span>
                        )}
                      </div>
                      <div className="absolute left-[14px] w-[2px] h-full bg-gray-300 border-l-2 border-dotted border-gray-600 z-[-1]"></div>
                      <div className="flex flex-col ml-2.5">
                        <div className="font-bold mb-1">{checkpoint.title}</div>
                        <div className="text-sm text-gray-700 truncate max-w-[180px]">{checkpoint.description}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Upload Section moved to the bottom */}
              {currentMilestoneIndex < totalMilestones && (
                <div className="mt-auto">
                  <div
                    className={`border-2 border-dashed border-gray-300 rounded-md p-5 text-center cursor-pointer mb-3.5 transition-opacity duration-200 relative ${
                      !hasStarted ? 'cursor-not-allowed opacity-50 border-gray-600' : ''
                    }`}
                    onDragOver={hasStarted ? handleDragOver : undefined}
                    onDrop={hasStarted ? (e) => handleDrop(e, challengeId._id) : undefined}
                  >
                    <input
                      type="file"
                      id={`fileInput-${challengeId._id}`}
                      className="hidden"
                      onChange={hasStarted ? (e) => handleFileChange(e, challengeId._id) : undefined}
                      disabled={!hasStarted}
                    />
                    <label
                      htmlFor={`fileInput-${challengeId._id}`}
                      className="flex flex-col items-center justify-center h-full"
                    >
                      {selectedFiles[challengeId._id] ? (
                        <div className="flex items-center justify-center">
                          <span>{selectedFiles[challengeId._id]?.name}</span>
                          <button
                            className="bg-none border-none text-red-600 text-lg cursor-pointer ml-2"
                            onClick={() =>
                              setSelectedFiles((prevFiles) => ({
                                ...prevFiles,
                                [challengeId._id]: null,
                              }))
                            }
                          >
                            ✕
                          </button>
                        </div>
                      ) : (
                        <div className="text-lg text-gray-600">
                          {hasStarted
                            ? 'Drag & drop a file here, or click to select a file'
                            : 'Upload available when the challenge starts'}
                        </div>
                      )}
                    </label>
                  </div>

                  <button
                    className={`bg-primary-color text-white py-2 px-4 rounded-md cursor-pointer w-full ${
                      isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={() => handleTaskUpload(challengeId._id, currentMilestoneIndex)}
                    disabled={isButtonDisabled}
                    style={{ width: '100px', height: '40px' }} // Maintain button dimensions
                  >
                    {uploading[challengeId._id] ? (
                      <div className="loader" style={{ width: '20px', height: '20px' }}></div>
                    ) : (
                      "Upload your task"
                    )}
                  </button>
                </div>
              )}
            </div>
          );
        })}

        {/* Placeholder to keep symmetry */}
        {enrolledChallenges.length % 2 !== 0 && (
          <div className="w-full sm:w-[320px] md:w-[350px]"></div>
        )}
      </div>

      {showModal && modalContent && (
        <div
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg z-50 overflow-hidden flex flex-col max-w-[90%] max-h-[90%]"

          style={modalDimensions}
        >
          <div className="p-2.5 flex justify-between items-center border-b border-gray-200">
            <h3>File preview</h3>
            <button
              className="bg-none border-none text-2xl cursor-pointer"
              onClick={() => setShowModal(false)}
            >
              ✕
            </button>
          </div>
          <div className="flex-grow flex justify-center items-center p-2 overflow-y-visible h-full w-full">
            {modalContent.fileType === 'image' ? (
              <img src={modalContent.fileUrl} alt="Checkpoint Resource" className="w-full h-full object-fill" onLoad={handleImageLoad} />
            ) : modalContent.fileType === 'video' ? (
              <video controls className="w-full h-full object-fill" onLoadedMetadata={handleVideoLoad}>
                <source src={modalContent.fileUrl} type={`video/${modalContent.fileUrl.split('.').pop()}`} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <iframe src={modalContent.fileUrl} title="Document" className="w-full h-full object-fill" />
            )}
          </div>
        </div>
      )}

        {showDescriptionModal && (
            <div className="fixed inset-0 flex items-center justify-center z-[80001] bg-black bg-opacity-50">
                <div className="relative bg-white rounded-lg shadow-lg w-[90vw] max-w-2xl transition-transform transform translate-y-0 scale-100">
                    <div className="p-5 border-b border-gray-200">
                        <h3 className="text-lg font-bold text-primary-color">
                            {descriptionContent.milestoneTitle} - {descriptionContent.challengeTitle}
                        </h3>
                        <button
                            className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-900"
                            onClick={() => setShowDescriptionModal(false)}
                        >
                            ✕
                        </button>
                    </div>

                    <div className="p-6 text-sm overflow-y-auto max-h-[60vh]">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(descriptionContent.quillContent)
                            }}
                            className="prose"
                        />
                    </div>

                    <div className="p-4 flex justify-end border-t border-gray-200">
                        <button
                            className="bg-primary-color text-white px-5 py-2 rounded-md"
                            onClick={() => setShowDescriptionModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        )}

    </div>
  );
};

export default MyChallenges;
