import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styles from './ResetPassword.module.css';
import api from '../../utils/auth';
import { ClipLoader } from 'react-spinners'; // Import the spinner component


const StudentResetPassword = () => {
  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar); // Use the class from module CSS

    // Cleanup by removing class when component unmounts
    return () => {
      document.body.classList.remove(styles.customScrollbar); // Use the class from module CSS
    };
  }, []);

  const onboardingImage = `${process.env.PUBLIC_URL}/assets/images/onboarding.jpg`; 
  const backbutton = `${process.env.PUBLIC_URL}/assets/icons/back-button.png`; 
  const logo = `${process.env.PUBLIC_URL}/assets/logos/Navigrated_logo.png`; 

  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [role] = useState('student');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for form submission

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setLoading(true); // Set loading state to true when form is submitted
    try {
      const response = await api.post(`/auth/reset-password?token=${token}`, { password, role });
      setMessage(response.data.message);
      setError('');
    } catch (error) {
      setError('Failed to reset password. Please try again.');
      setMessage('');
    } finally {
      setLoading(false); // Set loading state to false once the form submission is complete
    }
  };

  const handleGoBack = () => {
    navigate('/sign-in');
  };

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.spinnerOverlay}>
          <ClipLoader color="var(--primary-color)" loading={loading} size={50} />
        </div>
      )}
      {/* Left Column */}
      <div className={styles.leftColumn}>
      <div
          className={styles.goBackIcon}
          onClick={handleGoBack}
          style={{ cursor: 'pointer' }}
        >
          <img src={backbutton} alt="Go Back" />
        </div>
        <div className={styles.header}>
          <div className={styles.logoDiv}>
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
          <h1 className={styles.h1}>Enter new password</h1>
          <form className={styles.signupForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.label}>Password</label>
              <input
                type="password"
                id="password"
                className={styles.inputField}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="confirmPassword" className={styles.label}>Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                className={styles.inputField}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className={styles.buttonSpace}></div>
            {message && <p className={styles.successMessage}>{message}</p>}
            {error && <p className={styles.errorMessage}>{error}</p>}
            <button type="submit" className={styles.continueButton}>
              Reset Password
            </button>
          </form>
        </div>
      </div>
  
      {/* Right Column */}
      <div className={styles.rightColumn}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img src={onboardingImage} alt="Onboarding" />
            <div className={styles.overlay}></div>
            <div className={styles.blackOverlay}></div>
            <div className={styles.textOverlay}>
  <h2>Why Choose Navigrated?</h2>
  <p>
    Our mission with Navigrated is to help international students and professionals secure industry-relevant jobs and build successful ventures in the UK - through the power of community mentorship.
  </p>
  <ul className={styles.featuresList}>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Expert-led sessions</b></span>
      </div>
      <div className={styles.nonBoldText}>
        on landing sponsored jobs, entrepreneurship, and overcoming immigration challenges.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Mentor-led daily group calls</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Gain insights from professionals who have built careers at companies like Deloitte, KPMG, Loreal, Rolls Royce, etc.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>CV reviews</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Tools and challenges built to help you stand out in the competitive job market.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Build your network</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Attend both virtual and in-person events to expand your professional circle.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Success stories</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Be inspired by real experiences from mentors who’ve faced and overcome the same challenges you are now facing.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Exclusive insights</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Access templates, resources, and webinars designed to fast-track your UK career journey.
      </div>
    </li>
  </ul>
</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentResetPassword;
