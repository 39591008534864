import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import api from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons'; // Import both solid and regular icons
import { fetchCsrfToken } from '../../utils/csrf';

const RequestModal = ({ isOpen, onRequestClose, request }) => {
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`; // Default profile image path
  const [newAnswer, setNewAnswer] = useState('');
  const [answers, setAnswers] = useState([]);
  const [viewComments, setViewComments] = useState({});
  const [answersDisplayed, setAnswersDisplayed] = useState(3);
  const [commentsDisplayed, setCommentsDisplayed] = useState({});
  const [instructorProfileImage, setInstructorProfileImage] = useState(defaultProfileImage);
  const [isInstructor, setIsInstructor] = useState(false); // State to check if the user is an instructor

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfToken = await fetchCsrfToken();
        const response = await api.get('/help-forum/me', {
          headers: {
            'CSRF-Token': csrfToken,
          },
        });
        const user = response.data;
        setIsInstructor(user.role === 'instructor');
        setInstructorProfileImage(user.profilePicture ? `${user.profilePicture}` : defaultProfileImage);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchAnswersWithLikeStatus = async () => {
      try {
        const response = await api.get(`/help-forum/answers/${request._id}/likes`);
        const sortedAnswers = response.data.sort((a, b) => b.likes.length - a.likes.length);
        setAnswers(sortedAnswers);
      } catch (error) {
        console.error('Error fetching answers with like status:', error);
      }
    };

    fetchData();
    fetchAnswersWithLikeStatus();
  }, [request._id]);

  const handleAddAnswer = async () => {
    try {
      const response = await api.post('/help-forum/answer', {
        helpForumId: request._id,
        content: newAnswer,
      });
      const newAnswers = [...answers, { ...response.data, comments: [], likes: [], isLiked: false }];
      const sortedAnswers = newAnswers.sort((a, b) => b.likes.length - a.likes.length);
      setAnswers(sortedAnswers);
      setNewAnswer('');
    } catch (error) {
      console.error('Error adding answer:', error);
    }
  };

  const handleAddComment = async (answerId, comment) => {
    if (!comment) return; // Don't submit if the comment is empty
    try {
      const response = await api.post('/help-forum/comment', {
        answerId,
        content: comment,
      });
      const updatedAnswers = answers.map((answer) => {
        if (answer._id === answerId) {
          return { ...answer, comments: [...(answer.comments || []), response.data] };
        }
        return answer;
      });
      setAnswers(updatedAnswers);
      setViewComments((prevState) => ({
        ...prevState,
        [answerId]: {
          ...prevState[answerId],
          newComment: '',
        },
      }));
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleLike = async (answerId) => {
    try {
      const response = await api.post('/help-forum/like', { answerId });
      const updatedAnswers = answers.map((answer) => {
        if (answer._id === answerId) {
          const alreadyLiked = answer.isLiked;
          const newLikesCount = alreadyLiked ? answer.likes.length - 1 : answer.likes.length + 1;
          return { ...answer, likes: Array(newLikesCount).fill({ _id: 'dummy' }), isLiked: !alreadyLiked }; // Update likes count and status
        }
        return answer;
      });
      const sortedAnswers = updatedAnswers.sort((a, b) => b.likes.length - a.likes.length);
      setAnswers(sortedAnswers);
    } catch (error) {
      console.error('Error liking answer:', error);
    }
  };

  const toggleComments = (answerId) => {
    setViewComments((prevState) => ({
      ...prevState,
      [answerId]: !prevState[answerId],
      newComment: '', // Reset comment input when toggling comments
    }));
    setCommentsDisplayed((prevState) => ({
      ...prevState,
      [answerId]: 3,
    }));
  };

  const loadMoreAnswers = () => {
    setAnswersDisplayed(answersDisplayed + 3);
  };

  const loadMoreComments = (answerId) => {
    setCommentsDisplayed((prevState) => ({
      ...prevState,
      [answerId]: prevState[answerId] + 3,
    }));
  };

  const getProfileImage = (profilePicture) => {
    return profilePicture ? `${profilePicture}` : defaultProfileImage;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Help Request Details"
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-[90%] bg-white rounded-lg overflow-y-auto shadow-lg z-[3000] w-full max-w-[1000px]"
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-[80000]"
    >
      <div className="bg-white w-full">
        <div className="flex justify-end p-4">
          <button onClick={onRequestClose} className="bg-none border-none text-2xl cursor-pointer">
            ×
          </button>
        </div>
        <div className="max-w-[80%] mx-auto relative">
        {/* Updated title styling */}
        <h2 className="mb-4 text-xl font-semibold text-primary-color break-words border-b border-border-color pb-2">
          {request?.helpRequest || 'No request title available'}
        </h2>
          <p className="font-bold mb-2 text-lg border-b border-border-color pb-2">What is your main challenge?</p>
          <p className="mb-4 break-words">{request.mainChallenge}</p>
          <p className="font-bold mb-2 text-lg border-b border-border-color pb-2">What have you tried so far?</p>
          <p className="mb-4 break-words">{request.triedSoFar}</p>
          <p className="font-bold mb-2 text-lg border-b border-border-color pb-2">What is the desired outcome?</p>
          <p className="mb-4 break-words">{request.desiredOutcome}</p>
        </div>
        <div className="flex items-center gap-3 justify-end max-w-[80%] mx-auto py-8">
          <img src={getProfileImage(request.student?.profilePicture)} alt="Profile" className="w-[50px] h-[50px] rounded-full bg-gray-300" />
          <div className="flex flex-col">
            <p className="font-bold break-words">{`${request.student?.firstName} ${request.student?.lastName}`}</p>
            <div className="flex gap-2 mt-1">
              {request.tags.map((tag, index) => (
                <span key={index} className="bg-gray-200 px-2 py-1 rounded-full text-primary-color border border-primary-color text-sm break-words">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#f7f8fa] w-full pb-8">
        <div className="max-w-[80%] mx-auto">
          <h3 className="pt-4 pb-4 text-lg bg-[#f7f8fa]">Answers</h3>
          {answers.length === 0 && <p className="text-red-500 text-sm mt-5">No Mentor answered yet</p>}
          {answers.slice(0, answersDisplayed).map((answer) => (
            <div key={answer._id} className="py-4 pl-0 border-b border-border-color bg-[#f7f8fa]">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center gap-2">
                  <img src={getProfileImage(answer.instructor?.profilePicture)} alt="Profile" className="w-[30px] h-[30px] rounded-full bg-gray-300" />
                  <p className="break-words">{`${answer.instructor?.firstName} ${answer.instructor?.lastName}`}</p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    className={`bg-none border-none cursor-pointer text-lg transition-transform duration-300 ${
                      answer.isLiked ? 'text-primary-color' : 'text-gray-500'
                    }`}
                    onClick={() => handleLike(answer._id)}
                  >
                    <FontAwesomeIcon icon={answer.isLiked ? faThumbsUpSolid : faThumbsUp} />
                    <span className="ml-2">{answer.likes ? answer.likes.length : 0}</span>
                  </button>
                </div>
              </div>
              <p className="break-words">{answer.content}</p>
              <button className="bg-none border-none cursor-pointer text-primary-color text-sm mt-3" onClick={() => toggleComments(answer._id)}>
                {viewComments[answer._id] ? 'Hide Comments' : 'View Comments'}
              </button>
              {viewComments[answer._id] && (
                <div className="mt-3 ml-6 bg-gray-200 rounded-lg p-2">
                  {answer.comments.slice(0, commentsDisplayed[answer._id] || 3).map((comment) => (
                    <div key={comment._id} className="flex items-start gap-2 pt-3 pb-3 border-b border-border-color mx-auto">
                      <img src={getProfileImage(comment.user?.profilePicture)} alt="Profile" className="w-[20px] h-[20px] rounded-full bg-gray-300" />
                      <div className="w-full">
                        <p className="m-0 break-words">
                          <strong>{`${comment.user?.firstName} ${comment.user?.lastName}`}</strong>
                        </p>
                        <p className="m-0 break-words">{comment.content}</p>
                      </div>
                    </div>
                  ))}
                  {answer.comments.length > (commentsDisplayed[answer._id] || 3) && (
                    <button className="border-none cursor-pointer text-primary-color text-sm font-normal mt-2 pb-3" onClick={() => loadMoreComments(answer._id)}>
                      View More Comments
                    </button>
                  )}
                  <div className="flex items-center bg-gray-200 p-3 rounded-lg">
                    <input
                      type="text"
                      placeholder="Add a comment..."
                      value={viewComments[answer._id]?.newComment || ''}
                      onChange={(e) => setViewComments((prevState) => ({
                        ...prevState,
                        [answer._id]: {
                          ...prevState[answer._id],
                          newComment: e.target.value
                        }
                      }))}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddComment(answer._id, viewComments[answer._id]?.newComment || '');
                        }
                      }}
                      className="w-full border-none p-3 rounded-lg outline-none bg-white break-words"
                    />
                    <button
                      onClick={() => {
                        handleAddComment(answer._id, viewComments[answer._id]?.newComment || '');
                      }}
                      className="bg-primary-color text-white rounded-md px-3 py-1 ml-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
          {answers.length > answersDisplayed && (
            <div className="bg-[#f7f8fa]">
              <button className="bg-none border-none cursor-pointer text-primary-color text-lg font-medium mt-2 pb-3" onClick={loadMoreAnswers}>
                View More Answers
              </button>
            </div>
          )}
        </div>
        {isInstructor && (
          <div className="flex items-start max-w-[80%] mx-auto my-5">
            <img src={instructorProfileImage} alt="Profile" className="w-[40px] h-[40px] rounded-full mr-3" />
            <div className="flex flex-col w-full p-3 rounded-lg bg-white border border-border-color">
              <textarea
                className="p-3 border-none rounded-lg outline-none bg-white resize-none mb-3 break-words"
                value={newAnswer}
                onChange={(e) => setNewAnswer(e.target.value)}
                placeholder="Add your answer..."
              />
              <div className="flex justify-end">
                <button
                  onClick={handleAddAnswer}
                  className="bg-primary-color text-white border-none rounded-lg py-3 px-5 cursor-pointer ml-3 h-full inline-block"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RequestModal;
