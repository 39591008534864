import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './StudentForgotPassword.module.css';
import { ClipLoader } from 'react-spinners'; // Import the spinner component
import api from '../../utils/auth';

const StudentForgotPassword = () => {
  useEffect(() => {
    // Add class to body for different scrollbar styles
    document.body.classList.add(styles.customScrollbar); // Use the class from module CSS

    // Cleanup by removing class when component unmounts
    return () => {
      document.body.classList.remove(styles.customScrollbar); // Use the class from module CSS
    };
  }, []);

  const onboardingImage = `${process.env.PUBLIC_URL}/assets/images/onboarding.jpg`; 
  const backbutton = `${process.env.PUBLIC_URL}/assets/icons/back-button.png`; 
  const logo = `${process.env.PUBLIC_URL}/assets/logos/Navigrated_logo.png`; 


  const [email, setEmail] = useState('');
  const [role] = useState('student');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for form submission
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'Email address should not be empty';
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = 'Please enter a valid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); // Set loading state to true when form is submitted
      try {
        const response = await api.post(`/auth/forgot-password`, { email, role });
        if (response.status === 200) {
          setMessage(response.data.message); // Assuming response.data.message contains success message
          setErrors('');
        } else {
          setErrors('User does not exist.');
          setMessage('');
        }
      } catch (error) {
        setErrors('Failed to send password reset email. Please try again.');
        setMessage('');
      } finally {
        setLoading(false); // Set loading state to false once the form submission is complete
      }
    }
  };

  const handleGoBack = () => {
    navigate('/sign-in');
  };

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.spinnerOverlay}>
          <ClipLoader color="var(--primary-color)" loading={loading} size={50} />
        </div>
      )}
      {/* Left Column */}
      <div className={styles.leftColumn}>
        <div
          className={styles.goBackIcon}
          onClick={handleGoBack}
          style={{ cursor: 'pointer' }}
        >
          <img src={backbutton} alt="Go Back" />
        </div>
        <div className={styles.header}>
          <div className={styles.logoDiv}>
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
          <h1 className={styles.h1}>Send email for verification</h1>
          <form className={styles.signupForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>Email Address</label>
              <input
                type="email"
                id="email"
                className={styles.inputField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className={styles.errorMessage}>{errors.email}</p>
              )}
            </div>
            <div className={styles.buttonSpace}></div>
            {message && <p className={styles.successMessage}>{message}</p>}
            {errors && typeof errors === 'string' && (
              <p className={styles.errorMessage}>{errors}</p>
            )}
            <button type="submit" className={styles.continueButton}>
              Send Verification Email
            </button>
          </form>
        </div>
      </div>
  
      {/* Right Column */}
      <div className={styles.rightColumn}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img src={onboardingImage} alt="Onboarding" />
            <div className={styles.overlay}></div>
            <div className={styles.blackOverlay}></div>
            <div className={styles.textOverlay}>
  <h2>Why Choose Navigrated?</h2>
  <p>
    Our mission with Navigrated is to help international students and professionals secure industry-relevant jobs and build successful ventures in the UK - through the power of community mentorship.
  </p>
  <ul className={styles.featuresList}>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Expert-led sessions</b></span>
      </div>
      <div className={styles.nonBoldText}>
        on landing sponsored jobs, entrepreneurship, and overcoming immigration challenges.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Mentor-led daily group calls</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Gain insights from professionals who have built careers at companies like Deloitte, KPMG, Loreal, Rolls Royce, etc.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>CV reviews</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Tools and challenges built to help you stand out in the competitive job market.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Build your network</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Attend both virtual and in-person events to expand your professional circle.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Success stories</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Be inspired by real experiences from mentors who’ve faced and overcome the same challenges you are now facing.
      </div>
    </li>
    <li className={styles.featuresListItem}>
      <div className={styles.featureHeader}>
        <i className={`fa fa-check-circle-o ${styles.checkmark}`}></i>
        <span className={styles.boldText}><b>Exclusive insights</b></span>
      </div>
      <div className={styles.nonBoldText}>
        Access templates, resources, and webinars designed to fast-track your UK career journey.
      </div>
    </li>
  </ul>
</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentForgotPassword;
