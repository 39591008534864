import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import './../buttonloader.css';
import useUserStore from '../../store/useStudentStore';
import api from '../../utils/auth'; // Import your Axios instance for API calls

const StudentNotification = () => {
  const { userData, setUserData } = useUserStore(); // Get both userData and setUserData from Zustand store
  const [reminderWhatsApp, setReminderWhatsApp] = useState(true);
  const [appWhatsApp, setAppWhatsApp] = useState(true);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    // Set notification preferences and premium status from Zustand store
    if (userData.notificationPreferences) {
      setReminderWhatsApp(userData.notificationPreferences.reminderWhatsApp);
      setAppWhatsApp(userData.notificationPreferences.appWhatsApp);
    }
    setIsPremium(userData.isPremium);
  }, [userData]);

  const handleToggle = async (type, value) => {
    // Update the local state for toggling
    if (type === 'reminderWhatsApp') setReminderWhatsApp(value);
    if (type === 'appWhatsApp') setAppWhatsApp(value);

    // Update Zustand store with new notification preferences
    setUserData({
      ...userData,
      notificationPreferences: {
        ...userData.notificationPreferences,
        [type]: value,
      },
    });

    try {
      // Make API call to update notification preferences in the backend
      await api.post('/notifications/updatePreferences', { type, value });
      console.log(`${type} updated to ${value} successfully in backend`);
    } catch (error) {
      console.error('Error updating notification preferences in backend:', error);
    }
  };

  return (
    <div className="max-w-[700px] p-5">
      <div className="text-2xl font-bold">Notifications</div>
      <div className="text-sm font-normal text-gray-600 mb-11">
        Help yourself get notified of any important events.
      </div>
      <div className="flex flex-col space-y-8">
        <div>
          <div className="text-lg font-bold mb-2">Reminders</div>
          <div className={`flex flex-col md:flex-row md:items-start justify-between mb-6 space-y-4 md:space-y-0 ${!isPremium ? 'opacity-50 pointer-events-none' : ''}`}>
            <div className="text-sm text-gray-700 md:max-w-md">
              These are notifications to remind you about an important event like upcoming sessions.
              {!isPremium && <div className="text-xs text-red-500">Available for premium users only</div>}
            </div>
            <div className="flex items-center">
              <Switch 
                onChange={(checked) => handleToggle('reminderWhatsApp', checked)}
                checked={reminderWhatsApp}
                offColor="#ccc"
                onColor="#1c6b9b"
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={!isPremium}
              />
              <label className="ml-2">WhatsApp</label>
            </div>
          </div>
        </div>
        <div>
          <div className="text-lg font-bold mb-2">App Notifications</div>
          <div className={`flex flex-col md:flex-row md:items-start justify-between space-y-4 md:space-y-0 ${!isPremium ? 'opacity-50 pointer-events-none' : ''}`}>
            <div className="text-sm text-gray-700 md:max-w-md">
              These are app notifications that will help notify you whenever someone books a one-on-one session or buys your service.
              {!isPremium && <div className="text-xs text-red-500">Available for premium users only</div>}
            </div>
            <div className="flex items-center">
              <Switch 
                onChange={(checked) => handleToggle('appWhatsApp', checked)}
                checked={appWhatsApp}
                offColor="#ccc"
                onColor="#1c6b9b"
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={!isPremium}
              />
              <label className="ml-2">WhatsApp</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentNotification;
