import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import Modal from 'react-modal';
import styles from './InstructorServices.module.css';
import ClipLoader from 'react-spinners/ClipLoader';


const allSkills = [
  "Career Development Coaching", "Life Coaching", "Interview Preparation", "CV Review", "One-to-one support", 
  "Cover Letters", "Profile Assessment", "Job Interview preparation", "Mock interview", "LinkedIn Networking",
  "Personal Branding", "Entrepreneurship Guidance", "Sponsored Job-Searching", "Accountability coach", 
  "Deciding my Career Path", "Confidence Building", "Business in UK", "Global Talent Route", "Innovator Founder Route",
  "Networking Strategies", "Work Visa Guidance", "Start-up Advice", "Cultural Adaptation", "Industry Insights",
  "Professional Etiquette", "Goal Setting", "Time Management", "Stress Management", "Work-Life Balance", 
  "Professional Certifications", "Business Development", "Sales Strategies", "Negotiation Skills", 
  "Leadership Training", "Team Management", "Conflict Resolution", "Presentation Skills", 
  "Effective Communication", "Digital Marketing Strategies", "SEO Optimization", "E-commerce Strategies", 
  "Project Planning", "Risk Management", "Financial Planning", "Investment Strategies", "Budget Management", 
  "Market Analysis", "Product Development", "Innovation Coaching", "Software Development", "IT Security Advice", 
  "Data Analytics", "Machine Learning Insights", "Artificial Intelligence Trends", "Cloud Computing", 
  "Mobile Development", "User Experience Design", "Graphic Design Tips", "Freelancing scaling", 
  "Job Contract Negotiation", "Public Speaking", "Research Methodologies", "Graduate School Applications", 
  "Postdoctoral Opportunities", "PhD proposals and admission", "MRes admissions"
];

const InstructorServices = () => {
  const [instructors, setInstructors] = useState([]);
  const [services, setServices] = useState([]);
  const [instructorSearchTerm, setInstructorSearchTerm] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    serviceTitle: '',
    serviceDescription: '',
    price: '',
    image: null,
    industrySkills: [],
    searchTerm: ''
  });
  const [filteredSkills, setFilteredSkills] = useState(allSkills); // Show all skills initially
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const csrfToken = await fetchCsrfToken();
        const instructorResponse = await api.get('/admin/instructors', {
          headers: { 'CSRF-Token': csrfToken },
        });
        setInstructors(instructorResponse.data);
  
        const servicesResponse = await api.get('/admin/services', {
          headers: { 'CSRF-Token': csrfToken },
        });
        setServices(servicesResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false); // End loading
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    if (formState.searchTerm) {
      setFilteredSkills(
        allSkills.filter(skill =>
          skill.toLowerCase().includes(formState.searchTerm.toLowerCase())
        ).slice(0, 5)
      );
    } else {
      setFilteredSkills(allSkills); // Show all skills when search term is empty
    }
  }, [formState.searchTerm]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEdit = (service) => {
    setSelectedService(service);
    setFormState({
      serviceTitle: service.serviceTitle,
      serviceDescription: service.serviceDescription,
      price: service.price,
      image: service.image ? `${service.image}` : null,
      industrySkills: service.industrySkills,
      searchTerm: ''
    });
    setIsEditModalOpen(true);
  };

  const handleUpdateService = (updatedService) => {
    setServices(prevServices => prevServices.map(service => 
      service._id === updatedService._id ? { ...service, ...updatedService } : service
    ));
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this service!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const csrfToken = await fetchCsrfToken();
          await api.delete(`/admin/service/${id}`, {
            headers: { 'CSRF-Token': csrfToken },
          });
          setServices(services.filter(service => service._id !== id));
          Swal.fire('Deleted!', 'The service has been deleted.', 'success');
        } catch (err) {
          console.error('Error deleting service:', err);
          Swal.fire('Error!', 'There was an error deleting the service.', 'error');
        }
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        setFormState(prevState => ({
          ...prevState,
          image: event.target.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddSkill = skill => {
    if (formState.industrySkills.length < 5 && !formState.industrySkills.includes(skill)) {
      setFormState(prevState => ({
        ...prevState,
        industrySkills: [...prevState.industrySkills, skill],
        searchTerm: ''
      }));
      setIsDropdownVisible(false); // Hide dropdown after adding skill
    }
  };

  const handleRemoveSkill = skill => {
    setFormState(prevState => ({
      ...prevState,
      industrySkills: prevState.industrySkills.filter(s => s !== skill)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = await fetchCsrfToken();
      const updatedData = {
        serviceTitle: formState.serviceTitle !== selectedService.serviceTitle ? formState.serviceTitle : undefined,
        serviceDescription: formState.serviceDescription !== selectedService.serviceDescription ? formState.serviceDescription : undefined,
        price: formState.price !== selectedService.price ? formState.price : undefined,
        industrySkills: formState.industrySkills !== selectedService.industrySkills ? formState.industrySkills : undefined,
        image: formState.image !== (selectedService.image ? `${selectedService.image}` : null) ? formState.image : undefined,
      };
      const response = await api.patch(`/admin/service/${selectedService._id}`, updatedData, {
        headers: { 'CSRF-Token': csrfToken },
      });
      setServices(prevServices => prevServices.map(service => 
        service._id === selectedService._id ? { ...service, ...response.data } : service
      ));

      const fetchData = async () => {
        try {
          const csrfToken = await fetchCsrfToken();
          const instructorResponse = await api.get('/admin/instructors', {
            headers: { 'CSRF-Token': csrfToken },
          });
          setInstructors(instructorResponse.data);
  
          const servicesResponse = await api.get('/admin/services', {
            headers: { 'CSRF-Token': csrfToken },
          });
          setServices(servicesResponse.data);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      };
  
      fetchData();

      Swal.fire('Success!', 'Service updated successfully.', 'success');

      setIsEditModalOpen(false);
    } catch (err) {
      console.error('Error updating service:', err);
      Swal.fire('Error!', 'There was an error updating the service.', 'error');
    }
  };

  const filteredInstructors = instructors.filter(instructor =>
    instructor.firstName.toLowerCase().includes(instructorSearchTerm.toLowerCase()) ||
    instructor.lastName.toLowerCase().includes(instructorSearchTerm.toLowerCase()) ||
    instructor.email.toLowerCase().includes(instructorSearchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h2>Mentor Services</h2>
      <input
        type="text"
        placeholder="Search Mentors..."
        value={instructorSearchTerm}
        onChange={(e) => setInstructorSearchTerm(e.target.value)}
        className={styles.searchBar}
      />
      {loading ? (
      <div className={styles.loader}>
        <ClipLoader color={'#123abc'} loading={loading} size={50} />
      </div>
    ) : (
      <>
      {filteredInstructors.length > 0 ? (
        <ul className={styles.instructorList}>
          {filteredInstructors.map(instructor => (
            <li key={instructor._id} className={styles.instructorItem}>
              <div className={styles.instructorInfo}>
                <h3>{instructor.firstName} {instructor.lastName}</h3>
                <p>{instructor.email}</p>
              </div>
              <div className={styles.servicesGrid}>
                {services.filter(service => service.instructorId === instructor._id).length > 0 ? (
                  services.filter(service => service.instructorId === instructor._id).map(service => (
                    <div className={styles.serviceCard} key={service._id}>
                      <div className={styles.cardHeader}>
                        <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(service)} className={styles.icon} />
                        <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(service._id)} className={styles.icon} />
                      </div>
                      <div className={styles.cardImage}>
                        {service.image ? <img src={`${service.image}`} alt="Service" /> : <div className={styles.imagePlaceholder}></div>}
                      </div>
                      <div className={styles.cardContent}>
                        <div className={styles.serviceTitle}>{service.serviceTitle}</div>
                        <p className={styles.truncatedDescription}>{service.serviceDescription}</p>
                        <div className={styles.skillButtons}>
                          {service.industrySkills.map(skill => (
                            <button key={skill} className={styles.skillButton}>{skill}</button>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No services offered by this Mentor.</p>
                )}
              </div>
            </li>
          ))}
        </ul>
        ) : (
          <p>No Mentors found.</p>
        )}
      </>
    )}
      {selectedService && (
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={() => setIsEditModalOpen(false)}
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <h2>Edit Service</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <label htmlFor="serviceTitle">Service Title:</label>
              <input
                type="text"
                name="serviceTitle"
                value={formState.serviceTitle}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="serviceDescription">Service Description:</label>
              <textarea
                name="serviceDescription"
                value={formState.serviceDescription}
                onChange={handleChange}
                required
                rows="5"
              ></textarea>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="price">Price:</label>
              <input
                type="number"
                name="price"
                value={formState.price}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="imageUpload">Upload Image:</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
              {formState.image && <img src={formState.image} alt="Preview" className={styles.imagePreview} />}
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="industrySkills">Industry Skills:</label>
              <div className={styles.searchContainer}>
                <input
                  type="text"
                  name="searchTerm"
                  placeholder="Search for a skill..."
                  value={formState.searchTerm}
                  onChange={handleChange}
                  className={styles.searchInput}
                  onFocus={() => setIsDropdownVisible(true)}
                />
                {filteredSkills.length > 0 && isDropdownVisible && (
                  <div className={styles.dropdown} ref={dropdownRef}>
                    {filteredSkills.map(skill => (
                      <div
                        key={skill}
                        className={styles.dropdownItem}
                        onClick={() => handleAddSkill(skill)}
                      >
                        {skill}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.addedSkills}>
                {formState.industrySkills.map(skill => (
                  <div
                    key={skill}
                    className={`${styles.skill} ${styles.addedSkill}`}
                    onClick={() => handleRemoveSkill(skill)}
                  >
                    {skill}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <button type="submit" className={styles.saveButton}>Save</button>
              <button type="button" onClick={() => setIsEditModalOpen(false)} className={styles.closeButton}>Cancel</button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default InstructorServices;
