import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../utils/auth';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import CheckpointEditor from './CheckpointEditor';
import styles from './ManageChallenges.module.css';

// Modal setup
Modal.setAppElement('#root');

const ManageChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const [activeCheckpointIndex, setActiveCheckpointIndex] = useState(0);
  const [instructors, setInstructors] = useState([]);
  const [rewardInput, setRewardInput] = useState('');

  // Fetch challenges
  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await api.get('/admin/challenges');
        const challenges = response.data.map((challenge) => {
          // Ensure rewards are always an array
          if (!Array.isArray(challenge.rewards)) {
            try {
              challenge.rewards = JSON.parse(challenge.rewards);
            } catch (e) {
              console.error('Failed to parse rewards', e);
              challenge.rewards = []; // Default to empty array if parsing fails
            }
          }
          return challenge;
        });
        setChallenges(challenges);
      } catch (error) {
        console.error('Error fetching challenges:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchChallenges();
  }, []);
  
  

  // Fetch instructors
  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await api.get('/admin/instructors');
        const instructorOptions = response.data.map(instructor => ({
          value: instructor._id,
          label: `${instructor.firstName} ${instructor.lastName}`,
        }));
        setInstructors(instructorOptions);
      } catch (error) {
        console.error('Error fetching instructors:', error);
      }
    };

    fetchInstructors();
  }, []);

  const openModal = (challenge) => {
    setSelectedChallenge({
      ...challenge,
      host: instructors.find(inst => inst.value === challenge.host),
    });
    setCheckpoints(challenge.checkpoints || []);
    setActiveCheckpointIndex(0);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedChallenge(null);
    setCheckpoints([]);
    setRewardInput('');
  };

  const handleInputChange = (field, value) => {
    setSelectedChallenge(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleCheckpointChange = (index, updatedCheckpoint) => {
    const updatedCheckpoints = [...checkpoints];
    updatedCheckpoints[index] = updatedCheckpoint;
    setCheckpoints(updatedCheckpoints);
  };

  const handleRewardInputChange = (e) => {
    setRewardInput(e.target.value);
  };

  const handleAddReward = () => {
    const trimmedReward = rewardInput.trim();
    if (trimmedReward && !selectedChallenge.rewards.includes(trimmedReward)) {
      setSelectedChallenge((prevState) => ({
        ...prevState,
        rewards: [...prevState.rewards, trimmedReward],
      }));
      setRewardInput('');
    }
  };
  

  const handleRemoveReward = (reward) => {
    setSelectedChallenge((prevState) => ({
      ...prevState,
      rewards: prevState.rewards.filter((r) => r !== reward),
    }));
  };
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddReward();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Show loading Swal
    Swal.fire({
      title: 'Uploading...',
      text: 'Please wait while the challenge is being updated.',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
  
    const formData = new FormData();
    formData.append('title', selectedChallenge.title);
    formData.append('description', selectedChallenge.description);
    formData.append('host', selectedChallenge.host.value);
    formData.append('rewards', JSON.stringify(selectedChallenge.rewards));
    formData.append('startDate', selectedChallenge.startDate);
    formData.append('endDate', selectedChallenge.endDate);
  
    const checkpointData = checkpoints.map((checkpoint, index) => {
      if (checkpoint.file && checkpoint.file instanceof File) {
        // Correct field name 'checkpoints'
        formData.append('checkpoints', checkpoint.file, checkpoint.file.name);
      }
      return {
        title: checkpoint.title,
        quillContent: checkpoint.quillContent,
        fileUrl: checkpoint.fileUrl,
        fileName: checkpoint.file?.name,
      };
    });
  
    formData.append('checkpointsData', JSON.stringify(checkpointData));
  
    if (selectedChallenge.image && selectedChallenge.image instanceof File) {
      formData.append('image', selectedChallenge.image);
    }
  
    try {
      const response = await api.put(`/admin/challenges/${selectedChallenge._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Close loading Swal and show success message
      Swal.fire({
        title: 'Success!',
        text: 'Challenge updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
  
      closeModal();
      const updatedChallenges = await api.get('/admin/challenges');
      setChallenges(updatedChallenges.data);
    } catch (error) {
      console.error('Error updating challenge:', error);
  
      // Close loading Swal and show error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update challenge.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  
  
  
  

  const handleDelete = async (challengeId) => {
    try {
      await api.delete(`/admin/challenges/${challengeId}`);
      setChallenges(challenges.filter(challenge => challenge._id !== challengeId));
      Swal.fire({
        title: 'Deleted!',
        text: 'Challenge has been deleted.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('Error deleting challenge:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to delete challenge.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.manageChallengesContainer}>
      <h2>Manage Challenges</h2>
      <div className={styles.challengeGrid}>
        {challenges.map(challenge => (
          <div key={challenge._id} className={styles.challengeCard}>
            <div className={styles.challengeDetails}>
              <h3>{challenge.title}</h3>
              <p><strong>Host:</strong> {instructors.find(inst => inst.value === challenge.host)?.label || 'Unknown Host'}</p>
              <p><strong>Start Date:</strong> {new Date(challenge.startDate).toLocaleDateString()}</p>
              <p><strong>End Date:</strong> {new Date(challenge.endDate).toLocaleDateString()}</p>
              <p><strong>Rewards:</strong> {challenge.rewards.join(', ')}</p>
              </div>
            <div className={styles.cardActions}>
              <button className={styles.editButton} onClick={() => openModal(challenge)}>Edit</button>
              <button className={styles.deleteButton} onClick={() => handleDelete(challenge._id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>

      {selectedChallenge && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Edit Challenge"
          className={styles.modalContent}
          overlayClassName={styles.modalOverlay}
        >
          <h2>Edit Challenge</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label>Title</label>
              <input
                type="text"
                value={selectedChallenge.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
                required
                className={styles.inputField}
              />
            </div>

            <div className={styles.formGroup}>
              <label>Host</label>
              <Select
                value={selectedChallenge.host}
                onChange={(value) => handleInputChange('host', value)}
                options={instructors}
                placeholder="Select host"
                isSearchable
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label>Rewards</label>
              <div className={styles.rewardsInputContainer}>
                <input
                  type="text"
                  value={rewardInput}
                  onChange={handleRewardInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Add a reward and press Enter"
                  className={styles.inputField}
                />
                <button type="button" onClick={handleAddReward} className={styles.addButton}>Add</button>
              </div>
              <div className={styles.rewardsList}>
                {selectedChallenge.rewards.map((reward, index) => (
                  <div key={index} className={styles.rewardTag}>
                    {reward}
                    <span onClick={() => handleRemoveReward(reward)} className={styles.removeTag}>×</span>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.formGroup}>
              <label>Start Date</label>
              <input
                type="date"
                value={selectedChallenge.startDate.split('T')[0]}
                onChange={(e) => handleInputChange('startDate', e.target.value)}
                required
                className={styles.inputField}
                min={new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
              />
            </div>

            <div className={styles.formGroup}>
              <label>End Date</label>
              <input
                type="date"
                value={selectedChallenge.endDate.split('T')[0]}
                onChange={(e) => handleInputChange('endDate', e.target.value)}
                required
                className={styles.inputField}
                min={selectedChallenge.startDate.split('T')[0]}
              />
            </div>

            <div className={styles.formGroup}>
              <label>Image</label>
              <input
                type="file"
                onChange={(e) => handleInputChange('image', e.target.files[0])}
                className={styles.inputField}
              />
              {selectedChallenge.image && (
                <img
                  src={selectedChallenge.image}
                  alt="Challenge"
                  className={styles.imagePreview}
                />
              )}
            </div>

            <div className={styles.checkpointsContainer}>
              <h3>Checkpoints</h3>
              <div className={styles.checkpointsList}>
                {checkpoints.map((checkpoint, index) => (
                  <CheckpointEditor
                    key={index}
                    index={index}
                    checkpoint={checkpoint}
                    onUpdate={(updatedCheckpoint) => handleCheckpointChange(index, updatedCheckpoint)}
                  />
                ))}
              </div>
            </div>

            <button type="submit" className={styles.submitButton}>
              Update Challenge
            </button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default ManageChallenges;
