import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../utils/auth';
import { fetchCsrfToken } from '../../utils/csrf';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import GeneralModal from '../GeneralSessionModal/GeneralSessionModal';
import moment from 'moment-timezone';
import Pagination from '../paginationControl'; // Assuming you have a Pagination component

const UpcomingSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [timezone, setTimezone] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loading, setLoading] = useState(true);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      const csrfToken = await fetchCsrfToken();
      try {
        const [generalResponse, oneOnOneResponse] = await Promise.all([
          api.get(`/instructor-sessions/upcoming-general?page=${currentPage}&limit=6`, {
            headers: {
              'CSRF-Token': csrfToken,
            },
          }),
          api.get(`/instructor-sessions/upcoming-one-on-one?page=${currentPage}&limit=6`, {
            headers: {
              'CSRF-Token': csrfToken,
            },
          }),
        ]);

        const combinedSessions = [
          ...generalResponse.data.sessions.map((session) => ({ ...session, type: 'general' })),
          ...oneOnOneResponse.data.sessions.map((session) => ({ ...session, type: 'one-on-one' })),
        ];

        combinedSessions.sort(
          (a, b) =>
            new Date(a.startUTC || a.availability.start) - new Date(b.startUTC || b.availability.start)
        );

        setSessions(combinedSessions);
        console.log(combinedSessions);
        setTotalPages(Math.ceil(Math.max(generalResponse.data.totalSessions, oneOnOneResponse.data.totalSessions) / 6)); // Calculate total pages
        setTimezone(generalResponse.data.timezone || oneOnOneResponse.data.timezone); // Set timezone
      } catch (error) {
        console.error('Error fetching sessions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [currentPage]);

  const formatSessionDate = (utcDate) => {
    if (!utcDate || !timezone) return '';
    return moment(utcDate).tz(timezone).format('dddd, MMMM Do, YYYY');
  };

  const formatSessionTime = (startUTC, endUTC) => {
    if (!startUTC || !endUTC || !timezone) return '';
    const startTime = moment(startUTC).tz(timezone).format('hh:mm A');
    const endTime = moment(endUTC).tz(timezone).format('hh:mm A');
    return `${startTime} to ${endTime}`;
  };

  const openModal = (session) => {
    setSelectedSession(session);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSession(null);
  };

  const renderSession = (session) => (
    <div key={session._id} className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className={`absolute left-4 top-3 bottom-3 w-1 ${session.type === 'one-on-one' ? 'bg-green-500' : 'bg-red-400'}`}></div>
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-start mb-4">
          <div className="text-lg font-bold text-primary-color">
            {session.type === 'one-on-one'
              ? `${session.studentId.firstName} ${session.studentId.lastName}`
              : session.sessionName}
          </div>
          {session.pending && (
            <div className="flex items-center bg-red-400 text-white py-1 px-3 rounded-full text-sm">
              <FaClock className="mr-2" /> Pending
            </div>
          )}
          {session.type === 'general' && session.isVerified === null && (
          <div className="flex items-center bg-yellow-400 text-white py-1 px-3 rounded-full text-sm">
            <FaClock className="mr-2" /> Awaiting Approval
          </div>
          )}
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaCalendarAlt className="mr-2 text-primary-color" />
          <span>{session.type === 'one-on-one' ? formatSessionDate(session.startUTC) : formatSessionDate(session.availability.start)}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-2">
          <FaClock className="mr-2 text-primary-color" />
          <span>{session.type === 'one-on-one' ? formatSessionTime(session.startUTC, session.endUTC) : formatSessionTime(session.availability.start, session.availability.end)}</span>
        </div>
        <div className="text-sm text-black opacity-40">
          {session.type === 'one-on-one' ? 'One-on-One Session' : 'General Session'}
        </div>
        {session.type === 'general' && (
          <button className="mt-4 px-4 py-2 bg-primary-color text-white rounded-md hover:bg-primary-color-hover transition duration-300" onClick={() => openModal(session)}>
            View Details
          </button>
        )}
      </div>
    </div>
  );

  const renderSkeletonLoader = () => (
    <div className="flex border border-border-color rounded-lg p-6 bg-white shadow-md relative">
      <div className="flex-1 pl-8">
        <div className="flex justify-between items-start mb-4">
          <Skeleton width={200} />
        </div>
        <div className="flex flex-col justify-between mt-2">
          <Skeleton width={150} height={20} className="mb-2" />
          <Skeleton width={100} height={20} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="max-h-[575px] overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
        {loading ? (
          [...Array(6)].map((_, index) => <div key={index}>{renderSkeletonLoader()}</div>)
        ) : sessions.length > 0 ? (
          sessions.map((session) => renderSession(session))
        ) : (
          <p>No upcoming sessions available.</p>
        )}

        <GeneralModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          session={selectedSession}
          timezone={timezone}
          showApplyButton={false}
        />
      </div>
      {/* Pagination Controls */}
      {!loading && totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      )}
    </div>
  );
};

export default UpcomingSessions;
