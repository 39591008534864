import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../utils/auth';
import styles from './ChallengeSubmissions.module.css';

const ChallengeSubmissions = () => {
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [selectedCheckpointIndex, setSelectedCheckpointIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await api.get('/admin/challenges');
        const formattedChallenges = response.data.map(challenge => ({
          value: challenge._id,
          label: `${challenge.title} (${new Date(challenge.startDate).toLocaleDateString()} - ${new Date(challenge.endDate).toLocaleDateString()})`,
          checkpoints: challenge.checkpoints || []  // Ensure checkpoints is an array, even if it's empty
        }));
        setChallenges(formattedChallenges);
      } catch (error) {
        console.error('Error fetching challenges:', error);
        setError('Failed to load challenges.');
      }
    };

    fetchChallenges();
  }, []);

  const handleChallengeSelect = async (challenge) => {
    setLoading(true);
    setError('');
    try {
      const response = await api.get(`/admin/challenges/${challenge.value}/submissions`);
      setSelectedChallenge(challenge);
      setSubmissions(response.data);
      setSelectedCheckpointIndex(0);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setError('Failed to load submissions.');
    } finally {
      setLoading(false);
    }
  };

  const renderSubmissions = () => {
    if (loading) {
      return <p>Loading submissions...</p>;
    }

    if (error) {
      return <p className={styles.error}>{error}</p>;
    }


    // Check if submissions are available for the selected checkpoint
    const checkpointSubmissions = submissions.map((submission) => submission.milestones[selectedCheckpointIndex]);
  

    if (!checkpointSubmissions || checkpointSubmissions.length === 0 || checkpointSubmissions.every(submission => !submission)) {
      return <p>No submissions available for this checkpoint.</p>;
    }

    return checkpointSubmissions.map((milestone, index) => {
      if (!milestone) {
        return null;
      }

      return (
        <div key={index} className={styles.submissionCard}>
          <p><strong>Student:</strong> {submissions[index].studentName}</p>
          <p><strong>Date Submitted:</strong> {new Date(milestone.submissionDate).toLocaleDateString()}</p>
          <div className={styles.actions}>
            <a href={milestone.fileUrl} target="_blank" rel="noopener noreferrer">View Submission</a>
            <a href={milestone.fileUrl} download>Download Submission</a>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.challengeSubmissionsContainer}>
      <h2>Challenge Submissions</h2>
      <div className={styles.challengeSelection}>
        <h3>Select a Challenge</h3>
        <Select 
          options={challenges}
          onChange={handleChallengeSelect}
          placeholder="Search and select a challenge..."
        />
      </div>
      {selectedChallenge && (
        <>
          <h3>{selectedChallenge.label}</h3>
          {selectedChallenge.checkpoints && selectedChallenge.checkpoints.length > 0 ? (
            <div className={styles.checkpoints}>
              {selectedChallenge.checkpoints.map((checkpoint, index) => (
                <button
                  key={index}
                  className={`${styles.checkpointButton} ${index === selectedCheckpointIndex ? styles.active : ''}`}
                  onClick={() => setSelectedCheckpointIndex(index)}
                >
                  {checkpoint.title}
                </button>
              ))}
            </div>
          ) : (
            <p>No checkpoints available for this challenge.</p>
          )}
          <div className={styles.submissions}>
            {renderSubmissions()}
          </div>
        </>
      )}
    </div>
  );
};

export default ChallengeSubmissions;
