import React, { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment'; // Import moment
import useInstructorStore from '../../store/useInstructorStore'; // Import Zustand store
import { fetchCsrfToken } from '../../utils/csrf'; // Import your CSRF utility
import api from '../../utils/auth'; // Import API utility

const Education = () => {
  const { instructorData, setInstructorData } = useInstructorStore(); // Access Zustand store
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentEducationId, setCurrentEducationId] = useState(null);
  const [degree, setDegree] = useState('');
  const [institution, setInstitution] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [currentlyStudying, setCurrentlyStudying] = useState(false);
  const [description, setDescription] = useState('');
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [errors, setErrors] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false); // Loader for Save Changes/Add Education button
  const [loading, setLoading] = useState(true); // Loading state



      // Simulate fetching data with loading
      useEffect(() => {
        // Simulate an API call to fetch achievements (if needed)
        setTimeout(() => {
          setLoading(false); // Set loading to false after fetching is complete
        }, 100); // Simulate 1 second of loading time
      }, []);

  const fieldOfStudyOptions = [
    'Computer Science', 'Engineering', 'Business', 'Education', 'Health Sciences', 'Arts',
    'Social Sciences', 'Law', 'Natural Sciences', 'Mathematics', 'Humanities', 'Other'
  ];

  const educationLevelOptions = [
    'High School', 'Associate Degree', 'Bachelor\'s Degree', 'Master\'s Degree', 'PhD', 'Certificate'
  ];

  const formatDate = (date) => {
    if (!date) return 'Present';
    return moment(date).format('MMMM YYYY'); // Format date to 'Month Year'
  };

  const validateForm = () => {
    const newErrors = {};
    if (!degree) newErrors.degree = true;
    if (!institution) newErrors.institution = true;
    if (!from) newErrors.from = true;
    if (!currentlyStudying && !to) newErrors.to = true;
    if (!fieldOfStudy) newErrors.fieldOfStudy = true;
    if (!educationLevel) newErrors.educationLevel = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addEducation = async () => {
    if (!validateForm()) return;

    const newEducation = {
      degree,
      institution,
      from,
      to,
      currentlyStudying,
      description,
      fieldOfStudy,
      educationLevel
    };

    setLoadingSubmit(true); // Show loader for button
    try {
      const csrfToken = await fetchCsrfToken();

      const response = await api.post(`/instructorsEducation/education`, newEducation, {
        headers: { 'CSRF-Token': csrfToken, },
      });

      // Update the store with the new education
      setInstructorData({
        ...instructorData,
        education: [...instructorData.education, response.data]
      });

      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error adding education:', error);
    } finally {
      setLoadingSubmit(false); // Hide loader for button
    }
  };

  const updateEducation = async () => {
    if (!validateForm()) return;

    const updatedEducation = {
      degree,
      institution,
      from,
      to,
      currentlyStudying,
      description,
      fieldOfStudy,
      educationLevel
    };

    setLoadingSubmit(true); // Show loader for button
    try {
      const csrfToken = await fetchCsrfToken();

      const response = await api.put(`/instructorsEducation/education/${currentEducationId}`, updatedEducation, {
        headers: { 'CSRF-Token': csrfToken, },
      });

      // Update the store with the updated education
      setInstructorData({
        ...instructorData,
        education: instructorData.education.map(edu => edu._id === currentEducationId ? response.data : edu)
      });

      setModalIsOpen(false);
      clearForm();
    } catch (error) {
      console.error('Error updating education:', error);
    } finally {
      setLoadingSubmit(false); // Hide loader for button
    }
  };

  const deleteEducation = async (id) => {
    try {
      const csrfToken = await fetchCsrfToken();

      await api.delete(`/instructorsEducation/education/${id}`, {
        headers: { 'CSRF-Token': csrfToken, },
      });

      // Update the store to remove the deleted education
      setInstructorData({
        ...instructorData,
        education: instructorData.education.filter(edu => edu._id !== id)
      });
    } catch (error) {
      console.error('Error deleting education:', error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this education?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEducation(id);
        swal("Deleted!", "Your education has been deleted.", "success");
      } else {
        swal("Cancelled", "Your education is safe.", "error");
      }
    });
  };

  const handleEdit = (education) => {
    setIsEdit(true);
    setCurrentEducationId(education._id);
    setDegree(education.degree);
    setInstitution(education.institution);
    setFrom(education.from);
    setTo(education.to);
    setCurrentlyStudying(education.currentlyStudying);
    setDescription(education.description);
    setFieldOfStudy(education.fieldOfStudy);
    setEducationLevel(education.educationLevel);
    setModalIsOpen(true);
  };

  const handleAdd = () => {
    setIsEdit(false);
    clearForm();
    setModalIsOpen(true);
  };

  const clearForm = () => {
    setDegree('');
    setInstitution('');
    setFrom('');
    setTo('');
    setCurrentlyStudying(false);
    setDescription('');
    setFieldOfStudy('');
    setEducationLevel('');
    setErrors({});
  };

  const handleSave = () => {
    if (isEdit) {
      updateEducation();
    } else {
      addEducation();
    }
  };

  const EducationSkeleton = () => (
    <div className="max-w-[700px] p-5">
      <div className="flex flex-col md:flex-row justify-between mb-6">
        <div className="mb-4 md:mb-0">
          <div className="text-2xl font-bold">
            <Skeleton width={200} height={28} />
          </div>
          <Skeleton width={250} height={20} style={{ marginTop: '5px' }} />
        </div>
        <Skeleton width={160} height={40} />
      </div>

      {[...Array(1)].map((_, index) => (
        <div key={index} className="border border-[var(--black-overlay)] p-6 mb-4 rounded-[16px] bg-white">
          <div className="text-[#1673bb] text-[18px] font-semibold flex justify-between items-center">
            <h3 className="m-0 text-[18px] font-semibold pb-2">
              <Skeleton width={150} />
            </h3>
            <div className="flex space-x-4">
              <Skeleton width={20} height={20} circle />
              <Skeleton width={20} height={20} circle />
            </div>
          </div>
          <Skeleton width={200} height={15} style={{ marginBottom: '10px' }} />
          <Skeleton width={100} height={15} />
          <Skeleton width={200} height={15} style={{ marginTop: '10px' }} />
          <Skeleton width={200} height={15} />
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-[700px] p-5">
      {loading ? (
        <EducationSkeleton />
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between mb-6">
            <div className="mb-4 md:mb-0">
              <div className="text-2xl font-bold">
                Education
              </div>
              <p className="text-[14px] text-gray-600 mb-5">
                Your educational background.
              </p>
            </div>
            <button className="bg-white text-[var(--primary-color)] text-[14px] font-normal border border-[var(--primary-color)] px-6 py-3 cursor-pointer rounded-[8px] mb-4 md:mb-0 hover:bg-[var(--primary-color-hover)] transition delay-3000 hover:text-white" onClick={handleAdd}>
              + Add Education
            </button>
          </div>
          
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className="absolute max-h-[90vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 box-border rounded-[8px] outline-none w-full md:w-[600px] max-w-[90%] m-auto overflow-y-auto"
            overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.75)] z-[3000]"
            contentLabel="Add Education"
          >
            <div className="mt-2 flex justify-between items-center border-b border-[#ddd] pb-2">
              <div>
                <h2 className="m-0">{isEdit ? "Edit Education" : "Add Education"}</h2>
              </div>
              <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => setModalIsOpen(false)} />
            </div>
            <form className="mt-4">
              <div className="flex flex-col md:flex-row gap-4 flex-wrap">
                <div className="flex-1 flex flex-col mb-4 box-border w-full">
                  <label>Degree</label>
                  <input 
                    type="text" 
                    value={degree} 
                    onChange={(e) => setDegree(e.target.value)} 
                    className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.degree ? 'border-red-500' : ''}`}
                  />
                </div>
                <div className="flex-1 flex flex-col mb-4 box-border w-full">
                  <label>Institution</label>
                  <input 
                    type="text" 
                    value={institution} 
                    onChange={(e) => setInstitution(e.target.value)} 
                    className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.institution ? 'border-red-500' : ''}`}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 flex-wrap">
                <div className="flex-1 flex flex-col mb-4 box-border w-full">
                  <label>From</label>
                  <input 
                    type="date" 
                    value={from} 
                    onChange={(e) => setFrom(e.target.value)} 
                    className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.from ? 'border-red-500' : ''}`}
                  />
                </div>
                <div className="flex-1 flex flex-col mb-4 box-border w-full">
                  <label>To</label>
                  <input 
                    type="date" 
                    value={to} 
                    onChange={(e) => setTo(e.target.value)} 
                    disabled={currentlyStudying}
                    className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.to ? 'border-red-500' : ''} ${currentlyStudying ? 'bg-[#f0f0f0] cursor-not-allowed' : ''}`}
                  />
                </div>
              </div>
              <div className="flex items-center mb-4">
                <label className="flex items-center text-[14px]">
                  <input type="checkbox" checked={currentlyStudying} onChange={(e) => setCurrentlyStudying(e.target.checked)} className="mr-2" />
                  Currently studying
                </label>
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>Field of Study</label>
                <select 
                  value={fieldOfStudy} 
                  onChange={(e) => setFieldOfStudy(e.target.value)} 
                  className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.fieldOfStudy ? 'border-red-500' : ''}`}
                >
                  <option value="">Select field of study</option>
                  {fieldOfStudyOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>Education Level</label>
                <select 
                  value={educationLevel} 
                  onChange={(e) => setEducationLevel(e.target.value)} 
                  className={`mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full ${errors.educationLevel ? 'border-red-500' : ''}`}
                >
                  <option value="">Select education level</option>
                  {educationLevelOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="flex-1 flex flex-col mb-4 box-border w-full">
                <label>Description</label>
                <textarea 
                  rows={4} 
                  value={description} 
                  onChange={(e) => setDescription(e.target.value)} 
                  className="mt-2 p-3 border border-[#ccc] rounded-[8px] text-[14px] box-border w-full"
                ></textarea>
              </div>
              <div className="flex justify-end gap-2 mt-10 mb-10">
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-[var(--primary-color)] text-white px-6 py-3 rounded-[8px] cursor-pointer hover:bg-[var(--primary-color-hover)]"
                  disabled={loadingSubmit} // Disable while loading
                >
                  <div className="flex justify-center items-center">
                    {loadingSubmit ? (
                      <div className="loader" style={{ width: '24px', height: '24px' }}></div>
                    ) : (
                      isEdit ? "Save Changes" : "Add Education"
                    )}
                  </div>
                </button>
              </div>
            </form>
          </Modal>

          {instructorData.education.map((edu) => (
            <div key={edu._id} className="border border-[var(--black-overlay)] p-6 mb-4 rounded-[16px] bg-white">
              <div className="text-[#1673bb] text-[18px] font-semibold flex justify-between items-center">
                <h3 className="m-0 text-[18px] font-semibold pb-2">{edu.degree}</h3>
                <div className="flex space-x-4">
                  <FontAwesomeIcon icon={faEdit} className="cursor-pointer text-[#1673bb]" onClick={() => handleEdit(edu)} />
                  <FontAwesomeIcon icon={faTrash} className="cursor-pointer text-[#1673bb]" onClick={() => handleDelete(edu._id)} />
                </div>
              </div>
              <p className="text-[14px] text-black font-normal opacity-40 pb-2">{edu.institution}</p>
              <p className="text-[14px] text-black font-normal opacity-40">{formatDate(edu.from)} - {edu.to ? formatDate(edu.to) : 'Present'}</p>
              <p className="text-[14px] text-black font-normal opacity-40 pt-1">Field of Study: {edu.fieldOfStudy}</p>
              <p className="text-[14px] text-black font-normal opacity-40 pt-1">Education Level: {edu.educationLevel}</p>
              <p className="pt-2 text-[14px] overflow-hidden text-ellipsis display-webkit-box webkit-line-clamp-3 webkit-box-orient-vertical whitespace-pre-wrap">{edu.description}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Education;
